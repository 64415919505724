import React from 'react';

const VerticalDots = (props: any) => (
  <svg
    {...props}
    width="5"
    height="19"
    viewBox="0 0 5 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.5" cy="2.5" r="2.5" fill="#1A4346" />
    <circle cx="2.5" cy="9.5" r="2.5" fill="#1A4346" />
    <circle cx="2.5" cy="16.5" r="2.5" fill="#1A4346" />
  </svg>
);

export default VerticalDots;
