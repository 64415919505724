import React from 'react';

const AddFolder = (props: any) => (
  <svg
    {...props}
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.3 2.66667H11.3589L9.05445 0.390667C8.92925 0.26667 8.78048 0.168325 8.61669 0.101282C8.4529 0.0342394 8.2773 -0.000180188 8.1 7.09356e-07H2.7C1.21095 7.09356e-07 0 1.196 0 2.66667V21.3333C0 22.804 1.21095 24 2.7 24H24.3C25.7891 24 27 22.804 27 21.3333V5.33333C27 3.86267 25.7891 2.66667 24.3 2.66667ZM18.9 14.6667H14.85V18.6667H12.15V14.6667H8.1V12H12.15V8H14.85V12H18.9V14.6667Z" />
  </svg>
);

export default AddFolder;
