import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles(({ zIndex }) => ({
  backdrop: {
    zIndex: zIndex.drawer + 1,
    backdropFilter: 'blur(4px)',
  },
}));

export default function LoadingSpinner() {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress size={80} />
    </Backdrop>
  );
}
