import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from '../utils/redux-create-reducer';
import { push } from 'connected-react-router';
import { callApi } from '../utils/helpers';
import { signUpNewUser } from '../utils/api';
import amplitude from '../utils/analytics';
import { authSuccess } from './auth';
import { SIGNUP } from '../actions/auth';
import { notify } from '../actions/notification';

import { Routes } from '../constants';

function* signWorker({ payload: userData }: IAction) {
  try {
    const {
      data: { token, user },
    } = yield call(callApi, signUpNewUser, userData);

    yield call(authSuccess, token, user);
    yield call(amplitude.CREATE_ACCOUNT);
    yield put(push(Routes.Verify));
  } catch (error) {
    console.log(error.message);
    yield call(amplitude.CREATE_ACCOUNT_FAIL);
    yield put(notify(`${userData.email} is already in use!`));
  }
}

export default function* signupSaga() {
  yield takeLatest(SIGNUP, signWorker);
}
