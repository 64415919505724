import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Routes } from '../constants';

import {
  verifyFailure,
  verifySuccess,
  sendCodeSuccess,
  sendCodeFailure,
  SEND_CODE,
  setUser,
} from '../actions/auth';

import {
  parseQueryParams,
  callApi,
  appLoadingPlaceholder,
  redirectTo,
} from '../utils/helpers';

import { notify } from '../actions/notification';
import {
  verifyUser,
  sendVerificationCode,
  verifyNewEmail,
  getUserInfo,
} from '../utils/api';
import amplitude from '../utils/analytics';
import { IAuthState, getAuth } from '../reducers/auth';

export function* verifyWorker(code: string) {
  try {
    yield call(callApi, verifyUser, code);
    amplitude.VERIFY_ACCOUNT();
    yield put(verifySuccess());
    yield put(redirectTo(Routes.Setup));
    yield put(notify('verify-code-success', 'info'));
  } catch (err) {
    yield put(notify('verify-code-failure', 'error'));
    yield put(verifyFailure(err.message));
  }
}

export function* verifyEmail(code: string) {
  try {
    yield call(callApi, verifyNewEmail, code);
    yield put(verifySuccess());
    yield put(redirectTo(Routes.Settings));
    const { data: user } = yield call(getUserInfo);
    yield put(setUser(user));
    yield put(notify('verify-new-email-success'));
  } catch (err) {
    yield put(notify('oops-something-went-wrong', 'error'));
    yield put(verifyFailure(err.message));
  }
}

export function* sendVerCodeWorker() {
  try {
    yield call(callApi, sendVerificationCode);
    yield put(sendCodeSuccess());
    yield put(notify('send-code-success'));
  } catch (err) {
    yield put(sendCodeFailure(err.message));
    yield put(notify('send-code-failure', 'error'));
  }
}

export function* verifyPageWorker() {
  const { authorized, user }: IAuthState = yield select(getAuth);
  const { code, type } = yield call(parseQueryParams, window.location.href);
  if (authorized) {
    if (code) {
      if (type === 'email') {
        yield call(verifyEmail, code);
      } else if (!user.verified) {
        yield call(verifyWorker, code);
      }
    }
  } else {
    if (code) {
      yield put(redirectTo(Routes.Login));
      yield put(notify('login-to-verify', 'info'));
    }
  }
}

export default function* verifySaga() {
  yield takeLatest(SEND_CODE, sendVerCodeWorker);
  while (true) {
    yield call(appLoadingPlaceholder, Routes.Verify, verifyPageWorker);
  }
}
