// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { call, select } from 'redux-saga/effects';
import { belovedListFetcher } from './beloved';
import { foldersFetcher } from './folders';
import { messagesFetcher } from './messages';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';
import { getBelovedState, IBelovedState } from '../reducers/beloved';

export function* messagesFethcher(): any {
  const { selected: selectedBeloved }: IBelovedState = yield select(
    getBelovedState,
  );
  const maybeBelovedList = yield call(belovedListFetcher);

  if (maybeBelovedList.length) {
    const beloved = selectedBeloved || maybeBelovedList[0];
    yield call(foldersFetcher, beloved._id);
    yield call(messagesFetcher, beloved._id);
  }
  return null;
}

export default function* messagesPage() {
  while (true) {
    yield call(
      appLoadingPlaceholder,
      [Routes.Messages, Routes.PlaybackSettings],
      messagesFethcher,
    );
  }
}
