import React from 'react';

interface IProps {
  className?: string;
}

const Shield = (props: IProps) => (
  <svg
    {...props}
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.81818 0L0 4.36364V10.9091C0 16.9636 4.18909 22.6255 9.81818 24C15.4473 22.6255 19.6364 16.9636 19.6364 10.9091V4.36364L9.81818 0ZM8.72727 6.54545H10.9091V8.72727H8.72727V6.54545ZM8.72727 10.9091H10.9091V17.4545H8.72727V10.9091Z" />
  </svg>
);

export default Shield;
