import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import useModalStyles from './styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FolderIcon from '@material-ui/icons/Folder';

import { Checkbox, makeStyles } from '@material-ui/core';
import { IFolder, IFolderListOrder, IMessage } from '../../types.d';
import debounce from 'lodash/debounce';

const useStyles = makeStyles(({ greenDark, mainBlue }) => ({
  modalContent: {
    padding: 0,
    '& .Mui-checked': {
      color: mainBlue,
    },
  },
  icon: {
    fill: greenDark,
    verticalAlign: 'middle',
  },
  edit: {
    marginTop: 3,
  },
}));

interface IProps {
  isOpen: boolean;
  folders: IFolder[];
  message: IMessage;
  onClose: () => any;
  onFavorite: (message: IMessage) => any;
  onShowInFolders: (message: IMessage) => any;
}

export default function MessageInFoldersModal({
  message,
  isOpen,
  folders,
  onClose,
  onFavorite,
  onShowInFolders,
}: IProps) {
  const classes = {
    ...useModalStyles(),
    ...useStyles(),
  };
  const [t] = useTranslation();
  const [selectedFolders, setSelectedFolders] = useState(
    message.folderListOrder,
  );
  const [isFavorite, setFavorite] = useState(message.favorite);

  const updateFolder = debounce((folderListOrder: IFolderListOrder[]) => {
    onShowInFolders({ ...message, folderListOrder });
  }, 500);

  const updateFavorite = debounce((favorite: boolean) => {
    onFavorite({ ...message, favorite });
  }, 500);

  const makeFavorite = () => {
    setFavorite(!isFavorite);
    updateFavorite(!isFavorite);
  };

  const isFolderChecked = (folder: IFolder) =>
    selectedFolders.some((a) => folder._id === a._id);

  const updateFolderVisibility = (folder: IFolder) => {
    let updated = selectedFolders;
    if (!isFolderChecked(folder)) {
      updated = [...selectedFolders, { ...folder, orderNumber: 0 }];
    } else {
      updated = selectedFolders.filter((a) => a._id !== folder._id);
    }
    setSelectedFolders(updated);
    updateFolder(updated);
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography color="textPrimary" variant="h6">
          {t('show-in-folders')}:
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Box>
          <Box
            onClick={makeFavorite}
            display="flex"
            justifyContent="space-between"
          >
            <Box py={1} display="flex" alignItems="center">
              <Box pl={2} mr={2}>
                <FavoriteIcon className={classes.icon} />
              </Box>
              <Box>
                <Text>{t('favorites')}</Text>
              </Box>
            </Box>
            <Box mr={1} display="flex">
              <Checkbox checked={isFavorite} />
            </Box>
          </Box>
          {folders.map((folder) => (
            <Box
              onClick={() => updateFolderVisibility(folder)}
              key={folder._id}
              display="flex"
              justifyContent="space-between"
            >
              <Box py={1} display="flex" alignItems="center">
                <Box pl={2} mr={2}>
                  <FolderIcon className={classes.icon} color="primary" />
                </Box>
                <Box>
                  <Text>{folder.name}</Text>
                </Box>
              </Box>
              <Box mr={1} display="flex">
                <Checkbox checked={isFolderChecked(folder)} />
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
