import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import ResetCodeIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import { VerticalDotsIcon, TrashIcon, EditIcon } from '../icons';
import { BelovedActions } from '../../types.d';
import Dropdown, { Item } from '../MultilevelDropdown';

export interface IBelovedMenuProps {
  onSelect: (type: BelovedActions) => void;
}

export default function BelovedMenu({ onSelect }: IBelovedMenuProps) {
  const classes = useStyles();
  const [t] = useTranslation();

  const [key, setKey] = useState(Math.random());

  return (
    <Dropdown
      key={key}
      button={
        <IconButton>
          <VerticalDotsIcon alt="ok" />
        </IconButton>
      }
    >
      <Item
        className={classes.actionsItem}
        onClick={() => {
          onSelect(BelovedActions.delete);
          setKey(Math.random());
        }}
      >
        <span className={'actionsItemIcons'}>
          <TrashIcon />
          <span>{t('delete')}</span>
        </span>
      </Item>
      <Item
        className={classes.actionsItem}
        onClick={() => {
          onSelect(BelovedActions.editName);
          setKey(Math.random());
        }}
      >
        <span className={'actionsItemIcons'}>
          <EditIcon />
          <span>{t('edit-name')}</span>
        </span>
      </Item>
      <Item
        className={classes.actionsItem}
        onClick={() => {
          onSelect(BelovedActions.resetRecordingCode);
          setKey(Math.random());
        }}
      >
        <span className={'resetCode'}>
          <ResetCodeIcon />
          <span>{t('reset-recording-code')}</span>
        </span>
      </Item>
      <Item
        className={classes.actionsItem}
        onClick={() => {
          onSelect(BelovedActions.resetPlaybackCode);
          setKey(Math.random());
        }}
      >
        <span className={'resetCode'}>
          <ResetCodeIcon />
          <span>{t('reset-playback-code')}</span>
        </span>
      </Item>
    </Dropdown>
  );
}
