import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CssBaseline } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.mainBlue,
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <BusinessIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Privacy Policy
        </Typography>
        <Typography variant="body1">
          <h2>PRIVACY POLICY</h2>
          Revised: 03/29/2021 <br />
          <p>
            Thank you for choosing to be part of our community at Sound of Your
            Love, doing business as SoYL (“SoYL”, “we”, “us”, or “our”). We are
            committed to protecting your personal information and your right to
            privacy. If you have any questions or concerns about our policy, or
            our practices with regards to your personal information, please
            contact us at 
            <a href="mailto:support@soundofyourlove.com">
              support@soundofyourlove.com
            </a>
            .
          </p>
          <p>
            When you visit{' '}
            <a href="http://www.soundofyourlove.com">our website</a> and use our
            services, you trust us with your personal information. We take your
            privacy very seriously. In this privacy policy, we seek to explain
            to you in the clearest way possible what information we collect, how
            we use it and what rights you have in relation to it. We hope you
            take some time to read through it carefully, as it is important. If
            there are any terms in this privacy policy that you do not agree
            with, please discontinue use of our sites and our services.
          </p>
          <p>
            This privacy policy applies to all information collected through{' '}
            <a href="http://www.soundofyourlove.com">our website</a>, any
            related services, sales, marketing or events (we refer to them
            collectively in this privacy policy as the &quot;
            <strong>Services</strong>&quot;).
          </p>
          <p>
            <strong>
              Please read this privacy policy carefully as it will help you make
              informed decisions about sharing your personal information with
              us.
            </strong>
          </p>
          <p>
            <h2>TABLE OF CONTENTS</h2>
            <ol>
              <li>
                <a href="#whatWeCollect">WHAT INFORMATION DO WE COLLECT?</a>
              </li>
              <li>
                <a href="#howWeUse">HOW DO WE USE YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#infoShare">
                  WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </a>
              </li>
              <li>
                <a href="#howLong">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#infoSafe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
              </li>
              <li>
                <a href="#minors">DO WE COLLECT INFORMATION FROM MINORS?</a>
              </li>
              <li>
                <a href="#privacyRights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="#dataBreach">DATA BREACH</a>
              </li>
              <li>
                <a href="#doNotTrack">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </li>
              <li>
                <a href="#caResidents">
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </li>
              <li>
                <a href="#policyUpdates">DO WE MAKE UPDATES TO THIS POLICY?</a>
              </li>
              <li>
                <a href="#howToContact">
                  HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </a>
              </li>
            </ol>
          </p>
          <p>
            <ol>
              <li>
                <h3 id="whatWeCollect">WHAT INFORMATION DO WE COLLECT?</h3>
                <h4>Personal information you disclose to us</h4>
                <p>
                  <em>
                    <strong>In Short: </strong>We collect personal information
                    that you provide to us.
                  </em>
                </p>
                <p>
                  We collect personal information that you voluntarily provide
                  to us when registering for our Services, expressing an
                  interest in obtaining information about us or our products and
                  services, when participating in activities on our Services or
                  otherwise contacting us.
                </p>
                <p>
                  The personal information that we collect depends on the
                  context of your interactions with us and our Services, the
                  choices you make and the products and features you use. The
                  personal information we collect can include the following:
                </p>
                <p>
                  <strong>Personal Information Provided by You.</strong> We
                  collect first name, last name, email addresses, passwords; and
                  other similar data.
                </p>
                <p>
                  All personal information that you provide to us must be true,
                  complete and accurate, and you must notify us of any changes
                  to such personal information.
                </p>
                <h4>Information automatically collected</h4>
                <p>
                  <em>
                    <strong>In Short: </strong>Some information — such as IP
                    address and/or browser and device characteristics — may be
                    collected automatically when you visit our Services.
                  </em>
                </p>
                <p>
                  We may automatically collect certain information when you
                  visit, use or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes. Please read our &quot;
                  <a href="/cookie-policy">Cookie Policy</a>&quot; for more
                  information.
                </p>
              </li>
              <li>
                <h3 id="howWeUse">HOW DO WE USE YOUR INFORMATION?</h3>
                <p>
                  <em>
                    <strong>In Short: </strong>We process your information for
                    purposes based on legitimate business interests, the
                    fulfillment of our contract with you, compliance with our
                    legal obligations, and/or your consent.
                  </em>
                </p>
                <p>
                  We use personal information collected via our Services for a
                  variety of business purposes described below. We process your
                  personal information for these purposes in reliance on our
                  legitimate business interests, in order to enter into or
                  perform a contract with you, with your consent, and/or for
                  compliance with our legal obligations. We indicate the
                  specific processing grounds we rely on next to each purpose
                  listed below.
                </p>
                <p>
                  Below are some definitions that will help you understand the
                  roles and responsibilities of Sound of Your Love:
                </p>
                <ul>
                  <li>
                    “data controller” means a person who (either alone or
                    jointly or in common with other persons) determines the
                    purposes for which and the manner in which any personal
                    information are, or are to be used.
                  </li>
                  <li>
                    “data processor”, in relation to personal information, means
                    any person (other than an employee of the data controller)
                    who processes the data on behalf of the data controller.
                  </li>
                </ul>
                <p>
                  If you provide the data and the instructions, then you are the
                  data controller and Sound of Your Love is the data processor.
                </p>
                <p>
                  If we determine the purposes for which we collect and use your
                  personal information, then we are the Controller.
                </p>
                <p>We use the information we collect or receive:</p>
                <ul>
                  <li>
                    <strong>
                      To facilitate account creation and logon process.
                    </strong>
                  </li>
                  <li>
                    <strong>
                      To enforce our terms, conditions and policies for Business
                      Purposes, Legal Reasons and Contractual.
                    </strong>
                  </li>
                  <li>
                    <strong>
                      To respond to legal requests and prevent harm.{' '}
                    </strong>
                    If we receive a subpoena or other legal request, we may need
                    to inspect the data we hold to determine how to respond.
                  </li>
                  <li>
                    <strong>To manage user accounts. </strong>We may use your
                    information for the purposes of managing our account and
                    keeping it in working order.
                  </li>
                  <li>
                    <strong>To deliver services to the user. </strong>We may use
                    your information to provide you with the requested service.
                  </li>
                  <li>
                    <strong>
                      To respond to user inquiries/offer support to users.{' '}
                    </strong>
                    We may use your information to respond to your inquiries and
                    solve any potential issues you might have with the use of
                    our Services.
                  </li>
                  <li>
                    <strong>For other Business Purposes. </strong>We may use
                    your information for other Business Purposes, such as data
                    analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to evaluate
                    and improve our Services, products, marketing and your
                    experience. We may use and store this information in
                    aggregated and anonymized form so that it is not associated
                    with individual end users and does not include personal
                    information. We will not use identifiable personal
                    information without your consent. Please read our &quot;
                    <a href="/cookie-policy">Cookie Policy</a>&quot; for more
                    information.
                  </li>
                </ul>
              </li>
              <li>
                <h3 id="infoShare">
                  WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </h3>
                <p>
                  <em>
                    <strong>In Short: </strong>We only share information with
                    your consent, to comply with laws, to provide you with
                    services, to protect your rights, or to fulfill business
                    obligations.
                  </em>
                </p>
                <p>
                  We may process or share data based on the following legal
                  basis:
                </p>
                <ul>
                  <li>
                    <strong>Consent: </strong>We may process your data if you
                    have given us specific consent to use your personal
                    information in a specific purpose.
                  </li>
                  <li>
                    <strong>Legitimate Interests: </strong>We may process your
                    data when it is reasonably necessary to achieve our
                    legitimate business interests.
                  </li>
                  <li>
                    <strong>Performance of a Contract: </strong>Where we have
                    entered into a contract with you, we may process your
                    personal information to fulfill the terms of our contract.
                  </li>
                  <li>
                    <strong>Legal Obligations: </strong>We may disclose your
                    information where we are legally required to do so in order
                    to comply with applicable law, governmental requests, a
                    judicial proceeding, court order, or legal process, such as
                    in response to a court order or a subpoena (including in
                    response to public authorities to meet national security or
                    law enforcement requirements).
                  </li>
                  <li>
                    <strong>Vital Interests: </strong>We may disclose your
                    information where we believe it is necessary to investigate,
                    prevent, or take action regarding potential violations of
                    our policies, suspected fraud, situations involving
                    potential threats to the safety of any person and illegal
                    activities, or as evidence in litigation in which we are
                    involved.
                  </li>
                </ul>
                <p>
                  More specifically, we may need to process your data or share
                  your personal information in the following situations:
                </p>
                <ul>
                  <li>
                    <strong>Business Transfers. </strong>We may share or
                    transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets,
                    financing, or acquisition of all or a portion of our
                    business to another company.
                  </li>
                </ul>
              </li>
              <li>
                <h3 id="howLong">HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
                <p>
                  <em>
                    <strong>In Short: </strong>We keep your information for as
                    long as necessary to fulfill the purposes outlined in
                    this privacy policy unless otherwise required by law.
                  </em>
                </p>
                <p>
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this privacy policy,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting or other legal requirements). No
                  purpose in this policy will require us keeping your personal
                  information for longer than the period of time in which users
                  have an account with us.
                </p>
                <p>
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  it, or, if this is not possible (for example, because your
                  personal information has been stored in backup archives), then
                  we will securely store your personal information and isolate
                  it from any further processing until deletion is possible.
                </p>
              </li>
              <li>
                <h3 id="infoSafe">HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
                <p>
                  <em>
                    <strong>In Short: </strong>We aim to protect your personal
                    information through a system of organizational and technical
                    security measures.
                  </em>
                </p>
                <p>
                  We have implemented appropriate technical and organizational
                  security measures designed to protect the security of any
                  personal information we process. However, please also remember
                  that we cannot guarantee that the internet itself is 100%
                  secure. Although we will do our best to protect your personal
                  information, transmission of personal information to and from
                  our Services is at your own risk. You should only access the
                  services within a secure environment.
                </p>
              </li>
              <li>
                <h3 id="minors">DO WE COLLECT INFORMATION FROM MINORS?</h3>
                <p>
                  <em>
                    <strong>In Short: </strong>We do not knowingly collect data
                    from or market to children under 18 years of age.
                  </em>
                </p>
                <p>
                  We do not knowingly solicit data from or market to children
                  under 18 years of age. By using the Services, you represent
                  that you are at least 18 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent’s
                  use of the Services. If we learn that personal information
                  from users less than 18 years of age has been collected, we
                  will deactivate the account and take reasonable measures to
                  promptly delete such data from our records. If you become
                  aware of any data we have collected from children under age
                  18, please contact us at 
                  <a href="mailto:support@soundofyourlove.com">
                    support@soundofyourlove.com
                  </a>
                  .
                </p>
              </li>
              <li>
                <h3 id="privacyRights">WHAT ARE YOUR PRIVACY RIGHTS?</h3>
                <p>
                  <em>
                    <strong>In Short: </strong>In some regions, such as the
                    European Economic Area, you have rights that allow you
                    greater access to and control over your personal
                    information. You may review, change, or terminate your
                    account at any time.
                  </em>
                </p>
                <p>
                  In some regions (like the European Economic Area), you have
                  certain rights under applicable data protection laws. These
                  may include the right (i) to request access and obtain a copy
                  of your personal information, (ii) to request rectification or
                  erasure; (iii) to restrict the processing of your personal
                  information; and (iv) if applicable, to data portability. In
                  certain circumstances, you may also have the right to object
                  to the processing of your personal information. To make such a
                  request, please use the{' '}
                  <a href="#howToContact">contact details</a> provided below. We
                  will consider and act upon any request in accordance with
                  applicable data protection laws.
                </p>
                <p>
                  If we are relying on your consent to process your personal
                  information, you have the right to withdraw your consent at
                  any time by managing your settings from the{' '}
                  <a href="/cookie-policy">Cookie Policy</a> page. Please note
                  however that this will not affect the lawfulness of the
                  processing before its withdrawal.
                </p>
                <p>
                  If you are resident in the European Economic Area and you
                  believe we are unlawfully processing your personal
                  information, you also have the right to complain to your local
                  data protection supervisory authority. You can find their
                  contact details here:{' '}
                  <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                  </a>
                  .
                </p>
                <p>
                  If you have questions or comments about your privacy rights,
                  you may email us at 
                  <a href="mailto:support@soundofyourlove.com">
                    support@soundofyourlove.com
                  </a>
                  .
                </p>
                <h4>Account Information</h4>
                <p>
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                </p>
                <ul>
                  <li>
                    Log into your account settings and update your user account.
                  </li>
                  <li>Contact us using the contact information provided.</li>
                </ul>
                <p>
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, some information may be retained in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our Terms of Use and/or comply
                  with legal requirements.
                </p>
                <p>
                  <strong>
                    <u>Opting out of email marketing:</u>
                  </strong>
                   You can unsubscribe from our marketing email list at any time
                  by clicking on the unsubscribe link in the emails that we send
                  or by contacting us using the details provided below. You will
                  then be removed from the marketing email list – however, we
                  will still need to send you service-related emails that are
                  necessary for the administration and use of your account. To
                  otherwise opt-out, you may:
                </p>
                <ul>
                  <li>Access your account settings and update preferences.</li>
                  <li>Contact us using the contact information provided.</li>
                  <li>
                    Note your preferences when you register an account with the
                    site.
                  </li>
                </ul>
              </li>
              <li>
                <h3 id="dataBreach">DATA BREACH</h3>
                <p>
                  A privacy breach occurs when there is unauthorized access to
                  or collection, use, disclosure or disposal of personal
                  information. You will be notified about data breaches
                  when Sound of Your Love believes you are likely to be at risk
                  or serious harm. For example, a data breach may be likely to
                  result in serious financial harm or harm to your mental or
                  physical well- being. In the event that Sound of Your
                  Love becomes aware of a security breach which has resulted or
                  may result in unauthorized access, use or disclosure of
                  personal information Sound of Your Love will promptly
                  investigate the matter and notify the applicable Supervisory
                  Authority not later than 72 hours after having become aware of
                  it, unless the personal data breach is unlikely to result in a
                  risk to the rights and freedoms of natural persons.
                </p>
              </li>
              <li>
                <h3 id="doNotTrack">CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
                <p>
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track (“DNT”) feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. No uniform technology standard for recognizing and
                  implementing DNT signals has been finalized. As such, we do
                  not currently respond to DNT browser signals or any other
                  mechanism that automatically communicates your choice not to
                  be tracked online. If a standard for online tracking is
                  adopted that we must follow in the future, we will inform you
                  about that practice in a revised version of this privacy
                  policy.
                </p>
              </li>
              <li>
                <h3 id="caResidents">
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h3>
                <p>
                  <em>
                    <strong>In Short: </strong>Yes, if you are a resident of
                    California, you are granted specific rights regarding access
                    to your personal information.
                  </em>
                </p>
                <p>
                  California Civil Code Section 1798.83, also known as the
                  “Shine The Light” law, permits our users who are California
                  residents to request and obtain from us, once a year and free
                  of charge, information about categories of personal
                  information (if any) we disclosed to third parties for direct
                  marketing purposes and the names and addresses of all third
                  parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California
                  resident and would like to make such a request, please submit
                  your request in writing to us using the contact information
                  provided below.
                </p>
                <p>
                  If you are under 18 years of age, reside in California, and
                  have a registered account with us, you have the right to
                  request removal of unwanted data that you publicly post on
                  our Services. To request removal of such data, please contact
                  us using the contact information provided below, and include
                  the email address associated with your account and a statement
                  that you reside in California. We will make sure the data is
                  not publicly displayed on our Services, but please be aware
                  that the data may not be completely or comprehensively removed
                  from our systems.
                </p>
              </li>
              <li>
                <h3 id="policyUpdates">DO WE MAKE UPDATES TO THIS POLICY?</h3>
                <p>
                  <em>
                    <strong>In Short: </strong>Yes, we will update this policy
                    as necessary to stay compliant with relevant laws.
                  </em>
                </p>
                <p>
                  We may update this privacy policy from time to time. The
                  updated version will be indicated by an updated “Revised” date
                  and the updated version will be effective as soon as it is
                  accessible. If we make material changes to this privacy
                  policy, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a
                  notification. We encourage you to review this privacy
                  policy frequently to be informed of how we are protecting your
                  information.
                </p>
              </li>
              <li>
                <h3 id="howToContact">
                  HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </h3>
                <p>
                  If you have questions or comments about this policy, you
                  may contact our Data Protection Officer (DPO), Kevin Burgess,
                  by email at 
                  <a href="mailto:support@soundofyourlove.com">
                    support@soundofyourlove.com
                  </a>
                  , or by post to:
                </p>
                <p>
                  Sound of Your Love 3217 Epworth Crescent Oakville, ON L6M 0B3
                  Canada
                </p>
                <p>
                  If you are a resident in the European Economic Area, the
                  &quot;data controller&quot; of your personal information
                  is Sound of Your Love. Sound of Your Love has appointed Kevin
                  Burgess to be its representative in the EEA. You can contact
                  them directly regarding the processing of your information
                  by Sound of Your Love, or by post to:
                </p>
                <p>3217 Epworth Crescent Oakville, ON L6M 0B3 Canada</p>
              </li>
            </ol>
            <h4>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h4>
            <p>
              Based on the laws of some countries, you may have the right to
              request access to the personal information we collect from you,
              change that information, or delete it in some circumstances. To
              request to review, update, or delete your personal information,
              please send an email to{' '}
              <a href="mailto:support@soundofyourlove.com">
                support@soundofyourlove.com
              </a>
              . We will respond to your request within 30 days.
            </p>
          </p>
        </Typography>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
