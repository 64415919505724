/* eslint-disable @typescript-eslint/no-explicit-any */

export interface IState {
  [key: string]: any;
}

export interface IAction<T = any> {
  type: string;
  payload: T;
}

export type Reducer = (payload: any, state: IState, type: string) => IState;

interface IHandlers {
  [key: string]: Reducer;
}

let __DEV__ = false;
try {
  __DEV__ = process.env.NODE_ENV !== 'production';
} catch (e) {}

/**
 * Helper to Create reducer with immutability
 * @example
 * ```javascript
 * // With Helper
 * createReducer({counter: 0}, {
 *  increment: (payload, state) => ({counter: state.counter + 1}), // result - {counter: 1}
 *  decrement: (payload, {counter}) => ({counter: counter - 1}),  // result - {counter: -1}
 *  set: (counter) => ({counter}), // result - {counter: *anyNumber*}
 *  reset: () => ({counter: 0}), // result - {counter: 0}
 *  hello: () => ({hello: 'world'}), // result - {counter: 0, hello: 'world'}
 *  augment: (payload = {world: 'hello'}) => payload, // result - {counter: 0, world: 'hello'}
 * ['*']: (, {counter}) => ({counter: counter + 1}), // will increment counter for every action happened in application
 * });
 * // Without Helper
 * const initialState = {counter: 0};
 *
 * function counterReducer(state = initialState, action) {
 *   if (!(action && action.type)) {
 *     return initialState;
 *   }
 *   switch (action.type) {
 *     case 'increment':
 *       return { ...state, counter: state.counter + 1 };
 *     case 'decrement':
 *       return { ...state, counter: state.counter - 1 };
 *     case 'set':
 *       return { ...state, counter: action.payload };
 *     case 'reset':
 *       return initialState;
 *     case 'hello':
 *       return { ...state, hello: 'world' };
 *     case 'augment':
 *       return { ...state, ...action.payload }; // result - {counter: 0, world: 'hello'}
 *     default:
 *       return initialState;
 *   }
 * }
 * ```
 * @param initialState - initial state of redux branch state
 * @param handlers - object with types and handlers
 */
const createReducer = (initialState: IState = {}, handlers: IHandlers) => {
  if (__DEV__ && handlers['undefined']) {
    console.warn(
      'Reducer contains an "undefined" action type. Have you misspelled a constant?',
    );
  }

  return function reducer(state = initialState, action: IAction) {
    let newState = state;
    if (handlers.hasOwnProperty(action.type)) {
      const result = handlers[action.type](action.payload, state, action.type);
      newState = { ...state, ...result };
    }
    if (handlers['*']) {
      return {
        ...newState,
        ...handlers['*'](action.payload, newState, action.type),
      };
    }
    return newState;
  };
};

export default createReducer;
