import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({
    darkGreen,
    greenGrey,
    blueLight,
    greyLight,
    white,
    greenDark,
    breakpoints,
  }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 500,
      width: '100%',
      [breakpoints.down('xs')]: {
        '&& > *': {
          padding: '0 10px',
        },
      },
    },
    isDragging: {
      outline: `2px dashed ${greenGrey}`,
      '&&': {
        background: white,
      },
      '& > *': {
        opacity: 0.5,
      },
    },
    breadcrumbs: {
      padding: '8px 0',
      display: 'flex',
      '& button': {
        paddingLeft: 0,
      },
      '& svg': {
        fill: darkGreen,
      },
      '& span': {
        textTransform: 'initial',
        color: darkGreen,
        fontSize: 18,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginBottom: 20,
      },
    },
    title: {
      width: 420,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      '& svg': {
        fill: greenDark,
        fontSize: 32,
      },
      '& h1': {
        marginLeft: 12,
      },
    },
    pageDescription: {
      marginBottom: 40,
    },
    hidden: {
      opacity: 0.5,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    loop: {
      display: 'flex',
      alignItems: 'center',
      width: 420,
      marginBottom: 40,
      '& $loopLabel, & $loopSwitch': {
        marginTop: 30,
      },
    },
    loopLabel: {
      marginRight: 10,
    },
    loopSwitch: {
      marginRight: 60,
      [breakpoints.down(400)]: {
        marginRight: 0,
      },
    },
    countInput: {
      width: 200,
    },
    folders: {
      display: 'flex',
      flexWrap: 'wrap',
      [breakpoints.down('sm')]: {
        justifyContent: 'space-around',
      },
      [breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },

    folder: {
      position: 'relative',
      backgroundColor: blueLight,
      width: 250,
      height: 250,
      marginRight: 32,
      marginBottom: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: `1px 4px 10px rgba(26, 67, 70, 0.15)`,
      borderRadius: 4,
      '&&:last-child': {
        marginLeft: 0,
      },
    },
    ceil: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      '& svg': {
        width: 40,
        height: 40,
        fill: greenDark,
      },
    },
    showHide: {
      position: 'absolute',
      top: 19,
      left: 19,
      cursor: 'pointer',
      fill: greenGrey,
    },
    drag: {
      position: 'absolute',
      top: 19,
      right: 19,
      cursor: 'grab',
      '& svg': {
        fill: greenGrey,
      },
    },
    order: {
      position: 'absolute',
      bottom: 16,
      left: 16,
      color: greyLight,
      fontSize: 24,
      lineHeight: '24px',
    },
  }),
  { name: 'MessagesSettingsPage' },
);
