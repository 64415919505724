import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Text from '@material-ui/core/Typography';
import Validator from 'validator';
import PasswordInput from '../../components/PasswordInput';

import { update, SettingsActionTypes } from '../../actions/settings';
import useStyles from './styles';

export const Password = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const init = {
    currentPassword: '',
    password: '',
    passwordConfirm: '',
  };
  const [passwords, setPasswords] = useState(init);
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleChange = (name: keyof typeof init) => (
    evt: ChangeEvent<HTMLInputElement>,
  ) => setPasswords({ ...passwords, [name]: evt.target.value });

  const validate = () => {
    let formValid = true;
    const { currentPassword, password, passwordConfirm } = passwords;
    if (Validator.isEmpty(currentPassword)) {
      setCurrentPasswordError(t('missing-entry'));
      formValid = false;
    }
    if (Validator.isEmpty(password)) {
      setPasswordError(t('missing-entry'));
      formValid = false;
    }
    if (Validator.isEmpty(passwordConfirm)) {
      setConfirmPasswordError(t('missing-entry'));
      formValid = false;
    }
    if (
      !Validator.isLength(password, { min: 7 }) ||
      !Validator.matches(password, /[a-z]/) ||
      !Validator.matches(password, /[A-Z]/) ||
      !Validator.matches(password, /[0-9]/)
    ) {
      setPasswordError(t('password-error'));
      formValid = false;
    } else if (password !== passwordConfirm) {
      setConfirmPasswordError(t('confirm-password-error'));
      formValid = false;
    }
    if (currentPassword === password) {
      setPasswordError(t('same-password-error'));
      formValid = false;
    }
    return formValid;
  };

  const handleUpdateProfile = (type: SettingsActionTypes) => {
    const isValid = validate();
    if (isValid) {
      dispatch(update({ type, passwords }));
    }
  };

  const disabled = !(
    passwords.currentPassword &&
    passwords.password &&
    passwords.passwordConfirm
  );

  return (
    <Grid direction="column" container spacing={3}>
      <Hidden smDown>
        <Grid xs item lg={6}>
          <Text className={classes.title} variant="body1">
            {t('password-settings')}
          </Text>
        </Grid>
      </Hidden>

      <Grid xs item lg={6}>
        <PasswordInput
          className={classes.input}
          name="current-password"
          error={currentPasswordError}
          onChange={handleChange('currentPassword')}
          label={t('current-password')}
          value={passwords.currentPassword}
        />
      </Grid>
      <Grid xs item lg={6}>
        <PasswordInput
          className={classes.input}
          onChange={handleChange('password')}
          name="password"
          error={passwordError}
          label={t('create-password')}
          helperText={t('create-password-hint')}
          value={passwords.password}
        />
      </Grid>
      <Grid xs item lg={6}>
        <PasswordInput
          className={classes.input}
          onChange={handleChange('passwordConfirm')}
          error={confirmPasswordError}
          name="password-confirm"
          label={t('confirm-new-password')}
          value={passwords.passwordConfirm}
        />
      </Grid>
      <Grid xs item lg={6}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={() => handleUpdateProfile('password')}
        >
          {t('update-password')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Password;
