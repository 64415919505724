import { call, put, takeLatest, take, select } from 'redux-saga/effects';
import { IAction } from '../utils/redux-create-reducer';

import {
  showInFolders,
  deleteMessage,
  makeFavorite,
  changeMessageVisibility,
  getBelovedMessages,
  editMessageDescription,
  sortAllMessages,
  sortMessagesInFolders,
} from '../utils/api';

import { callApi, sortMessages } from '../utils/helpers';

import {
  fetch,
  fetchSuccess,
  fetchFailure,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE,
  updateSuccess,
  updateFailure,
  IUpdateParams,
  deleteSuccess,
  sortSuccess,
  SORT,
  ISortParams,
} from '../actions/messages';
import { notify } from '../actions/notification';
import { IMessage } from '../types';
import { getBelovedState, IBelovedState } from '../reducers/beloved';
import amplitude from '../utils/analytics';
const convertToListOrder = (messages: IMessage[]) =>
  messages.map(({ _id }, orderNumber: number) => ({
    _id,
    orderNumber,
  }));

function* fetchWorker({ payload: belovedId }: IAction<string>) {
  try {
    const { data: messages } = yield call(
      callApi,
      getBelovedMessages,
      belovedId,
    );
    yield put(fetchSuccess(sortMessages(messages, 'all')));
  } catch (error) {
    yield put(fetchFailure((error as Error).message));
  }
}

function* sortWorker({ payload: { messages, folder } }: IAction<ISortParams>) {
  try {
    const { selected }: IBelovedState = yield select(getBelovedState);
    if (folder === 'all' || folder === 'favorites') {
      const newMessages =
        folder === 'favorites'
          ? messages.map((m, favoriteListOrder) => ({
              ...m,
              favoriteListOrder,
            }))
          : messages;
      yield call(callApi, sortAllMessages, newMessages);
      amplitude.REORDER_MESSAGE({
        belovedId: selected && selected._id,
        messageId: null,
        playlistId: folder,
      });
      yield put(sortSuccess({ messages: newMessages, folder }));
    } else {
      const listOrder = convertToListOrder(messages);
      yield call(callApi, sortMessagesInFolders, listOrder, folder);
      amplitude.REORDER_MESSAGE({
        belovedId: selected && selected._id,
        messageId: null,
        playlistId: folder,
      });
      const newMessages = messages.map((m: IMessage, orderNumber) => ({
        ...m,
        folderListOrder: m.folderListOrder.map((flo) => ({
          ...flo,
          orderNumber: flo._id === folder ? orderNumber : flo.orderNumber,
        })),
      }));
      yield put(sortSuccess({ messages: newMessages, folder }));
      yield put(notify('sort-messages-success'));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateWorker({ payload: { type, message } }: IAction<IUpdateParams>) {
  try {
    const { selected }: IBelovedState = yield select(getBelovedState);
    switch (type) {
      case 'changeDescription':
        {
          const { data: msg } = yield call(
            callApi,
            editMessageDescription,
            message._id,
            message.description,
          );
          amplitude.EDIT_DESCRIPTION_MESSAGE({
            belovedId: selected && selected._id,
            messageId: message._id,
          });
          yield put(updateSuccess(msg));
        }
        break;
      case 'changeVisibility':
        {
          const { data: msg } = yield call(
            callApi,
            changeMessageVisibility,
            message._id,
            !message.show,
          );
          message.show
            ? amplitude.MUTE_MESSAGE({
                belovedId: selected && selected._id,
                messageId: message._id,
              })
            : amplitude.UNMUTE_MESSAGE({
                belovedId: selected && selected._id,
                messageId: message._id,
              });
          yield put(updateSuccess(msg));
          yield put(
            notify(
              message.show ? 'hide-message-success' : 'show-message-success',
            ),
          );
        }
        break;
      case 'delete':
        {
          const { data: msg } = yield call(callApi, deleteMessage, message._id);
          amplitude.DELETE_MESSAGE({
            belovedId: selected && selected._id,
            messageId: message._id,
          });
          yield put(deleteSuccess(msg));
          yield put(notify('delete-message-success'));
        }
        break;
      case 'makeFavorite':
        {
          const { data: msg } = yield call(
            callApi,
            makeFavorite,
            message._id,
            !message.favorite,
          );
          message.favorite
            ? amplitude.REMOVE_MESSAGE_FROM_FAVORITES({
                belovedId: selected && selected._id,
                messageId: message._id,
              })
            : amplitude.ADD_MESSAGE_TO_FAVORITES({
                belovedId: selected && selected._id,
                messageId: message._id,
              });
          yield put(updateSuccess(msg));
          yield put(
            notify(
              message.favorite
                ? 'remove-from-favorite-success'
                : 'add-to-favorite-success',
            ),
          );
        }

        break;
      case 'showInFolders':
        const folderIds = message.folderListOrder.map((a) => a._id);
        yield call(callApi, showInFolders, message._id, folderIds);
        amplitude.UPDATE_MESSAGE_IN_PLAYLISTS({
          belovedId: selected && selected._id,
          messageId: message._id,
          playlistIds: folderIds,
        });
        yield put(updateSuccess(message));
        yield put(notify('show-in-folders-success'));

        break;
      default:
        yield put(updateFailure('wrong type of update'));
    }
  } catch (error) {
    yield put(updateFailure((error as Error).message));
    console.error(error);
  }
}

export function* messagesFetcher(belovedId: string) {
  // start request
  yield put(fetch(belovedId));

  // wait for response success or fail;
  const { type, payload } = yield take([FETCH_SUCCESS, FETCH_FAILURE]);

  if (type === FETCH_SUCCESS && payload) {
    return payload.length ? payload : [];
  }
  return [];
}

export default function* messagesSaga() {
  yield takeLatest(FETCH, fetchWorker);
  yield takeLatest(SORT, sortWorker);
  yield takeLatest(UPDATE, updateWorker);
}
