import React, { useState } from 'react';
import cx from 'classnames';
import ReactTagsInput from 'react-tagsinput';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  ({ mainBlue, breakpoints, error }) => ({
    inputTagsContainer: {
      '&& .react-tagsinput-input': {
        [breakpoints.down('sm')]: {
          marginTop: 0,
        },
        marginTop: 0,
      },
    },
    inputTagsLabel: {
      color: '#5F7B7E',
      fontSize: 15,
    },
    hidden: {
      opacity: 0,
    },
    focused: {
      '&& .react-tagsinput': {
        borderBottomColor: mainBlue,
      },
    },
    error: {
      '&& $inputTagsLabel': {
        color: error,
      },
      '&& .react-tagsinput': {
        borderBottomColor: error,
      },
    },
  }),
  { name: 'TagsInput' },
);

interface IProps {
  placeholder: string;
  value: string[];
  email?: boolean;
  onChange: (list: string[]) => void;
}

export default function TagsInput({
  onChange,
  value,
  placeholder,
  email,
}: IProps) {
  const classes = useStyles();

  const [focused, setFocus] = useState(false);
  const [hasError, setError] = useState(false);

  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <div
      className={cx(classes.inputTagsContainer, {
        [classes.error]: hasError,
        [classes.focused]: focused,
      })}
    >
      <label className={cx(classes.inputTagsLabel)}>{placeholder}</label>
      <ReactTagsInput
        addKeys={[9, 13, 32, 186]}
        value={value}
        onChange={(val: string[]) => {
          setError(false);
          onChange(val);
        }}
        addOnBlur
        onlyUnique
        inputProps={{
          placeholder: '',
          onFocus: () => setFocus(true),
          onBlur: () => setFocus(false),
        }}
        onChangeInput={() => setError(false)}
        tagProps={{ className: 'react-tagsinput-tag info' }}
        {...(email
          ? {
              validationRegex: emailPattern,
              onValidationReject: () => setError(true),
            }
          : {})}
      />
    </div>
  );
}
