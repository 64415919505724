import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Validator from 'validator';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import amp from '../utils/analytics';
import { resetPassword } from '../utils/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.darkGreen,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: 'linear-gradient(45deg, #F2105A 30%, #F2105A 90%)',
    color: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ResetPassword = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [code, setCode] = useState(null);
  const [passwordCheck, setPasswordCheck] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    numbers: false,
  });
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setCode(query.get('code'));
  }, [location]);

  const validateInputs = () => {
    setPasswordError('');
    setPasswordConfirmError('');
    if (Validator.isEmpty(password)) {
      setPasswordError('Missing entry');
      return false;
    }
    if (Validator.isEmpty(passwordConfirm)) {
      setPasswordConfirmError('Missing entry');
      return false;
    }
    if (
      !lengthValidate(password) ||
      !lowercaseValidate(password) ||
      !uppercaseValidate(password) ||
      !numbersValidate(password)
    ) {
      setPasswordError('Password format is invalid');
      return false;
    }
    if (password !== passwordConfirm) {
      setErrorMessage('Password and confirmation password do not match');
      return false;
    }
    return true;
  };

  const lengthValidate = (value) => {
    return Validator.isLength(value, { min: 7, max: undefined });
  };

  const lowercaseValidate = (value) => {
    return Validator.matches(value, /[a-z]/);
  };

  const uppercaseValidate = (value) => {
    return Validator.matches(value, /[A-Z]/);
  };

  const numbersValidate = (value) => {
    return Validator.matches(value, /[0-9]/);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    if (!validateInputs()) {
      return;
    }
    try {
      const response = await resetPassword(code, password);
      if (response.status === 200) {
        amp.RESET_PASSWORD();
        setSuccessMessage('Password changed successfully.');
      } else if (response.status === 403) {
        amp.RESET_PASSWORD_EXPIRED();
        setErrorMessage('The password reset link is invalid or has expired.');
      } else {
        amp.RESET_PASSWORD_FAIL();
        setErrorMessage('There was a problem resetting your password.');
      }
    } catch (error) {
      setErrorMessage('There was a problem resetting your password.');
    }
  };

  const handleChange = (event) => {
    setPasswordCheck({
      ...passwordCheck,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePasswordChange = (event) => {
    setErrorMessage('');
    setPasswordError('');
    const value = event.target.value;
    setPassword(value);
    setPasswordCheck({
      length: lengthValidate(value),
      lowercase: lowercaseValidate(value),
      uppercase: uppercaseValidate(value),
      numbers: numbersValidate(value),
    });
  };

  const handlePasswordConfirmChange = (event) => {
    setErrorMessage('');
    setPasswordConfirmError('');
    setPasswordConfirm(event.target.value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h6">Reset Password</Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage ? (
          <>
            <Alert severity="success">You password has been reset.</Alert>
            <div className={classes.content}>
              <br />
              <span>You may head over to the </span>
              <Link component={RouterLink} to="/login" variant="body2">
                login page
              </Link>
              <span>
                {' '}
                and enter your new credentials to access your account.
              </span>
            </div>
          </>
        ) : (
          <>
            <p>Enter a new password below to change your password.</p>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={passwordError.length > 0}
                    helperText={passwordError}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handlePasswordChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={passwordConfirmError.length > 0}
                    helperText={passwordConfirmError}
                    variant="outlined"
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Confirm Password"
                    type="password"
                    id="passwordConfirm"
                    autoComplete="current-password"
                    onChange={handlePasswordConfirmChange}
                  />
                </Grid>
              </Grid>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  Your password must contain:
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={passwordCheck.length}
                        checked={passwordCheck.length}
                        onChange={handleChange}
                        name="length"
                      />
                    }
                    label="At least 7 characters"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={passwordCheck.lowercase}
                        checked={passwordCheck.lowercase}
                        onChange={handleChange}
                        name="lowercase"
                      />
                    }
                    label="Lower case letters (a-z)"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={passwordCheck.uppercase}
                        checked={passwordCheck.uppercase}
                        onChange={handleChange}
                        name="uppercase"
                      />
                    }
                    label="Upper case letters (A-Z)"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        disabled={passwordCheck.numbers}
                        checked={passwordCheck.numbers}
                        onChange={handleChange}
                        name="numbers"
                      />
                    }
                    label="Numbers (0-9)"
                  />
                </FormGroup>
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Change
              </Button>
            </form>
          </>
        )}
      </div>
    </Container>
  );
};

export default ResetPassword;
