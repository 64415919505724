import React, { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Text from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useModalStyles from './styles';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import {
  CookieCategory,
  cookieCategoryDescription,
  isCookieCategoryFound,
} from '../../utils/cookies';

const useStyles = makeStyles(({}) => ({
  content: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  cell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

interface IProps {
  cookie: string;
  isOpen: boolean;
  onClose: () => any;
  onSave: (categories: { [key: string]: boolean }) => any;
}

interface ICookieRowProps {
  name: string;
  description: string;
  category: CookieCategory;
  selected: boolean;
  onChange: (category: CookieCategory, value: boolean) => any;
}

function Row({
  name,
  description,
  category,
  selected,
  onChange,
}: ICookieRowProps) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const [checked, setChecked] = useState(selected);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange(category, event.target.checked);
  };

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Text className={classes.content}>{name}</Text>
        </TableCell>
        <TableCell align="right">
          <Switch
            color="primary"
            disabled={category === CookieCategory.Necessary}
            checked={checked}
            onChange={handleChange}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Text className={classes.content}>{description}</Text>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CookieSettings({
  cookie,
  isOpen,
  onClose,
  onSave,
}: IProps) {
  const classes = {
    ...useModalStyles(),
    ...useStyles(),
  };
  const [t] = useTranslation();
  const [cookiePreferences, setCookiePreferences] = useState(() => {
    const initialPreferences = {};
    Object.values(CookieCategory).forEach((category) => {
      initialPreferences[category] =
        category === CookieCategory.Necessary
          ? true
          : cookie !== undefined && isCookieCategoryFound(cookie, category);
    });
    return initialPreferences;
  });

  const onChangePreference = (category: CookieCategory, value: boolean) => {
    setCookiePreferences({
      ...cookiePreferences,
      [category]: value,
    });
  };

  const onSaveCookie = () => {
    onSave(cookiePreferences);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Text color="textPrimary" component="span" variant="h6">
          {t('manage-settings')}
        </Text>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <Text className={classes.content}>{t('cookie-use-summary')}</Text>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableBody>
                {Object.values(CookieCategory).map((category, index) => {
                  const categoryKey = cookieCategoryDescription(category);
                  return (
                    <Row
                      key={`category-${index}`}
                      name={`${t(categoryKey)} Cookies`}
                      description={t(`${categoryKey}-cookies`)}
                      category={category}
                      selected={cookiePreferences[category]}
                      onChange={onChangePreference}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          onClick={onSaveCookie}
          color="primary"
          autoFocus
        >
          {t('save-choices')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
