// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const PLAY = 'PLAYER/PLAY';
export const TICK = 'PLAYER/TICK';
export const STOP = 'PLAYER/STOP';
export const DOWNLOAD = 'PLAYER/DOWNLOAD';
export const LISTEN = 'PLAYER/LISTEN';
export const ENDED = 'PLAYER/ENDED';
export const PAUSE_RESUME = 'PLAYER/PAUSE_RESUME';

export interface IPlayerAction {
  messageId: string;
  recordingId: string;
  description?: string;
  createdAt?: string;
}

export const types = {
  PLAY,
  STOP,
  TICK,
  DOWNLOAD,
  LISTEN,
  PAUSE_RESUME,
  ENDED,
};

export const play = createAction<IPlayerAction>(PLAY);
export const tick = createAction<{ duration: number; currentTime: number }>(
  TICK,
);
export const download = createAction<IPlayerAction>(DOWNLOAD);
export const stop = createEmptyAction(STOP);
export const listen = createAction<string>(LISTEN);
export const pauseResume = createAction<string>(PAUSE_RESUME);
export const ended = createEmptyAction(ENDED);

export default {
  types,
  download,
  tick,
  play,
  stop,
  listen,
  pauseResume,
  ended,
};
