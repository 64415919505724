import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Text from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PageContainer from './PageContainer';
import { useTranslation } from 'react-i18next';
import { Routes, mainEmailContact, socialLinks } from '../constants';
import {
  CookieCategory,
  cookieConsentName,
  saveCookie,
} from '../utils/cookies';
import { useDispatch } from 'react-redux';
import { formatedTranslation, redirectTo } from '../utils/helpers';
import { useCookies } from 'react-cookie';
import CookieSettings from '../components/modal/CookieSettings';

const useStyles = makeStyles(
  ({ darkGreen, blueLight, breakpoints }) => ({
    container: {
      backgroundColor: blueLight,
    },
    root: {
      padding: 32,
      width: '100%',
    },
    top: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      marginBottom: 22,
    },
    bottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      [breakpoints.up('sm')]: {
        padding: 20,
      },
      '&& *': {
        [breakpoints.down('sm')]: {
          textAlign: 'center',
        },
        fontWeight: 400,
        fontSize: 12,
        color: darkGreen,
      },
    },
    defaultSection: {
      width: '33%',
      [breakpoints.down('xs')]: {
        width: '50%',
      },
      display: 'flex',
      flexDirection: 'column',
      '&& section > div': {
        display: 'flex',
        flexWrap: 'wrap',
      },
      '&& section': {
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        [breakpoints.down('xs')]: {
          flexDirection: 'column-reverse',
        },
      },
    },
    socialSection: {
      width: '33%',
      [breakpoints.down('xs')]: {
        width: '100%',
        marginTop: 14,
      },
    },
    sectionHeader: {
      paddingBottom: 14,
      [breakpoints.down('xs')]: {
        paddingBottom: 0,
      },
    },
    socialSectionHeader: {
      paddingBottom: 14,
    },
    socialLinks: {
      '& a': {
        marginRight: 10,
      },
    },
    copyright: {},
    sectionLink: {
      marginTop: 8,
      [breakpoints.down('xs')]: {
        marginBottom: 8,
      },
      '&& a': {
        fontWeight: 600,
      },
    },
    consent: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#353535',
      color: 'white',
      left: '0',
      position: 'fixed',
      width: '100%',
      zIndex: 999,
      bottom: 0,
      '& a': {
        color: 'white',
      },
      '& p': {
        color: 'white',
      },
      padding: 10,
    },
    consentButtons: {
      display: 'flex',
      width: '70%',
      [breakpoints.down('md')]: {
        width: '90%',
      },
      marginTop: 10,
      '& button': {
        marginLeft: 10,
        marginRight: 10,
        flex: 1,
      },
    },
  }),
  { name: 'Footer' },
);

const Footer = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([cookieConsentName]);
  const [isPreferencesOpen, setPreferencesOpen] = useState(false);

  const cookiePolicyLink = {
    tag: 'a',
    value: '',
    props: {
      href: Routes.CookiePolicy,
      onClick: (evt: any) => {
        evt.preventDefault();
        dispatch(redirectTo(Routes.CookiePolicy));
      },
    },
  };

  const cookieText = formatedTranslation('cookie-use', [cookiePolicyLink]);

  const onAccept = () => {
    const allCategories = {};
    Object.values(CookieCategory).forEach((category) => {
      if (category !== CookieCategory.Necessary) {
        allCategories[category] = true;
      }
    });
    saveCookie(allCategories, setCookie);
  };

  const onPreferencesSave = (categories: { [key: string]: boolean }) => {
    setPreferencesOpen(false);
    saveCookie(categories, setCookie);
  };

  const onPreferencesOpen = () => {
    setPreferencesOpen(true);
  };

  const onPreferencesClose = () => {
    setPreferencesOpen(false);
  };

  const showConsent = cookies[cookieConsentName] === undefined;

  return (
    <PageContainer className={classes.container}>
      <div className={classes.root}>
        <div className={classes.top}>
          <div className={classes.defaultSection}>
            <Text className={classes.sectionHeader} variant="h5">
              {t('support')}
            </Text>
            <section>
              <div className={classes.sectionLink}>
                <Link
                  color="secondary"
                  href={`mailto:${mainEmailContact}`}
                  variant="body2"
                  data-cy="contactLink"
                >
                  {t('contact-us')}
                </Link>
              </div>
              <div className={classes.sectionLink}>
                <Link
                  color="secondary"
                  href={Routes.FAQ}
                  variant="body2"
                  data-cy="faqLink"
                >
                  {t('faq')}
                </Link>
              </div>
              <div className={classes.sectionLink}>
                <Link
                  color="secondary"
                  href={Routes.Donate}
                  variant="body2"
                  data-cy="donateLink"
                >
                  {t('donate')}
                </Link>
              </div>
            </section>
          </div>
          <div className={classes.defaultSection}>
            <Text className={classes.sectionHeader} variant="h5">
              {t('company')}
            </Text>
            <section>
              <div className={classes.sectionLink}>
                <Link
                  color="secondary"
                  href="/docs/soyl_press_release_2020_12_14.pdf"
                  variant="body2"
                  data-cy="pressLink"
                >
                  {t('press')}
                </Link>
              </div>
            </section>
          </div>
          <div className={classes.socialSection}>
            <Text className={classes.socialSectionHeader} variant="h5">
              {t('spread-the-word')}
            </Text>
            <div className={classes.socialLinks}>
              <Link
                target="_blank"
                color="secondary"
                href={socialLinks.facebook}
                variant="body2"
              >
                <FacebookIcon />
              </Link>
              <Link
                target="_blank"
                color="secondary"
                href={socialLinks.instagram}
                variant="body2"
              >
                <InstagramIcon />
              </Link>
              <Link
                target="_blank"
                color="secondary"
                href={socialLinks.twitter}
                variant="body2"
              >
                <TwitterIcon />
              </Link>
              <Link
                target="_blank"
                color="secondary"
                href={socialLinks.youtube}
                variant="body2"
              >
                <YouTubeIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.bottom}>
          <Text component="span" variant="body2">
            <Link
              color="secondary"
              href={Routes.Home}
              variant="body2"
              data-cy="homeLink"
            >
              &copy; Sound of Your Love {new Date().getFullYear()}.
            </Link>{' '}
            <Link
              color="secondary"
              target="_blank"
              href="https://faithtech.com"
              variant="body2"
              data-cy="FaithLink"
            >
              A FaithTech Labs company.
            </Link>
            {' All rights reserved. | '}
            <Link
              color="secondary"
              href={Routes.TermsOfService}
              variant="body2"
              data-cy="tosLink"
            >
              {t('terms-of-use')}
            </Link>
            {' | '}
            <Link
              color="secondary"
              href={Routes.PrivacyPolicy}
              variant="body2"
              data-cy="privacyPolicyLink"
            >
              {t('privacy')}
            </Link>
            {' | '}
            <Link
              color="secondary"
              href={Routes.CookiePolicy}
              variant="body2"
              data-cy="cookiePolicyLink"
            >
              {t('cookies')}
            </Link>
          </Text>
        </div>
      </div>
      {showConsent && (
        <>
          <div className={classes.consent}>
            <Text variant="body2">{cookieText}</Text>
            <div className={classes.consentButtons}>
              <Button
                onClick={onPreferencesOpen}
                color="primary"
                variant="outlined"
              >
                {t('manage-cookies')}
              </Button>
              <Button onClick={onAccept} color="primary" variant="contained">
                {t('accept-cookies')}
              </Button>
            </div>
          </div>
          <CookieSettings
            cookie={cookies[cookieConsentName]}
            isOpen={isPreferencesOpen}
            onClose={onPreferencesClose}
            onSave={onPreferencesSave}
          />
        </>
      )}
    </PageContainer>
  );
};

export default Footer;
