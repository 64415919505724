import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import BillingPlan from '../../components/BillingPlan';

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    root: {
      '& .MuiGrid-spacing-xs-10': {
        textAlign: 'center',
        marginTop: 0,
        [breakpoints.up('lg')]: {
          margin: 0,
        },
      },
    },
  }),
  { name: 'SettingsMyPlan' },
);

export const MyPlan = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BillingPlan currentPlan="basic" />
    </div>
  );
};

export default MyPlan;
