import React from 'react';

interface IProps {
  className?: string;
}

const Move = (props: IProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.9198 11.8305L20.092 8.80969C20.06 8.7845 20.0215 8.76884 19.981 8.7645C19.9405 8.76017 19.8995 8.76733 19.8629 8.78518C19.8263 8.80303 19.7954 8.83083 19.7739 8.86541C19.7523 8.89998 19.7409 8.93993 19.741 8.98068V10.9216H13.0814V4.26197H15.0253C15.2053 4.26197 15.3073 4.05199 15.1963 3.91099L12.1725 0.0832314C12.1525 0.0573293 12.1268 0.0363589 12.0974 0.0219306C12.068 0.00750221 12.0357 0 12.003 0C11.9703 0 11.938 0.00750221 11.9086 0.0219306C11.8792 0.0363589 11.8535 0.0573293 11.8335 0.0832314L8.80969 3.91099C8.7845 3.94302 8.76884 3.9815 8.7645 4.02201C8.76017 4.06253 8.76733 4.10345 8.78518 4.14008C8.80303 4.17671 8.83083 4.20757 8.86541 4.22913C8.89998 4.25069 8.93993 4.26207 8.98068 4.26197H10.9216V10.9216H4.26197V8.97768C4.26197 8.79769 4.05199 8.6957 3.91099 8.80669L0.0832314 11.8305C0.0573293 11.8505 0.0363589 11.8762 0.0219306 11.9056C0.00750221 11.935 0 11.9673 0 12C0 12.0327 0.00750221 12.065 0.0219306 12.0944C0.0363589 12.1238 0.0573293 12.1495 0.0832314 12.1695L3.90799 15.1933C4.04899 15.3043 4.25897 15.2053 4.25897 15.0223V13.0814H10.9186V19.741H8.97468C8.79469 19.741 8.6927 19.951 8.80369 20.092L11.8275 23.9168C11.9145 24.0277 12.0825 24.0277 12.1665 23.9168L15.1903 20.092C15.3013 19.951 15.2023 19.741 15.0193 19.741H13.0814V13.0814H19.741V15.0253C19.741 15.2053 19.951 15.3073 20.092 15.1963L23.9168 12.1725C23.9426 12.1522 23.9635 12.1264 23.978 12.0969C23.9925 12.0674 24.0001 12.0351 24.0004 12.0022C24.0007 11.9694 23.9936 11.9369 23.9797 11.9072C23.9657 11.8774 23.9452 11.8512 23.9198 11.8305Z" />
  </svg>
);

export default Move;
