import { createAction, createEmptyAction } from '../utils/actions';
import { IMessage } from '../types';

export const FETCH_MESSAGES = 'PLAYBACK/FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'PLAYBACK/FETCH_MESSAGES_SUCCESS ';
export const FETCH_MESSAGES_FAILURE = 'PLAYBACK/FETCH_MESSAGES_FAILURE';

export const TOGGLE_LOOP = 'PLAYBACK/TOGGLE_LOOPING';
export const SET_PAGE = 'PLAYBACK/SET_PAGE';

export const types = {
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  TOGGLE_LOOP,
  SET_PAGE,
};

export interface IFetchMessagesAction {
  invitationCode: number;
  playbackPasscode: number;
}

export const fetchMessages = createAction<IFetchMessagesAction>(FETCH_MESSAGES);
export const fetchMessagesSuccess = createAction<IMessage[]>(
  FETCH_MESSAGES_SUCCESS,
);
export const fetchMessagesFailure = createAction<string>(
  FETCH_MESSAGES_FAILURE,
);
export const toggleLoop = createEmptyAction(TOGGLE_LOOP);
export const setPage = createAction<number>(SET_PAGE);

export default {
  types,
  fetchMessages,
  fetchMessagesSuccess,
  fetchMessagesFailure,
  toggleLoop,
  setPage,
};
