// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { NOTIFY, CLOSE } from '../actions/notification';

export interface INotificationState {
  isOpen: boolean;
  message: string;
  duration: number;
  position: SnackbarOrigin;
  type: 'success' | 'error' | 'warning' | 'info';
  messageVars: Record<string, any>;
}

interface IState {
  notification: INotificationState;
}

export const initialState: INotificationState = {
  isOpen: false,
  message: 'Invitation has been sent',
  duration: 6000,
  position: {
    vertical: 'top',
    horizontal: 'right',
  },
  type: 'info',
  messageVars: {},
};

export const getNotification = (state: IState): INotificationState =>
  state.notification;

export default createReducer(initialState, {
  [NOTIFY]: ({ duration, type, message }) => ({
    isOpen: true,
    type,
    message,
    duration: duration !== undefined ? duration : initialState.duration,
  }),
  [CLOSE]: () => ({ isOpen: false }),
});
