import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { IMessage } from '../../types.d';
import useStyles from './styles';
import { IPlayer } from '../../reducers/player';
import { PlayPauseIcon } from '../icons';
import { convertSecondsToTime } from '../../utils/helpers';
export type PlayFunc = (message: IMessage) => any;

interface IMessagesTableRow {
  message: IMessage;
  onPlayStop: PlayFunc;
  playerInfo: IPlayer;
}

const MessagesTableRow = ({
  message,
  onPlayStop,
  playerInfo,
}: IMessagesTableRow) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [msg, setMsg] = useState(message);
  const { _id, createdAt, description, duration } = msg;
  const isPlaying =
    playerInfo.status === 'playing' && playerInfo.messageId === _id;
  const isActive =
    (playerInfo.status === 'playing' || playerInfo.status === 'paused') &&
    playerInfo.messageId === _id;
  const createdTime = moment(createdAt).fromNow();

  useEffect(() => {
    setMsg(message);
  }, [message]);

  const isLoading = isPlaying && !playerInfo.currentTime;

  const playEl = (
    <Tooltip
      title={
        (isLoading
          ? t('loading')
          : isPlaying
          ? t('pause')
          : t('play')) as string
      }
    >
      <IconButton
        className={classes.playButton}
        onClick={() => onPlayStop(msg)}
        tabIndex={0}
        aria-label={t('play')}
      >
        {isLoading ? (
          <CircularProgress size={32} />
        ) : (
          <PlayPauseIcon active={isPlaying} />
        )}
      </IconButton>
    </Tooltip>
  );

  const createdAtEl = (
    <div aria-label={`${t('created-at')} ${createdTime}`} tabIndex={0}>
      {createdTime}
    </div>
  );

  const descriptionEl = (
    <div aria-label={description} tabIndex={0} className={classes.description}>
      {description}
    </div>
  );

  const tickTime = convertSecondsToTime(
    Math.ceil(playerInfo.duration || 0) - Math.ceil(playerInfo.currentTime),
  );

  const durationEl = (
    <div aria-label={`${t('message-duration-is')}:${duration}`} tabIndex={0}>
      {isActive && playerInfo.currentTime
        ? tickTime
        : Number(duration) === 0
        ? '-'
        : convertSecondsToTime(Number(duration))}
    </div>
  );

  return (
    <TableRow className={cx(classes.row, { [classes.isActive]: isActive })}>
      <TableCell
        align="center"
        className={cx(classes.icon, classes.playIcon, classes.cell)}
      >
        {playEl}
      </TableCell>
      <TableCell className={cx(classes.createdAt, classes.cell)} align="center">
        {createdAtEl}
      </TableCell>
      <TableCell
        className={cx(classes.description, classes.cell)}
        align="center"
      >
        {descriptionEl}
      </TableCell>
      <TableCell className={cx(classes.duration, classes.cell)} align="center">
        {durationEl}
      </TableCell>
    </TableRow>
  );
};

export default MessagesTableRow;
