import createReducer from '../utils/redux-create-reducer';
import {
  AUTHORIZE,
  SET_AUTH_ERROR,
  LOGOUT,
  LOGIN,
  SET_USER,
} from '../actions/auth';
import { VERIFY_SUCCESS } from '../actions/verify';
import { IMe } from '../types.d';

export interface IAuthState {
  token: string;
  isLoading: boolean;
  authorized: boolean;
  error?: Error;
  verifyErrorMessage: string;
  user: IMe;
}

export const initialState: IAuthState = {
  token: '',
  error: undefined,
  verifyErrorMessage: '',
  authorized: false,
  isLoading: false,
  user: {
    accountRelatedNotifications: true,
    allNotifications: true,
    firstname: '',
    lastname: '',
    email: '',
    verified: false,
    createdAt: '',
    _id: '',
    optOutAnalytics: false,
  },
};

export const getAuth = (state: { auth: IAuthState }): IAuthState => state.auth;

export default createReducer(initialState, {
  [LOGIN]: () => ({ isLoading: true }),
  [AUTHORIZE]: ({ token, user }: { token: string; user: IMe }) => ({
    user,
    authorized: true,
    isLoading: false,
    token,
    error: undefined,
  }),
  [SET_USER]: (user) => ({ user }),
  [LOGOUT]: () => initialState,
  [SET_AUTH_ERROR]: (error) => ({ error, isLoading: false }),
  [VERIFY_SUCCESS]: (_, { user }) => ({ user: { ...user, verified: true } }),
});
