// use 'npm run g:a {name}' - to generate new action
import { createAction } from '../utils/actions';
import { IMessage, MessageActions } from '../types.d';

export const FETCH = 'MESSAGES/FETCH';
export const FETCH_SUCCESS = 'MESSAGES/FETCH_SUCCESS';
export const FETCH_FAILURE = 'MESSAGES/FETCH_FAILURE';

export const UPDATE = 'MESSAGE/UPDATE';
export const UPDATE_SUCCESS = 'MESSAGES/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'MESSAGES/UPDATE_FAILURE';
export const DELETE_SUCCESS = 'MESSAGES/DELETE_SUCCESS';
export const ADD_SUCCESS = 'MESSAGES/ADD_SUCCESS';
export const SORT = 'MESSAGES/SORT';
export const SORT_SUCCESS = 'MESSAGES/SORT_SUCCESS';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_SUCCESS,
  ADD_SUCCESS,

  SORT,
  SORT_SUCCESS,
};

export interface IUpdateParams {
  type: MessageActions;
  message: IMessage;
}

export interface ISortParams {
  folder: string;
  messages: IMessage[];
}

export const fetch = createAction<string>(FETCH);
export const fetchSuccess = createAction<IMessage[]>(FETCH_SUCCESS);
export const fetchFailure = createAction<string>(FETCH_FAILURE);

export const update = createAction<IUpdateParams>(UPDATE);
export const updateSuccess = createAction<IMessage>(UPDATE_SUCCESS);
export const deleteSuccess = createAction<IMessage>(DELETE_SUCCESS);
export const addSuccess = createAction<IMessage>(ADD_SUCCESS);
export const sortSuccess = createAction<ISortParams>(SORT_SUCCESS);
export const updateFailure = createAction<string>(UPDATE_FAILURE);

export const sort = createAction<ISortParams>(SORT);

export default {
  types,
  fetch,
  fetchSuccess,
  fetchFailure,

  update,
  updateSuccess,
  updateFailure,
  sort,
  sortSuccess,
};
