import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import Text from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import PageContainer from '../../components/PageContainer';
import SettingsMenuModal from '../../components/modal/SettingsMenu';

import {
  StarIcon,
  LockIcon,
  ShieldIcon,
  AccountCloseIcon,
} from '../../components/icons';

import Menu, { SettingsPagesType, ISettingsPage } from './Menu';
import MyPlan from './MyPlan';
import Profile from './Profile';
import Password from './Password';
import NotificationPreferences from './NotificationPreferences';
import Privacy from './Privacy';
import CloseAccount from './CloseAccount';
import { redirectTo } from '../../utils/helpers';
import { Routes } from '../../constants';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles(
  ({ greenDark }) => ({
    root: {
      padding: 10,
    },
    header: {
      padding: '22px 10px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    body: {},
    title: {
      fontSize: 13,
      '& svg': {
        minWidth: 32,
        minHeight: 32,
        fill: greenDark,
      },
      '& h1': {
        marginLeft: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: 'inherit',
        textOverflow: 'ellipsis',
      },
    },
  }),
  { name: 'Settings' },
);

const Settings = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isModalMenuOpen, setModalMenu] = useState(false);

  const pagesData: Record<SettingsPagesType, FC> = {
    profile: Profile,
    myPlan: MyPlan,
    notificationPreferences: NotificationPreferences,
    password: Password,
    privacy: Privacy,
    closeAccount: CloseAccount,
  };

  const menuList: ISettingsPage[] = [
    {
      name: 'profile',
      title: t('profile'),
      icon: PersonIcon,
    },
    // {
    //   name: 'myPlan',
    //   title: t('my-plan'),
    //   icon: CalendarStarIcon,
    // },
    {
      name: 'password',
      title: t('password'),
      icon: LockIcon,
    },
    {
      name: 'notificationPreferences',
      title: t('notification-preferences'),
      icon: NotificationsIcon,
    },
    {
      name: 'privacy',
      title: t('privacy'),
      icon: ShieldIcon,
    },
    {
      name: 'closeAccount',
      title: t('close-account'),
      icon: AccountCloseIcon,
    },
  ];

  const pages = Object.keys(pagesData);

  const setCurrentPage = (name: string) =>
    dispatch(redirectTo(`${Routes.Settings}/${name}`));

  const currentPage = window.location.pathname.replace(
    `${Routes.Settings}`,
    '',
  );
  const currentPageName = currentPage.replace('/', '');

  if (!currentPage) {
    dispatch(redirectTo(`${Routes.Settings}/${pages[0]}`));
  }

  const toggleModalMenu = () => {
    if (isMobile) {
      setModalMenu(!isModalMenuOpen);
    }
  };

  const currentPageItem = menuList.find(
    (item) => item.name === currentPageName,
  );

  return (
    <PageContainer
      sidebar={
        <Menu
          menuList={menuList}
          currentPage={currentPageName}
          onChange={setCurrentPage}
        />
      }
    >
      <Grid className={classes.root} container direction="column">
        <Grid item>
          <Box
            onClick={toggleModalMenu}
            display="flex"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              py={2}
              alignItems="center"
              className={classes.title}
            >
              <Hidden smDown>
                <StarIcon />
              </Hidden>
              <Hidden mdUp>
                {currentPageItem && <currentPageItem.icon />}
              </Hidden>

              <Text component="h1" variant="h1">
                <Hidden smDown>{t('settings')}</Hidden>
                <Hidden mdUp>{currentPageItem && currentPageItem.title}</Hidden>
              </Text>
            </Box>
            <Hidden mdUp>
              <Box display="flex" alignItems="center">
                <DownIcon color="secondary" />
                <SettingsMenuModal
                  isOpen={isModalMenuOpen}
                  onClose={toggleModalMenu}
                  currentPage={currentPage.replace('/', '')}
                  onSelect={setCurrentPage}
                  menuList={menuList}
                />
              </Box>
            </Hidden>
          </Box>
        </Grid>
        <Grid item>
          <Switch>
            {pages.map((pageName) => (
              <Route
                key={pageName}
                path={`${Routes.Settings}/${pageName}`}
                component={pagesData[pageName]}
              />
            ))}
          </Switch>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Settings;
