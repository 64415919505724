import React, { useRef, useState } from 'react';
import cx from 'classnames';
import Carousel from 'react-elastic-carousel';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';
import EditIcon from './icons/Edit';
import { FolderActions, IFolder } from '../types.d';
import { AddFolderIcon } from './icons';

const useStyles = makeStyles(
  ({ mainBlue, white, greenDark }) => ({
    root: {},
    header: {
      display: 'flex',
      justifyContent: 'space-around',
      '&& > div': {
        cursor: 'pointer',
      },
      '&& svg': {
        width: 36,
        height: 36,
        fill: greenDark,
      },
    },
    container: {
      maxHeight: 790,
      paddingTop: 28,
    },
    carousel: {
      '& .rec-arrow-down, .rec-arrow-up, .rec-arrow-left, .rec-arrow-right': {
        display: 'none',
      },
      '& .rec-slider-container': {
        margin: 0,
      },
    },
    folder: {
      borderRadius: 2,
      height: 120,
      '& p': {
        marginTop: 5,
        lineHeight: '22px',
      },
    },
    active: {
      '&&': {
        backgroundColor: mainBlue,
      },
      '& p': {
        color: white,
      },
      '& svg': {
        fill: white,
      },
      '& .carouselMenuLittleButton': {
        display: 'block',
      },
    },
    listItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      background: white,

      '&:hover': {
        backgroundColor: mainBlue,
        '& p': {
          color: white,
        },
        '& svg': {
          fill: white,
        },
        '& .carouselMenuLittleButton': {
          display: 'block',
        },
      },
    },
    arrow: {
      padding: 0,
      transform: 'scaleY(1)',
      transformOrigin: 'top',
      transition: 'transform 0.16s linear',
    },
    arrowHidden: {
      transform: 'scaleY(0)',
      height: 1,
    },
    littleButton: {
      top: 5,
      position: 'absolute',
      padding: 2,
      display: 'none',
      '& svg': {
        fontSize: 22,
      },
    },
    addAlbum: {
      fill: mainBlue,
    },
  }),
  { name: 'CarouselMenu' },
);

export interface ISelectArgs {
  type: 'folder' | 'favorites' | 'all';
  folder?: IFolder;
}

interface ICarouselMenuProps {
  folders: IFolder[];
  selectedFolder: string;
  onAdd: () => any;
  onSelect: (el: ISelectArgs) => any;
  onChange: (type: FolderActions, folder: IFolder) => any;
  itemsToShow?: number;
}

export default function CarouselMenu({
  folders,
  selectedFolder,
  onAdd,
  onSelect,
  onChange,
  itemsToShow = 3,
}: ICarouselMenuProps) {
  const ref = useRef<any>();
  const classes = useStyles();
  const [t] = useTranslation();

  const [currentIndex, setIndex] = useState(0);

  const prev = () => {
    ref.current && ref.current.slidePrev();
  };

  const next = () => {
    ref.current && ref.current.slideNext();
  };

  const showArrows = folders.length > itemsToShow;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ListItem
          onClick={() => onSelect({ type: 'all' })}
          className={cx(classes.listItem, classes.folder, {
            [classes.active]: selectedFolder === 'all',
          })}
          button
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <MenuIcon style={{ fontSize: 36 }} color="secondary" />
            <Text variant="body2" color="secondary">
              {t('all-messages')}
            </Text>
          </Box>
        </ListItem>
        <ListItem
          onClick={() => onSelect({ type: 'favorites' })}
          className={cx(classes.listItem, classes.folder, {
            [classes.active]: selectedFolder === 'favorites',
          })}
          button
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <FavoriteIcon style={{ fontSize: 36 }} color="secondary" />
            <Text variant="body2" color="secondary">
              {t('favorites')}
            </Text>
          </Box>
        </ListItem>

        {showArrows && (
          <Collapse in={currentIndex > 0}>
            <ListItem
              onClick={() => prev()}
              className={cx(classes.listItem, classes.arrow)}
              button
            >
              <Box
                width={100}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <UpIcon style={{ fontSize: 36 }} color="secondary" />\
              </Box>
            </ListItem>
          </Collapse>
        )}
        <Carousel
          infinity
          ref={ref}
          className={classes.carousel}
          verticalMode={true}
          itemsToShow={showArrows ? itemsToShow : folders.length}
          pagination={false}
          onPrevEnd={() =>
            setIndex(currentIndex - 1 === -1 ? 0 : currentIndex - 1)
          }
          onNextEnd={() =>
            setIndex(currentIndex + 1 === 6 ? 6 : currentIndex + 1)
          }
        >
          {folders.map((folder, i) => (
            <ListItem
              key={folder.name + i}
              className={cx(classes.listItem, classes.folder, {
                [classes.active]: selectedFolder === folder._id,
              })}
              onClick={() => onSelect({ type: 'folder', folder })}
              button
            >
              <IconButton
                style={{ right: 35 }}
                onClick={() => onChange(FolderActions.edit, folder)}
                className={cx('carouselMenuLittleButton', classes.littleButton)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                style={{ right: 5 }}
                onClick={() => onChange(FolderActions.delete, folder)}
                className={cx('carouselMenuLittleButton', classes.littleButton)}
              >
                <DeleteIcon />
              </IconButton>
              <Box display="flex" flexDirection="column" alignItems="center">
                <FolderIcon style={{ fontSize: 36 }} color="secondary" />
                <Text variant="body2" color="secondary">
                  {folder.name}
                </Text>
              </Box>
            </ListItem>
          ))}
        </Carousel>
        {showArrows && (
          <Collapse in={currentIndex + itemsToShow < folders.length}>
            <ListItem
              className={cx(classes.listItem, classes.arrow)}
              button
              onClick={() => next()}
            >
              <Box
                width={100}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <DownIcon style={{ fontSize: 36 }} color="secondary" />
              </Box>
            </ListItem>
          </Collapse>
        )}
        <ListItem
          onClick={() => onAdd()}
          className={cx(classes.listItem, classes.folder)}
          button
        >
          <Box
            width={100}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <AddFolderIcon className={classes.addAlbum} />
            <Text variant="body2" color="secondary">
              {t('add-album')}
            </Text>
          </Box>
        </ListItem>
      </div>
    </div>
  );
}
