import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({
    mainBlue,
    darkGreen,
    blueLight,
    pink,
    white,
    greenDark,
    breakpoints,
    greenGrey,
  }) => ({
    root: {
      padding: 10,
    },
    content: {
      boxShadow: `1px 4px 12px 1px rgba(26, 67, 70, 0.07)`,
      borderRadius: 10,
      flexGrow: 1,
      height: '100%',
      [breakpoints.up(1025)]: {
        margin: '0 20px',
        padding: '23px 32px',
      },
      padding: '10px 5px',
    },
    emptyPrompt: {
      flexGrow: 1,
      maxWidth: '100%',
    },
    title: {
      width: '100%',
      [breakpoints.down('sm')]: {
        paddingTop: 35,
        paddingBottom: 10,
        paddingLeft: 0,
      },
      display: 'flex',
      alignItems: 'center',
      '& h1': {
        marginLeft: 12,
        whiteSpace: 'nowrap',
      },
      '& svg': {
        minWidth: 16,
        minHeight: 16,
        fill: greenDark,
      },
    },
    description: {
      marginBottom: 30,
    },
    descriptionText: {
      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    table: {},
    phoneNumbersBar: {
      boxShadow: `1px 4px 12px 1px rgba(26, 67, 70, 0.07)`,
      borderRadius: 13,
      width: '100%',
      height: '100%',
      paddingBottom: 50,
      padding: 10,
      '& svg': {
        fill: greenGrey,
      },
      [breakpoints.up('sm')]: {
        marginRight: 10,
        minWidth: 400,
        padding: 24,
        paddingBottom: 90,
      },
    },
    poster: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      '& img': {
        width: 130,
        height: 120,
      },
    },
    invitationButtons: {
      width: 600,
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down('md')]: {
        padding: '32px 24px',
        width: '100%',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
      },
    },
    recordButton: {
      marginTop: 10,
      backgroundColor: blueLight,
      color: darkGreen,
      padding: '5px 20px',
      fontWeight: 600,
      width: 292,
      fontSize: 20,
      '&:hover': {
        color: white,
        backgroundColor: mainBlue,
      },
    },
    playbackButton: {
      marginTop: 10,
      width: 292,
      padding: '5px 20px',
      fontSize: 20,
      backgroundColor: pink,
      color: white,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: '#D7755E',
      },
    },
    phoneItemText: {
      '&& span': {
        color: greenDark,
      },
      '&& p': {
        fontWeight: 600,
        color: darkGreen,
      },
    },
    envelope: {
      position: 'fixed',
      zIndex: 599,
      '& button': {
        borderRadius: '50%',
        padding: 15,
      },
      '& svg': {
        fill: white,
      },
      bottom: 30,
      right: 30,
    },
    actionSkipButtons: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        width: '70%',
      },
    },
    later: {
      fontSize: 16,
    },
    recording: {
      fontWeight: 600,
      color: mainBlue,
    },
  }),
  { name: 'Setup' },
);
