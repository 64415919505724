import { createSelector } from 'reselect';
import { getMessages } from '../reducers/messages';
import { getFolders } from '../reducers/folders';
import { sortMessages, filterMessagesByFolder } from '../utils/helpers';
import { IMessage } from '../types';

/**
 * Memoized function to sort all messages and filter by folder - look **reselect** usage
 *
 * @example
 * ```javascript
 * const state = {
 *   messages: [
 *     { order: 3, foldersIds: [] },
 *     { order: 2, foldersIds: [{ id: 2 }] },
 *     { order: 1, foldersIds: [{ id: 2 }] },
 *   ],
 *   folders: {
 *     folders: [...],
 *     selectedFolderId: 2,
 *   }
 * };
 * const result = getFilteredMessages(state);
 * result:  [
 *   { order: 1, foldersIds: [{ id: 2 }] },
 *   { order: 2, foldersIds: [{ id: 2 }] },
 * ],
 *
 * ```
 */
export const getFilteredMessages = createSelector(
  getFolders,
  getMessages,
  (folder, messages): IMessage[] => {
    return sortMessages(
      filterMessagesByFolder(messages.list, folder.selectedFolderId),
      folder.selectedFolderId,
    );
  },
);
