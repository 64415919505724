import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Selectbox, { ISelectboxItem } from './Selectbox';
import { IBeloved } from '../types';

interface IBelovedSelectboxProps {
  onSelect: (beloved: IBeloved) => void;
  selectedBeloved?: IBeloved | null;
  belovedList: IBeloved[];
}

export default function BelovedSelectbox({
  onSelect,
  selectedBeloved,
  belovedList,
}: IBelovedSelectboxProps) {
  const [t] = useTranslation();

  const selectBeloved = ({ value: belovedId }: ISelectboxItem) => {
    onSelect(belovedList.find((b) => b._id === belovedId) as IBeloved);
  };

  return (
    <Selectbox
      onSelect={selectBeloved}
      defaultValue={{
        label: get(selectedBeloved, 'name', ''),
        value: get(selectedBeloved, '_id', ''),
      }}
      items={belovedList.map(({ name: label, _id: value }) => ({
        label,
        value,
      }))}
      label={t('select-loved-one')}
    />
  );
}
