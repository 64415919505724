import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Text from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { update } from '../../actions/settings';
import useSettingsStyles from './styles';

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    button: {
      width: '30%',
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
  { name: 'SettingsCloseAccount' },
);

export const CloseAccount = () => {
  const classes = {
    ...useSettingsStyles(),
    ...useStyles(),
  };
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [agreeOne, setAgreeOne] = useState(false);
  const [agreeTwo, setAgreeTwo] = useState(false);

  const closeAccount = () => {
    dispatch(update({ type: 'closeAccount' }));
  };

  return (
    <Grid direction="column" container spacing={3}>
      <Hidden smDown>
        <Grid item>
          <Text className={classes.title} variant="body1">
            {t('close-account')}
          </Text>
        </Grid>
      </Hidden>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeOne}
              onChange={() => setAgreeOne(!agreeOne)}
              name="agreeOne"
            />
          }
          label={t('close-account-agree-one')}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeTwo}
              onChange={() => setAgreeTwo(!agreeTwo)}
              name="agreeTwo"
            />
          }
          label={t('close-account-agree-two')}
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={!(agreeTwo && agreeOne)}
          onClick={closeAccount}
        >
          {t('close-account')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CloseAccount;
