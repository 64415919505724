import { IMe } from '../types';
import { createAction } from '../utils/actions';

export interface IProfile extends IMe {
  password?: string;
  currentPassword?: string;
}

export type SettingsActionTypes =
  | 'name'
  | 'email'
  | 'password'
  | 'privacy'
  | 'closeAccount'
  | 'notificationPreferences';

interface IPasswords {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
}
interface INotificationPreferences {
  allNotifications: boolean;
  accountRelatedNotifications: boolean;
}

export interface ISettingsAction {
  type: SettingsActionTypes;
  profile?: IProfile;
  passwords?: IPasswords;
  optOutAnalytics?: boolean;
  notificationPreferences?: INotificationPreferences;
}

export const UPDATE = 'SETTINGS/UPDATE';
export const UPDATE_SUCCESS = 'SETTINGS/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'SETTINGS/UPDATE_FAILURE';

export const types = {
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
};

export const update = createAction<ISettingsAction>(UPDATE);
export const updateSuccess = createAction<string>(UPDATE);
export const updateFailure = createAction<string>(UPDATE);

export default {
  types,
  update,
  updateSuccess,
  updateFailure,
};
