// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer, { IAction } from '../utils/redux-create-reducer';
import { OPEN, CLOSE } from '../actions/confirmation';

export interface IConfirmationState {
  isOpen: boolean;
  agreeText: string;
  disagreeText: string;
  agreeAction: IAction;
  disagreeAction: IAction;
  head: string;
  headTags: string[];
  headParams: Record<string, any>;
  body: string;
  bodyTags: string[];
  bodyParams: Record<string, any>;
}

interface IState {
  confirmation: IConfirmationState;
}

export const initialState: IConfirmationState = {
  isOpen: false,
  head: '',
  headParams: {},
  headTags: [],
  body: '',
  bodyParams: {},
  bodyTags: [],
  agreeText: 'yes',
  disagreeText: 'no',
  agreeAction: {
    type: 'agreeAction',
    payload: {},
  },
  disagreeAction: {
    type: 'disagreeAction',
    payload: {},
  },
};

export const getConfirmationState = (state: IState): IConfirmationState =>
  state.confirmation;

export default createReducer(initialState, {
  [OPEN]: (payload) =>
    Object.assign({ ...initialState }, payload, { isOpen: true }),
  [CLOSE]: () => ({ isOpen: false }),
});
