import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getLocation, push } from 'connected-react-router';
import get from 'lodash/get';

import { init } from '../actions/app';
import { authorize, setAuthError, LOGIN, LOGOUT } from '../actions/auth';

import { api, getUserInfo, login } from '../utils/api';

import { IMe } from '../types';
import { Routes } from '../constants';
import { getToken, setToken } from '../utils/localStorage';
import { callApi } from '../utils/helpers';
import amplitude from '../utils/analytics';
import { IAction } from '../utils/redux-create-reducer';
import { notify } from '../actions/notification';
import { resetApp } from '../actions/root';

const setAuthHeader = (token: string) =>
  (api.defaults.headers.common['Authorization'] = `Bearer ${token}`);

export function* authSuccess(token: string, user: IMe) {
  yield call(setAuthHeader, token);
  yield call(setToken, token);
  yield put(authorize({ token, user }));
  yield put(init());
}

function* handleError(error: Error) {
  console.error(error);
  yield call(setToken, null);
}

function makeLogin() {
  let tryCount = 0;

  return function* loginWorker({ payload: { email, password } }: IAction): any {
    const loc = yield select(getLocation);
    try {
      const {
        data: { user, token },
      } = yield call(callApi, login, email, password);

      amplitude.LOGIN();

      yield call(authSuccess, token, user);

      const referrer = get(loc, 'state.from.pathname');
      const search = get(loc, 'state.from.search') || '';

      if (referrer) {
        yield put(push(referrer + search));
      } else if (!user.verified) {
        yield put(push(Routes.Verify));
      } else {
        yield put(push(Routes.Messages));
      }
    } catch (error) {
      tryCount++;
      amplitude.LOGIN_FAIL({ tryCount });
      yield call(handleError, error);
      yield put(notify('log-in-error', 'error'));
      yield put(setAuthError(error));
    }
  };
}

export function* logoutWorker() {
  yield call(setToken, null);
  yield put(push(Routes.Home));
  yield put(resetApp());
}

export default function* authSaga(): any {
  const existingToken = yield call(getToken);

  yield call(setAuthHeader, existingToken);
  try {
    const { data: user } = yield call(callApi, getUserInfo, existingToken);
    yield call(authSuccess, existingToken, user);
  } catch (error) {
    yield call(handleError, error);
  }
  yield takeLatest(LOGIN, makeLogin());
  yield takeLatest(LOGOUT, logoutWorker);
}
