import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Text from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import { update } from '../../actions/settings';
import { getAuth, IAuthState } from '../../reducers/auth';
import { redirectTo, formatedTranslation } from '../../utils/helpers';
import { Routes } from '../../constants';
import useSettingsStyles from './styles';

const useStyles = makeStyles(
  ({ blueLight, darkGreen, greenDark, breakpoints }) => ({
    title: {
      fontSize: 24,
      fontWeight: 600,
      color: greenDark,
    },

    enabled: {
      color: darkGreen,
      fontWeight: 600,
      backgroundColor: blueLight,
    },
    button: {
      width: '40%',
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
  { name: 'SettingsPrivacy' },
);

export const Privacy = () => {
  const classes = {
    ...useSettingsStyles(),
    ...useStyles(),
  };
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { user }: IAuthState = useSelector(getAuth);
  const [optOut, setOptOut] = useState(user.optOutAnalytics);

  useEffect(() => {
    setOptOut(user.optOutAnalytics);
  }, [user]);

  const switchAnalytics = () => {
    const optOutAnalytics = !optOut;
    setOptOut(!optOut);
    dispatch(update({ type: 'privacy', optOutAnalytics }));
  };

  const link = {
    tag: 'a',
    props: {
      href: '',
      onClick: (evt: any) => {
        evt.preventDefault();
        dispatch(redirectTo(Routes.PrivacyPolicy));
      },
    },
  };

  const title = formatedTranslation(
    optOut ? 'opt-in-analytics' : 'opt-out-analytics',
    [link],
  );

  return (
    <Grid direction="column" container spacing={3}>
      <Hidden smDown>
        <Grid xs item>
          <Text className={classes.title} variant="body1">
            {t('privacy')}
          </Text>
        </Grid>
      </Hidden>
      <Grid xs item>
        <Text variant="body1">{title}</Text>
        <Text variant="caption">{t('analytics-description')}</Text>
      </Grid>

      <Grid xs item>
        <Button
          className={cx(classes.button, {
            [classes.enabled]: optOut,
          })}
          color="primary"
          variant="contained"
          onClick={switchAnalytics}
        >
          {t(optOut ? 'opt-in' : 'opt-out')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Privacy;
