import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ darkGreen }) => ({
    textArea: {
      width: '70%',
    },
    body: {
      textAlign: 'center',
      color: darkGreen,
      fontSize: 18,
      fontWeight: 'normal',
      lineHeight: '34px',
    },
    footer: {
      justifyContent: 'center',
      padding: '40px 0',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      '& h6': {
        fontWeight: 600,
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      padding: 25,
      '& button': {
        width: '70%',
      },
    },
  }),
  { name: 'Inline' },
);
