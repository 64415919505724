import React, { useState, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import FolderIcon from '@material-ui/icons/Folder';
import update from 'immutability-helper';

import { getFolders, IFolders } from '../../reducers/folders';
import { getPlayer, IPlayer } from '../../reducers/player';
import { getBelovedState, IBelovedState } from '../../reducers/beloved';
import { getRecordState } from '../../reducers/record';

import { getFilteredMessages } from '../../selectors/messages';
import {
  StarIcon,
  AddFolderIcon,
  MicrophoneIcon,
} from '../../components/icons';
import MessagesTable from '../../components/MessagesTable';
import MessagesAccordeonRow from '../../components/MessagesTable/MessagesAccordeonRow';
import MessagesTableRow from '../../components/MessagesTable/MessagesTableRow';
import CarouselMenu from '../../components/CarouselMenu';
import AddEditEntityName from '../../components/modal/AddEditEntityName';
import BelovedSelectbox from '../../components/BelovedSelectbox';
import FoldersListModal from '../../components/modal/FoldersList';
import PageContainer from '../../components/PageContainer';
import RecordMessageModal from '../../components/modal/RecordMessage';

import {
  changeMessage,
  playStopMessage,
  downloadMessage,
  saveNewFolder,
  changeFolder,
  selectBeloved,
  selectFolder,
  sortMessages,
  openRecordMessageModal,
  closeRecordMessageModal,
  saveMessage,
} from './dispatcher';

import emptyFolder from '../../assets/emptyFolder.svg';
import passcode from '../../assets/passcode.svg';
import { FolderActions, IFolder, IMessage } from '../../types.d';
import useStyles from './styles';
import { makeMessagesPageTitle, goTo } from '../../utils/helpers';
import { rowsPerPage, Routes } from '../../constants';
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';

const Messages = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //====================REDUX SELECTORS=====================
  const { list: folders, selectedFolderId }: IFolders = useSelector(
    getFolders,
    shallowEqual,
  );

  const messagesList: IMessage[] = useSelector(
    getFilteredMessages,
    shallowEqual,
  );
  const {
    list: belovedList,
    selected: selectedBeloved,
  }: IBelovedState = useSelector(getBelovedState);
  const playerInfo: IPlayer = useSelector(getPlayer);

  const {
    isOpen: isRecordingModalOpen,
    keyCount: recordingKeyCount,
  } = useSelector(getRecordState);

  //====================REACT STATE=====================
  const [isFolderModalOpen, setFolderModalOpen] = useState(false);
  const [isFoldersSelectModalOpen, setFoldersSelectModalOpen] = useState(false);
  const [messages, setMessages] = useState(messagesList);
  const [edittingFolder, setEdittingFolder] = useState<IFolder | null>(null);
  const [currentPage, setPage] = useState<number>(1);

  let icon;

  if (selectedFolderId === 'favorites') {
    icon = <FavoriteIcon color="secondary" />;
  } else if (selectedFolderId === 'all') {
    icon = <StarIcon className={classes.icon} />;
  } else {
    icon = <FolderIcon color="secondary" />;
  }

  const Row = isMobile ? MessagesAccordeonRow : MessagesTableRow;

  //====================HANDLERS=====================
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragMsg = messages[dragIndex];
      setMessages(
        update(messages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragMsg],
          ],
        }),
      );
    },
    [messages],
  );

  const saveOrdering = useCallback(() => {
    const newMessages =
      selectedFolderId === 'all'
        ? messages.map((m, i) => ({
            ...m,
            listOrder: i,
          }))
        : messages;

    sortMessages(newMessages, selectedFolderId);
  }, [messages, selectedFolderId]);

  const toggleFolderModal = () => {
    setEdittingFolder(null);
    setFolderModalOpen(!isFolderModalOpen);
  };
  const saveFolder = (name: string) => {
    if (edittingFolder) {
      changeFolder(FolderActions.edit, { ...edittingFolder, name });
      setFolderModalOpen(false);
    } else {
      saveNewFolder(setFolderModalOpen)(name);
    }
  };

  const toggleFoldersSelectModal = () => {
    if (isMobile) {
      setFoldersSelectModalOpen(!isFoldersSelectModalOpen);
    }
  };

  const switchFolderAndCloseModal = (cb: any) => (data: any) => {
    cb(data);
    setFoldersSelectModalOpen(false);
  };

  const editOrRemoveFolder = (type: FolderActions, folder: IFolder) => {
    if (type === FolderActions.edit) {
      setEdittingFolder(folder);
      setFolderModalOpen(true);
    } else {
      changeFolder(FolderActions.delete, folder);
    }
  };

  useEffect(() => {
    setMessages(messagesList);
  }, [messagesList]);

  useEffect(() => {
    setPage(1);
  }, [selectedFolderId]);

  let emptyMessagesText = '';
  if (!belovedList.length) {
    emptyMessagesText = t('empty-folder-add-beloved');
  } else if (selectedFolderId === 'all' && !messages.length) {
    emptyMessagesText = t('empty-folder-all-message-title');
  } else if (selectedFolderId === 'favorites' && !messages.length) {
    emptyMessagesText = t('empty-folder-favorites-message-title');
  } else if (!messages.length) {
    emptyMessagesText = t('empty-folder-message-title');
  }

  const title = t(makeMessagesPageTitle(selectedFolderId, folders));

  const showPagination = messages.length > rowsPerPage;
  const endPosition = rowsPerPage * currentPage;
  const startPosition = endPosition - rowsPerPage;
  const paginatedMessages = messages.slice(startPosition, endPosition);

  const pagination = {
    current: currentPage,
    rowsPerPage,
    total: messages.length,
    onChange: setPage,
  };

  const sideBar = (
    <Box className={classes.sidebarMenu}>
      {selectedBeloved && (
        <Box display="flex" justifyContent="center">
          <Button
            className={classes.recordMessageButton}
            color="secondary"
            onClick={openRecordMessageModal}
            startIcon={<MicrophoneIcon color={theme.mainBlue} />}
          >
            {t('record-message')}
          </Button>
        </Box>
      )}
      <CarouselMenu
        folders={folders}
        selectedFolder={selectedFolderId}
        onAdd={toggleFolderModal}
        onChange={editOrRemoveFolder}
        onSelect={selectFolder}
      />
    </Box>
  );

  return (
    <PageContainer sidebar={sideBar}>
      <div className={classes.root}>
        <div className={classes.header}>
          <div onClick={toggleFoldersSelectModal} className={classes.title}>
            {icon}
            <Text component="h1" variant="h1">
              {title}
            </Text>
            {isMobile && <DownIcon color="secondary" />}
            {isMobile && (
              <IconButton
                className={classes.settingsMiniButton}
                color="secondary"
                onClick={goTo(Routes.PlaybackSettings)}
              >
                <SettingsIcon />
              </IconButton>
            )}
          </div>
          {!isMobile && (
            <Button
              className={classes.settingsButton}
              color="secondary"
              onClick={goTo(Routes.PlaybackSettings)}
              startIcon={<SettingsIcon />}
            >
              {t('playback-settings')}
            </Button>
          )}
          {belovedList.length > 0 && (
            <BelovedSelectbox
              onSelect={selectBeloved}
              selectedBeloved={selectedBeloved}
              belovedList={belovedList}
            />
          )}
        </div>
        {isMobile && (
          <Box
            mx={1}
            my={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {selectedBeloved && (
              <Button
                color="primary"
                onClick={openRecordMessageModal}
                startIcon={<MicrophoneIcon color={theme.mainBlue} />}
              >
                {t('record-message')}
              </Button>
            )}
            <Text> </Text>
            <Button
              className={classes.addAlbum}
              onClick={toggleFolderModal}
              startIcon={<AddFolderIcon />}
              color="primary"
            >
              {t('add-album')}
            </Button>
          </Box>
        )}

        <MessagesTable
          showPagination={showPagination}
          pagination={pagination}
          isEmpty={paginatedMessages.length === 0}
        >
          {paginatedMessages.map((msg: IMessage, index: number) => (
            <Row
              key={msg._id}
              index={index}
              id={msg._id}
              moveRow={moveRow}
              onDrop={saveOrdering}
              message={msg}
              playerInfo={playerInfo}
              folders={folders}
              onDownload={downloadMessage}
              onChange={changeMessage}
              onPlayStop={playStopMessage(playerInfo)}
            />
          ))}
        </MessagesTable>
        {messages.length === 0 && (
          <div className={classes.emptyFolderPlaceholder}>
            <Text variant="body1">{emptyMessagesText}</Text>
            {belovedList.length === 0 && (
              <Box my={5}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={goTo(Routes.Setup)}
                >
                  {t('setup-page')}
                </Button>
              </Box>
            )}
            {messages.length === 0 &&
              !(
                selectedFolderId === 'all' || selectedFolderId === 'favorites'
              ) && <img src={emptyFolder} alt="empty folder" />}
            {messages.length === 0 && selectedFolderId === 'all' && (
              <img src={passcode} alt="empty folder" />
            )}
          </div>
        )}
      </div>
      <AddEditEntityName
        entity="album"
        isOpen={isFolderModalOpen}
        name={(edittingFolder && edittingFolder.name) || ''}
        onClose={toggleFolderModal}
        onSave={saveFolder}
      />
      <FoldersListModal
        isOpen={isFoldersSelectModalOpen}
        onClose={toggleFoldersSelectModal}
        folders={folders}
        selectedFolder={selectedFolderId}
        onChange={editOrRemoveFolder}
        onSelect={switchFolderAndCloseModal(selectFolder)}
      />
      {selectedBeloved && (
        <RecordMessageModal
          isOpen={isRecordingModalOpen}
          beloved={selectedBeloved}
          recordPasscode={selectedBeloved.recordPasscode}
          onClose={closeRecordMessageModal}
          onSave={saveMessage}
          key={recordingKeyCount}
        />
      )}
    </PageContainer>
  );
};

export default Messages;
