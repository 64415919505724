import React, { useState, useEffect, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import Menu from './MessageMenu';
import AddEditDescriptionModal from '../modal/AddEditMessageDescription';
import { IMessage, MessageActions, IFolder } from '../../types.d';
import useStyles from './styles';
import { IPlayer } from '../../reducers/player';
import { HeartIcon, DownloadIcon, PlayPauseIcon } from '../icons';
import { convertSecondsToTime } from '../../utils/helpers';

export type PlayFunc = (message: IMessage) => any;
export type ChangeFunc = (type: MessageActions, message: IMessage) => any;
export type ChangeDescriptionFunc = (message: IMessage, isNew?: boolean) => any;

interface IMessagesTableRow {
  message: IMessage;
  onPlayStop: PlayFunc;
  onDownload: PlayFunc;
  playerInfo: IPlayer;
  onChange: ChangeFunc;
  onDrop: (item: DragItem, monitor: DropTargetMonitor) => void;
  folders: IFolder[];
  id: any;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const MessagesTableRow = ({
  message,
  onChange,
  onDrop,
  onPlayStop,
  onDownload,
  playerInfo,
  folders,
  id,
  index,
  moveRow,
}: IMessagesTableRow) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [msg, setMsg] = useState(message);
  const [isModalOpen, setModalStatus] = useState(false);
  const {
    _id,
    show,
    favorite,
    folderListOrder: albums,
    playback_count: count,
    createdAt,
    description,
    viewed,
    duration,
  } = msg;

  const isPlaying = playerInfo.isPlaying && playerInfo.messageId === _id;

  const createdTime = moment(createdAt).fromNow();
  const STATUS_TEXT = t(count > 0 ? 'played' : 'not-played');
  const ALBUMS_TEXT = t('message-in-albums', {
    albums: albums.map((a) => a.name).join(', '),
  });
  const FAVORITE_TEXT = t(
    favorite ? 'remove-from-favorites' : 'add-to-favorites',
  );
  const HIDE_SHOW_TEXT = t(show ? 'hide-message' : 'show-message');
  const DOWNLOAD_TEXT = t('download-message');
  const PLAYED_COUNT_TEXT = t('played-count', { count });

  const editMessage = (
    type: MessageActions,
    key: keyof IMessage,
    value: IMessage[keyof IMessage],
  ) => {
    const newMsg = { ...msg, [key]: value };
    setMsg(newMsg);
    onChange(type, newMsg);
  };

  const editDescription = (desc = '') => {
    editMessage(MessageActions.changeDescription, 'description', desc);
    setModalStatus(false);
  };

  useEffect(() => {
    setMsg(message);
  }, [message]);

  const isLoading = isPlaying && !playerInfo.currentTime;
  const playEl = (
    <Tooltip
      title={
        (isLoading ? t('loading') : isPlaying ? t('stop') : t('play')) as string
      }
    >
      <IconButton
        className={cx(classes.playButton, 'soyl-table-playButton', {
          [classes.playButtonActive]: isPlaying,
        })}
        onClick={() => onPlayStop(msg)}
        tabIndex={0}
        aria-label={t('play')}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <PlayPauseIcon active={isPlaying} />
        )}
      </IconButton>
    </Tooltip>
  );

  const showEl = (
    <IconButton
      onClick={() => editMessage(MessageActions.changeVisibility, 'show', show)}
      tabIndex={0}
    >
      <Tooltip title={HIDE_SHOW_TEXT}>
        {show ? (
          <VolumeUpIcon aria-label={HIDE_SHOW_TEXT} />
        ) : (
          <VolumeOffIcon aria-label={HIDE_SHOW_TEXT} />
        )}
      </Tooltip>
    </IconButton>
  );

  const favoriteEl = (
    <Tooltip title={FAVORITE_TEXT}>
      <IconButton
        onClick={() =>
          editMessage(MessageActions.makeFavorite, 'favorite', favorite)
        }
        tabIndex={0}
        aria-label={FAVORITE_TEXT}
      >
        <HeartIcon active={favorite} />
      </IconButton>
    </Tooltip>
  );

  const downloadEl = (
    <Tooltip title={DOWNLOAD_TEXT}>
      <IconButton onClick={() => onDownload(msg)} tabIndex={0}>
        <DownloadIcon alt={DOWNLOAD_TEXT} />
      </IconButton>
    </Tooltip>
  );

  const albumsEl = (
    <div tabIndex={0} aria-label={ALBUMS_TEXT}>
      {albums.map((a) => a.name).join(', ')}
    </div>
  );

  const statusEl = (
    <div aria-label={`${t('status')}: ${STATUS_TEXT}`} tabIndex={0}>
      {STATUS_TEXT}
    </div>
  );

  const countEl = (
    <div tabIndex={0} aria-label={PLAYED_COUNT_TEXT}>
      {count ? count : '-'}
    </div>
  );

  const createdAtEl = (
    <div aria-label={`${t('created-at')} ${createdTime}`} tabIndex={0}>
      {createdTime}
    </div>
  );

  const descriptionEl = (
    <div
      tabIndex={0}
      onClick={() => setModalStatus(true)}
      aria-label={description}
      className={classes.description}
    >
      {description}
    </div>
  );
  const tickTime = convertSecondsToTime(
    Math.ceil(playerInfo.duration || 0) - Math.ceil(playerInfo.currentTime),
  );

  const durationEl = (
    <div aria-label={`${t('message-duration-is')}:${duration}`} tabIndex={0}>
      {isPlaying && playerInfo.currentTime
        ? tickTime
        : Number(duration) === 0
        ? '-'
        : convertSecondsToTime(Number(duration))}
    </div>
  );
  const ref = useRef<HTMLTableRowElement>(null);
  const [, drop] = useDrop({
    accept: 'row',
    drop: onDrop,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'row', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <TableRow
      ref={ref}
      className={cx(classes.row, {
        [classes.new]: !viewed,
        [classes.hidden]: !show,
        [classes.isDragging]: isDragging,
      })}
    >
      <TableCell
        align="center"
        className={cx(classes.icon, classes.playIcon, classes.cell)}
      >
        {playEl}
      </TableCell>
      <TableCell
        align="center"
        className={cx(classes.icon, classes.showHide, classes.cell)}
      >
        {showEl}
      </TableCell>
      <TableCell align="center" className={cx(classes.icon, classes.cell)}>
        {favoriteEl}
      </TableCell>
      <TableCell align="center" className={cx(classes.icon, classes.cell)}>
        {downloadEl}
      </TableCell>
      <TableCell align="center" className={cx(classes.albums, classes.cell)}>
        {albumsEl}
      </TableCell>
      <TableCell align="center" className={cx(classes.status, classes.cell)}>
        {statusEl}
      </TableCell>
      <TableCell
        className={cx(classes.playbackCount, classes.cell)}
        align="center"
      >
        {countEl}
      </TableCell>
      <TableCell className={cx(classes.createdAt, classes.cell)} align="center">
        {createdAtEl}
      </TableCell>
      <TableCell
        className={cx(classes.description, classes.cell)}
        align="center"
      >
        {description.length ? (
          descriptionEl
        ) : (
          <>
            <Button
              size="small"
              color="primary"
              onClick={() => setModalStatus(true)}
            >
              {t('add')}
            </Button>
          </>
        )}
        <AddEditDescriptionModal
          key={msg._id}
          isOpen={isModalOpen}
          messageId={msg._id}
          onClose={() => setModalStatus(false)}
          description={description}
          onSave={editDescription}
          onRemove={editDescription}
        />
      </TableCell>
      <TableCell className={cx(classes.duration, classes.cell)} align="center">
        {durationEl}
      </TableCell>
      <TableCell className={cx(classes.icon)}>
        <Menu
          onShowInFolders={(message) =>
            onChange(MessageActions.showInFolders, message)
          }
          message={message}
          folders={folders}
          onDelete={() => onChange(MessageActions.delete, msg)}
        />
      </TableCell>
    </TableRow>
  );
};

export default MessagesTableRow;
