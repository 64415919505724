import { all } from 'redux-saga/effects';
import auth from './auth';
import signup from './signup';
import folders from './folders';
import beloved from './beloved';
import messages from './messages';
import messagesPage from './messagesPage';
import playbackSettings from './playbackSettings';
import setupPage from './setupPage';
import player from './player';
import verify from './verify';
import settings from './settings';
import record from './record';
import invitePage from './invitePage';
import playback from './playback';

const allSagas = [
  auth,
  signup,
  beloved,
  folders,
  messages,
  messagesPage,
  playbackSettings,
  setupPage,
  player,
  verify,
  settings,
  record,
  invitePage,
  playback,
];

export default function* appSagas() {
  yield all(allSagas.map((s) => s()));
}
