import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Validator from 'validator';
import amp from '../utils/analytics';
import { forgotPassword } from '../utils/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.darkGreen,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: 'linear-gradient(45deg, #F2105A 30%, #F2105A 90%)',
    color: '#ffffff',
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccess(false);

    if (!Validator.isEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    const response = await forgotPassword(email);
    if (response.status === 201) {
      amp.FORGOT_PASSWORD();
      setSuccess(true);
    } else {
      setErrorMessage(
        'Oops! Looks like there was a problem getting this sorted out.',
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h6">
          Let&apos;s get you into your account
        </Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {!success && (
          <p>
            Enter the email address associated with your Sound of Your Love
            account and we will send you a link to reset your password.
          </p>
        )}
        {success && (
          <>
            <Alert severity="success">We&apos;ve processed your request.</Alert>
            <div className={classes.content}>
              <p>
                If there&apos;s an account associated with this email, you
                should receive a password reset link shortly. Check your inbox
                and click on the link in the email to reset your password.
              </p>
              <p>
                If you have trouble finding the email, check your spam folder or
                click the button below to resend the email.
              </p>
            </div>
          </>
        )}
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send Reset Link
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
