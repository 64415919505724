import { call, put, select, take } from 'redux-saga/effects';

import { ISettingsAction, UPDATE } from '../actions/settings';
import { notify } from '../actions/notification';
import { logout, setUser } from '../actions/auth';

import { IAuthState, getAuth } from '../reducers/auth';

import { IAction } from '../utils/redux-create-reducer';
import {
  updateUserInfo,
  sendUserEmail,
  updatePassword,
  closeAccount,
} from '../utils/api';
import { callApi } from '../utils/helpers';
import amplitude from '../utils/analytics';

export default function* verifySaga() {
  while (true) {
    try {
      const {
        payload: {
          type,
          profile,
          passwords,
          notificationPreferences,
          optOutAnalytics,
        },
      }: IAction<ISettingsAction> = yield take(UPDATE);

      const { user }: IAuthState = yield select(getAuth);

      if (type === 'name') {
        const { firstname, lastname } = profile || {
          firstname: '',
          lastname: '',
        };
        const nameData = { firstname, lastname };
        yield call(callApi, updateUserInfo, nameData);
        amplitude.EDIT_NAME_ACCOUNT();
        yield put(setUser({ ...user, ...nameData }));
        yield put(notify('update-name-success'));
      }

      if (type === 'email') {
        const { email } = profile || {};
        yield call(callApi, sendUserEmail, email);
        amplitude.EDIT_EMAIL_ACCOUNT();
        yield put(notify('update-email-send-request'));
      }

      if (type === 'password') {
        const { currentPassword, password } = passwords || {};
        yield call(callApi, updatePassword, currentPassword, password);
        amplitude.CHANGE_PASSWORD();
        yield put(notify('update-password-success'));
      }

      if (type === 'notificationPreferences') {
        yield call(callApi, updateUserInfo, notificationPreferences);
        amplitude.EDIT_NOTIFICATION_SETTINGS({ notificationPreferences });
        yield put(notify('update-notification-preferences-success'));
      }

      if (type === 'privacy') {
        yield call(callApi, updateUserInfo, { optOutAnalytics });
        if (optOutAnalytics !== undefined) {
          amplitude.EDIT_PRIVACY_SETTINGS({ optOutAnalytics });
          yield put(setUser({ ...user, ...{ optOutAnalytics } }));
        }
        yield put(notify('update-analytics-success'));
      }

      if (type === 'closeAccount') {
        yield call(callApi, closeAccount);
        amplitude.CLOSE_ACCOUNT();
        yield put(logout());
        yield put(notify('close-account-closed-success'));
      }
    } catch (error) {
      console.error(error);
      yield put(notify('oops-something-went-wrong', 'error'));
    }
  }
}
