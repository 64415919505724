import ReactGA from 'react-ga';
import amplitude from 'amplitude-js';
import { isDevelopment } from '../env';
import { googleAnalyticsId, googleAnalyticsDuration } from '../constants';

export const initializeAmplitude = () => {
  amplitude
    .getInstance()
    .init(process.env.REACT_APP_AMPLITUDE_API_KEY || '', '', {
      disableCookies: true,
      includeUtm: true,
      includeReferrer: true,
    });
};

export const initializeGA = () => {
  // Cookie duration: 7 days
  ReactGA.initialize(googleAnalyticsId, {
    debug: isDevelopment,
    gaOptions: { cookieExpires: 60 * 60 * 24 * googleAnalyticsDuration },
  });
  // Avoid tracking personal data to avoid the need
  // for a GDPR consent banner right now.
  ReactGA.ga('set', 'anonymizeIp', true);
};
