import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ darkGreen, breakpoints, spacing }) => ({
    textArea: {
      width: '70%',
    },
    body: {
      textAlign: 'center',
      color: darkGreen,
      fontSize: 18,
      fontWeight: 'normal',
      lineHeight: '34px',
    },
    footer: {
      justifyContent: 'center',
      padding: '40px 0',
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center',
      '& h6': {
        fontWeight: 600,
      },
    },
    dialogContent: {
      display: 'flex',
      justifyContent: 'center',
      width: 500,
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'center',
      padding: 25,
      '& button': {
        width: '70%',
      },
    },
    titleText: {
      color: darkGreen,
      fontWeight: 600,
      textAlign: 'center',
      marginTop: spacing(3),
      marginRight: spacing(3),
      fontSize: 24,
      lineHeight: '34px',
    },
    closeButton: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      color: darkGreen,
    },
  }),
  { name: 'Modal' },
);
