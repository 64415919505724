import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Validator from 'validator';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Text from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PasswordInput from '../components/PasswordInput';

import { login } from '../actions/auth';
import loginImage from '../assets/signup.svg';
import { goTo } from '../utils/helpers';
import { Routes } from '../constants';
import { isDevelopment } from '../env';

const useStyles = makeStyles(
  ({ greenGrey, breakpoints }) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down(1025)]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      margin: 40,
      '& h2': {
        alignSelf: 'center',
        fontWeight: 'bold',
      },
    },
    imageSection: {
      [breakpoints.up(1441)]: {
        padding: '40px 140px',
      },
      [breakpoints.down(1441)]: {
        '& img': {
          width: 700,
        },
      },
      [breakpoints.down(1281)]: {
        '& img': {
          width: 550,
        },
      },
      [breakpoints.down(1025)]: {
        '& img': {
          width: 400,
        },
      },
      [breakpoints.down(601)]: {
        display: 'none',
      },
      padding: '0px 20px',
    },
    signup: {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        textTransform: 'initial',
      },
      '& > *': {
        padding: 10,
      },
    },
    forgotPassword: {
      '& button': {
        textTransform: 'none',
      },
    },
    formSection: {
      display: 'flex',
      [breakpoints.up(1025)]: {
        justifyContent: 'flex-start',
      },
      [breakpoints.down(1025)]: {
        justifyContent: 'center',
      },
      marginTop: '40px',
      width: '100%',
      '& .soyl-login-button': {
        width: '100%',
      },
      [breakpoints.up(601)]: {
        '& form': {
          width: 500,
        },
      },
      [breakpoints.down(601)]: {
        '& form': {
          width: '100%',
        },
      },
      '& .MuiFormHelperText-root': {
        color: greenGrey,
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  }),
  { name: 'Login' },
);

interface State {
  email: string;
  password: string;
}

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const email = isDevelopment ? 'developer@example.com' : '';
  const password = isDevelopment ? 'Dev310PeR' : '';
  const [userData, setUserData] = React.useState<State>({
    email,
    password,
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState(' ');

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
  };

  const validateFormInputs = () => {
    let formValid = true;
    clearErrors();
    if (!Validator.isEmail(userData.email)) {
      setEmailError(t('email-error'));
      formValid = false;
    }
    if (Validator.isEmpty(userData.password)) {
      setPasswordError(t('missing-entry'));
      formValid = false;
    }
    return formValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validateFormInputs()) {
      return;
    }
    dispatch(login(userData));
  };

  const handleChange = (prop: keyof State, value?: boolean) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserData({ ...userData, [prop]: value || event.target.value });
  };

  return (
    <div className={cx(classes.container)}>
      <div className={cx(classes.imageSection)}>
        <img src={loginImage} alt={t('log-in')} />
      </div>
      <div className={cx(classes.formSection)}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={4}>
            <Text variant="h2">{t('log-in')}</Text>
            <Grid item sm={12}>
              <TextField
                id="email"
                type="email"
                autoComplete="email"
                autoFocus
                helperText={emailError}
                error={emailError.length > 0}
                value={userData.email}
                onChange={handleChange('email')}
                label={t('email')}
              />
            </Grid>
            <Grid item sm={12}>
              <PasswordInput
                name="password"
                error={passwordError}
                value={userData.password}
                onChange={handleChange('password')}
                label={t('password')}
              />
            </Grid>
            <Grid item sm={12}>
              <Button
                type="submit"
                className="soyl-login-button"
                variant="contained"
                color="primary"
              >
                {t('log-in')}
              </Button>
            </Grid>
            <Grid item sm={12}>
              <div className={classes.forgotPassword}>
                <Button onClick={goTo(Routes.ForgotPassword)} color="primary">
                  {t('forgot-password')}
                </Button>
              </div>
            </Grid>
            <Grid item sm={12}>
              <Divider />
              <div className={classes.signup}>
                <Text variant="body2">{t('dont-have-account')}</Text>
                <Button onClick={goTo(Routes.SignUp)} color="primary">
                  {t('sign-up')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default Login;
