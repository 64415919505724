import React from 'react';

const Edit = (props: any) => (
  <svg
    {...props}
    width="18"
    height="22"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.4 14.48H0.6C0.268125 14.48 0 14.766 0 15.12V15.84C0 15.928 0.0675 16 0.15 16H14.85C14.9325 16 15 15.928 15 15.84V15.12C15 14.766 14.7319 14.48 14.4 14.48ZM2.73188 12.8C2.76938 12.8 2.80688 12.796 2.84438 12.79L5.99813 12.2C6.03562 12.192 6.07125 12.174 6.0975 12.144L14.0456 3.666C14.063 3.6475 14.0768 3.62552 14.0862 3.60133C14.0956 3.57713 14.1005 3.55119 14.1005 3.525C14.1005 3.49881 14.0956 3.47287 14.0862 3.44867C14.0768 3.42448 14.063 3.4025 14.0456 3.384L10.9294 0.058C10.8938 0.02 10.8469 0 10.7963 0C10.7456 0 10.6988 0.02 10.6631 0.058L2.715 8.536C2.68687 8.566 2.67 8.602 2.6625 8.642L2.10938 12.006C2.09113 12.1131 2.09765 12.2234 2.12836 12.3273C2.15907 12.4311 2.21305 12.5254 2.28562 12.602C2.40937 12.73 2.565 12.8 2.73188 12.8Z" />
  </svg>
);

export default Edit;
