import {
  call,
  takeLatest,
  put,
  fork,
  cancel,
  cancelled,
  delay,
  race,
  take,
  select,
} from 'redux-saga/effects';
import { getLocation } from 'connected-react-router';
import get from 'lodash/get';
import { IAction } from '../utils/redux-create-reducer';
import { Routes, rowsPerPage } from '../constants';
import { IMessage } from '../types';

import { stopLoading, startLoading } from '../actions/app';
import {
  PLAY,
  STOP,
  DOWNLOAD,
  PAUSE_RESUME,
  ENDED,
  IPlayerAction,
  play,
  tick,
  stop,
  listen,
  ended,
} from '../actions/player';
import { setPage } from '../actions/playback';
import { callApi } from '../utils/helpers';
import { getRecording, getInviteRecording, markPlayed } from '../utils/api';
import { cleanTextForFilename, formatDateForFilename } from '../utils/helpers';
import { getBelovedState, IBelovedState } from '../reducers/beloved';
import { getPlayer } from '../reducers/player';

import amplitude from '../utils/analytics';
import { getPlaybackState } from '../reducers/playback';
interface IPlayerLib {
  instance: HTMLAudioElement | null;
  ticker: any;
  play: (url: string) => void;
  pauseResume: () => void;
  stop: () => void;
}
function Player(): IPlayerLib {
  return {
    instance: null,
    ticker: null,
    play(url: string) {
      this.stop();
      this.instance = new Audio(url);
      this.instance && this.instance.play();
    },
    pauseResume() {
      if (this && this.instance) {
        this.instance.paused ? this.instance.play() : this.instance.pause();
      }
    },
    stop() {
      if (this && this.instance) {
        this.instance.pause();
      }
    },
  };
}

const player = Player();

function* runTick(rec: HTMLAudioElement): any {
  while (true) {
    const { duration, currentTime } = rec;
    if (duration - currentTime <= 0) {
      yield put(ended());
      break;
    }
    yield put(tick({ duration, currentTime }));
    yield delay(1000);
    if (yield cancelled()) {
      break;
    }
  }
}

function* makeUrl(recordingId: string, setLoading: boolean, isInvite: boolean) {
  try {
    if (setLoading) {
      yield put(startLoading());
    }
    const { data: file } = isInvite
      ? yield call(getInviteRecording, recordingId)
      : yield call(getRecording, recordingId);
    return window.URL.createObjectURL(new Blob([file], { type: 'audio/mpeg' }));
  } catch (err) {
    console.error(err);
    return '';
  } finally {
    if (setLoading) {
      yield put(stopLoading());
    }
  }
}

function* downloadWorker({
  payload: { messageId, recordingId, description, createdAt },
}: IAction<IPlayerAction>): any {
  const location = yield select(getLocation);
  const route = get(location, 'pathname', '');
  const isInvite = route === Routes.Playback;
  const url = yield call(makeUrl, recordingId, true, isInvite);
  const { selected }: IBelovedState = yield select(getBelovedState);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${(selected && cleanTextForFilename(selected.name)) || 'Audio'}-${
      description ? cleanTextForFilename(description) + '-' : ''
    }${formatDateForFilename(createdAt)}.mp3`,
  );
  document.body.appendChild(link);
  link.click();
  amplitude.DOWNLOAD_MESSAGE({
    belovedId: selected && selected._id,
    messageId: messageId,
  });
}

function* playWorker({ payload }: IAction<IPlayerAction>): any {
  const location = yield select(getLocation);
  const route = get(location, 'pathname', '');
  const isInvite = route === Routes.Playback;
  const { url, stop } = yield race({
    url: call(makeUrl, payload.recordingId, false, isInvite),
    stop: take(STOP),
  });
  if (stop) {
    return;
  }
  const { selected }: IBelovedState = yield select(getBelovedState);
  yield put(listen(payload.messageId));
  yield call(player.play.bind(player), url);
  if (route === Routes.Messages) {
    amplitude.REVIEW_MESSAGE({
      belovedId: selected && selected._id,
      messageId: payload.messageId,
    });
  }
  player.ticker = yield fork(runTick, player.instance as HTMLAudioElement);
}

function* pauseResumeWorker(): any {
  if (!player.instance) return;
  // Stop ticker if playing
  if (!player.instance.paused) {
    yield cancel(player.ticker);
  }
  yield call(player.pauseResume.bind(player));
  // Start ticker if now playing
  if (!player.instance.paused) {
    player.ticker = yield fork(runTick, player.instance as HTMLAudioElement);
  }
}

function* stopWorker() {
  yield call(player.stop.bind(player));
  if (player.ticker) {
    yield cancel(player.ticker);
  }
}

function* endedWorker(): any {
  const { messageId } = yield select(getPlayer);
  const { messages, loop } = yield select(getPlaybackState);
  const location = yield select(getLocation);
  const route = get(location, 'pathname', '');
  if (route === Routes.Playback) {
    try {
      const { data: msg } = yield call(callApi, markPlayed, messageId);
      amplitude.PLAY_MESSAGE({
        belovedId: msg.recipient,
        messageId: msg._id,
        playbackCount: msg.playback_count,
      });
    } catch (err) {
      console.error(err);
    }
    if (loop) {
      const selectedIndex = messages.findIndex(
        (msg: IMessage) => msg._id === messageId,
      );
      if (selectedIndex !== -1) {
        const nextIndex = selectedIndex + 1;
        const nextMessage =
          nextIndex >= messages.length ? messages[0] : messages[nextIndex];
        const nextPage = Math.floor(nextIndex / rowsPerPage) + 1;
        yield put(setPage(nextPage));
        yield put(
          play({
            messageId: nextMessage._id,
            recordingId: nextMessage.recordingId,
          }),
        );
        return;
      }
    }
  }
  yield put(stop());
}

export default function* playerSaga() {
  yield takeLatest(PLAY, playWorker);
  yield takeLatest(STOP, stopWorker);
  yield takeLatest(DOWNLOAD, downloadWorker);
  yield takeLatest(PAUSE_RESUME, pauseResumeWorker);
  yield takeLatest(ENDED, endedWorker);
}
