import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import useModalStyles from './styles';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FolderIcon from '@material-ui/icons/Folder';

import { EditIcon } from '../icons';
import { makeStyles } from '@material-ui/core';
import { FolderActions, IFolder } from '../../types.d';
import { ISelectArgs } from '../CarouselMenu';

const useStyles = makeStyles(({ greenDark, white, mainBlue }) => ({
  modalContent: {
    padding: 0,
  },
  icon: {
    fill: greenDark,
    verticalAlign: 'middle',
  },
  edit: {
    marginTop: 3,
  },
  active: {
    '&& p': {
      color: white,
    },
    backgroundColor: mainBlue,
    '&& svg': {
      fill: white,
    },
  },
}));

interface IProps {
  isOpen: boolean;
  folders: IFolder[];
  selectedFolder: string;
  onClose: () => any;
  onSelect: (el: ISelectArgs) => any;
  onChange: (type: FolderActions, folder: IFolder) => any;
}

export default function FoldersListModal({
  folders,
  selectedFolder,
  isOpen,
  onClose,
  onChange,
  onSelect,
}: IProps) {
  const classes = {
    ...useModalStyles(),
    ...useStyles(),
  };
  const [t] = useTranslation();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography color="textPrimary" variant="h6">
          {t('select-folder')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Box>
          <Box
            className={cx({ [classes.active]: 'all' === selectedFolder })}
            display="flex"
            justifyContent="space-between"
          >
            <Box py={1} display="flex" alignItems="center">
              <Box pl={2} mr={2}>
                <MenuIcon className={classes.icon} />
              </Box>
              <Box onClick={() => onSelect({ type: 'all' })}>
                <Text>{t('all-messages')}</Text>
              </Box>
            </Box>
          </Box>
          <Box
            className={cx({ [classes.active]: 'favorites' === selectedFolder })}
            display="flex"
            justifyContent="space-between"
          >
            <Box py={1} display="flex" alignItems="center">
              <Box pl={2} mr={2}>
                <FavoriteIcon className={classes.icon} />
              </Box>
              <Box onClick={() => onSelect({ type: 'favorites' })}>
                <Text>{t('favorites')}</Text>
              </Box>
            </Box>
          </Box>
          {folders.map((folder) => (
            <Box
              key={folder._id}
              display="flex"
              justifyContent="space-between"
              className={cx({
                [classes.active]: folder._id === selectedFolder,
              })}
            >
              <Box py={1} display="flex" alignItems="center">
                <Box pl={2} mr={2}>
                  <FolderIcon className={classes.icon} color="primary" />
                </Box>
                <Box onClick={() => onSelect({ type: 'folder', folder })}>
                  <Text>{folder.name}</Text>
                </Box>
              </Box>
              <Box display="flex">
                <IconButton
                  onClick={() => onChange(FolderActions.edit, folder)}
                >
                  <EditIcon className={cx(classes.icon, classes.edit)} />
                </IconButton>

                <IconButton
                  onClick={() => onChange(FolderActions.delete, folder)}
                >
                  <DeleteIcon className={classes.icon} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
