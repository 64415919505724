import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InviteActions, IMessage } from '../../types.d';
import { verifyInvite } from '../../actions/invite';
import { play, pauseResume, stop } from '../../actions/player';
import { toggleLoop, setPage } from '../../actions/playback';
import { IInviteState, getPlaybackInviteState } from '../../reducers/invite';
import { getPlaybackState } from '../../reducers/playback';
import { getPlayer, IPlayer } from '../../reducers/player';
import PageContainer from '../../components/PageContainer';
import InvitationPasscode from '../../components/inline/InvitationPasscode';
import MessagesTable from '../../components/PlaybackMessagesTable';
import MessagesTableRow from '../../components/PlaybackMessagesTable/MessagesTableRow';

import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import { rowsPerPage } from '../../constants';

import useStyles from './styles';

const Playback = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    isRequestingPasscode,
    invitationCode,
    beloved,
  }: IInviteState = useSelector(getPlaybackInviteState);
  const { loop, currentPage, messages } = useSelector(getPlaybackState);
  const playerInfo: IPlayer = useSelector(getPlayer);

  const updatePage = (page: number) => {
    if (playerInfo.status === 'playing') {
      dispatch(stop());
    }
    dispatch(setPage(page));
  };

  const showPagination = messages.length > rowsPerPage;
  const endPosition = rowsPerPage * currentPage;
  const startPosition = endPosition - rowsPerPage;
  const paginatedMessages = messages.slice(startPosition, endPosition);
  const pagination = {
    current: currentPage,
    rowsPerPage,
    total: messages.length,
    onChange: updatePage,
  };

  const verifyPasscode = (passcodeInput: string) => {
    dispatch(
      verifyInvite({
        type: InviteActions.playback,
        invitationCode,
        passcode: parseInt(passcodeInput),
      }),
    );
  };

  const playStopMessage = (playerInfo: any) => ({
    _id,
    recordingId,
  }: IMessage) => {
    // New message
    if (playerInfo.messageId !== _id) {
      dispatch(play({ messageId: _id, recordingId }));
    } else {
      // Current message playing: pause/resume
      dispatch(pauseResume(_id));
    }
  };

  const title = beloved
    ? t('messages-for', { name: beloved.name })
    : t('playback-invitation');

  const noMessages = messages.length === 0;

  return (
    <PageContainer>
      <Box className={classes.root}>
        <Box className={classes.titleContainer}>
          <Text className={classes.titleText}>{title}</Text>
        </Box>
        <Grid container direction="column">
          {isRequestingPasscode && (
            <Grid container item>
              <Paper className={classes.invitationContainer}>
                <Text className={classes.ctaText}>
                  {t('enter-invitation-passcode')}
                </Text>
                <InvitationPasscode onSave={verifyPasscode} />
              </Paper>
            </Grid>
          )}
          {beloved && (
            <>
              {!noMessages && (
                <div className={classes.loop}>
                  <Text className={classes.loopLabel} variant="body2">
                    {t('loop')}
                  </Text>
                  <Switch
                    className={classes.loopSwitch}
                    color="primary"
                    checked={loop}
                    onChange={() => {
                      dispatch(toggleLoop());
                    }}
                  />
                </div>
              )}
              <MessagesTable
                showPagination={showPagination}
                pagination={pagination}
                isEmpty={paginatedMessages.length === 0}
              >
                {paginatedMessages.map((msg: IMessage) => (
                  <MessagesTableRow
                    key={msg._id}
                    message={msg}
                    playerInfo={playerInfo}
                    onPlayStop={playStopMessage(playerInfo)}
                  />
                ))}
              </MessagesTable>
              {noMessages && (
                <div className={classes.emptyFolderPlaceholder}>
                  <Text variant="body1">
                    {t('empty-folder-all-message-title')}
                  </Text>
                </div>
              )}
            </>
          )}
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Playback;
