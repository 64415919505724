import { localStorageKey } from '../constants';

export const setItem = localStorage.setItem.bind(localStorage);
export const getItem = localStorage.getItem.bind(localStorage);
export const removeItem = localStorage.removeItem.bind(localStorage);

export const setToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(localStorageKey, token);
  } else {
    localStorage.removeItem(localStorageKey);
  }
};

export const getToken = () => {
  return localStorage.getItem(localStorageKey);
};
