import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from '../components/modal/styles';

import { close } from '../actions/confirmation';
import {
  getConfirmationState,
  IConfirmationState,
} from '../reducers/confirmation';

export default function Confirmation() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation();
  const {
    isOpen,
    head,
    headParams,
    body,
    bodyParams,
    agreeAction,
    disagreeAction,
    disagreeText,
    agreeText,
  }: IConfirmationState = useSelector(getConfirmationState);

  const closeModal = () => {
    dispatch(close());
  };

  const onNo = () => {
    dispatch(disagreeAction);
    dispatch(close());
  };

  const onYes = () => {
    dispatch(agreeAction);
    dispatch(close());
  };

  const headElement =
    headParams instanceof Array ? (
      <Trans i18nKey={head}>
        {headParams.map(([tag, value]) => React.createElement(tag, {}, value))}
      </Trans>
    ) : (
      t(head, headParams)
    );

  const bodyElement =
    bodyParams instanceof Array ? (
      <Trans i18nKey={body}>
        {bodyParams.map(([tag, value]) => React.createElement(tag, {}, value))}
      </Trans>
    ) : (
      t(body, bodyParams)
    );

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          className={classes.titleText}
          color="textPrimary"
          component="div"
          variant="h6"
        >
          {headElement}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.body}>{bodyElement}</DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={onNo} color="default">
          {t(disagreeText)}
        </Button>

        <Button onClick={onYes} color="primary" autoFocus>
          {t(agreeText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
