import React from 'react';

interface IProps {
  className?: string;
}

const CloudGear = (props: IProps) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.4 12.8C18.4 13.4365 18.1471 14.047 17.6971 14.4971C17.247 14.9471 16.6365 15.2 16 15.2C14.656 15.2 13.6 14.128 13.6 12.8C13.6 12.1635 13.8529 11.553 14.3029 11.1029C14.753 10.6529 15.3635 10.4 16 10.4C16.6365 10.4 17.247 10.6529 17.6971 11.1029C18.1471 11.553 18.4 12.1635 18.4 12.8ZM32 3.2V22.4C32 23.2487 31.6629 24.0626 31.0627 24.6627C30.4626 25.2629 29.6487 25.6 28.8 25.6H6.4L0 32V3.2C0 2.35131 0.337142 1.53737 0.937258 0.937258C1.53737 0.337142 2.35131 0 3.2 0H28.8C29.6487 0 30.4626 0.337142 31.0627 0.937258C31.6629 1.53737 32 2.35131 32 3.2ZM23.632 14.912L21.92 13.6C21.936 13.328 21.936 13.056 21.92 12.8C21.952 12.544 21.952 12.272 21.92 12L23.616 10.688C23.6879 10.6263 23.7366 10.542 23.7541 10.4489C23.7715 10.3558 23.7567 10.2595 23.712 10.176L22.112 7.408C22.016 7.2 21.808 7.152 21.6 7.2L19.632 8C19.2 7.68 18.768 7.408 18.272 7.2L17.968 5.104C17.9513 5.01856 17.9055 4.94156 17.8384 4.88612C17.7713 4.83068 17.687 4.80024 17.6 4.8H14.4C14.208 4.8 14.032 4.944 14 5.136L13.696 7.248C13.216 7.44 12.752 7.696 12.32 8L10.336 7.2C10.144 7.136 9.936 7.2 9.84 7.376L8.24 10.144C8.144 10.32 8.176 10.528 8.336 10.656L10.032 12C9.968 12.512 9.968 13.056 10.032 13.6L8.336 14.912C8.2641 14.9737 8.21539 15.058 8.19794 15.1511C8.18048 15.2442 8.19532 15.3405 8.24 15.424L9.84 18.192C9.936 18.4 10.144 18.448 10.336 18.4L12.32 17.6C12.736 17.92 13.184 18.192 13.68 18.4L13.984 20.496C14.032 20.688 14.208 20.8 14.4 20.8H17.6C17.792 20.8 17.968 20.656 18 20.464L18.304 18.352C18.784 18.144 19.2 17.888 19.648 17.6L21.648 18.4C21.84 18.4 22.048 18.4 22.144 18.192L23.744 15.424C23.7901 15.3395 23.8041 15.2412 23.7835 15.1471C23.763 15.0531 23.7092 14.9696 23.632 14.912Z" />
  </svg>
);

export default CloudGear;
