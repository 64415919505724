import React from 'react';
import cx from 'classnames';
import useStyles from './styles';

export interface IItem {
  children?: React.ReactNode;
  onClick?: (evt: any) => void;
  isActive?: boolean;
  className?: string;
}

export default function Item({
  isActive = false,
  className = '',
  onClick = () => 1,
  children = null,
}: IItem) {
  const classnames = useStyles();
  return (
    <li
      className={cx(classnames.item, isActive && classnames.active, className)}
      onClick={onClick}
      tabIndex={0}
    >
      {children}
    </li>
  );
}
