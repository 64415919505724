import React from 'react';
import Button from '@material-ui/core/Button';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
const DOTS = '...';

const useStyles = makeStyles(
  ({ greenGrey, blueLight, darkGreen, white, mainBlue, greenDark }) => ({
    root: {
      display: 'flex',
    },
    button: {
      fontSize: 18,
      color: greenGrey,
      height: '2.2rem',
      padding: '0.1rem 1rem',
      '&:hover': {
        background: blueLight,
      },
    },
    pageButton: {
      letterSpacing: 'unset',
      border: '0',
      borderRadius: '30px !important',
      transition: 'all .3s',
      padding: '0px 11px',
      margin: 3,
      minWidth: 30,
      height: 30,
      minHeight: 'auto',
      lineHeight: 30,
      textTransform: 'uppercase',
      background: 'transparent',
      position: 'relative',
      float: 'left',
      textDecoration: 'none',
      boxSizing: 'border-box',

      '&,&:hover,&:focus': {
        color: darkGreen,
      },
      '&:hover,&:focus': {
        zIndex: '3',
        backgroundColor: blueLight,
        borderColor: greenDark,
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    active: {
      '&:hover': {
        color: white,
        backgroundColor: greenDark,
      },
      '&:focus': {
        color: white,
        backgroundColor: mainBlue,
        border: greenDark,
      },
      backgroundColor: mainBlue,
      color: white,
    },
    disabled: {
      display: 'block !important',
    },
  }),
);

export const pagination = (currentPage: number, totalPage: number) => {
  // algorhytm from - https://github.com/superRaytin/paginationjs/blob/master/src/pagination.js
  const range = [];
  const showFirstOnEllipsisShow = true;
  const showLastOnEllipsisShow = true;
  let i;

  const pageRange = 1;

  let rangeStart = currentPage - pageRange;
  let rangeEnd = currentPage + pageRange;

  if (rangeEnd > totalPage) {
    rangeEnd = totalPage;
    rangeStart = totalPage - pageRange * 2;
    rangeStart = rangeStart < 1 ? 1 : rangeStart;
  }

  if (rangeStart <= 1) {
    rangeStart = 1;
    rangeEnd = Math.min(pageRange * 2 + 1, totalPage);
  }

  if (rangeStart <= 3) {
    for (i = 1; i < rangeStart; i++) {
      range.push(i);
    }
  } else {
    if (showFirstOnEllipsisShow) {
      range.push(1);
    }
    range.push(DOTS);
  }

  for (i = rangeStart; i <= rangeEnd; i++) {
    range.push(i);
  }

  if (rangeEnd >= totalPage - 2) {
    for (i = rangeEnd + 1; i <= totalPage; i++) {
      range.push(i);
    }
  } else {
    range.push(DOTS);

    if (showLastOnEllipsisShow) {
      range.push(totalPage);
    }
  }

  return range.map(String);
};

export interface IPaginationProps {
  current: number;
  rowsPerPage: number;
  total: number;
  onChange: (page: number) => void;
}

const Pagination = ({
  current: cur,
  rowsPerPage: rpp,
  total,
  onChange,
}: IPaginationProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const rowsPerPage = rpp < 1 ? 1 : rpp;
  const current = cur < 1 ? 1 : cur;
  const totalPages = Math.ceil(total / rowsPerPage);

  const buttonHandler = (pageNum: string) => () => {
    if (pageNum !== DOTS) {
      onChange(Number(pageNum));
    }
  };

  const onPrev = () => {
    const prevPage = current - 1;

    if (prevPage >= 1) {
      onChange(Number(prevPage));
    }
  };

  const onNext = () => {
    const nextPage = current + 1;
    if (nextPage <= totalPages) {
      onChange(Number(nextPage));
    }
  };
  const buttons = pagination(current, totalPages).map(
    (pageNum: string, i: number) => {
      return (
        <Button
          className={cx(classes.pageButton, {
            [classes.active]: pageNum === String(current),
          })}
          disabled={pageNum === DOTS}
          onClick={buttonHandler(pageNum)}
          key={pageNum + i}
        >
          {pageNum}
        </Button>
      );
    },
  );
  return (
    <div className={classes.root}>
      <Button
        disabled={current < 2}
        onClick={onPrev}
        className={cx(classes.button, { [classes.disabled]: current < 2 })}
      >
        {t('prev')}
      </Button>
      {buttons}
      <Button
        disabled={current >= totalPages}
        onClick={onNext}
        className={cx(classes.button, {
          [classes.disabled]: current >= totalPages,
        })}
      >
        {t('next')}
      </Button>
    </div>
  );
};

export default Pagination;
