// copy from  https://github.com/kontentino/react-multilevel-dropdown

import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './styles';

export interface IDropdownProps {
  button?: any;
  children?: React.ReactNode;
  isDisabled?: boolean;
  position?: 'left' | 'right';
  wrapperClassName?: string;
  menuClassName?: string;
}

export default function Dropdown({
  button,
  children,
  isDisabled = false,
  position = 'left',
}: IDropdownProps) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useRef(document.createElement('div'));
  const menuRef = useRef(document.createElement('ul'));
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const handleClick = useCallback((e) => {
    if (!(menuRef.current && menuRef.current.contains(e.target))) {
      setOpen(false);
    }
  }, []);

  const handleButtonOnClick = () => {
    if (isDisabled) {
      return;
    }

    const { x, y, width } = dropdownRef.current.getBoundingClientRect();

    const offsetX = position === 'left' ? -(width / 2) - 250 : width + 10;

    setLeft(x + window.pageXOffset + offsetX);
    setTop(y + window.pageYOffset);

    setOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const defaultButton = (
    <IconButton>{isOpen ? <UpIcon /> : <DownIcon />}</IconButton>
  );

  return (
    <div className={cx(classes.dropdown)}>
      <div ref={dropdownRef} tabIndex={0} onClick={handleButtonOnClick}>
        {button || defaultButton}
      </div>
      {isOpen && (
        <div className={cx(classes.menu)}>
          {ReactDOM.createPortal(
            <ul
              ref={menuRef}
              className={cx(classes.menuList)}
              style={{ top, left }}
            >
              {children}
            </ul>,
            document.getElementById('root') as HTMLElement,
          )}
        </div>
      )}
    </div>
  );
}

export { default as Item } from './Item';
export { default as Submenu } from './Submenu';
