import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({
    blueLight,
    darkGreen,
    pink,
    white,
    mainBlue,
    greenDark,
    breakpoints,
  }) => ({
    root: {
      [breakpoints.up(1025)]: {
        // minHeight: 500,
      },
    },
    pagination: {
      marginTop: 32,
      alignSelf: 'center',
    },
    empty: {
      '&&': {
        minHeight: 'initial',
      },
    },
    new: {
      background: blueLight,
    },
    hidden: {
      opacity: 0.5,
    },
    isDragging: {
      opacity: 0,
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    table: {
      '& td, & th': {
        color: darkGreen,
      },
      '& th': {
        fontSize: '1rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
      },
    },
    icon: {
      width: '3rem',
      paddingLeft: 0,
      paddingRight: 0,
      cursor: 'pointer',
    },
    description: {
      width: 200,
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    cell: {
      '&&': {
        padding: 10,
        [breakpoints.down('md')]: {
          fontSize: 16,
          textAlign: 'center',
        },
      },
    },
    invite: {
      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    actions: {
      '& > button': {
        backgroundColor: 'initial',
      },
    },
    actionsButton: {
      '&:hover': {
        borderRadius: '50%',
        background: blueLight,
      },
    },
    actionsSubItem: {
      '&& .actionsItemArrowCheckbox': {
        padding: 0,
      },
      '&& .actionsItemArrowCheckbox svg': {
        width: 20,
      },
      '&&:hover': {
        color: white,
        background: mainBlue,

        '&& .actionsItemArrowCheckbox svg': {
          fill: white,
        },
      },
    },
    actionsItem: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 250,
      '&&': {
        fontSize: 18,
      },
      color: darkGreen,
      '&&:hover': {
        color: white,
        background: mainBlue,
        '& .actionsItemIcons > svg': {
          fill: white,
        },
        '&& .actionsItemArrow': {
          boxSizing: 'border-box',
          width: 6,
          height: 6,
          borderWidth: '6px 0px 6px 9px',
          border: `6px solid ${white}`,
          borderColor: `transparent transparent transparent ${white}`,
        },
      },
      '&& .actionsItemIcons > svg': {
        width: 15,
        height: 16,
        marginRight: '.5rem',
        fill: greenDark,
      },
      '&& .resetCode': {
        position: 'relative',
      },
      '&& .resetCode > span': {
        marginLeft: 24,
      },
      '&& .resetCode > svg': {
        position: 'absolute',
        fontSize: 20,
        top: 3,
        left: 0,
      },
    },
    actionsArrow: {
      boxSizing: 'border-box',
      width: 6,
      height: 6,
      borderWidth: '6px 0px 6px 9px',
      border: `6px solid ${greenDark}`,
      borderColor: `transparent transparent transparent ${greenDark}`,
    },
    name: {
      '&&': {
        fontWeight: 600,
      },
    },
    recording: {
      '&&': { color: pink },
    },
    playback: {
      '&&': { color: mainBlue },
    },
  }),
  { name: 'BelovedTable' },
);
