// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { PLAY, STOP, TICK, PAUSE_RESUME } from '../actions/player';
import { PlayerState } from '../types';

export interface IPlayer {
  isPlaying: boolean;
  status: PlayerState;
  messageId: string;
  messageUrl: string;
  currentTime: number;
  duration: number;
}

interface IState {
  player: IPlayer;
}

export const initialState: IPlayer = {
  isPlaying: false,
  status: 'inactive',
  messageId: '',
  messageUrl: '',
  currentTime: 0,
  duration: 0,
};

export const getPlayer = (state: IState): IPlayer => state.player;

export default createReducer(initialState, {
  [PLAY]: ({ messageId, messageUrl }) => ({
    messageId,
    messageUrl,
    isPlaying: true,
    status: 'playing',
  }),
  [TICK]: ({ duration, currentTime }) => ({ duration, currentTime }),
  [STOP]: () => initialState,
  [PAUSE_RESUME]: ({}, { isPlaying }) => ({
    isPlaying: !isPlaying,
    status: isPlaying ? 'paused' : 'playing',
  }),
});
