import React from 'react';
interface IProps {
  active?: boolean;
  color?: string;
  width?: number;
  height?: number;
}

const HeartIcon = ({
  active,
  color = '#F7957E',
  width = 24,
  height = 22,
}: IProps) => {
  const empty = (
    <path
      d="M17.5275 1.76C18.1515 1.75946 18.7692 1.88684 19.3446 2.13466C19.92 2.38248 20.4414 2.74576 20.8783 3.2032C21.7785 4.14162 22.2831 5.40598 22.2831 6.7232C22.2831 8.04043 21.7785 9.30479 20.8783 10.2432L12 19.4744L3.12172 10.2432C2.22153 9.30479 1.71695 8.04043 1.71695 6.7232C1.71695 5.40598 2.22153 4.14162 3.12172 3.2032C3.55885 2.74609 4.08031 2.383 4.65563 2.13514C5.23095 1.88728 5.8486 1.75962 6.4725 1.75962C7.0964 1.75962 7.71405 1.88728 8.28936 2.13514C8.86468 2.383 9.38614 2.74609 9.82328 3.2032L12 5.4912L14.1682 3.2208C14.6037 2.75791 15.1257 2.38996 15.7029 2.13896C16.2801 1.88797 16.9007 1.75908 17.5275 1.76ZM17.5275 2.36091e-06C16.6753 -0.000739233 15.8316 0.173235 15.0458 0.511724C14.26 0.850213 13.5479 1.3464 12.9512 1.9712L12 2.9568L11.0488 1.9712C10.4514 1.34753 9.73912 0.852202 8.95344 0.51409C8.16775 0.175977 7.32439 0.00184574 6.4725 0.00184574C5.62061 0.00184574 4.77724 0.175977 3.99156 0.51409C3.20587 0.852202 2.49359 1.34753 1.89624 1.9712C0.68094 3.24159 0 4.9512 0 6.732C0 8.51281 0.68094 10.2224 1.89624 11.4928L12 22L22.1038 11.4928C23.3191 10.2224 24 8.51281 24 6.732C24 4.9512 23.3191 3.24159 22.1038 1.9712C21.5066 1.3472 20.7943 0.851528 20.0087 0.513096C19.223 0.174664 18.3795 0.000242966 17.5275 2.36091e-06Z"
      fill={color}
    />
  );
  const full = (
    <path
      d="M17.5516 0C15.8434 0 14.2206 0.704 13.0249 1.936L12 2.992L11.0605 2.024C8.58363 -0.616 4.48399 -0.616 2.00712 1.936L1.92171 2.024C-0.640569 4.664 -0.640569 8.888 1.92171 11.528L12 22L22.0783 11.528C24.6406 8.888 24.6406 4.664 22.0783 2.024C20.8826 0.704 19.2598 0 17.5516 0Z"
      fill={color}
    />
  );

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {active ? full : empty}
    </svg>
  );
};

export default HeartIcon;
