import {
  googleAnalyticsDuration,
  localStorageKey,
  sessionStorageKey,
} from '../constants';

export const cookieConsentName = 'CookieConsent';

// Cookie categories unique values
export enum CookieCategory {
  Necessary = 'cn1',
  Analytics = 'ba3',
  Targeting = 'xt2',
}

export enum CookieDurationType {
  Session = 'session',
  Persistent = 'persistent',
  Variable = 'variable',
}

export enum CookieStorageType {
  HTTPCookie = 'http-cookie',
  HTMLLocalStorage = 'html-local-storage',
}

export const firstPartyCookies = [
  {
    category: CookieCategory.Necessary,
    name: cookieConsentName,
    storage: CookieStorageType.HTTPCookie,
    purpose: 'cookie-consent',
    durationType: CookieDurationType.Variable,
    durationLength: 1,
    durationUnit: 'year',
  },
  {
    category: CookieCategory.Necessary,
    name: localStorageKey,
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-soundofyourlove-token',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Necessary,
    name: `${sessionStorageKey}_record__`,
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-soyl-recording-invite',
    durationType: CookieDurationType.Session,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Necessary,
    name: `${sessionStorageKey}_playback__`,
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-soyl-playback-invite',
    durationType: CookieDurationType.Session,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Necessary,
    name: 'i18nextLng',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-i18next-lng',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Analytics,
    name: '_gid',
    storage: CookieStorageType.HTTPCookie,
    purpose: 'cookie-ga-gid',
    durationType: CookieDurationType.Variable,
    durationLength: 1,
    durationUnit: 'day',
  },
  {
    category: CookieCategory.Analytics,
    name: '_ga',
    storage: CookieStorageType.HTTPCookie,
    purpose: 'cookie-ga-ga',
    durationType: CookieDurationType.Variable,
    durationLength: googleAnalyticsDuration,
    durationUnit: 'day',
  },
  {
    category: CookieCategory.Analytics,
    name: '_gat',
    storage: CookieStorageType.HTTPCookie,
    purpose: 'cookie-ga-gat',
    durationType: CookieDurationType.Variable,
    durationLength: 1,
    durationUnit: 'minute',
  },
  {
    category: CookieCategory.Analytics,
    name: 'amp_',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-amp-id',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Analytics,
    name: 'amplitude_unsent_',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-amplitude-unsent-id',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Analytics,
    name: 'amplitude_unsent_identify_',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-amplitude_unsent-identify-id',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
];

export const thirdPartyCookies = [
  {
    category: CookieCategory.Targeting,
    name: 'yt-remote-connected-devices',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-remote-connected-devices',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt-remote-device-id',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-remote-device-id',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt.innertube::nextId',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-innertube-nextId',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt.innertube::requests',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-innertube-requests',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt-player-bandaid-host',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-player-bandaid-host',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt-player-bandwidth',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-player-bandwidth',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt-player-headers-readable',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-player-headers-readable',
    durationType: CookieDurationType.Persistent,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt-remote-fast-check-period',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-remote-fast-check-period',
    durationType: CookieDurationType.Session,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt-remote-session-app',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-remote-session-app',
    durationType: CookieDurationType.Session,
    durationLength: 0,
    durationUnit: '',
  },
  {
    category: CookieCategory.Targeting,
    name: 'yt-remote-session-name',
    storage: CookieStorageType.HTMLLocalStorage,
    purpose: 'cookie-yt-remote-session-name',
    durationType: CookieDurationType.Session,
    durationLength: 0,
    durationUnit: '',
  },
];

export const cookieCategoryDescription = (category: CookieCategory) => {
  switch (category) {
    case CookieCategory.Necessary:
      return 'necessary';
    case CookieCategory.Analytics:
      return 'analytics';
    case CookieCategory.Targeting:
      return 'targeting';
    default:
      return 'uknown';
  }
};

interface CookieSetOptions {
  path?: string;
  expires?: Date;
}

type CookieSetFunc = (
  name: string,
  value: string,
  options?: CookieSetOptions,
) => void;

export const saveCookie = (
  categories: { [CookieCategory: string]: boolean },
  setCookie: CookieSetFunc,
) => {
  const newCookie = Object.keys(categories)
    .filter((key) => categories[key])
    .join('.');
  const expirationDate = new Date();
  // Expires in 1 year
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  setCookie(cookieConsentName, newCookie, {
    path: '/',
    expires: expirationDate,
  });
};

export const isCookieCategoryFound = (
  cookie: string,
  category: CookieCategory,
) => {
  return cookie.split('.').includes(category);
};

export const isCookieOptOutAnalytics = (cookie: string) => {
  return !(cookie && cookie.split('.').includes(CookieCategory.Analytics));
};
