import React from 'react';

const Download = (props: any) => (
  <svg
    {...props}
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5001 16.5L18.9001 9.625H14.8501V0H12.1501V9.625H8.1001L13.5001 16.5Z"
      fill="#256065"
    />
    <path
      d="M24.3 19.25H2.7V9.625H0V19.25C0 20.7666 1.21095 22 2.7 22H24.3C25.7891 22 27 20.7666 27 19.25V9.625H24.3V19.25Z"
      fill="#256065"
    />
  </svg>
);

export default Download;
