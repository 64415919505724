import { makeStyles } from '@material-ui/core';

export default makeStyles(
  ({ greenDark, breakpoints }) => ({
    title: {
      fontSize: 24,
      fontWeight: 600,
      color: greenDark,
    },
    input: {
      width: '100%',
    },
    button: {
      width: '60%',
      minWidth: 200,
      [breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 40,
      },
    },
  }),
  { name: 'SettingsShared' },
);
