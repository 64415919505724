import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import app from './app';
import auth from './auth';
import beloved from './beloved';
import messages from './messages';
import folders from './folders';
import player from './player';
import notification from './notification';
import confirmation from './confirmation';
import invite from './invite';
import record from './record';
import playback from './playback';
import { RESET_APP } from '../actions/root';

const rootReducer = (history: History) => (state: any, action: any) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }
  return allReducers(history)(state, action);
};

const allReducers = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    folders,
    messages,
    beloved,
    player,
    notification,
    confirmation,
    invite,
    record,
    playback,
  });

export default rootReducer;
