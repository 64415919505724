import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/muiTheme';
import { AmplitudeProvider } from 'react-amplitude-hooks';
import amplitude from 'amplitude-js';
import store from './store';
import './i18n';
import { CookiesProvider } from 'react-cookie';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const rootElement = document.getElementById('root') as HTMLElement;

const render = (Application: React.FC): void => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AmplitudeProvider
          amplitudeInstance={amplitude.getInstance()}
          apiKey={process.env.REACT_APP_AMPLITUDE_API_KEY || ''}
          config={{
            disableCookies: true,
            includeUtm: true,
            includeReferrer: true,
          }}
        >
          <ThemeProvider theme={theme}>
            <CookiesProvider>
              <PayPalScriptProvider
                options={{
                  'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID || 'sb',
                }}
              >
                <Application />
              </PayPalScriptProvider>
            </CookiesProvider>
          </ThemeProvider>
        </AmplitudeProvider>
      </Provider>
    </React.StrictMode>,
    rootElement,
  );
};

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

render(App);

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe');
//   axe(React, ReactDOM, 1000);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
