// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import find from 'lodash/find';
import {
  FETCH,
  FETCH_FAILURE,
  FETCH_SUCCESS,
  UPDATE_SUCCESS,
  DELETE_SUCCESS,
  ADD_SUCCESS,
  UPDATE,
  SORT_SUCCESS,
} from '../actions/messages';
import { LISTEN } from '../actions/player';
import { IMessage } from '../types.d';

export interface IMessages {
  isLoading?: boolean;
  list: IMessage[];
  error?: Error;
}

interface IState {
  messages: IMessages;
}

export const initialState: IMessages = {
  isLoading: false,
  list: [],
  error: undefined,
};

export const getMessages = (state: IState): IMessages => state.messages;

const updateMessageInList = (list: IMessage[], updated: IMessage) =>
  list.map((m) => (m._id === updated._id ? updated : m));

export default createReducer(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: (list) => ({ list, isLoading: false }),
  [FETCH_FAILURE]: (error) => ({ error, isLoading: false }),
  [UPDATE]: () => ({ isLoading: true }),
  [UPDATE_SUCCESS]: (message: IMessage, { list }) => {
    const messages = updateMessageInList(list, message);
    return {
      isLoading: false,
      list: messages,
    };
  },
  [SORT_SUCCESS]: ({ messages }, { list }) => ({
    list: list.map((msg: IMessage) => find(messages, { _id: msg._id }) || msg),
  }),
  // remove message from list
  [DELETE_SUCCESS]: (message: IMessage, { list }) => {
    const removed = list.filter((m: IMessage) => m._id !== message._id);
    return {
      isLoading: false,
      list: removed,
      backup: removed,
    };
  },
  // add message to the list
  [ADD_SUCCESS]: (newMessage: IMessage, { list }) => ({
    list: [newMessage, ...list],
  }),
  [LISTEN]: (_id, { list }) => ({
    list: list.map((l: IMessage) =>
      l._id === _id ? { ...l, viewed: true } : l,
    ),
  }),
});
