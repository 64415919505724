import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ blueLight, darkGreen, greyLight, breakpoints }) => ({
    root: {
      minHeight: 500,
    },
    pagination: {
      marginTop: 32,
      alignSelf: 'center',
      '&& span': {
        lineHeight: 1,
      },
    },
    empty: {
      '&&': {
        minHeight: 'initial',
      },
      '&& th': {
        borderBottom: `2px solid ${greyLight}`,
      },
    },
    isActive: {
      background: blueLight,
    },
    cell: {},
    tableContainer: {
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    table: {
      '& td, & th': {
        color: darkGreen,
      },
      '& th': {
        fontSize: '1rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
      },
    },
    playButton: {
      padding: '1rem',
    },
    playIcon: {
      '&&': {
        width: 48,
        paddingLeft: '1rem',
      },
    },
    icon: {
      width: '3rem',
      paddingLeft: 0,
      paddingRight: 0,
      cursor: 'pointer',
      [breakpoints.down(1440)]: {
        width: '1rem',
      },
    },
    row: {
      [breakpoints.down(1440)]: {
        '& td': {
          fontSize: 16,
        },
      },
      cursor: 'grab',
    },
    createdAt: {},
    duration: {
      minWidth: 50,
    },
    description: {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'PlaybackMessagesTable' },
);
