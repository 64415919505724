import React from 'react';
import cx from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Menu from './Menu';

import { IBeloved, BelovedActions, InviteActions } from '../../types.d';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface IBelovedTableRow {
  beloved: IBeloved;
  onChange: (type: BelovedActions, beloved: IBeloved) => void;
  onInvite: (type: InviteActions, beloved: IBeloved) => void;
}

const BelovedTableRow = ({ beloved, onChange, onInvite }: IBelovedTableRow) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { name, recordPasscode, playbackPasscode } = beloved;

  return (
    <TableRow>
      <TableCell className={cx(classes.cell, classes.name)}>{name}</TableCell>
      <TableCell className={cx(classes.cell, classes.recording)}>
        {recordPasscode}
        <br />
        <Link
          className={classes.invite}
          onClick={() => onInvite(InviteActions.record, beloved)}
          color="secondary"
          variant="body2"
        >
          {t('show-invite')}
        </Link>
      </TableCell>
      <TableCell className={cx(classes.cell, classes.playback)}>
        {playbackPasscode}
        <br />
        <Link
          className={classes.invite}
          onClick={() => onInvite(InviteActions.playback, beloved)}
          color="secondary"
          variant="body2"
        >
          {t('show-invite')}
        </Link>
      </TableCell>
      <TableCell className={cx(classes.cell, classes.icon)}>
        <Menu onSelect={(type: BelovedActions) => onChange(type, beloved)} />
      </TableCell>
    </TableRow>
  );
};

export default BelovedTableRow;
