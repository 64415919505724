import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';

import {
  Box,
  Grid,
  Button,
  Hidden,
  Typography as Text,
  Paper,
} from '@material-ui/core';

import alone from '../../assets/alone.svg';
import dashboard from '../../assets/dashboard.svg';
import recorder from '../../assets/recorder.svg';
import passcode from '../../assets/passcode.svg';
import heart from '../../assets/heart.svg';
// import doctor from '../../assets/doctor.svg';
// import charity from '../../assets/charity.svg';
// import nurse from '../../assets/nurse.svg';
// import friends from '../../assets/friends.svg';
// import pastor from '../../assets/pastor.svg';

// import BillingPlan from '../../components/BillingPlan';
import Video from '../../components/Video';
import { goTo } from '../../utils/helpers';
import { Routes, testimonials } from '../../constants';
import { getAuth, IAuthState } from '../../reducers/auth';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { authorized }: IAuthState = useSelector(getAuth);
  const carouselRef = useRef<any>(null);
  const testimonialTime = 15000;

  let resetTimeout: NodeJS.Timeout;

  const steps = [
    {
      id: 'step1',
      title: t('step1'),
      head: t('step1-head'),
      description: t('step1-description'),
      image: dashboard,
      reverse: false,
    },
    {
      id: 'step2',
      title: t('step2'),
      head: t('step2-head'),
      description: t('step2-description'),
      image: recorder,
      reverse: true,
    },
    {
      id: 'step3',
      title: t('step3'),
      head: t('step3-head'),
      description: t('step3-description'),
      image: passcode,
      reverse: false,
    },
  ];

  // const auditoryList = [
  //   {
  //     id: 'doctor',
  //     title: t('for-healthcare-workers'),
  //     description: t('bring-soundofyourlove-to-your-hospital'),
  //     icon: doctor,
  //     route: Routes.Healthcare,
  //   },
  //   {
  //     id: 'hospice',
  //     title: t('for-hospice-workers'),
  //     description: t('bring-soundofyourlove-to-your-hospice'),
  //     icon: charity,
  //     route: Routes.Hospice,
  //   },
  //   {
  //     id: 'chaplains',
  //     title: t('for-chaplains'),
  //     description: t('offer-soundofyourlove-as-resource'),
  //     icon: nurse,
  //     route: Routes.Chaplain,
  //   },
  //   {
  //     id: 'friends',
  //     title: t('for-friends-and-family'),
  //     description: t('create-soundtrack-of-love'),
  //     icon: friends,
  //     route: Routes.Friends,
  //   },
  //   {
  //     id: 'church',
  //     title: t('for-faith-leaders'),
  //     description: t('share-soundofyourlove-with-your-congregation'),
  //     icon: pastor,
  //     route: Routes.FaithLeaders,
  //   },
  // ];

  const sloganSection = (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        justify="center"
        className={classes.sloganBox}
      >
        <Grid md={6} sm={12} item>
          <Text className={classes.slogan}>{t('slogan')}</Text>
          <Text color={'secondary'} className={classes.serviceDescription}>
            {t('slogan-description')}
          </Text>
          <Box mt={5}>
            {!authorized && (
              <Button
                onClick={goTo(Routes.SignUp)}
                className={classes.signupButton}
                color="primary"
                variant="contained"
              >
                {t('sign-up-for-free')}
              </Button>
            )}
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid md={6} sm={12} item>
            <Box className={classes.videoBox}>
              <Video videoId="_V2e0ZG_qgA" />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden mdUp>
        <Box display="flex" my={5} justifyContent="center" textAlign="center">
          <Video videoId="_V2e0ZG_qgA" />
        </Box>
      </Hidden>
    </>
  );

  // const quoteSection = (
  //   <Box display="flex" alignSelf="center" className={classes.quoteBox}>
  //     <Text component="i">{t('home-page-quote')}</Text>
  //   </Box>
  // );

  const stepsSection = (
    <Grid container justify="center" spacing={3} className={classes.stepsBox}>
      <Grid item>
        <Text className={classes.stepsTitle}>{t('home-page-steps-title')}</Text>
      </Grid>
      {steps.map(({ id, title, head, description, image, reverse }) => (
        <Grid
          key={id}
          container
          spacing={10}
          direction={reverse ? 'row-reverse' : 'row'}
          justify="space-between"
          className={classes.step}
        >
          <Grid item md={5} sm={12}>
            <Hidden mdUp>
              <Text className={classes.stepTitle}>{title}</Text>
            </Hidden>
            <img src={image} alt="recorder" />
          </Grid>
          <Grid item md={7} sm={12}>
            <Hidden smDown>
              <Text className={classes.stepTitle}>{title}</Text>
            </Hidden>
            <Text variant="h2" color="primary" className={classes.stepHead}>
              {head}
            </Text>
            <Text color="secondary" className={classes.stepDescription}>
              {description}
            </Text>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const testimonialSection = (
    <Box my={25} className={classes.story}>
      <Box my={7} textAlign="center">
        <Text className={classes.storyTitle}>{t('testimonials')}</Text>
      </Box>
      <Paper elevation={3} className={classes.storyBox}>
        <Box className={classes.storyIcon}>
          <img src={heart} alt="heart" />
        </Box>
        <Box pt={10} pb={5} px={5}>
          <Carousel
            enableSwipe
            ref={carouselRef}
            enableAutoPlay
            disableArrowsOnEnd={false}
            autoPlaySpeed={testimonialTime}
            className={classes.carousel}
            itemsToShow={1}
            onNextEnd={({ index }: any) => {
              clearTimeout(resetTimeout);
              if (index + 1 === testimonials.length) {
                resetTimeout = setTimeout(() => {
                  carouselRef.current && carouselRef.current.goTo(0);
                }, testimonialTime); // same time
              }
            }}
          >
            {testimonials.map(({ text, author }) => (
              <Text key={author} className={classes.storyText}>
                {text}
                <br />
                <br />
                {'– '}
                {author}
              </Text>
            ))}
          </Carousel>
        </Box>
      </Paper>
    </Box>
  );

  const summarySection = (
    <Box display="flex" my={5} justifyContent="center">
      <Grid
        container
        spacing={10}
        direction="row"
        justify="space-between"
        className={classes.summary}
      >
        <Grid item md={5} sm={12}>
          <Hidden mdUp>
            <Text variant="h2" color="primary" className={classes.summaryHead}>
              {t('slogan')}
            </Text>
          </Hidden>
          <img width="100%" src={alone} alt="Alone" />
        </Grid>
        <Grid item md={7} sm={12}>
          <Hidden smDown>
            <Text variant="h2" color="primary" className={classes.summaryHead}>
              {t('slogan')}
            </Text>
          </Hidden>
          <Text color="secondary" className={classes.summaryDescription}>
            {t('home-page-quote')}
          </Text>
        </Grid>
      </Grid>
    </Box>
  );

  const divider = (
    <Box className={classes.dividerBox}>
      <hr style={{ borderTop: '1px solid #333', width: '80%' }} />
    </Box>
  );

  const instructionVideoSection = (
    <Box display="flex" my={5} justifyContent="center" textAlign="center">
      <Video videoId="WZle1xmqx6M" />
    </Box>
  );

  // const priceSection = (
  //   <Grid
  //     container
  //     direction="column"
  //     spacing={10}
  //     style={{
  //       textAlign: 'center',
  //       width: '100%',
  //       maxWidth: 1217,
  //       alignSelf: 'center',
  //       padding: 20,
  //     }}
  //   >
  //     <Grid item>
  //       <Text className={classes.stepsTitle}>{t('pricing')}</Text>
  //     </Grid>
  //     <Grid item>
  //       <BillingPlan currentPlan={'basic'} />
  //     </Grid>
  //   </Grid>
  // );

  // const auditorySection = (
  //   <Grid container alignItems="center" justify="center" spacing={3}>
  //     <Grid item>
  //       <Text className={classes.stepsTitle}>{t('how-can-we-help-you')}</Text>
  //     </Grid>
  //     <Grid container item spacing={3} alignItems="center">
  //       {auditoryList.map(({ id, title, description, icon, route }) => (
  //         <Grid
  //           md={4}
  //           xs={12}
  //           key={id}
  //           item
  //           style={{ padding: 25, textAlign: 'center', height: '100%' }}
  //           container
  //           direction="column"
  //           justify="space-between"
  //           alignItems="center"
  //         >
  //           <Box mt={8} mb={3}>
  //             <img src={icon} alt={id} />
  //           </Box>
  //           <Text className={classes.auditoryTitle}>{title}</Text>
  //           <Text className={classes.auditoryDescription}>{description}</Text>
  //           <Box style={{ justifySelf: 'flex-end' }} my={3} width="50%">
  //             <Button
  //               onClick={goTo(route)}
  //               color="primary"
  //               className={classes.auditoryButton}
  //               variant="contained"
  //             >
  //               {t('learn-more')}
  //             </Button>
  //           </Box>
  //         </Grid>
  //       ))}
  //     </Grid>
  //   </Grid>
  // );
  return (
    <Box
      className={classes.root}
      display="flex"
      width="100%"
      py={15}
      flexDirection="column"
      justifyContent="space-between"
    >
      {sloganSection}
      {/*quoteSection*/}
      {divider}
      {summarySection}
      {stepsSection}
      {instructionVideoSection}
      {testimonialSection}
      {/* {priceSection} */}
      {/* auditorySection */}
    </Box>
  );
};

export default Home;
