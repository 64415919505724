import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import GearIcon from '@material-ui/icons/Settings';
import {
  CloudGearIcon,
  NotaListIcon,
  FAQIcon,
  HeartIcon,
} from '../components/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import logo from '../images/logo-light.png';
import wave from '../assets/wave.svg';

import { logout } from '../actions/auth';
import { getAuth, IAuthState } from '../reducers/auth';
import { Routes, protectedRoutes } from '../constants';
import Dropdown from '../components/Dropdown';

const useStyles = makeStyles(
  ({
    white,
    darkGreen,
    greenDark,
    mainBlue,
    blueLight,
    greyLight,
    greenGrey,
    breakpoints: { up, down },
  }) => ({
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    guest: {
      '& .MuiPaper-root': {
        background: 'rgba(255,255,255, 0)',
        boxShadow: 'initial',
        '& a': {
          color: darkGreen,
        },
      },
      [down(960)]: {
        '& $wave': {
          height: 300,
          backgroundSize: 'cover',
        },
      },
      [down(601)]: {
        '& $wave': {
          height: 235,
        },
      },
      [down(481)]: {
        '& $wave': {
          height: 190,
        },
      },
      [down(420)]: {
        '& $wave': {
          height: 170,
        },
      },
    },
    wave: {
      position: 'absolute',
      backgroundImage: `url(${wave})`,
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
    },
    navBar: {
      minHeight: 100,
      maxWidth: 1550,
      width: '100%',
      alignSelf: 'center',
    },
    list: {
      width: 250,
    },
    listItem: {
      '&&:hover': {
        backgroundColor: mainBlue,
        '&& .soylsvgfaqquestion': {
          fill: white,
        },
        '&& .soylsvgfaqanswer': {
          fill: blueLight,
        },
        '&& .soylsvgfaqtext': {
          fill: mainBlue,
        },
        '&& svg': {
          fill: white,
        },
        '&& span': {
          color: white,
        },
      },
      '&& svg': {
        fill: darkGreen,
      },
      '&& .soylsvgfaqquestion': {
        fill: greenDark,
      },
      '&& .soylsvgfaqanswer': {
        fill: darkGreen,
      },
      '&& .soylsvgfaqtext': {
        fill: greyLight,
      },
    },
    title: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '21px',
      color: white,
      textTransform: 'uppercase',
      marginLeft: '1.3rem',
    },
    logo: {
      height: 50,
    },
    avatar: {
      color: darkGreen,
      background: blueLight,
      fontSize: '1rem',
      fontWeight: 600,
      cursor: 'pointer',
      textTransform: 'uppercase',
    },
    avatarButton: {
      margin: 'auto 1rem',
      marginRight: 70,
      '&& svg': {
        fill: white,
      },
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
      padding: '0.1rem 1rem',
      height: '2.2rem',
      alignSelf: 'center',
      color: white,
      '&:hover': {
        background: 'rgba(255,255,255,0.2)',
      },
    },
    donate: {
      background: '#e7fcff',
    },
    sectionDesktop: {
      display: 'none',
      [up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [up('md')]: {
        display: 'none',
      },
    },
    username: {},
    userDD: {
      minWidth: 200,
      marginRight: 70,
      '&& $username': {
        cursor: 'initial',
        textTransform: 'capitalize',
        fontWeight: 600,
        color: darkGreen,
        '&:hover': {
          color: darkGreen,
          backgroundColor: white,
        },
        '& span': {
          fontWeight: 'normal',
          textTransform: 'lowercase',
        },
      },
      '&& li, && a': {
        display: 'flex',
        justifyContent: 'center',
        textTransform: 'uppercase',
        color: greenGrey,
        fontSize: 18,
        '&:hover': {
          color: white,
          backgroundColor: mainBlue,
        },
      },
      '&& a': {
        color: mainBlue,
      },
    },
  }),
  { name: 'Navbar' },
);

function Navbar() {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const { authorized, user }: IAuthState = useSelector(getAuth);

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const openMenu = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  function handleLoginLogout() {
    if (authorized) {
      dispatch(logout());
    } else {
      dispatch(push(Routes.Login));
    }
  }

  const isWave = !protectedRoutes.some((r) =>
    window.location.pathname.includes(r),
  );

  return (
    <div className={cx(classes.root, { [classes.guest]: isWave })}>
      {isWave && <div className={cx(classes.wave)} />}
      <AppBar position="static">
        <Toolbar className={classes.navBar}>
          <IconButton color="primary" aria-label="Menu">
            <Link component={RouterLink} to={Routes.Home}>
              <img src={logo} className={classes.logo} alt="logo" />
            </Link>
          </IconButton>
          <div className={classes.title}>Sound of Your Love</div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button
              component={RouterLink}
              to={Routes.Home}
              className={classes.menuButton}
            >
              {t('home')}
            </Button>
            {authorized && (
              <>
                <Button
                  component={RouterLink}
                  to={Routes.Messages}
                  className={classes.menuButton}
                >
                  {t('messages')}
                </Button>
                <Button
                  component={RouterLink}
                  to={Routes.Setup}
                  className={classes.menuButton}
                >
                  {t('setup')}
                </Button>
              </>
            )}
            <Button
              component={RouterLink}
              to={Routes.FAQ}
              className={classes.menuButton}
            >
              {t('faq')}
            </Button>
            <Button
              component={RouterLink}
              to={Routes.Donate}
              color="primary"
              className={cx(classes.menuButton, { [classes.donate]: isWave })}
            >
              {t('donate')}
            </Button>
            {!authorized && (
              <Button
                component={RouterLink}
                to={Routes.Login}
                className={classes.menuButton}
                startIcon={<LockOutlinedIcon />}
              >
                {t('log-in')}
              </Button>
            )}
            {authorized && (
              <>
                <Dropdown
                  className={classes.userDD}
                  button={
                    <IconButton className={classes.avatarButton}>
                      <Avatar
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className={classes.avatar}
                      >
                        {(user.firstname || '')[0] || ''}
                        {(user.lastname || '')[0] || ''}
                      </Avatar>
                    </IconButton>
                  }
                >
                  <MenuItem className={classes.username}>
                    <div>
                      {user.firstname} {user.lastname} <span>({t('you')})</span>
                    </div>
                  </MenuItem>

                  <MenuItem component={RouterLink} to={Routes.Settings}>
                    <div>{t('settings')}</div>
                  </MenuItem>
                  <MenuItem onClick={handleLoginLogout}>
                    <div>{t('logout')}</div>
                  </MenuItem>
                </Dropdown>
                {/* <Button
                  className={classes.menuButton}
                  startIcon={<CalendarIcon />}
                >
                  Basic plan
                </Button> */}
              </>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={openMenu}
              className={cx(classes.avatarButton)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={Boolean(menuAnchor)} onClose={closeMenu}>
        <div
          className={cx(classes.list)}
          role="presentation"
          onClick={closeMenu}
          onKeyDown={closeMenu}
        >
          <List>
            {!authorized && (
              <ListItem
                button
                onClick={handleLoginLogout}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <LockOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('login')} />
              </ListItem>
            )}
            {authorized && (
              <>
                <ListItem>
                  <ListItemText
                    primary={
                      <span>
                        {user.firstname} {user.lastname}{' '}
                        <span>({t('you')})</span>
                      </span>
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  to={Routes.Messages}
                  component={RouterLink}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <NotaListIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('messages')} />
                </ListItem>
                <ListItem
                  button
                  to={Routes.Setup}
                  component={RouterLink}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <CloudGearIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('setup')} />
                </ListItem>
                <ListItem
                  button
                  to={Routes.Settings}
                  component={RouterLink}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <GearIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('settings')} />
                </ListItem>
              </>
            )}
            <ListItem
              button
              to={Routes.FAQ}
              component={RouterLink}
              className={classes.listItem}
            >
              <ListItemIcon>
                <FAQIcon />
              </ListItemIcon>
              <ListItemText primary={t('faq')} />
            </ListItem>
            <ListItem
              button
              to={Routes.Donate}
              component={RouterLink}
              className={cx([classes.listItem, classes.donate])}
            >
              <ListItemIcon>
                <HeartIcon active={true} color="#1A4346" />
              </ListItemIcon>
              <ListItemText primary={t('donate')} />
            </ListItem>
            {authorized && (
              <ListItem
                button
                onClick={handleLoginLogout}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('logout')} />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
}

export default Navbar;
