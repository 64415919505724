import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getLocation } from 'connected-react-router';
import { saveRecording, makeFavorite, showInFolders } from '../utils/api';
import { callApi } from '../utils/helpers';
import { IAction } from '../utils/redux-create-reducer';
import get from 'lodash/get';
import find from 'lodash/find';
import { Routes } from '../constants';

import {
  ISaveMessageAction,
  saveRecordingSuccess,
  saveRecordingFailure,
  SAVE_RECORDING,
} from '../actions/record';
import { notify } from '../actions/notification';
import { addSuccess as addMessage } from '../actions/messages';
import { saveRecordingSuccess as onboardingSaveRecordingSuccess } from '../actions/beloved';

import { getFolders, IFolders } from '../reducers/folders';

import amplitude from '../utils/analytics';

function* saveMessageWorker({
  payload: { beloved, recordPasscode, audioData, duration },
}: IAction<ISaveMessageAction>): any {
  const location = yield select(getLocation);
  const { list: folders, selectedFolderId }: IFolders = yield select(
    getFolders,
  );
  const formData = new FormData();
  formData.append('invitationCode', beloved.invitationCode.toString());
  formData.append('recordPasscode', recordPasscode.toString());
  formData.append('messageDuration', duration.toString());
  formData.append('messageFile', audioData);
  try {
    let { data: message } = yield call(callApi, saveRecording, formData);
    amplitude.SAVE_RECORDING({
      belovedId: beloved._id,
      messageId: message._id,
      duration: message.duration,
    });
    const route = get(location, 'pathname', '');
    switch (route) {
      // If saving from Messages page, update message based on
      // the current selected folder/favorite
      case Routes.Messages: {
        if (selectedFolderId === 'favorites') {
          const { data: msg } = yield call(
            callApi,
            makeFavorite,
            message._id,
            true,
          );
          message = { ...msg };
        } else if (selectedFolderId !== 'all') {
          const folderIds = [];
          folderIds.push(selectedFolderId);
          const { data: msg } = yield call(
            callApi,
            showInFolders,
            message._id,
            folderIds,
          );
          // Add folder name
          const selectedFolder = find(folders, ['_id', selectedFolderId]);
          if (selectedFolder && msg.folderListOrder.length === 1) {
            const folderListOrder = {
              ...msg.folderListOrder[0],
              name: selectedFolder.name,
            };
            msg.folderListOrder = [folderListOrder];
          }
          message = { ...msg };
        }
        yield put(addMessage(message));
        break;
      }
      // If saving from onboarding flow, update that flow
      case Routes.Setup: {
        yield put(onboardingSaveRecordingSuccess());
        break;
      }
    }
    yield put(saveRecordingSuccess());
    yield put(notify('save-message-success'));
  } catch (err) {
    yield put(saveRecordingFailure((err as Error).message));
    yield put(notify('save-message-failure', 'error'));
  }
}

export default function* recordSaga() {
  yield takeLatest(SAVE_RECORDING, saveMessageWorker);
}
