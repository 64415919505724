import eq from 'lodash/eq';
import * as selectors from '../selectors';

/**
 * Return color from green to red base on number
 * @function
 * @param timeDiff {number}
 * @return {string}
 */
const getTimeDiffColor = (timeDiff: number) => {
  return timeDiff > 500
    ? '#ee0000'
    : timeDiff > 200
    ? '#cc5500'
    : timeDiff > 100
    ? '#997700'
    : timeDiff > 60
    ? '#889900'
    : timeDiff > 30
    ? '#669900'
    : timeDiff > 20
    ? '#00aa00'
    : timeDiff > 10
    ? '#00bb22'
    : '#00aa77';
};
/**
 * Redux logger
 * @function
 */
const logger = () => {
  const cache = {};
  return (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    Object.keys(selectors).forEach((selectorName) => {
      const selector = selectors[selectorName];
      try {
        const startTime = Date.now();
        const oldState = cache[selectorName];
        cache[selectorName] = selector(store.getState());

        if (!eq(oldState, cache[selectorName])) {
          const timeDiff = Date.now() - startTime;

          console.groupCollapsed(
            ` Selector %c"${selectorName}"%c  -  %c${timeDiff} ms`,
            'color: blue',
            'color: black',
            `color: ${getTimeDiffColor(timeDiff)}`,
          );
          console.log(cache[selectorName]);
          console.groupEnd();
        }
      } catch (e) {}
    });
    return result;
  };
};
export default logger;
