import React from 'react';
import cx from 'classnames';
import useStyles from './styles';

export interface ISubmenu {
  children: React.ReactNode;
  position?: 'left' | 'right';
  className?: string;
}

export default function Submenu({
  children,
  position = 'left',
  className = '',
}: ISubmenu) {
  const classes = useStyles();
  return (
    <div
      className={cx(
        classes.submenu,
        position === 'right' && classes.submenuRight,
        className,
      )}
    >
      <ul>{children}</ul>
    </div>
  );
}
