import React from 'react';

const NotaList = (props: any) => (
  <svg
    {...props}
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 19.7665H14.3182V22.5903H0V19.7665ZM0 9.88327H20.0455V12.7071H0V9.88327ZM0 0H28.6364V2.82379H0V0ZM24.3409 15.7709V7.05948H31.5V9.88327H27.2045V19.7665C27.2041 20.6656 26.9135 21.5411 26.3749 22.2665C25.8363 22.9919 25.0776 23.5295 24.2086 23.8015C23.3396 24.0735 22.4054 24.0658 21.5411 23.7795C20.6769 23.4931 19.9274 22.9431 19.4012 22.2089C18.875 21.4747 18.5994 20.5944 18.6142 19.6955C18.629 18.7966 18.9335 17.9256 19.4836 17.2087C20.0337 16.4917 20.8009 15.966 21.6741 15.7075C22.5473 15.449 23.4813 15.4712 24.3409 15.7709Z" />
  </svg>
);

export default NotaList;
