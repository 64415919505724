import React from 'react';

interface IProps {
  className?: string;
}

const AccountClose = (props: IProps) => (
  <svg
    {...props}
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.4983 0C9.93031 0 8.70536 0.578181 7.82339 1.73454C6.94142 2.8909 6.50044 4.36908 6.50044 6.16908C6.48955 8.40544 7.20819 10.1782 8.65636 11.4873C8.84146 11.6618 8.90679 11.8854 8.85235 12.1582L8.4767 12.9436C8.35693 13.2054 8.17999 13.41 7.94588 13.5572C7.71178 13.7045 7.21908 13.9145 6.46777 14.1872C6.4351 14.1982 5.74641 14.4218 4.40168 14.8582C3.05695 15.2945 2.34103 15.5345 2.25392 15.5782C1.33929 15.96 0.740418 16.3582 0.457317 16.7727C0.152439 17.46 0 19.2 0 21.9927H22.9965C22.9965 19.2 22.8441 17.46 22.5392 16.7727C22.2561 16.3582 21.6572 15.96 20.7426 15.5782C20.6555 15.5345 19.9396 15.2945 18.5948 14.8582C17.2501 14.4218 16.5614 14.1982 16.5287 14.1872C15.7774 13.9145 15.2847 13.7045 15.0506 13.5572C14.8165 13.41 14.6396 13.2054 14.5198 12.9436L14.1442 12.1582C14.0897 11.8854 14.1551 11.6618 14.3402 11.4873C15.7883 10.1782 16.507 8.40544 16.4961 6.16908C16.4961 4.36908 16.0551 2.8909 15.1731 1.73454C14.2912 0.578181 13.0662 0 11.4983 0Z" />
    <path
      d="M19.0176 13.9629C16.2713 13.9629 13.998 16.2052 13.998 18.9615C13.998 21.7177 16.2713 23.9992 19.0176 23.9992C21.7639 23.9992 23.998 21.7177 23.998 18.9615C23.998 16.2052 21.7639 13.9629 19.0176 13.9629ZM21.4828 20.6039C21.7113 20.8333 21.7113 21.2063 21.4828 21.4359C21.2561 21.6632 20.8844 21.667 20.6539 21.4359L19.0176 19.793L17.342 21.436C17.1135 21.6654 16.7418 21.6654 16.5131 21.436C16.2846 21.2067 16.2846 20.8337 16.5131 20.6041L18.1498 18.9615L16.5131 17.3188C16.2846 17.0893 16.2846 16.7162 16.5131 16.4869C16.7418 16.2575 17.1135 16.2575 17.342 16.4869L19.0176 18.1299L20.6539 16.4869C20.882 16.2579 21.2537 16.2571 21.4828 16.4869C21.7113 16.7162 21.7113 17.0893 21.4828 17.3188L19.8461 18.9615L21.4828 20.6039Z"
      fill="#46AFB7"
    />
  </svg>
);

export default AccountClose;
