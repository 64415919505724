import React, { useState, useEffect, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Text from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Delete';
import DragIndicator from '@material-ui/icons/DragIndicator';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import AddEditDescriptionModal from '../modal/AddEditMessageDescription';
import { IMessage, MessageActions, IFolder } from '../../types.d';
import useStyles from './styles';
import { IPlayer } from '../../reducers/player';
import {
  HeartIcon,
  // EyeIcon,
  DownloadIcon,
  PlayPauseIcon,
  EditIcon,
} from '../icons';
import { convertSecondsToTime } from '../../utils/helpers';
import MessageInFoldersModal from '../modal/MessageInFolders';

export type PlayFunc = (message: IMessage) => any;
export type ChangeFunc = (type: MessageActions, message: IMessage) => any;
export type ChangeDescriptionFunc = (message: IMessage, isNew?: boolean) => any;

interface IMessagesAccordeonRow {
  message: IMessage;
  onPlayStop: PlayFunc;
  onDownload: PlayFunc;
  playerInfo: IPlayer;
  onChange: ChangeFunc;
  onDrop: (item: DragItem, monitor: DropTargetMonitor) => void;
  folders: IFolder[];
  id: any;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const MessagesAccordeonRow = ({
  message,
  onChange,
  onDrop,
  onPlayStop,
  onDownload,
  playerInfo,
  folders,
  id,
  index,
  moveRow,
}: IMessagesAccordeonRow) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [msg, setMsg] = useState(message);
  const [isModalOpen, setModalStatus] = useState(false);
  const [isMsgInFoldersModalOpen, setMsgInFoldersModalStatus] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const toggleMsgInFolders = () => {
    setMsgInFoldersModalStatus(!isMsgInFoldersModalOpen);
  };

  const expand = (panel: string) => (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {
    _id,
    show,
    favorite,
    folderListOrder: albums,
    playback_count: count,
    createdAt,
    description,
    duration,
  } = msg;

  const isPlaying = playerInfo.isPlaying && playerInfo.messageId === _id;

  const createdTime = moment(createdAt).fromNow();
  const STATUS_TEXT = t(count > 0 ? 'played' : 'not-played');
  const ALBUMS_TEXT = t('message-in-albums', {
    albums: albums.map((a) => a.name).join(', '),
  });
  const FAVORITE_TEXT = t(
    favorite ? 'remove-from-favorites' : 'add-to-favorites',
  );
  const HIDE_SHOW_TEXT = t(show ? 'hide-message' : 'show-message');
  const DOWNLOAD_TEXT = t('download-message');
  const PLAYED_COUNT_TEXT = t('played-count', { count });

  const editMessage = (
    type: MessageActions,
    key: keyof IMessage,
    value: IMessage[keyof IMessage],
  ) => {
    const newMsg = { ...msg, [key]: value };
    setMsg(newMsg);
    onChange(type, newMsg);
  };

  const editDescription = (desc = '') => {
    editMessage(MessageActions.changeDescription, 'description', desc);
    setModalStatus(false);
  };

  useEffect(() => {
    setMsg(message);
  }, [message]);

  const isLoading = isPlaying && !playerInfo.currentTime;

  const playEl = (
    <IconButton
      className={cx(classes.playButton, 'soyl-table-playButton', {
        [classes.playButtonActive]: isPlaying,
      })}
      onClick={(e: any) => {
        e.stopPropagation();
        onPlayStop(msg);
      }}
      tabIndex={0}
      aria-label={t('play')}
    >
      {isLoading ? <CircularProgress /> : <PlayPauseIcon active={isPlaying} />}
    </IconButton>
  );

  const showEl = (
    <IconButton
      onClick={(e: any) => {
        e.stopPropagation();
        editMessage(MessageActions.changeVisibility, 'show', show);
      }}
      tabIndex={0}
    >
      {show ? (
        <VolumeUpIcon aria-label={HIDE_SHOW_TEXT} />
      ) : (
        <VolumeOffIcon aria-label={HIDE_SHOW_TEXT} />
      )}
    </IconButton>
  );

  const favoriteEl = (
    <IconButton
      onClick={(e: any) => {
        e.stopPropagation();
        editMessage(MessageActions.makeFavorite, 'favorite', favorite);
      }}
      tabIndex={0}
      aria-label={FAVORITE_TEXT}
    >
      <HeartIcon active={favorite} />
    </IconButton>
  );

  const downloadEl = (
    <IconButton onClick={() => onDownload(msg)} tabIndex={0}>
      <DownloadIcon alt={DOWNLOAD_TEXT} />
    </IconButton>
  );
  const removeEl = (
    <IconButton
      onClick={() => onChange(MessageActions.delete, msg)}
      tabIndex={0}
    >
      <RemoveIcon color="secondary" />
    </IconButton>
  );
  const editEl = (
    <IconButton onClick={() => setModalStatus(true)} tabIndex={0}>
      <EditIcon alt={'description icon'} />
    </IconButton>
  );

  const albumsEl = (
    <Text variant="body2" tabIndex={0} aria-label={ALBUMS_TEXT}>
      {albums.map((a) => a.name).join(', ')}
    </Text>
  );

  const statusEl = (
    <Text
      variant="body2"
      aria-label={`${t('status')}: ${STATUS_TEXT}`}
      tabIndex={0}
    >
      {STATUS_TEXT}
    </Text>
  );

  const countEl = (
    <Text variant="body2" tabIndex={0} aria-label={PLAYED_COUNT_TEXT}>
      {count ? count : '-'}
    </Text>
  );

  const createdAtEl = (
    <Text
      variant="body2"
      aria-label={`${t('created-at')} ${createdTime}`}
      tabIndex={0}
    >
      {createdTime}
    </Text>
  );

  const tickTime = convertSecondsToTime(
    Math.ceil(playerInfo.duration || 0) - Math.ceil(playerInfo.currentTime),
  );

  const durationEl = (
    <Text
      variant="body2"
      aria-label={`${t('message-duration-is')}:${duration}`}
      tabIndex={0}
    >
      {isPlaying && playerInfo.currentTime
        ? tickTime
        : Number(duration) === 0
        ? '-'
        : convertSecondsToTime(Number(duration))}
    </Text>
  );
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'row',
    drop: onDrop,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'row', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(preview(ref));

  return (
    <Accordion
      ref={ref}
      className={cx(classes.accRoot)}
      expanded={expanded === _id}
      onChange={expand(_id)}
    >
      <AccordionSummary
        className={cx(classes.accHeader, {
          [classes.isDragging]: isDragging,
          [classes.hidden]: !show,
        })}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        <TableRow>
          <TableCell
            align="center"
            className={cx(classes.icon, classes.drag, classes.cell)}
          >
            <div ref={drag}>
              <DragIndicator />
            </div>
          </TableCell>
          <TableCell
            align="center"
            className={cx(classes.icon, classes.playIcon, classes.cell)}
          >
            {playEl}
          </TableCell>
          <TableCell
            align="center"
            className={cx(classes.icon, classes.showHide, classes.cell)}
          >
            {showEl}
          </TableCell>
          <TableCell align="center" className={cx(classes.icon, classes.cell)}>
            {favoriteEl}
          </TableCell>

          <TableCell
            className={cx(classes.duration, classes.cell)}
            align="center"
          >
            {durationEl}
          </TableCell>
          <TableCell className={cx(classes.albums, classes.cell)}>
            <Text
              variant="body2"
              tabIndex={0}
              aria-label={albums[0] && albums[0].name}
            >
              {albums[0] && albums[0].name}
            </Text>
          </TableCell>
        </TableRow>
      </AccordionSummary>
      <AccordionDetails className={classes.accBody}>
        <Box display="flex">
          <Box className={classes.accBodyTitle}>
            <Text>{t('download')}</Text>
          </Box>
          <Box className={classes.accBodyIcon}>{downloadEl}</Box>
        </Box>
        <Box display="flex">
          <Box className={classes.accBodyTitle}>
            <Text>{t('description')}</Text>
          </Box>
          <Box className={classes.accBodyIcon}>{editEl}</Box>
        </Box>
        {description && (
          <Box display="flex">
            <Text className={classes.descriptionMobile}>{description}</Text>
          </Box>
        )}
        <Box display="flex">
          <Box className={classes.accBodyTitle}>
            <Text>{t('status')}</Text>
          </Box>
          <Box className={classes.accBodyIcon}> {statusEl}</Box>
        </Box>
        <Box display="flex">
          <Box className={classes.accBodyTitle}>
            <Text>{t('played-back-count')}</Text>
          </Box>
          <Box className={classes.accBodyIcon}> {countEl}</Box>
        </Box>
        <Box display="flex">
          <Box className={classes.accBodyTitle}>
            <Text>{t('added')}</Text>
          </Box>
          <Box className={classes.accBodyIcon}> {createdAtEl}</Box>
        </Box>
        <Box display="flex">
          <Box className={classes.accBodyTitle}>
            <Text>{t('albums')}</Text>
          </Box>
          <Box className={cx(classes.accBodyIcon, classes.albums)}>
            {albums.length ? (
              <Box display="flex" onClick={toggleMsgInFolders}>
                <Box>{albumsEl}</Box>
                <Box>
                  <EditIcon alt={'description icon'} />
                </Box>
              </Box>
            ) : (
              <Button onClick={toggleMsgInFolders} color="primary">
                {t('show-in-folders')}
              </Button>
            )}
          </Box>
        </Box>
        <Box display="flex">
          <Box className={classes.accBodyTitle}>
            <Text>{t('remove')}</Text>
          </Box>
          <Box className={cx(classes.deleteMessage)}>{removeEl}</Box>
        </Box>
      </AccordionDetails>
      <AddEditDescriptionModal
        key={msg._id}
        isOpen={isModalOpen}
        messageId={msg._id}
        onClose={() => setModalStatus(false)}
        description={description}
        onSave={editDescription}
        onRemove={editDescription}
      />
      <MessageInFoldersModal
        isOpen={isMsgInFoldersModalOpen}
        onShowInFolders={(message) =>
          onChange(MessageActions.showInFolders, message)
        }
        onFavorite={(message) => onChange(MessageActions.makeFavorite, message)}
        folders={folders}
        message={msg}
        onClose={toggleMsgInFolders}
      />
    </Accordion>
  );
};

export default MessagesAccordeonRow;
