import amplitude from 'amplitude-js';

export const Event = {
  VIEW_PAGE: 'view page',
  PLAY_VIDEO: 'play video',
  PAUSE_VIDEO: 'pause video',
  END_VIDEO: 'end video',
  CREATE_ACCOUNT: 'create account',
  CREATE_ACCOUNT_FAIL: 'create account error',
  VERIFY_ACCOUNT: 'verify account',
  LOGIN: 'login',
  LOGIN_FAIL: 'login error',
  LOGOUT: 'logout',
  FORGOT_PASSWORD: 'forgot password',
  RESET_PASSWORD: 'reset password',
  RESET_PASSWORD_EXPIRED: 'reset password expired',
  RESET_PASSWORD_FAIL: 'reset password error',
  CREATE_LOVED_ONE: 'create loved one',
  DELETE_LOVED_ONE: 'delete loved one',
  EDIT_NAME_LOVED_ONE: 'edit loved one name',
  REVIEW_MESSAGE: 'review message',
  UPDATE_MESSAGE: 'update message',
  DOWNLOAD_MESSAGE: 'download message',
  DELETE_MESSAGE: 'delete message',
  RESET_PASSCODE_RECORDING: 'reset recording passcode',
  RESET_PASSCODE_PLAYBACK: 'reset playback passcode',
  EDIT_NAME_ACCOUNT: 'edit name',
  EDIT_EMAIL_ACCOUNT: 'edit email',
  CHANGE_PASSWORD: 'change password',
  EDIT_NOTIFICATION_SETTINGS: 'edit notifications settings',
  EDIT_PRIVACY_SETTINGS: 'edit privacy settings',
  CLOSE_ACCOUNT: 'close account',
  SEND_RECORDING_INVITATION: 'send recording invitation',
  SEND_PLAYBACK_INSTRUCTIONS: 'send playback instructions',
  ADD_PLAYLIST: 'add playlist',
  EDIT_NAME_PLAYLIST: 'edit playlist name',
  DELETE_PLAYLIST: 'delete playlist',
  MUTE_PLAYLIST: 'mute playlist',
  UNMUTE_PLAYLIST: 'unmute playlist',
  ADD_MESSAGE_TO_FAVORITES: 'add message to favorites',
  REMOVE_MESSAGE_FROM_FAVORITES: 'remove message from favorites',
  UPDATE_MESSAGE_IN_PLAYLISTS: 'update message in playlists',
  REORDER_PLAYLIST: 'reorder playlist',
  EDIT_LOOP_COUNT: 'edit loop count',
  MUTE_MESSAGE: 'mute message',
  UNMUTE_MESSAGE: 'unmute message',
  EDIT_DESCRIPTION_MESSAGE: 'edit message description',
  REORDER_MESSAGE: 'reorder message',
  START_DONATION: 'start donation',
  COMPLETE_DONATION: 'complete donation',
  CANCEL_DONATION: 'cancel donation',
  DONATION_ERROR: 'donation error',
  VERIFY_RECORDING_INVITE: 'verify recording invite',
  VERIFY_RECORDING_INVITE_ERROR: 'verify recording invite error',
  START_RECORDING: 'start recording',
  PAUSE_RECORDING: 'pause recording',
  CONTINUE_RECORDING: 'continue recording',
  DONE_RECORDING: 'complete recording',
  REVIEW_RECORDING: 'review recording',
  CANCEL_RECORDING: 'cancel recording',
  SAVE_RECORDING: 'save recording',
  VERIFY_PLAYBACK_INVITE: 'verify playback invite',
  VERIFY_PLAYBACK_INVITE_ERROR: 'verify playback invite error',
  PLAY_MESSAGE: 'play message',
};

type AmpFunc = (data?: any) => any;

type AmpKey = keyof typeof Event;

const makeEventLog = (eventDesc: string): AmpFunc => (data) =>
  amplitude.getInstance().logEvent(eventDesc, data);

const amp = Object.keys(Event).reduce(
  (result, key) => ({ ...result, [key]: makeEventLog(Event[key]) }),
  {},
) as Record<AmpKey, AmpFunc>;

export default amp;
