import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CssBaseline } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import Text from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { formatedTranslation } from '../../utils/helpers';
import {
  firstPartyCookies,
  thirdPartyCookies,
  CookieDurationType,
  cookieCategoryDescription,
} from '../../utils/cookies';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { cookieConsentName, saveCookie } from '../../utils/cookies';
import { useCookies } from 'react-cookie';
import CookieSettings from '../../components/modal/CookieSettings';

const useStyles = makeStyles(({ spacing, darkGreen, breakpoints }) => ({
  paper: {
    marginTop: spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: spacing(1),
  },
  tableCaption: {
    display: 'flex',
    width: '100%',
    fontWeight: 600,
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  table: {
    '& td, & th': {
      fontSize: '1rem',
      lineHeight: '1rem',
      color: darkGreen,
    },
    '& th': {
      fontSize: '1rem',
      lineHeight: '1rem',
      textTransform: 'uppercase',
    },
  },
  cell: {
    '&&': {
      padding: 10,
      [breakpoints.down('md')]: {
        fontSize: 16,
        textAlign: 'center',
      },
    },
  },
}));

const CookiePolicy = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [cookies, setCookie] = useCookies([cookieConsentName]);
  const [isPreferencesOpen, setPreferencesOpen] = useState(false);

  const onPreferencesOpen = () => {
    setPreferencesOpen(true);
  };

  const onPreferencesClose = () => {
    setPreferencesOpen(false);
  };

  const onPreferencesSave = (categories: { [key: string]: boolean }) => {
    setPreferencesOpen(false);
    saveCookie(categories, setCookie);
  };

  const showCookieSettings = cookies[cookieConsentName] !== undefined;

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <BusinessIcon />
        </Avatar>
        <Text component="h1" variant="h5">
          {t('cookie-policy')}
        </Text>
        <Text variant="body2">
          {formatedTranslation('cookie-policy-intro', [{ tag: 'p' }])}
        </Text>
        <ul>
          {t<{ name: string; description: string }[]>(
            'cookie-policy-categories',
            {
              returnObjects: true,
            },
          ).map((item, index) => {
            return (
              <li key={`category-${index}`}>
                {' '}
                <Text variant="body2">
                  <strong>{item.name}</strong>: {item.description}
                </Text>
              </li>
            );
          })}
        </ul>
        <Text variant="body2">
          {formatedTranslation('cookie-policy-use', [{ tag: 'p' }])}
        </Text>
        <Text variant="body2" className={classes.tableCaption}>
          {t('cookies-set-by-us')}
        </Text>
        <TableContainer className={classes.tableContainer}>
          <Table
            size="small"
            className={classes.table}
            aria-label={t('cookie-table-aria-label')}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>{t('category')}</TableCell>
                <TableCell className={classes.cell}>{t('name')}</TableCell>
                <TableCell className={classes.cell}>{t('purpose')}</TableCell>
                <TableCell className={classes.cell}>
                  {t('retention-period')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firstPartyCookies.map((entry, index) => {
                const categoryKey = cookieCategoryDescription(entry.category);
                return (
                  <TableRow key={`fp-row-${index}`}>
                    <TableCell className={classes.cell}>
                      {t(categoryKey)}
                    </TableCell>
                    <TableCell className={classes.cell}>{entry.name}</TableCell>
                    <TableCell className={classes.cell}>
                      {t(entry.purpose)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {entry.durationType === CookieDurationType.Variable
                        ? t(`${entry.durationUnit}-with-count`, {
                            count: entry.durationLength,
                          })
                        : t(entry.durationType)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Text variant="body2" className={classes.tableCaption}>
          {t('cookies-set-by-others')}
        </Text>
        <TableContainer className={classes.tableContainer}>
          <Table
            size="small"
            className={classes.table}
            aria-label={t('cookie-table-aria-label')}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>{t('category')}</TableCell>
                <TableCell className={classes.cell}>{t('name')}</TableCell>
                <TableCell className={classes.cell}>{t('purpose')}</TableCell>
                <TableCell className={classes.cell}>
                  {t('retention-period')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {thirdPartyCookies.map((entry, index) => {
                const categoryKey = cookieCategoryDescription(entry.category);
                return (
                  <TableRow key={`tp-row-${index}`}>
                    <TableCell className={classes.cell}>
                      {t(categoryKey)}
                    </TableCell>
                    <TableCell className={classes.cell}>{entry.name}</TableCell>
                    <TableCell className={classes.cell}>
                      {t(entry.purpose)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {entry.durationType === CookieDurationType.Variable
                        ? t(`${entry.durationUnit}-with-count`, {
                            count: entry.durationLength,
                          })
                        : t(entry.durationType)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {showCookieSettings && (
          <>
            <Button
              onClick={onPreferencesOpen}
              color="primary"
              variant="contained"
            >
              {t('manage-cookies')}
            </Button>
            <CookieSettings
              cookie={cookies[cookieConsentName]}
              isOpen={isPreferencesOpen}
              onClose={onPreferencesClose}
              onSave={onPreferencesSave}
            />
          </>
        )}
        <p></p>
        <Text variant="body2" component="i">
          {t('last-updated')}: 2021/02/18
        </Text>
      </div>
    </Container>
  );
};

export default CookiePolicy;
