import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from '../utils/redux-create-reducer';
import { callApi } from '../utils/helpers';
import { getPlaybackMessages } from '../utils/api';
import {
  fetchMessagesSuccess,
  fetchMessagesFailure,
  IFetchMessagesAction,
  FETCH_MESSAGES,
} from '../actions/playback';

function* fetchMessagesWorker({
  payload: { invitationCode, playbackPasscode },
}: IAction<IFetchMessagesAction>): any {
  try {
    const { data: messages } = yield call(
      callApi,
      getPlaybackMessages,
      invitationCode,
      playbackPasscode,
    );
    yield put(fetchMessagesSuccess(messages));
  } catch (error) {
    yield put(fetchMessagesFailure((error as Error).message));
  }
}

export default function* playbackSaga() {
  yield takeLatest(FETCH_MESSAGES, fetchMessagesWorker);
}
