import React from 'react';

interface IProps {
  active?: boolean;
  color?: string;
  width?: number;
  height?: number;
}

const PlayPause = ({
  active,
  color = '#46AFB7',
  width = 32,
  height = 32,
}: IProps) => {
  const play = (
    <path
      d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0ZM21.1464 16.2464L13.3464 21.9214C13.3037 21.9522 13.2533 21.9705 13.2008 21.9744C13.1482 21.9784 13.0957 21.9678 13.0488 21.9438C13.0019 21.9198 12.9626 21.8834 12.9351 21.8385C12.9075 21.7936 12.8929 21.7419 12.8929 21.6893V10.3464C12.8927 10.2937 12.9071 10.2419 12.9346 10.1968C12.962 10.1518 13.0014 10.1152 13.0484 10.0912C13.0954 10.0671 13.1481 10.0566 13.2007 10.0606C13.2533 10.0647 13.3037 10.0833 13.3464 10.1143L21.1464 15.7857C21.1833 15.8118 21.2133 15.8463 21.2341 15.8864C21.2548 15.9265 21.2657 15.9709 21.2657 16.0161C21.2657 16.0612 21.2548 16.1057 21.2341 16.1458C21.2133 16.1858 21.1833 16.2204 21.1464 16.2464Z"
      fill={color}
    />
  );
  const pause = (
    <path
      d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0ZM13.1429 21.4286C13.1429 21.5857 13.0143 21.7143 12.8571 21.7143H11.1429C10.9857 21.7143 10.8571 21.5857 10.8571 21.4286V10.5714C10.8571 10.4143 10.9857 10.2857 11.1429 10.2857H12.8571C13.0143 10.2857 13.1429 10.4143 13.1429 10.5714V21.4286ZM21.1429 21.4286C21.1429 21.5857 21.0143 21.7143 20.8571 21.7143H19.1429C18.9857 21.7143 18.8571 21.5857 18.8571 21.4286V10.5714C18.8571 10.4143 18.9857 10.2857 19.1429 10.2857H20.8571C21.0143 10.2857 21.1429 10.4143 21.1429 10.5714V21.4286Z"
      fill={color}
    />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {active ? pause : play}
    </svg>
  );
};

export default PlayPause;
