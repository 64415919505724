// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { IFolder } from '../types';
import {
  FETCH,
  FETCH_FAILURE,
  FETCH_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  ADD,
  ADD_SUCCESS,
  SELECT,
} from '../actions/folders';

import { SORT_SUCCESS } from '../actions/playbackSettings';

export interface IFolders {
  isLoading?: boolean;
  selectedFolderId: string;
  list: IFolder[];
  error?: Error;
}

interface IState {
  folders: IFolders;
}

export const initialState: IFolders = {
  isLoading: false,
  selectedFolderId: 'all',
  list: [],
  error: undefined,
};

export const getFolders = (state: IState): IFolders => state.folders;

export default createReducer(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: (list) => ({
    selectedFolderId: 'all',
    list,
    isLoading: false,
  }),
  [SORT_SUCCESS]: (list) => ({ list }),
  [FETCH_FAILURE]: (error) => ({ error, isLoading: false }),
  [ADD]: () => ({ isLoading: true }),
  [ADD_SUCCESS]: (newFolder, { list }) => ({
    list: [...list, newFolder],
    isLoading: false,
  }),
  [SELECT]: (selectedFolderId) => ({ selectedFolderId }),
  [UPDATE]: () => ({ isLoading: true }),
  [UPDATE_SUCCESS]: (folder: IFolder, { list }) => ({
    isLoading: false,
    list: folder.isDeleted
      ? list.filter((f: IFolder) => f._id !== folder._id)
      : list.map((a: IFolder) => (a._id === folder._id ? folder : a)),
  }),
});
