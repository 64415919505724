// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { call } from 'redux-saga/effects';
import { belovedListFetcher } from './beloved';

import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* setupFethcher(): any {
  return yield call(belovedListFetcher);
}

export default function* setupPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.Setup, setupFethcher);
  }
}
