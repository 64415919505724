import { createAction, createEmptyAction } from '../utils/actions';
import { IBeloved } from '../types';

export const OPEN_RECORDING = 'RECORD/OPEN';
export const CLOSE_RECORDING = 'RECORD/CLOSE';
export const SAVE_RECORDING = 'RECORD/SAVE';
export const SAVE_RECORDING_SUCCESS = 'RECORD/SAVE_SUCCESS';
export const SAVE_RECORDING_FAILURE = 'RECORD/SAVE_FAILURE';

export const types = {
  OPEN_RECORDING,
  CLOSE_RECORDING,
  SAVE_RECORDING,
  SAVE_RECORDING_SUCCESS,
  SAVE_RECORDING_FAILURE,
};

export interface ISaveMessageAction {
  beloved: IBeloved;
  recordPasscode: number;
  audioData: Blob;
  duration: number;
}

export const openRecording = createEmptyAction(OPEN_RECORDING);
export const closeRecording = createEmptyAction(CLOSE_RECORDING);
export const saveRecording = createAction<ISaveMessageAction>(SAVE_RECORDING);
export const saveRecordingSuccess = createEmptyAction(SAVE_RECORDING_SUCCESS);
export const saveRecordingFailure = createAction<string>(
  SAVE_RECORDING_FAILURE,
);

export default {
  types,
  openRecording,
  closeRecording,
  saveRecording,
  saveRecordingSuccess,
  saveRecordingFailure,
};
