import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, IAuthState } from '../reducers/auth';
import { sendCode } from '../actions/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.darkGreen,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  resend: {
    color: theme.white,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Verify = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { user }: IAuthState = useSelector(getAuth);

  const [t] = useTranslation();

  const resendCode = () => {
    dispatch(sendCode());
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h6">{t('verify-your-email-address')}</Typography>
        <div className={classes.content}>
          <p>{t('verify-check-inbox', { email: user.email })}</p>
          <p>{t('verify-check-spam')}</p>
          <Button
            variant="contained"
            className={classes.resend}
            onClick={resendCode}
            color="primary"
          >
            Resend email
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Verify;
