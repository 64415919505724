import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InviteActions } from '../../types.d';
import { verifyInvite } from '../../actions/invite';
import {
  ISaveMessageAction,
  openRecording,
  closeRecording,
  saveRecording,
} from '../../actions/record';
import { IInviteState, getRecordInviteState } from '../../reducers/invite';
import { getRecordState } from '../../reducers/record';

import InvitationPasscode from '../../components/inline/InvitationPasscode';
import RecordMessageModal from '../../components/modal/RecordMessage';
import PageContainer from '../../components/PageContainer';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Text from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';

import { MicrophoneIcon } from '../../components/icons';

import useStyles from './styles';

const Record = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    isRequestingPasscode,
    invitationCode,
    beloved,
    passcode,
  }: IInviteState = useSelector(getRecordInviteState);
  const { isOpen, keyCount } = useSelector(getRecordState);

  const verifyPasscode = (passcodeInput: string) => {
    dispatch(
      verifyInvite({
        type: InviteActions.record,
        invitationCode,
        passcode: parseInt(passcodeInput),
      }),
    );
  };

  const saveMessage = (params: ISaveMessageAction) => {
    dispatch(saveRecording(params));
  };

  const handleRecordingModelOpen = () => {
    dispatch(openRecording());
  };

  const handleRecordingModelClose = () => {
    dispatch(closeRecording());
  };

  return (
    <PageContainer>
      <Box className={classes.root}>
        <Box className={classes.titleContainer}>
          <MicrophoneIcon />
          <Text className={classes.titleText}>{t('invitation-to-record')}</Text>
        </Box>
        <Grid container direction="column">
          {isRequestingPasscode && (
            <Grid container item>
              <Paper className={classes.invitationContainer}>
                <Text className={classes.ctaText}>
                  {t('enter-invitation-passcode')}
                </Text>
                <InvitationPasscode onSave={verifyPasscode} />
              </Paper>
            </Grid>
          )}
          {beloved && (
            <>
              <RecordMessageModal
                isOpen={isOpen}
                beloved={beloved}
                recordPasscode={passcode}
                onClose={handleRecordingModelClose}
                onSave={saveMessage}
                key={keyCount}
              />
              <Grid container item>
                <Box className={classes.recordContainer}>
                  <Box className={classes.instructionsContainer}>
                    <Text className={classes.instructionsText} variant="body2">
                      {t('record-instructions', { name: beloved.name })}
                    </Text>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRecordingModelOpen}
                  >
                    {t('record-message')}
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Record;
