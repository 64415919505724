import app from './app';
import auth from './auth';
import beloved from './beloved';
import messages from './messages';
import folders from './folders';
import player from './player';
import verify from './verify';
import notification from './notification';
import invite from './invite';
import record from './record';
import playback from './playback';

export default {
  app,
  auth,
  beloved,
  messages,
  folders,
  player,
  verify,
  notification,
  invite,
  record,
  playback,
};
