// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

import { IAction } from '../utils/redux-create-reducer';

export const OPEN = 'CONFIRMATION/OPEN';
export const CLOSE = 'CONFIRMATION/CLOSE';

export const types = {
  OPEN,
  CLOSE,
};

interface IConfirm {
  agreeText?: string;
  disagreeText?: string;
  agreeAction: IAction;
  disagreeAction: IAction;
  head: string;
  headParams?: Record<string, any> | [string, Record<string, any>][];
  body?: string;
  bodyParams?: Record<string, any> | [string, Record<string, any>][];
}

export const open = createAction<IConfirm>(OPEN);
export const close = createEmptyAction(CLOSE);

export default {
  types,
  open,
  close,
};
