import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ breakpoints, spacing, pink, error, darkGreen, greenGrey }) => ({
    modal: {
      maxWidth: 1440,
      width: '100%',
      minHeight: 600,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '@keyframes pulse': {
      from: { opacity: 0.2 },
      to: { opacity: 1 },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px 0 10px 0',
    },
    titleText: {
      paddingLeft: 10,
    },
    ctaText: {
      textTransform: 'uppercase',
      fontSize: 20,
      paddingBottom: 40,
    },
    duration: {
      paddingBottom: 20,
    },
    warning: {
      fontWeight: 600,
      color: pink,
    },
    recordButton: {
      background: error,
      borderRadius: '50%',
      width: '80px',
      height: '80px',
      '&&:hover': {
        background: pink,
      },
    },
    pauseButton: {
      background: darkGreen,
      margin: 15,
      '&&:hover': {
        background: greenGrey,
      },
    },
    stopButton: {
      background: error,
      margin: 15,
    },
    closeButton: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      color: darkGreen,
    },
    pauseStopContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0 10px 0',
    },
    micIcon: {
      fontSize: 20,
      color: 'black',
    },
    recordIcon: {
      fontSize: 40,
      color: 'white',
    },
    pauseIcon: {
      fontSize: 40,
      color: 'white',
      animationName: '$pulse',
      animationDuration: '1.5s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 0 20px 0',
    },
    actionButton: {
      color: 'white',
      margin: 10,
    },
    listenButton: {
      background: pink,
    },
    deleteButton: {
      background: greenGrey,
    },
    audioContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '70%',
      [breakpoints.down('md')]: {
        width: '90%',
      },
      minHeight: 200,
    },
    audioPreview: {},
    waveform: {
      width: '100%',
    },
    player: {
      display: 'none',
    },
    visible: {
      display: 'block',
    },
    hidden: {
      display: 'none',
    },
    show: {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 400ms ease-in',
    },
    hide: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 200ms ease-in',
    },
    enter: {
      opacity: 0.01,
    },
    enterActive: {
      opacity: 1,
      transition: 'opacity 200ms ease-in',
    },
    exit: {
      opacity: 1,
    },
    exitActive: {
      opacity: 0,
      transition: 'opacity 200ms ease-in',
    },
    collapse: {
      height: 0,
      overflow: 'hidden',
    },
    collapseIn: {
      height: 'auto',
      overflow: 'visible',
    },
    collapseNotIn: {
      visibility: 'hidden',
    },
    recordingLimit: {
      fontSize: 18,
      color: greenGrey,
      paddingTop: 10,
    },
    disclaimer: {
      fontSize: 18,
      color: pink,
      [breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  }),
  { name: 'RecordMessage' },
);
