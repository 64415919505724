import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import omit from 'lodash/omit';
import Validator from 'validator';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Text from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PasswordInput from '../components/PasswordInput';

import { signup } from '../actions/auth';
import signupImage from '../assets/signup.svg';
import { formatedTranslation, redirectTo } from '../utils/helpers';
import { Routes } from '../constants';

const useStyles = makeStyles(
  ({ greenGrey, breakpoints }) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down(1025)]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      margin: 40,
      '& h2': {
        alignSelf: 'center',
        fontWeight: 'bold',
      },
    },
    imageSection: {
      [breakpoints.up(1441)]: {
        padding: '40px 140px',
      },
      [breakpoints.down(1441)]: {
        '& img': {
          width: 700,
        },
      },
      [breakpoints.down(1281)]: {
        '& img': {
          width: 550,
        },
      },
      [breakpoints.down(1025)]: {
        '& img': {
          width: 400,
        },
      },
      [breakpoints.down(601)]: {
        display: 'none',
      },
      padding: '0px 20px',
    },
    login: {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        textTransform: 'initial',
      },
      '& > *': {
        padding: 10,
      },
    },

    agreeError: {
      '&& .MuiIconButton-label': {
        color: 'red',
      },
    },
    formSection: {
      display: 'flex',
      [breakpoints.up(1025)]: {
        justifyContent: 'flex-start',
      },
      [breakpoints.down(1025)]: {
        justifyContent: 'center',
      },
      marginTop: '40px',
      width: '100%',
      '& .soyl-signup-button': {
        width: '100%',
      },
      [breakpoints.up(601)]: {
        '& form': {
          width: 500,
        },
      },
      [breakpoints.down(601)]: {
        '& form': {
          width: '100%',
        },
      },
      '& .MuiFormHelperText-root': {
        color: greenGrey,
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    agreeLabel: {
      '& .MuiFormControlLabel-label': {
        zIndex: 3,
      },
    },
  }),
  { name: 'SignUp' },
);

interface State {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirm: string;
  agreeTerms: boolean;
  receiveUpdates: boolean;
}

const SignUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const privacyPolicyLink = {
    tag: 'a',
    value: '',
    props: {
      href: Routes.PrivacyPolicy,
      onClick: (evt: any) => {
        evt.preventDefault();
        dispatch(redirectTo(Routes.PrivacyPolicy));
      },
    },
  };

  const termsOfUseLink = {
    tag: 'a',
    value: '',
    props: {
      href: Routes.TermsOfService,
      onClick: (evt: any) => {
        evt.preventDefault();
        dispatch(redirectTo(Routes.TermsOfService));
      },
    },
  };

  const agreeTermsText = formatedTranslation('agreeTerms-text', [
    privacyPolicyLink,
    termsOfUseLink,
  ]);

  const [userData, setUserData] = React.useState<State>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    passwordConfirm: '',
    agreeTerms: false,
    receiveUpdates: false,
  });

  const [firstnameError, setFirstnameError] = useState('');
  const [lastnameError, setLastnameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [agreeTermsError, setAgreeTermsError] = useState('');

  const clearErrors = () => {
    setFirstnameError('');
    setLastnameError('');
    setEmailError('');
    setPasswordError('');
    setConfirmPasswordError('');
    setAgreeTermsError('');
  };

  const validateFormInputs = () => {
    let formValid = true;
    clearErrors();
    if (Validator.isEmpty(userData.firstname)) {
      setFirstnameError(t('missing-entry'));
      formValid = false;
    }
    if (Validator.isEmpty(userData.lastname)) {
      setLastnameError(t('missing-entry'));
      formValid = false;
    }
    if (!Validator.isEmail(userData.email)) {
      setEmailError(t('missing-entry'));
      formValid = false;
    }
    if (Validator.isEmpty(userData.password)) {
      setPasswordError(t('missing-entry'));
      formValid = false;
    }
    if (Validator.isEmpty(userData.passwordConfirm)) {
      setConfirmPasswordError(t('missing-entry'));
      formValid = false;
    }
    if (!userData.agreeTerms) {
      setAgreeTermsError(t('missing-entry'));
      formValid = false;
    }
    if (
      !Validator.isLength(userData.password, { min: 7, max: undefined }) ||
      !Validator.matches(userData.password, /[a-z]/) ||
      !Validator.matches(userData.password, /[A-Z]/) ||
      !Validator.matches(userData.password, /[0-9]/)
    ) {
      setPasswordError(t('password-error'));
      formValid = false;
    } else if (userData.password !== userData.passwordConfirm) {
      setConfirmPasswordError(t('confirm-password-error'));
      formValid = false;
    }
    return formValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validateFormInputs()) {
      return;
    }
    dispatch(signup(omit(userData, ['passwordConfirm'])));
  };

  const handleChange = (prop: keyof State, value?: boolean) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserData({ ...userData, [prop]: value || event.target.value });
  };

  return (
    <div className={cx(classes.container)}>
      <div className={cx(classes.imageSection)}>
        <img src={signupImage} alt={t('sign-up')} />
      </div>
      <div className={cx(classes.formSection)}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={4}>
            <Text variant="h2">{t('sign-up')}</Text>
            <Grid item sm={12}>
              <TextField
                id="first-name"
                autoComplete="fname"
                autoFocus
                helperText={firstnameError}
                error={firstnameError.length > 0}
                value={userData.firstname}
                onChange={handleChange('firstname')}
                label={t('first-name')}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="last-name"
                autoComplete="lname"
                helperText={lastnameError}
                error={lastnameError.length > 0}
                value={userData.lastname}
                onChange={handleChange('lastname')}
                label={t('last-name')}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="email"
                type="email"
                autoComplete="email"
                helperText={emailError}
                error={emailError.length > 0}
                value={userData.email}
                onChange={handleChange('email')}
                label={t('email')}
              />
            </Grid>
            <Grid item sm={12}>
              <PasswordInput
                name="create-password"
                error={passwordError}
                value={userData.password}
                helperText={t('create-password-hint')}
                onChange={handleChange('password')}
                label={t('create-password')}
              />
            </Grid>
            <Grid item sm={12}>
              <PasswordInput
                name="repeat-password"
                error={confirmPasswordError}
                value={userData.passwordConfirm}
                onChange={handleChange('passwordConfirm')}
                label={t('repeat-password')}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControlLabel
                className={cx(classes.agreeLabel, {
                  [classes.agreeError]: agreeTermsError,
                })}
                control={
                  <Checkbox
                    checked={userData.agreeTerms}
                    onChange={handleChange('agreeTerms', !userData.agreeTerms)}
                    name="agreeTerms"
                  />
                }
                label={agreeTermsText}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userData.receiveUpdates}
                    onChange={handleChange(
                      'receiveUpdates',
                      !userData.receiveUpdates,
                    )}
                    name="receive-updates"
                  />
                }
                label={t('receive-updates')}
              />
            </Grid>
            <Grid item sm={12}>
              <Button
                type="submit"
                className="soyl-signup-button"
                variant="contained"
                color="primary"
              >
                {t('sign-up')}
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Divider />
              <div className={classes.login}>
                <Text variant="body2">{t('have-an-account')}</Text>
                <Button
                  onClick={() => dispatch(redirectTo(Routes.Login))}
                  color="primary"
                >
                  {t('log-in')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
