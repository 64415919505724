import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from './styles';
import { VerticalDotsIcon, TrashIcon, MoveToIcon } from '../icons';
import { IFolder, IFolderListOrder, IMessage } from '../../types';
import Dropdown, { Submenu, Item } from '../MultilevelDropdown';
import { IconButton } from '@material-ui/core';

interface IProps {
  onShowInFolders: (message: IMessage) => any;
  onDelete: () => any;
  folders: IFolder[];
  message: IMessage;
}

export default function MessageMenu({
  onShowInFolders,
  onDelete,
  folders,
  message,
}: IProps) {
  const classes = useStyles();
  const [t] = useTranslation();
  const [selectedFolders, setSelectedFolders] = useState(
    message.folderListOrder,
  );
  const [key, setKey] = useState(Math.random());

  const updateFolder = debounce((folderListOrder: IFolderListOrder[]) => {
    onShowInFolders({ ...message, folderListOrder });
  }, 500);

  const isFolderChecked = (folder: IFolder) =>
    selectedFolders.some((a) => folder._id === a._id);

  const updateFolderVisibility = (folder: IFolder) => {
    let updated = selectedFolders;
    if (!isFolderChecked(folder)) {
      updated = [...selectedFolders, { ...folder, orderNumber: 0 }];
    } else {
      updated = selectedFolders.filter((a) => a._id !== folder._id);
    }

    setSelectedFolders(updated);
    updateFolder(updated);
  };

  return (
    <Dropdown
      key={key}
      button={
        <IconButton>
          <VerticalDotsIcon alt="ok" />
        </IconButton>
      }
    >
      {folders.length > 0 && (
        <Item className={classes.messageActionsItem}>
          <span className={'messageActionsItemIcons'}>
            <MoveToIcon />
            <span>{t('show-in-folders')}</span>
          </span>
          <i
            className={`${classes.messageActionsArrow} messageActionsItemArrow`}
          />
          <Submenu>
            {folders.map((a) => (
              <Item
                key={a.name}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  updateFolderVisibility(a);
                }}
                className={cx(
                  classes.messageActionsItem,
                  classes.messageActionsSubItem,
                )}
              >
                {a.name}
                <Checkbox
                  className={`messageActionsItemArrowCheckbox`}
                  checked={isFolderChecked(a)}
                  color="primary"
                />
              </Item>
            ))}
          </Submenu>
        </Item>
      )}
      <Item
        className={classes.messageActionsItem}
        onClick={() => {
          onDelete();
          setKey(Math.random());
        }}
      >
        <span className={'messageActionsItemIcons'}>
          <TrashIcon />
          <span>{t('delete-this-message')}</span>
        </span>
      </Item>
    </Dropdown>
  );
}
