import React from 'react';

const Calendar = (props: any) => (
  <svg
    {...props}
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.7998 10.7998H7.1998V13.1998H4.7998V10.7998ZM4.7998 15.5998H7.1998V17.9998H4.7998V15.5998ZM9.5998 10.7998H11.9998V13.1998H9.5998V10.7998ZM9.5998 15.5998H11.9998V17.9998H9.5998V15.5998ZM14.3998 10.7998H16.7998V13.1998H14.3998V10.7998ZM14.3998 15.5998H16.7998V17.9998H14.3998V15.5998Z"
      fill="#1A4346"
    />
    <path
      d="M2.4 24H19.2C20.5236 24 21.6 22.9236 21.6 21.6V4.8C21.6 3.4764 20.5236 2.4 19.2 2.4H16.8V0H14.4V2.4H7.2V0H4.8V2.4H2.4C1.0764 2.4 0 3.4764 0 4.8V21.6C0 22.9236 1.0764 24 2.4 24ZM19.2 7.2L19.2012 21.6H2.4V7.2H19.2Z"
      fill="#1A4346"
    />
  </svg>
);

export default Calendar;
