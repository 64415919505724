import { IFolder, FolderActions } from '../types';
import { createAction } from '../utils/actions';

export const FETCH = 'FOLDERS/FETCH';
export const FETCH_SUCCESS = 'FOLDERS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'FOLDERS/FETCH_FAILURE';

export const UPDATE = 'FOLDERS/UPDATE';
export const UPDATE_SUCCESS = 'FOLDERS/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'FOLDERS/UPDATE_FAILURE';

export const ADD = 'FOLDERS/ADD';
export const ADD_SUCCESS = 'FOLDERS/ADD_SUCCESS';
export const ADD_FAILURE = 'FOLDERS/ADD_FAILURE';

export const SELECT = 'FOLDERS/SELECT';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  ADD,
  ADD_SUCCESS,
  ADD_FAILURE,

  SELECT,
};

export interface IUpdateParams {
  folder: IFolder;
  type: FolderActions;
  sortedList?: { _id: string; orderNumber: number }[];
}

export const fetch = createAction<string>(FETCH);
export const fetchSuccess = createAction<IFolder[]>(FETCH_SUCCESS);
export const fetchFailure = createAction<string>(FETCH_FAILURE);

export const update = createAction<IUpdateParams>(UPDATE);
export const updateSuccess = createAction<IFolder>(UPDATE_SUCCESS);
export const updateFailure = createAction<string>(UPDATE_FAILURE);

export const add = createAction<string>(ADD);
export const addSuccess = createAction<IFolder>(ADD_SUCCESS);
export const addFailure = createAction<string>(ADD_FAILURE);

export const select = createAction<string>(SELECT);

export default {
  types,
  fetch,
  fetchSuccess,
  fetchFailure,

  update,
  updateSuccess,
  updateFailure,

  add,
  addSuccess,
  addFailure,
};
