import createReducer from '../utils/redux-create-reducer';
import {
  REQUEST_INVITE_PASSCODE,
  VERIFY_INVITE,
  VERIFY_INVITE_SUCCESS,
  VERIFY_INVITE_FAILURE,
} from '../actions/invite';
import { IBeloved, InviteActions } from '../types.d';

export interface IInviteState {
  isRequestingPasscode: boolean;
  invitationCode: number;
  passcode: number;
  beloved: IBeloved | null;
  error?: Error;
}

export interface ITypedInviteState {
  record: IInviteState;
  playback: IInviteState;
}

interface IState {
  invite: ITypedInviteState;
}

export const initialState: ITypedInviteState = {
  record: {
    isRequestingPasscode: false,
    invitationCode: 0,
    passcode: 0,
    beloved: null,
    error: undefined,
  },
  playback: {
    isRequestingPasscode: false,
    invitationCode: 0,
    passcode: 0,
    beloved: null,
    error: undefined,
  },
};

export const getRecordInviteState = (state: IState): IInviteState =>
  state.invite.record;
export const getPlaybackInviteState = (state: IState): IInviteState =>
  state.invite.playback;

const inviteTypeReducer = (
  type: InviteActions,
  newState: any,
  record: IInviteState,
  playback: IInviteState,
) => {
  return type === InviteActions.record
    ? { record: { ...record, ...newState } }
    : { playback: { ...playback, ...newState } };
};

export default createReducer(initialState, {
  [REQUEST_INVITE_PASSCODE]: (
    { type, invitationCode },
    { record, playback },
  ) => {
    const newState = {
      isRequestingPasscode: true,
      invitationCode,
    };
    return inviteTypeReducer(type, newState, record, playback);
  },
  [VERIFY_INVITE]: (
    { type, invitationCode, passcode },
    { record, playback },
  ) => {
    const newState = { invitationCode, passcode };
    return inviteTypeReducer(type, newState, record, playback);
  },
  [VERIFY_INVITE_SUCCESS]: ({ type, beloved }, { record, playback }) => {
    const newState = { beloved, isRequestingPasscode: false };
    return inviteTypeReducer(type, newState, record, playback);
  },
  [VERIFY_INVITE_FAILURE]: ({ type, error }, { record, playback }) => {
    const newState = { error, isRequestingPasscode: true };
    return inviteTypeReducer(type, newState, record, playback);
  },
});
