import {
  Box,
  Button,
  makeStyles,
  Typography as Text,
  Grid,
} from '@material-ui/core';
import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatedTranslation } from '../utils/helpers';

const useStyles = makeStyles(
  ({ greenDark, greyLight, darkGreen, blueLight2, mainBlue, white }) => ({
    active: {
      '&&': {
        borderRadius: 10,
        backgroundColor: mainBlue,
        '&& .MuiTypography-root, && .MuiButton-root': {
          color: white,
        },
      },
    },
    plan: {
      backgroundColor: blueLight2,
    },
    root: {
      marginBottom: 80,
      '&& .MuiTypography-root': {
        color: greenDark,
      },
      '&& button': {
        width: '70%',
      },
      '&& .soyl-plan-premium': {
        '& .MuiTypography-subtitle1': {
          color: darkGreen,
          '& span': {
            fontSize: 24,
          },
        },
      },
    },
    feature: {
      padding: 8,
      textAlign: 'center',
      borderBottom: `1px solid ${greyLight}`,
    },
  }),
  { name: 'BillingPlan' },
);
const boldTranslation = (key: string, value?: Record<string, any>) =>
  formatedTranslation(key, [{ tag: 'b', value }]) as any;

export default function BillingPlan({ currentPlan }: { currentPlan?: string }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const unlim = t('unlimited');

  const plans = [
    {
      id: 'basic',
      name: t('basic'),
      type: t('personal'),
      price: t('free'),
      buttonText: t('your-plan'),
      features: [
        boldTranslation('beloved-qty', { qty: 1 }),
        boldTranslation('messages-qty', { qty: 10 }),
        boldTranslation('storage-time', {
          qty: 6,
          time: t('month', { count: 6 }),
        }),
        boldTranslation('ticket-support'),
      ],
    },
    {
      id: 'premium',
      name: t('premium'),
      type: t('personal'),
      buttonText: t('buy-now'),
      price: formatedTranslation('price', [
        { tag: 'b', value: { price: 10 } },
        { tag: 'span' },
      ]) as any,
      features: [
        boldTranslation('beloved-qty', { qty: 10 }),
        boldTranslation('message-qty', { qty: 100 }),
        boldTranslation('storage-time', {
          qty: 1,
          time: t('year', { count: 1 }),
        }),
        boldTranslation('playback-loop', {
          qty: 15,
          time: t('minute', { count: 15 }),
        }),
        boldTranslation('ticket-support'),
      ],
    },
    {
      id: 'enterprise',
      name: t('enterprise'),
      type: t('business'),
      price: t('contact-us'),
      buttonText: t('contact-us'),
      features: [
        boldTranslation('beloved-qty', { qty: unlim }),
        boldTranslation('messages-qty', { qty: unlim }),
        boldTranslation('storage-time', {
          qty: 2,
          time: t('year', { count: 2 }),
        }),
        boldTranslation('playback-loop', { qty: t('configurable') }),
        t('message-download'),
        t('dedicated-phone-support'),
        t('dedicated-local-access-number'),
        t('reporting'),
        t('service-provisioning'),
        t('admin-dashboard'),
        t('vanity-url'),
        t('company-branding'),
      ],
    },
  ];
  return (
    <Grid className={classes.root} container>
      {plans.map((plan) => (
        <Grid
          className={cx(classes.plan, `soyl-plan-${plan.id}`, {
            [classes.active]: currentPlan === plan.id,
          })}
          item
          container
          direction="column"
          justify="space-between"
          md={4}
          sm={12}
          key={plan.id}
        >
          <Box my={5} p={3}>
            <Box textAlign="center" mb={5}>
              <Text variant="subtitle2">{plan.name}</Text>
              <Text variant="body1">{plan.type}</Text>
            </Box>
            <Box textAlign="center" mb={5}>
              <Text variant="subtitle1">{plan.price}</Text>
            </Box>
            {plan.features.map((feature) => (
              <Box key={feature} className={classes.feature}>
                <Text variant="body2">{feature}</Text>
              </Box>
            ))}
          </Box>
          <Box mb={5}>
            <Button
              color="primary"
              variant={plan.id !== currentPlan ? 'contained' : undefined}
            >
              {plan.buttonText}
            </Button>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
