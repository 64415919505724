import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Text from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import { update } from '../../actions/settings';
import { getAuth, IAuthState } from '../../reducers/auth';
import useSettingsStyles from './styles';

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    preference: {
      width: '70%',
      minWidth: 280,
      [breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 200,
      },
    },
    switch: {
      marginLeft: '10%',
    },
  }),
  { name: 'SettingsNotificationPreferences' },
);

export const NotificationPreferences = () => {
  const classes = {
    ...useSettingsStyles(),
    ...useStyles(),
  };
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { user }: IAuthState = useSelector(getAuth);

  const { allNotifications, accountRelatedNotifications } = user;
  const [state, setState] = useState({
    allNotifications,
    accountRelatedNotifications,
  });

  useEffect(() => {
    setState({ allNotifications, accountRelatedNotifications });
  }, [allNotifications, accountRelatedNotifications]);

  const handleChange = (
    type: 'allNotifications' | 'accountRelatedNotifications',
  ) => () => {
    const newState = { ...state, [type]: !state[type] };
    setState(newState);
    dispatch(
      update({
        type: 'notificationPreferences',
        notificationPreferences: newState,
      }),
    );
  };

  return (
    <Grid direction="column" container spacing={3}>
      <Hidden smDown>
        <Grid item xs>
          <Text className={classes.title} variant="body1">
            {t('notification-preferences')}
          </Text>
        </Grid>
      </Hidden>
      <Grid item xs>
        <Box display="flex">
          <Box className={classes.preference}>
            <Text variant="body1">{t('receive-all-emails')}</Text>
            <Text variant="caption">{t('receive-all-emails-description')}</Text>
          </Box>
          <Box className={classes.switch}>
            <Switch
              color="primary"
              onChange={handleChange('allNotifications')}
              checked={state.allNotifications}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs>
        <Box display="flex">
          <Box className={classes.preference}>
            <Text variant="body1">{t('receive-related-emails')}</Text>
            <Text variant="caption">
              {t('receive-related-emails-description')}
            </Text>
          </Box>
          <Box className={classes.switch}>
            <Switch
              onChange={handleChange('accountRelatedNotifications')}
              color="primary"
              checked={state.accountRelatedNotifications}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotificationPreferences;
