import React from 'react';

const MoveTo = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.61905 11.4286H10.6667V9.14286L14.4762 12.5714L10.6667 16V13.7143H7.61905V11.4286ZM6.85714 5.33333H11.0476L6.85714 1.14286V5.33333ZM1.52381 0H7.61905L12.1905 4.57143V7.8781C11.7105 7.71048 11.2 7.61905 10.6667 7.61905C9.45425 7.61905 8.29149 8.10068 7.43418 8.95799C6.57687 9.8153 6.09524 10.9781 6.09524 12.1905C6.09524 13.3638 6.53714 14.4305 7.26095 15.2381H1.52381C1.11967 15.2381 0.732083 15.0776 0.446313 14.7918C0.160544 14.506 0 14.1184 0 13.7143V1.52381C0 1.11967 0.160544 0.732083 0.446313 0.446313C0.732083 0.160544 1.11967 0 1.52381 0Z" />
    </svg>
  );
};

export default MoveTo;
