import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination, { IPaginationProps } from '../../components/Pagination';
import useStyles from './styles';
import { ClockIcon, CalendarIcon } from '../icons';
import { useMobile } from '../../utils/helpers';

interface IMessagesTable {
  children: any;
  isEmpty?: boolean;
  showPagination?: boolean;
  pagination: IPaginationProps;
}

const MessagesTable = ({
  children,
  isEmpty,
  pagination,
  showPagination,
}: IMessagesTable) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const isMobile = useMobile();

  return (
    <div className={cx(classes.root, { [classes.empty]: isEmpty })}>
      <TableContainer
        className={cx(classes.tableContainer, { [classes.empty]: isEmpty })}
      >
        <Table
          size="small"
          className={classes.table}
          aria-label="Table messages"
        >
          <TableHead>
            {isMobile && (
              <TableRow className={classes.accContentTitle}>
                <TableCell></TableCell>
                <TableCell align="center" className={classes.accAction}>
                  {t('action')}
                </TableCell>
                <TableCell className={classes.accClock}>
                  <ClockIcon />
                </TableCell>
                <TableCell className={classes.accAlbum}>{t('album')}</TableCell>
              </TableRow>
            )}
            {!isMobile && (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="center">{t('album')}</TableCell>
                <TableCell align="center">{t('status')}</TableCell>
                <TableCell align="center">{t('played-back-count')}</TableCell>
                <TableCell align="center">
                  <CalendarIcon alt="calendar" />
                </TableCell>
                <TableCell align="center">{t('description')}</TableCell>
                <TableCell align="center">
                  <ClockIcon alt="calendar" />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
        {showPagination && (
          <div className={classes.pagination}>
            <Pagination {...pagination} />
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default MessagesTable;
