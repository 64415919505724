import axios from 'axios';
import { REACT_APP_API_BASE_URL, REACT_APP_SOYL_API_KEY } from '../env';
import {
  ISignupData,
  IMe,
  IMessage,
  IFolder,
  IListOrder,
  IBeloved,
  IProfile,
  InviteActions,
} from '../types.d';

export const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL || '',
});

api.defaults.headers.common['Content-Type'] = 'application/json';

/**
 * HTTP POST method used to SignUp user
 * @param userData { firstname, lastname}
 */
export const signUpNewUser = (userData: ISignupData) =>
  api.post<IMe>('/api/users', userData);

/**
 * HTTP POST method used to send code for user to reset his password
 * @param email - email string
 */
export const forgotPassword = (email: string) =>
  api.post('/api/resetcodes', { email });

/**
 * HTTP POST method used to set new user password
 * @param code
 * @param password
 */
export const resetPassword = (code: string, password: string) =>
  api.post('/api/resetcodes/user', {
    code,
    password,
  });

export const addSubscriber = (firstname: string, email: string) =>
  api.post('/api/subscribers', {
    firstname,
    email,
  });

/**
 * Login user
 * @param email
 * @param password
 * @returns {Promise} {userData}
 */
export const login = (email: string, password: string) =>
  api.post('/api/users/login', {
    email,
    password,
  });

export const logout = () => api.get('/api/users/logout');

/**
 * Verify email address of new user
 * @param code - verification code
 */
export const verifyUser = (code: string) =>
  api.post<{ isVerified: boolean }>('/api/users/verify', {
    code,
  });

/**
 * Send verification code for new user to verify email address
 */
export const sendVerificationCode = () =>
  api.post('/api/users/send_verification_code');

/**
 * Update messages (show, favorite, order, description, etc.)
 * @param messages - list of updated messages
 */
export const updateBelovedMessages = (messages: any) =>
  api.put('/api/message/', {
    messages,
  });

/**
 * Delete message
 * @param id - message id
 */
export const deleteBelovedMessage = (id: string) =>
  api.delete(`/api/message/${id}`);

/**
 * Download message as file.mp3
 * @param id - message recording id (file name on s3 bucket)
 */
export const getRecording = (id: string) =>
  api.get(`/api/recordings/${id}.mp3`, { responseType: 'blob' });

export const getInviteRecording = (id: string) =>
  api.get(`/api/invite/recordings/${id}.mp3`, {
    responseType: 'blob',
    headers: { SOYL_API_KEY: REACT_APP_SOYL_API_KEY },
  });

/**
 * Save message
 * @param data - Form data
 */
export const saveRecording = (data: FormData) =>
  api.post('/api/message/upload', data);

/**
 * Change user token for userData
 * @param token - Bearer token
 */
export const getUserInfo = (token?: string) =>
  api.get('/api/users/me', {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });

/**
 * Send invitation to listen or record message for some email addresses.
 * @param belovedId - beloved id
 * @param emails - list of email addresses to send invitation
 * @param invitationFlag - type of invitation - 'record' | 'playback',
 */
export const sendInvitation = (
  belovedId: string,
  emails: string[],
  invitationFlag: 'record' | 'playback',
) =>
  api.post(`/api/beloved/${belovedId}/invitation`, {
    emails,
    invitationFlag,
  });

// SETTINGS ==================================================
/**
 * update user info
 * @param user - user (admin) info
 */
export const updateUserInfo = (user: Partial<IProfile>) =>
  api.patch('/api/users/me', user);

/**
 * send verification code to email to change email address for current user.
 * @param email - email address
 */
export const sendUserEmail = (email: string) =>
  api.post('/api/users/send_verification_to_change_email', { email });

/**
 * Change user password
 * @param currentPassword - current user password
 * @param newPassword - new user password
 */
export const updatePassword = (currentPassword: string, newPassword: string) =>
  api.post('/api/users/me/password', { currentPassword, newPassword });

/**
 * Approve new email address by providing secret code which was sent to email address
 * @param code - verification code
 */
export const verifyNewEmail = (code: string) =>
  api.post('/api/users/verify_new_email', { code });

/**
 * Remove everithing from database about this user
 */
export const closeAccount = () => api.post('/api/users/close_account');

// BELOVED - CRUD ==================================================
/**
 * Fetch list of beloved of current user
 */
export const getBelovedInfo = () => api.get('/api/users/me/beloved');

/**
 * Fetch list of beloved based on invite info
 */
export const getBelovedByInvite = (
  invitationCode: number,
  passcode: number,
  type: InviteActions,
) =>
  api.get(`/api/beloved/invitation/${invitationCode}`, {
    params: {
      passcode,
      type: type === InviteActions.record ? 'record' : 'playback',
    },
  });

/**
 * Remove beloved
 * @param id - beloved id
 */
export const deleteBeloved = (id: string) => api.delete(`/api/beloved/${id}`);

/**
 * Reset passcode for beloved to record or listen message
 * @param id - beloved id
 * @param resRecPasscode - is recordPasscode
 */
export const resetPasscode = (id: string, resRecPasscode: string) =>
  api.patch(`/api/beloved/${id}`, {
    [resRecPasscode ? 'recordPasscode' : 'playbackPasscode']: '123456',
  });

/**
 * update beloved name
 * @param id - beloved id
 * @param name - beloved name
 */
export const updateName = (id: string, name: string) =>
  api.patch(`/api/beloved/${id}`, {
    name,
  });

/**
 * Create new beloved for current user
 * @param name - beloved name
 */
export const createBeloved = (name: string) =>
  api.post('/api/beloved', {
    name,
  });

/**
 * Save playback settings for beloved (order of folders, count of loop to play messages, show or hide some folders)
 * @param id - beloved id
 * @param params - playback settings
 */
export const editPlaybackSettings = (id: string, params: Partial<IBeloved>) =>
  api.put(`/api/beloved/${id}/playback-settings`, params);

// MESSAGES - CRUD ==================================================
export const getBelovedMessages = (belovedId: string) =>
  api.get<IMessage[]>(`/api/beloved/${belovedId}/messages`);

/**
 * Save list of folders where this message should be shown
 * @param messageId - message id
 * @param foldersIds - list of folder ids
 */
export const showInFolders = (messageId: string, foldersIds: string[]) =>
  api.put<IMessage>(`/api/message/${messageId}/folder`, {
    folderIds: foldersIds,
  });

/**
 * Delete message
 * @param messageId - message id
 */
export const deleteMessage = (messageId: string) =>
  api.delete(`/api/message/${messageId}`);

/**
 * toggle message as favorite
 * @param messageId - message id
 * @param favorite - is favorite
 */
export const makeFavorite = (messageId: string, favorite: boolean) =>
  api.put<IMessage>(`/api/message/${messageId}/favorite`, { favorite });

/**
 * Save sort order of messages
 * note: probably the same as updateBelovedMessages *********
 * @alias updateBelovedMessages
 * @param messages - list of updated messages
 */
export const sortAllMessages = (messages: IMessage[]) =>
  api.put(`/api/message`, messages);

/**
 * Save sort order related to folders
 * @param messagesListOrder - list of messages ids and order number
 * @param folder - folder id
 */
export const sortMessagesInFolders = (
  messagesListOrder: IListOrder[],
  folder: string,
) => api.put(`/api/folder/${folder}/order`, { sortedList: messagesListOrder });

/**
 * Update message description
 * note: probably the same as *updateBelovedMessages* or *sortAllMessages* *********
 * @alias updateBelovedMessages
 * @alias sortAllMessages
 * @param messageId - message id
 * @param description - message description
 */
export const editMessageDescription = (
  messageId: string,
  description: string,
) =>
  api.put<IMessage>(`/api/message/${messageId}/description`, {
    description,
  });

/**
 * Change message visibility (play or not to loved one)
 * @param messageId - message id
 * @param show - is show
 * @alias updateBelovedMessages
 * @alias sortAllMessages
 */
export const changeMessageVisibility = (messageId: string, show: boolean) =>
  api.put<IMessage>(`/api/message/${messageId}/show`, { show });

/**
 * Fetch playback messages baseed on loved one info
 * @param invitationCode - invitation id
 * @param playbackPasscode - playback passcode
 */
export const getPlaybackMessages = (
  invitationCode: number,
  playbackPasscode: number,
) =>
  api.get(`/api/beloved/invitation/${invitationCode}/messages`, {
    params: { playbackPasscode },
  });

/**
 * Mark message as played
 * @param messageId - message id
 */
export const markPlayed = (messageId: string) =>
  api.put<IMessage>(
    `/api/message/${messageId}/played`,
    {},
    {
      headers: { SOYL_API_KEY: REACT_APP_SOYL_API_KEY },
    },
  );

// FOLDERS - CRUD ==================================================
/**
 * Fetch list of folders for loved one
 * @param belovedId - beloved id
 */
export const getBelovedFolders = (belovedId: string) =>
  api.get<IFolder>(`/api/beloved/${belovedId}/folders`);

/**
 * add new folder (playlist)
 * @param name - folder name
 * @param recipient - beloved id
 */
export const addFolder = (name: string, recipient: string) =>
  api.post<IFolder>(`/api/folder`, { name, recipient });

/**
 * Change folder visibility (play or not to loved one)
 * @param folderId - folder id
 * @param show - is show
 */
export const changeFolderVisibility = (folderId: string, show: boolean) =>
  api.put<IFolder>(`/api/folder/${folderId}/show`, { show });

/**
 * sort folders  - order to play for loved one
 * @param folders - list of folders data
 */
export const changeFolderOrder = (folders: IFolder[]) =>
  api.put<{ success: boolean }>(`/api/folder`, { folders });

/**
 * Change folder (playlist) name
 * @param folderId - folder id
 * @param name - folder name
 */
export const renameFolder = (folderId: string, name: string) =>
  api.put<IFolder>(`/api/folder/${folderId}/rename`, { name });

/**
 * remove folder
 * @param folderId - folder id
 */
export const deleteFolder = (folderId: string) =>
  api.delete(`/api/folder/${folderId}`);
