import { createStore, applyMiddleware, compose } from 'redux';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga';
import { isDevelopment } from './env';
import browserHelpers from './utils/browserHelpers';
import selectorLogger from './utils/selectorsLogger';

import { Routes } from './constants';

import createRootReducer from './reducers';
import appSagas from './sagas';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME || Routes.Home,
});

const sagaConfig: SagaMiddlewareOptions = {};

const composer =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

if (isDevelopment) {
  const createSagaMonitor = require('@clarketm/saga-monitor');

  sagaConfig.sagaMonitor = createSagaMonitor.default({
    level: 'debug',
    // effectTrigger: true, // show triggered effects
    // effectResolve: true, // show resolved effects
    // effectReject: true, // show rejected effects
    // effectCancel: true, // show cancelled effects
    actionDispatch: true, // show dispatched actions
  });
}

const sagaMiddleware = createSagaMiddleware(sagaConfig);
const routeMiddleware = routerMiddleware(history);

const middlewares = [sagaMiddleware, routeMiddleware];

if (isDevelopment) {
  middlewares.push(selectorLogger());
}

const store = createStore(
  createRootReducer(history),
  undefined,
  composer(applyMiddleware(...middlewares)),
);

if (isDevelopment) {
  browserHelpers(store);
}
sagaMiddleware.run(appSagas);

export default store;
