import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import useModalStyles from './styles';

const useStyles = makeStyles(({}) => ({}));
interface IProps {
  isOpen: boolean;
  entity: string;
  name?: string;
  placeholder?: string;
  onSave: (name: string) => any;
  onClose: () => any;
}

export default function AddEditEntityName({
  isOpen,
  entity,
  name = '',
  placeholder = '',
  onClose,
  onSave,
}: IProps) {
  const classes = {
    ...useModalStyles(),
    ...useStyles(),
  };
  const [t] = useTranslation();

  const [newName, setNewName] = useState(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  };

  useEffect(() => {
    setNewName(name);
  }, [name]);

  const isDirty = name !== newName;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <Typography color="textPrimary" variant="h6">
          {t(name.length ? `edit-${entity}` : `add-${entity}`)}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.textArea}
          id={`add_edit_${entity}_name`}
          value={newName}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          onClick={() => {
            onSave(newName);
            setNewName('');
          }}
          color="primary"
          autoFocus
          disabled={!isDirty}
        >
          {t(name ? 'save' : 'add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
