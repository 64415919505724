import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ darkGreen, blueLight, greenDark, breakpoints }) => ({
    root: {},
    titleBox: {
      padding: '40px 10px 40px 40px',
      width: '100%',
      alignSelf: 'center',
      maxWidth: 1440,
      flexWrap: 'nowrap',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '10px 40px',
        textAlign: 'center',
      },
    },
    title: {
      fontSize: 36,
      lineHeight: '36px',
      color: greenDark,
      fontWeight: 'bold',
      paddingBottom: 10,
      textTransform: 'uppercase',
      [breakpoints.down('sm')]: {
        paddingBottom: 5,
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '24px',
      },
    },
    summary: {
      fontSize: 24,
      lineHeight: '24px',
      fontWeight: 300,
    },
    videoBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    instructionVideoBox: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      paddingBottom: '40px',
    },
    videoContainer: {
      position: 'relative',
      paddingBottom: '40.25%' /* 16:9 */,
      width: '75%',
      height: 0,
    },
    video: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoBox: {
      position: 'relative',
      padding: '10px 53px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      maxWidth: 1440,
    },
    infoPrompt: {
      fontSize: 24,
      lineHeight: '24px',
      fontWeight: 600,
      color: greenDark,
    },
    infoDetails: {
      fontSize: 24,
      lineHeight: '30px',
      fontWeight: 300,
    },
    quoteBox: {
      '& i': {
        maxWidth: 1100,
        padding: '30px 20px',
        fontWeight: 500,
        fontSize: 24,
        color: greenDark,
        lineHeight: '30px',
        [breakpoints.down('sm')]: {
          padding: '50px 20px',
        },
      },
      textAlign: 'center',
    },
    resourceTitle: {
      fontSize: 28,
      lineHeight: '28px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      padding: 10,
      [breakpoints.down('lg')]: {
        height: 60,
      },
    },
    resourceImage: {
      width: '60%',
      borderRadius: '6px',
    },
    resourceDescription: {
      fontSize: 24,
      lineHeight: '24px',
      fontWeight: 300,
      padding: 10,
      [breakpoints.down('lg')]: {
        height: 60,
      },
    },
    resourceButton: {
      color: darkGreen,
      fontWeight: 600,
      backgroundColor: blueLight,
      width: '100%',
    },
    resourcesBox: {
      padding: '40px 40px 10px 40px',
    },
    pdfDownload: {
      padding: 25,
      textAlign: 'center',
      height: '100%',
    },
    socialMedia: {
      padding: 25,
      '& img': {
        width: '100%',
        borderRadius: '6px',
        pointerEvents: 'none',
      },
    },
  }),
  { name: 'InfoShared' },
);
