// use 'npm run g:a {name}' - to generate new action
import { IFolder } from '../types';
import { createAction } from '../utils/actions';

export const CHANGE_LOOP = 'PLAYBACK_SETTINGS/CHANGE_LOOP';
export const CHANGE_LOOP_SUCCESS = 'PLAYBACK_SETTINGS/CHANGE_LOOP_SUCCESS';
export const CHANGE_LOOP_FAILURE = 'PLAYBACK_SETTINGS/CHANGE_LOOP_FAILURE';

export const CHANGE_VISIBILITY = 'PLAYBACK_SETTINGS/CHANGE_VISIBILITY';
export const CHANGE_VISIBILITY_SUCCESS =
  'PLAYBACK_SETTINGS/CHANGE_VISIBILITY_SUCCESS';
export const CHANGE_VISIBILITY_FAILURE =
  'PLAYBACK_SETTINGS/CHANGE_VISIBILITY_FAILURE';

export const SORT = 'PLAYBACK_SETTINGS/SORT';
export const SORT_SUCCESS = 'PLAYBACK_SETTINGS/SORT_SUCCESS';
export const SORT_FAILURE = 'PLAYBACK_SETTINGS/SORT_FAILURE';

export const types = {
  CHANGE_LOOP,
  CHANGE_LOOP_SUCCESS,
  CHANGE_LOOP_FAILURE,

  CHANGE_VISIBILITY,
  CHANGE_VISIBILITY_SUCCESS,
  CHANGE_VISIBILITY_FAILURE,

  SORT,
  SORT_SUCCESS,
  SORT_FAILURE,
};

export const changeLoop = createAction<number>(CHANGE_LOOP);
export const changeLoopSuccess = createAction<number>(CHANGE_LOOP_SUCCESS);
export const changeLoopFailure = createAction<string>(CHANGE_LOOP_FAILURE);

export const changeVisibility = createAction<Partial<IFolder>>(
  CHANGE_VISIBILITY,
);
export const changeVisibilitySuccess = createAction<Partial<IFolder>>(
  CHANGE_VISIBILITY_SUCCESS,
);
export const changeVisibilityFailure = createAction<string>(
  CHANGE_VISIBILITY_FAILURE,
);

export const sort = createAction<Partial<IFolder>[]>(SORT);
export const sortSuccess = createAction<Partial<IFolder>[]>(SORT_SUCCESS);
export const sortFailure = createAction<string>(SORT_FAILURE);

export default {
  types,

  changeLoop,
  changeLoopSuccess,
  changeLoopFailure,

  changeVisibility,
  changeVisibilitySuccess,
  changeVisibilityFailure,

  sort,
  sortSuccess,
  sortFailure,
};
