import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

import useStyles from './styles';

interface IProps {
  showTitle?: boolean;
  onSave: (passcode: string) => any;
}

export default function InvitationPasscode({
  showTitle = false,
  onSave,
}: IProps) {
  const classes = useStyles();
  const [t] = useTranslation();

  const [passcode, setPasscode] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasscode(event.target.value);
  };

  const isDirty = passcode.length > 0;

  return (
    <>
      {showTitle && (
        <Box className={classes.title}>
          <Text color="textPrimary" variant="h6">
            {t('enter-invitation-passcode')}
          </Text>
        </Box>
      )}
      <Box className={classes.content}>
        <TextField
          className={classes.textArea}
          id={'enter_passcode'}
          value={passcode}
          placeholder={t('passcode')}
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.actions}>
        <Button
          variant="contained"
          onClick={() => {
            onSave(passcode);
            setPasscode('');
          }}
          color="primary"
          autoFocus
          disabled={!isDirty}
        >
          {t('ok')}
        </Button>
      </Box>
    </>
  );
}
