import React from 'react';

interface IProps {
  className?: string;
}

const Copy = (props: IProps) => (
  <svg
    {...props}
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.4737 0H2.21053C0.994737 0 0 0.981818 0 2.18182V17.4545H2.21053V2.18182H15.4737V0ZM18.7895 4.36364H6.63158C5.41579 4.36364 4.42105 5.34545 4.42105 6.54545V21.8182C4.42105 23.0182 5.41579 24 6.63158 24H18.7895C20.0053 24 21 23.0182 21 21.8182V6.54545C21 5.34545 20.0053 4.36364 18.7895 4.36364ZM18.7895 21.8182H6.63158V6.54545H18.7895V21.8182Z" />
  </svg>
);

export default Copy;
