// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { IBeloved, InviteActions } from '../types.d';
import {
  FETCH,
  FETCH_FAILURE,
  FETCH_SUCCESS,
  SELECT,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  ADD,
  ADD_SUCCESS,
  ADD_FAILURE,
  SAVE_RECORDING_SUCCESS,
  CONFIRM_RECORDING,
  SKIP_RECORDING,
  SEND_INVITATION_SUCCESS,
  SKIP_INVITATION,
} from '../actions/beloved';

import { CHANGE_LOOP } from '../actions/playbackSettings';

export enum BelovedOnboarding {
  addLovedOne = 'addLovedOne',
  recordMessage = 'recordMessage',
  recordingConfirmation = 'recordingConfirmation',
  sendRecordingInvitation = 'sendRecordingInvitation',
  sendPlaybackInstruction = 'sendPlaybackInstruction',
}

export interface IBelovedState {
  isLoading?: boolean;
  selected: IBeloved | null;
  list: IBeloved[];
  error?: Error;
  onboarding: BelovedOnboarding | null;
}

interface IState {
  beloved: IBelovedState;
}

export const initialState: IBelovedState = {
  isLoading: false,
  selected: null,
  list: [],
  error: undefined,
  onboarding: null,
};

export const getBelovedState = (state: IState): IBelovedState => state.beloved;

export default createReducer(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [SELECT]: (selected) => ({ selected }),
  [ADD]: () => ({ isLoading: true, onboarding: BelovedOnboarding.addLovedOne }),
  [UPDATE]: () => ({ isLoading: true }),
  [CHANGE_LOOP]: (loopCount, { selected }) => ({
    selected: { ...(selected || {}), loopCount },
  }),
  [FETCH_SUCCESS]: (list, { selected }) => ({
    list,
    selected: selected || list[0],
    isLoading: false,
  }),
  [ADD_SUCCESS]: (newBeloved: IBeloved, { list }) => ({
    isLoading: false,
    selected: newBeloved,
    list: [...list, newBeloved],
    onboarding: BelovedOnboarding.recordMessage,
  }),
  [UPDATE_SUCCESS]: (beloved: IBeloved, { list, selected }) => ({
    isLoading: false,
    selected: selected._id === beloved._id ? beloved : selected,
    list: beloved.isDeleted
      ? list.filter((f: IBeloved) => f._id !== beloved._id)
      : list.map((a: IBeloved) => (a._id === beloved._id ? beloved : a)),
  }),
  [FETCH_FAILURE]: (error) => ({ error, isLoading: false }),
  [ADD_FAILURE]: (error) => ({ error, isLoading: false, onboarding: null }),
  [UPDATE_FAILURE]: (error) => ({ error, isLoading: false }),
  [SAVE_RECORDING_SUCCESS]: () => ({
    onboarding: BelovedOnboarding.recordingConfirmation,
  }),
  [CONFIRM_RECORDING]: () => ({
    onboarding: BelovedOnboarding.sendRecordingInvitation,
  }),
  [SKIP_RECORDING]: () => ({
    onboarding: BelovedOnboarding.sendRecordingInvitation,
  }),
  [SEND_INVITATION_SUCCESS]: (invite, { onboarding }) => ({
    onboarding:
      onboarding && invite.type === InviteActions.record
        ? BelovedOnboarding.sendPlaybackInstruction
        : null,
  }),
  [SKIP_INVITATION]: (invite, { onboarding }) => ({
    onboarding:
      onboarding && invite.type === InviteActions.record
        ? BelovedOnboarding.sendPlaybackInstruction
        : null,
  }),
});
