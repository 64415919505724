import React from 'react';

interface IProps {
  active?: boolean;
  color?: string;
  width?: number;
  height?: number;
  alt?: string;
}

const RecordPause = ({
  active = false,
  color = '#256065',
  width = 18,
  height = 32,
  alt = '',
}: IProps) => {
  const record = (
    <path
      d="M3.62835 9.23849V4.99861C3.62835 2.27615 5.97358 0 8.95841 0C11.9006 0 14.2885 2.27615 14.2885 4.99861V9.23849H3.62835ZM17.9982 9.50628V18.0307C16.2499 23.0293 11.0904 23.788 11.0904 23.788V29.4561H16.7616V32H1.1552V29.4561H6.86903V23.788C6.86903 23.788 1.75217 23.0293 0.00390625 18.0307V9.50628L2.09329 8.47978V16.2455C2.09329 16.2455 2.7329 21.4672 8.95841 21.4672C15.1839 21.4672 15.9088 16.2455 15.9088 16.2455V8.47978L17.9982 9.50628ZM14.2885 10.7113V14.5495C14.2885 17.272 11.9006 19.5481 8.95841 19.5481C5.97358 19.5481 3.62835 17.272 3.62835 14.5495V10.7113H14.2885Z"
      fill={color}
    />
  );

  const pause = (
    <path
      d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0ZM13.1429 21.4286C13.1429 21.5857 13.0143 21.7143 12.8571 21.7143H11.1429C10.9857 21.7143 10.8571 21.5857 10.8571 21.4286V10.5714C10.8571 10.4143 10.9857 10.2857 11.1429 10.2857H12.8571C13.0143 10.2857 13.1429 10.4143 13.1429 10.5714V21.4286ZM21.1429 21.4286C21.1429 21.5857 21.0143 21.7143 20.8571 21.7143H19.1429C18.9857 21.7143 18.8571 21.5857 18.8571 21.4286V10.5714C18.8571 10.4143 18.9857 10.2857 19.1429 10.2857H20.8571C21.0143 10.2857 21.1429 10.4143 21.1429 10.5714V21.4286Z"
      fill={color}
    />
  );

  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {active ? pause : record}
    </svg>
  );
};

export default RecordPause;
