import { createAction, createEmptyAction } from '../utils/actions';
import { IAuthData, ILoginData, IMe, ISignupData } from '../types.d';

export const SET_LOADING = 'AUTH/SET_LOADING';
export const SET_USER = 'AUTH/SET_USER';
export const LOGIN = 'AUTH/LOGIN';
export const LOGOUT = 'AUTH/LOGOUT';
export const SIGNUP = 'AUTH/SIGNUP';
export const AUTHORIZE = 'AUTH/AUTHORIZE';
export const SET_AUTH_ERROR = 'AUTH/ERROR';

export const VERIFY = 'AUTH/VERIFY';
export const VERIFY_SUCCESS = 'AUTH/VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'AUTH/VERIFY_FAILURE';

export const SEND_CODE = 'AUTH/SEND_VERIFICATION_CODE';
export const SEND_CODE_SUCCESS = 'AUTH/SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_CODE_FAILURE = 'AUTH/SEND_VERIFICATION_CODE_FAILURE';

export const types = {
  SET_LOADING,
  LOGIN,
  LOGOUT,
  SIGNUP,
  AUTHORIZE,
  SET_AUTH_ERROR,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  SEND_CODE,
  SEND_CODE_SUCCESS,
  SEND_CODE_FAILURE,
  SET_USER,
};

export const setLoading = createAction<boolean>(SET_LOADING);
export const authorize = createAction<IAuthData>(AUTHORIZE);
export const setAuthError = createAction<string>(SET_AUTH_ERROR);
export const login = createAction<ILoginData>(LOGIN);
export const logout = createEmptyAction(LOGOUT);
export const signup = createAction<ISignupData>(SIGNUP);
export const setUser = createAction<IMe>(SET_USER);

export const verify = createAction<string>(VERIFY);
export const verifySuccess = createEmptyAction(VERIFY_SUCCESS);
export const verifyFailure = createAction<string>(VERIFY_FAILURE);

export const sendCode = createEmptyAction(SEND_CODE);
export const sendCodeSuccess = createEmptyAction(SEND_CODE_SUCCESS);
export const sendCodeFailure = createAction<string>(SEND_CODE_FAILURE);

export default {
  types,
  setLoading,
  authorize,
  setAuthError,
  login,
  logout,
  signup,
  verify,
  verifySuccess,
  verifyFailure,
  setUser,
};
