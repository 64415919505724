import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  function ({
    darkGreen,
    mainBlue,
    blueLight,
    greenDark,
    greyLight,
    pink,
    white,
    breakpoints,
  }) {
    return {
      root: {},
      sloganBox: {
        padding: '10px 40px',
        width: '100%',
        alignSelf: 'center',
        maxWidth: 1440,
        '&& button': {
          maxWidth: 300,
          width: '100%',
        },
        [breakpoints.down('sm')]: {
          flexDirection: 'column',
          padding: '10px 40px',
          textAlign: 'center',
          '&& button': {
            width: '100%',
          },
        },
      },
      slogan: {
        fontSize: 30, // 40 64
        lineHeight: '40px', // 50px 74px
        color: greenDark,
        fontWeight: 'bold',
        paddingBottom: 30,
        textTransform: 'uppercase',
        [breakpoints.down('sm')]: {
          paddingBottom: 10,
          fontSize: 20, // 24 36
          fontWeight: 700,
          lineHeight: '30px', // 34px 46px
        },
      },
      serviceDescription: {
        fontSize: 18, // 20 64
        lineHeight: '28px', // 30px 74px
        [breakpoints.down('sm')]: {
          fontSize: 18, // 18
          lineHeight: '24px', // 24px
        },
      },
      quoteBox: {
        '& i': {
          maxWidth: 1100,
          padding: '10px 5px', // 30px 20px
          fontWeight: 400,
          fontSize: 24, // 38
          color: greenDark,
          lineHeight: '28px', // 58px
          marginBottom: 10, // 100

          [breakpoints.down('sm')]: {
            fontSize: 24, // 24
            lineHeight: '28px', // 34px
            padding: '10px 5px', // 50px 20px
          },
        },
        textAlign: 'center',
      },
      stepsTitle: {
        fontWeight: 400,
        fontSize: 24, // 28 42
        color: greenDark,
        lineHeight: '36px', //  40px 68px
        paddingTop: 30,
        [breakpoints.down('sm')]: {
          textAlign: 'center',
          fontSize: 18, // 20 24
          lineHeight: '24px', // 28px 34px
          padding: 10,
        },
      },
      stepsBox: {
        background: greyLight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
        [breakpoints.down('sm')]: {
          marginTop: 10,
        },
      },
      step: {
        width: '100%',
        maxWidth: 1440,
        padding: '10px 50px', // '10px 5px' 30px 20px
        [breakpoints.up('md')]: {
          '& div:last-child': {
            marginTop: 40,
          },
        },
        '& img': {
          width: '100%',
        },
        [breakpoints.down('sm')]: {
          textAlign: 'center',
          padding: 10, // 10
          '& div:first-child': {
            padding: 10,
            marginTop: 5, // 40
          },
          // '& p, & h2': {
          //   lineHeight: '34px',
          //   padding: '5px 0 0 0', // 10px 0
          // },
          '& img': {
            width: '80%',
          },
        },
      },
      stepTitle: {
        fontWeight: 400,
        fontSize: 20, // 24 38
        lineHeight: '26px', // 30px 58px
        color: pink,
        [breakpoints.down('sm')]: {
          fontSize: 18, // 24
          lineHeight: '24px', // 34px
          padding: '20px 0 5px 0', // 50px 20px
        },
      },
      stepHead: {
        fontSize: 20, // 22 32
        lineHeight: '34px', // 38px 74px
        [breakpoints.down('sm')]: {
          fontSize: 18,
          lineHeight: '24px',
        },
      },
      stepDescription: {
        fontSize: 18, // 20 28
        lineHeight: '30px', // 32px 34px
        [breakpoints.down('sm')]: {
          fontSize: 18,
          lineHeight: '24px',
          padding: '0 0 20px 0', // 10px 0
        },
      },
      story: {
        background:
          'linear-gradient(180deg, #46AFB7 10.74%, rgba(70, 175, 183, 0) 60%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
        '& > .MuiBox-root': {
          marginTop: 20,
          marginBottom: 20,
        },
        [breakpoints.down('sm')]: {
          margin: 40,
          '& > .MuiBox-root': {
            margin: 40,
          },
        },
      },
      storyTitle: {
        fontSize: 24, // 30 62
        lineHeight: '28px', // 30px 62px
        color: white,
        [breakpoints.down('sm')]: {
          fontSize: 18, // 20 24
          lineHeight: '18px', // 20px 24px
        },
      },
      storyText: {
        fontSize: 18, // 20 36
        lineHeight: '28px', // 30px 56px
        [breakpoints.down('sm')]: {
          fontSize: 18, // 20
          lineHeight: '24px', // 30px
        },
      },
      storyBox: {
        backgroundColor: white,
        width: '75%',
        position: 'relative',
        borderRadius: 15,
        [breakpoints.down('sm')]: {
          width: '90%',
          '& div:last-child': {
            textAlign: 'center',
            padding: '15px 0px 10px 0',
          },
        },
      },
      storyIcon: {
        position: 'absolute',
        left: '50%',
        marginLeft: -50,
        marginTop: -15, // -25
        '& img': {
          width: 60,
        },
        [breakpoints.down('sm')]: {
          marginLeft: -25,
          marginTop: -15,
          '& img': {
            width: 60,
          },
        },
      },
      auditoryTitle: {
        fontSize: 36,
        lineHeight: '36px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        padding: 10,
        [breakpoints.down('lg')]: {
          height: 80,
        },
      },
      auditoryDescription: {
        fontSize: 20, // 24
        lineHeight: '20px', // 24px
        fontWeight: 300,
        padding: 10,
        [breakpoints.down('lg')]: {
          height: 80,
        },
      },
      auditoryButton: {
        color: darkGreen,
        fontWeight: 600,
        backgroundColor: blueLight,
        width: '100%',
      },
      carousel: {
        '& .rec-carousel-item': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        '& button.rec-arrow': {
          background: 'none',
          boxShadow: 'none',
          '&:hover, &:focus': {
            backgroundColor: mainBlue,
          },
          [breakpoints.down('sm')]: {
            display: 'none',
          },
        },
        '& .rec-pagination': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          '& .rec-dot.rec-dot_active': {
            background: mainBlue,
          },
          '& .rec-dot': {
            background: greenDark,
            boxShadow: 'none',
            width: 12,
            height: 12,
          },
        },
      },
      signupButton: {
        fontSize: 18, // 20
      },
      videoBox: {
        display: 'flex',
        justifyContent: 'center',
      },
      summaryBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
        [breakpoints.down('sm')]: {
          marginTop: 10,
        },
      },
      dividerBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 50,
        [breakpoints.down('sm')]: {
          margin: 0,
        },
      },
      summary: {
        width: '100%',
        maxWidth: 1440,
        padding: '0px 50px',
        [breakpoints.up('md')]: {
          '& div:last-child': {
            marginTop: 40,
          },
        },
        '& img': {
          width: '100%',
        },
        [breakpoints.down('sm')]: {
          textAlign: 'center',
          padding: 0,
          '& > .MuiGrid-item': {
            padding: '0px 20px 10px 20px',
            margin: 0,
          },
          '& img': {
            width: '80%',
          },
        },
      },
      summaryHead: {
        fontSize: 20, // 22 32
        lineHeight: '34px', // 38px 74px
        [breakpoints.down('sm')]: {
          fontSize: 18,
          lineHeight: '24px',
          padding: '10px 0px 20px 0px',
        },
      },
      summaryDescription: {
        fontSize: 18,
        lineHeight: '30px',
        [breakpoints.down('sm')]: {
          lineHeight: '24px',
        },
      },
    };
  },
  { name: 'Home' },
);
