import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ breakpoints, mainBlue, greenDark }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: 500,
    },
    icon: {
      fill: greenDark,
    },
    switchAlbum: {
      '&& svg': {
        fill: greenDark,
        fontSize: 33,
      },
      color: greenDark,
    },
    addAlbum: {
      '&& svg': {
        fill: mainBlue,
      },
    },

    sidebarMenu: {
      minWidth: 130,
      boxShadow: '0 0 15px #aaa',
      clipPath: 'inset(0px -15px 0px 0px)',
      [breakpoints.down(600)]: {
        minHeight: 130,
        width: '100&',
      },
    },

    header: {
      padding: '22px 10px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    title: {
      paddingTop: 10,
      display: 'flex',
      width: 360,
      fontSize: 13,
      alignItems: 'center',
      '& svg': {
        minWidth: 32,
        minHeight: 32,
      },
      '& h1': {
        marginLeft: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: 'inherit',
        textOverflow: 'ellipsis',
      },
      [breakpoints.down('md')]: {
        cursor: 'pointer',
        width: '100%',
        '& h1': {
          flexGrow: 1,
        },
      },
    },
    settingsButton: {
      marginTop: 20,
      textTransform: 'initial',
    },
    settingsMiniButton: {
      alignSelf: 'flex-end',
      '&& svg': {
        minHeight: 12,
        minWidth: 12,
      },
    },
    emptyFolderPlaceholder: {
      marginTop: 64,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        width: '100%',
        maxWidth: 500,
      },
      [breakpoints.down('md')]: {
        marginTop: 30,
        padding: 40,
        textAlign: 'center',
      },
    },
    recordMessageButton: {
      marginTop: 20,
      textTransform: 'initial',
    },
    pagination: {
      alignSelf: 'flex-end',
    },
  }),
  { name: 'MessagesPage' },
);
