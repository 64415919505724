import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';

interface IProps {
  isOpen: boolean;
  description: string;
  messageId: string;
  onSave: (description: string) => any;
  onRemove: () => any;
  onClose: () => any;
}

export default function AddEditMessageDescription({
  isOpen,
  description,
  messageId,
  onClose,
  onSave,
  onRemove,
}: IProps) {
  const classes = useStyles();
  const [t] = useTranslation();

  const [newDescription, setNewDescription] = useState(description);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDescription(event.target.value);
  };

  const removeDescription = () => {
    if (description) {
      onRemove();
    } else {
      setNewDescription('');
      onClose();
    }
  };

  useEffect(() => {
    setNewDescription(description);
  }, [description]);

  const isDirty = description !== newDescription;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <Typography color="textPrimary" variant="h6">
          {t(description.length ? 'edit-description' : 'add-description')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.textArea}
          id={`add_edit_description_text_${messageId}`}
          multiline
          rows={4}
          value={newDescription}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {description && (
          <Button
            variant="contained"
            onClick={removeDescription}
            color="primary"
          >
            {t('delete')}
          </Button>
        )}
        <Button
          onClick={() => onSave(newDescription)}
          color="primary"
          autoFocus
          variant="contained"
          disabled={!isDirty}
        >
          {t(description ? 'save' : 'add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
