// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction } from '../utils/actions';

export const RESET_APP = 'ROOT/RESET_APP';

export const types = {
  RESET_APP,
};

export const resetApp = createEmptyAction(RESET_APP);

export default {
  types,
  resetApp,
};
