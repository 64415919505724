import React from 'react';

const MoveTo = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.857143 14.2222C0.857143 15.2 1.62857 16 2.57143 16H9.42857C10.3714 16 11.1429 15.2 11.1429 14.2222V3.55556H0.857143V14.2222ZM12 0.888889H9L8.14286 0H3.85714L3 0.888889H0V2.66667H12V0.888889Z" />
    </svg>
  );
};

export default MoveTo;
