import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ breakpoints }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    invitationContainer: {
      boxShadow: `1px 4px 12px 1px rgba(26, 67, 70, 0.07)`,
      borderRadius: 10,
      flexGrow: 1,
      height: '100%',
      [breakpoints.up('lg')]: {
        margin: '0 20px',
        padding: '23px 32px',
      },
      padding: '10px 5px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '30px 0 20px 0',
    },
    titleText: {
      paddingLeft: 10,
    },
    recordContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: 40,
    },
    instructionsContainer: {
      width: '70%',
      [breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    ctaText: {
      fontSize: 20,
      paddingBottom: 40,
    },
    instructionsText: {
      paddingBottom: 40,
      textAlign: 'center',
    },
  }),
  { name: 'RecordPage' },
);
