import { Store } from 'redux';
import actions from '../actions';

/**
 * Build object of redux actions as functions that could be called with params and finally dispatch the same action to redux store
 * @example
 * ```javascript
 * const actions = {
 *  application: {
 *   startLoading: (type) => (payload = {}) => ({type, payload}) // {type: 'APPLICATION/START_LOADING', payload:{}},
 *   stopLoading: (type) => (payload = {}) => ({type, payload}) // {type: 'APPLICATION/STOP_LOADING', payload: {}},
 *   setError:  (type) => (payload) => ({type, payload}) // {type: 'APPLICATION/STOP_LOADING', payload: Error},
 * }
 * };
 *  const newActions = actionsDispatchers(store);
 * newActions: {
 *  application: {
 *   startLoading: () => store.dispatch({type: 'APPLICATION/START_LOADING', payload:{}}),
 *   stopLoading: () => store.dispatch({type: 'APPLICATION/STOP_LOADING', payload:{}}),
 *   setError: (Error) => store.dispatch({type: 'APPLICATION/STOP_LOADING', payload:Error),
 * }
 * }
 *
 * ```
 * @param store - Redux store object
 */
const actionsDispatchers = (store: any) =>
  Object.keys(actions).reduce((actionsList, actFileName) => {
    const methodsList = Object.keys(actions[actFileName]).reduce(
      (methods, methodName) => {
        const method = actions[actFileName][methodName];
        return {
          ...methods,
          [methodName]: (payload?: any) => store.dispatch(method(payload)),
        };
      },
      {},
    );

    return { ...actionsList, [actFileName]: methodsList };
  }, {});

export default (store: Store) => {
  const soyl = {
    actions: actionsDispatchers(store),
  };
  // window.soyl.state - show current state of redux tree
  Object.defineProperty(soyl, 'state', {
    get: () => store.getState(),
  });

  // window.soyl.dispatch - redux dispatch function
  Object.defineProperty(soyl, 'dispatch', {
    get: () => store.dispatch,
  });
  // window.soyl // {actions, state, dispatch}
  Object.defineProperty(window, 'soyl', {
    get: () => soyl,
  });
};
