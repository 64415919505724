import { call, put, takeLatest, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  getBelovedInfo,
  deleteBeloved,
  updateName,
  resetPasscode,
  createBeloved,
  sendInvitation,
} from '../utils/api';

import { callApi } from '../utils/helpers';

import {
  fetch,
  fetchSuccess,
  fetchFailure,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE,
  updateSuccess,
  updateFailure,
  ADD,
  addSuccess,
  addFailure,
  sendInvitationSuccess,
  IUpdateParams,
  SEND_INVITATION,
  ISendParams,
  SKIP_INVITATION,
  IInviteParams,
} from '../actions/beloved';

import { InviteActions } from '../types.d';

import { notify } from '../actions/notification';
import { IAction } from '../utils/redux-create-reducer';
import amplitude from '../utils/analytics';

import { getBelovedState, IBelovedState } from '../reducers/beloved';

import { Routes } from '../constants';

function* fetchWorker() {
  try {
    const { data: beloved } = yield call(callApi, getBelovedInfo);
    yield put(fetchSuccess(beloved));
  } catch (error) {
    yield put(fetchFailure(error));
  }
}

function* addWorker({ payload: belovedName }: IAction<string>) {
  try {
    const { data: beloved } = yield call(callApi, createBeloved, belovedName);
    amplitude.CREATE_LOVED_ONE({ belovedId: beloved._id });
    yield put(addSuccess(beloved));
    yield put(notify('add-beloved-success'));
  } catch (error) {
    yield put(notify('oops-something-went-wrong', 'error'));
    yield put(addFailure(error));
  }
}
function* sendInvitationWorker({
  payload: { beloved, type, emails },
}: IAction<ISendParams>): any {
  const { onboarding }: IBelovedState = yield select(getBelovedState);
  try {
    yield call(callApi, sendInvitation, beloved._id, emails, type);
    type === 'record'
      ? amplitude.SEND_RECORDING_INVITATION({ belovedId: beloved._id })
      : amplitude.SEND_PLAYBACK_INSTRUCTIONS({ belovedId: beloved._id });
    type === 'record'
      ? yield put(sendInvitationSuccess({ type: InviteActions.record }))
      : yield put(sendInvitationSuccess({ type: InviteActions.playback }));
    yield put(notify('sent-invitation-success'));
    if (onboarding && type === 'playback') {
      yield put(push(Routes.Messages));
    }
  } catch (error) {
    yield put(notify('oops-something-went-wrong', 'error'));
    yield put(addFailure(error));
  }
}

export function* skipInvitationWorker({
  payload: { type },
}: IAction<IInviteParams>) {
  if (type === InviteActions.playback) {
    yield put(push(Routes.Messages));
  }
}

function* updateWorker({ payload: { beloved, type } }: IAction<IUpdateParams>) {
  try {
    switch (type) {
      case 'delete':
        {
          const { data: deletedBeloved } = yield call(
            callApi,
            deleteBeloved,
            beloved._id,
          );
          amplitude.DELETE_LOVED_ONE({ belovedId: beloved._id });
          yield put(updateSuccess({ ...deletedBeloved, isDeleted: true }));
          yield put(notify('delete-beloved-success'));
        }
        break;
      case 'editName':
        {
          const { data: updatedBeloved } = yield call(
            callApi,
            updateName,
            beloved._id,
            beloved.name,
          );
          amplitude.EDIT_NAME_LOVED_ONE({ belovedId: beloved._id });
          yield put(updateSuccess(updatedBeloved));
          yield put(notify('rename-beloved-success'));
        }
        break;
      case 'resetPlaybackCode':
        {
          const { data: updatedBeloved } = yield call(
            callApi,
            resetPasscode,
            beloved._id,
            false,
          );
          amplitude.RESET_PASSCODE_PLAYBACK({ belovedId: beloved._id });
          yield put(updateSuccess(updatedBeloved));
          yield put(notify('reset-playback-code-success'));
        }
        break;
      case 'resetRecordingCode':
        {
          const { data: updatedBeloved } = yield call(
            callApi,
            resetPasscode,
            beloved._id,
            true,
          );
          amplitude.RESET_PASSCODE_RECORDING({ belovedId: beloved._id });
          yield put(updateSuccess(updatedBeloved));
          yield put(notify('reset-recoding-code-success'));
        }
        break;
      default: {
        yield put(updateFailure('Wrong type provided'));
        yield put(notify('oops-something-went-wrong', 'error'));
        console.error('Wrong type provided');
      }
    }
  } catch (error) {
    yield put(updateFailure(error.message));
    console.error(error);
  }
}

export function* belovedListFetcher() {
  yield put(fetch());

  const { type, payload } = yield take([FETCH_SUCCESS, FETCH_FAILURE]);

  if (type === FETCH_SUCCESS && payload) {
    return payload.length ? payload : [];
  }
  return [];
}

export default function* messagesSaga() {
  yield takeLatest(FETCH, fetchWorker);
  yield takeLatest(UPDATE, updateWorker);
  yield takeLatest(ADD, addWorker);
  yield takeLatest(SEND_INVITATION, sendInvitationWorker);
  yield takeLatest(SKIP_INVITATION, skipInvitationWorker);
}
