import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import type {
  CreateOrderActions,
  OnApproveData,
  OnApproveActions,
} from '@paypal/paypal-js/types/components/buttons';
import Validator from 'validator';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Text from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useTranslation } from 'react-i18next';
import donateImage from '../assets/donate.svg';

import store from '../store';
import { notify } from '../actions/notification';

import amplitude from '../utils/analytics';

const useStyles = makeStyles(
  ({ greenGrey, breakpoints }) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down(1025)]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      margin: 40,
      '& h2': {
        alignSelf: 'center',
        fontWeight: 'bold',
      },
    },
    imageSection: {
      [breakpoints.up(1441)]: {
        padding: '40px 140px',
      },
      [breakpoints.down(1441)]: {
        '& img': {
          width: 700,
        },
      },
      [breakpoints.down(1281)]: {
        '& img': {
          width: 550,
        },
      },
      [breakpoints.down(1025)]: {
        '& img': {
          width: 400,
        },
      },
      [breakpoints.down(601)]: {
        display: 'none',
      },
      padding: '0px 20px',
    },
    formSection: {
      display: 'flex',
      [breakpoints.up(1025)]: {
        justifyContent: 'flex-start',
      },
      [breakpoints.down(1025)]: {
        justifyContent: 'center',
      },
      marginTop: '40px',
      width: '100%',
      [breakpoints.up(601)]: {
        '& form': {
          width: 500,
        },
      },
      [breakpoints.down(601)]: {
        '& form': {
          width: '100%',
        },
      },
      '& .MuiFormHelperText-root': {
        color: greenGrey,
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    instruction: {
      paddingTop: 5,
    },
    amountButton: {
      width: '100%',
    },
    info: {
      fontSize: '0.9rem',
      paddingBottom: 5,
    },
    amountEntry: {
      display: 'flex',
    },
    currencyLabel: {
      fontSize: '1.1rem',
    },
  }),
  { name: 'Donate' },
);

interface IAmountSelectionProps {
  amount: number;
  onClick: (value: number) => any;
}

const AmountSelection = ({ amount, onClick }: IAmountSelectionProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6}>
      <Button
        className={classes.amountButton}
        onClick={() => onClick(amount)}
        variant="contained"
        color="primary"
      >
        {`$${amount}`}
      </Button>
    </Grid>
  );
};

const Donate = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [amount, setAmount] = useState('1');
  const [amountError, setAmountError] = useState('');
  const [orderId, setOrderId] = useState('');
  const amountInputRef = useRef<any>();
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = React.useState('CAD');

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const updateAmount = (value: number) => {
    setAmountError('');
    setAmount(value + '');
  };

  const validateAmount = useCallback(() => {
    if (amountInputRef.current) {
      // Check input amount, in case of custom account entered
      const inputAmount = amountInputRef.current.value.substring(1);
      const inputNumber = parseFloat(inputAmount.replace(/[^\d.]/g, ''));
      if (
        !Validator.isCurrency(inputAmount, {
          allow_negatives: false,
        })
      ) {
        setAmountError(t('enter-valid-amount'));
      } else if (inputNumber <= 0) {
        setAmountError(t('enter-amount-greater-than-zero'));
      } else {
        setAmountError('');
      }
    }
  }, [t]);

  const handleClick = useCallback(
    (e) => {
      if (
        !(amountInputRef.current && amountInputRef.current.contains(e.target))
      ) {
        validateAmount();
      }
    },
    [validateAmount],
  );

  const handleAmountKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      validateAmount();
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value.substring(1));
  };

  const handleCurrencyChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setCurrency(event.target.value as string);
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency: event.target.value as string,
      },
    });
  };

  const createOrder = (
    data: Record<string, unknown>,
    actions: CreateOrderActions,
  ) => {
    const orderAmount = parseFloat(amount.replace(/[^\d.]/g, '')).toString();
    return actions.order
      .create({
        purchase_units: [
          {
            description: 'Sound of Your Love Donation',
            amount: {
              value: orderAmount,
              currency_code: currency,
            },
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then((orderId: string) => {
        setOrderId(orderId);
        amplitude.START_DONATION({
          orderId,
          amount,
          currency,
        });
        return orderId;
      });
  };

  const onApprove = (data: OnApproveData, actions: OnApproveActions) => {
    return actions.order.capture().then(() => {
      amplitude.COMPLETE_DONATION({
        orderId: data.orderID,
        amount,
        currency,
        payerId: data.payerID,
      });
      store.dispatch(notify('thanks-donation'));
    });
  };

  const onCancel = (data: Record<string, unknown>) => {
    amplitude.CANCEL_DONATION({
      orderId: data.orderID,
      amount,
      currency,
    });
    store.dispatch(notify('donation-canceled'));
  };

  const onError = () => {
    amplitude.DONATION_ERROR({});
    store.dispatch(notify('donation-error', 'error'));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageSection}>
        <img src={donateImage} alt={t('donate')} />
      </div>
      <div className={classes.formSection}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Text variant="h2">{t('donate')}</Text>
            <Grid item xs={12}>
              <Divider />
              <Text className={classes.instruction} variant="body2">
                1. {t('specify-an-amount')}
              </Text>
            </Grid>
            {[10, 25, 50, 100].map((amount, index) => (
              <AmountSelection
                key={`amount-${index}`}
                amount={amount}
                onClick={updateAmount}
              />
            ))}
            <Grid item xs={12}>
              <TextField
                id="amount"
                autoComplete="transaction-amount"
                inputRef={amountInputRef}
                helperText={
                  amountError.length > 0
                    ? amountError
                    : t('enter-custom-amount')
                }
                error={amountError.length > 0}
                value={`$${amount}`}
                onChange={handleAmountChange}
                onKeyPress={handleAmountKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        labelId="select-currency-label"
                        id="select-currency"
                        value={currency}
                        onChange={handleCurrencyChange}
                      >
                        {['CAD', 'USD'].map((option) => (
                          <MenuItem key={`menu-${option}`} value={option}>
                            <Text variant="body2">{option}</Text>
                          </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Text className={classes.instruction} variant="body2">
                2. {t('make-donation')}
              </Text>
            </Grid>
            {orderId && (
              <Grid item xs={12}>
                <Text className={classes.instruction} variant="body2">
                  {t('order-id')}
                  {' : '}
                  {orderId}
                </Text>
              </Grid>
            )}
            <Grid item xs={12}>
              {/* lol https://github.com/Microsoft/TypeScript/issues/27552#issuecomment-495830020
               // @ts-ignore */ /* prettier-ignore */}
              <PayPalButtons createOrder={createOrder}
                onApprove={onApprove}
                onCancel={onCancel}
                onError={onError}
                forceReRender={amount}
                style={{
                  color: 'blue',
                  shape: 'rect',
                  height: 40,
                }}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default Donate;
