import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  item: {
    position: 'relative',
    fontSize: '14px',
    lineHeight: 1.4,
    padding: '8px 12px',
    cursor: 'pointer',
    userSelect: 'none',
    outline: 'none',
    transition: 'all 160ms ease-out',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#EEF3FF',
      color: '#2C5DE5',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },

  active: {
    backgroundColor: '#EEF3FF',
    color: '#2C5DE5 !important',
  },

  submenu: {
    backgroundColor: '#fff',
    minWidth: '180px',
    borderRadius: '4px',
    boxShadow: '0px 12px 32px rgba(26, 26, 26, 0.2)',
    zIndex: 1100,
    position: 'absolute',
    display: 'none',
    top: '-5px',
    right: '98%',
    '& > ul': {
      listStyle: 'none',
      paddingLeft: '0',
      marginTop: '12px',
      marginBottom: '12px',
    },
  },
  submenuRight: {
    left: '98%',
  },

  dropdown: { position: 'relative', display: 'inline-flex' },
  button: {
    backgroundColor: '#EEF3FF',
    borderRadius: '4px',
    outline: 'none',
    userSelect: 'none',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: 'filter 160ms ease-out',
    border: 'none',
    padding: '0 16px',
    fontWeight: 600,
    fontSize: '13px',
    '&:hover': { filter: 'brightness(0.9)' },
  },
  ddActive: { filter: 'brightness(0.9)' },
  disabled: { cursor: 'not-allowed' },
  'disabled:hover': { filter: 'brightness(1) !important' },
  menu: {
    position: 'absolute',
    top: '44px',
    right: '0',
    zIndex: 1000,
    flexDirection: 'column',
    minWidth: '180px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 12px 32px rgba(26, 26, 26, 0.2)',
    '& > ul': {
      listStyle: 'none',
      paddingLeft: '0',
      marginTop: '12px',
      marginBottom: '12px',
    },
  },
  menuList: {
    position: 'absolute',
    zIndex: 20,
    backgroundColor: 'white',
    padding: '12px 0',
    borderRadius: 5,
    boxShadow: '0 12px 32px rgba(26,26,26,.2)',
  },
  menuRight: { left: '0' },
}));
