import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getAuth } from '../reducers/auth';
import { Routes } from '../constants';
import { getApplication, IApplication } from '../reducers/app';
import LoadingSpinner from '../components/LoadingSpinner';

export interface IProtectedRouteProps extends RouteProps {
  component: FC;
}

const redirectTo = (pathname: string, from: any) => (
  <Redirect
    to={{
      pathname,
      state: {
        from,
      },
    }}
  />
);

const ProtectedRoute = ({
  component: Component,
  ...rest
}: IProtectedRouteProps) => {
  const app: IApplication = useSelector(getApplication);
  const { user, authorized } = useSelector(getAuth);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authorized) {
          if (!user.verified) {
            return redirectTo(Routes.Verify, props.location);
          }
          return <Component {...rest} {...props} />;
        }
        if (!app.isLoading) {
          return redirectTo(Routes.Login, props.location);
        }
        return <LoadingSpinner />;
      }}
    />
  );
};

export default ProtectedRoute;
