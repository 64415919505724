import createReducer from '../utils/redux-create-reducer';
import {
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  TOGGLE_LOOP,
  SET_PAGE,
} from '../actions/playback';
import { IMessage } from '../types.d';

export interface IPlaybackState {
  isLoading: boolean;
  loop: boolean;
  currentPage: number;
  messages: IMessage[];
  error?: Error;
}

interface IState {
  playback: IPlaybackState;
}

export const initialState: IPlaybackState = {
  isLoading: false,
  loop: false,
  currentPage: 1,
  messages: [],
  error: undefined,
};

export const getPlaybackState = (state: IState): IPlaybackState =>
  state.playback;

export default createReducer(initialState, {
  [FETCH_MESSAGES]: () => ({ isLoading: true }),
  [FETCH_MESSAGES_SUCCESS]: (messages) => ({
    messages,
    isLoading: false,
  }),
  [FETCH_MESSAGES_FAILURE]: (error) => ({ error, isLoading: false }),
  [TOGGLE_LOOP]: ({}, { loop }) => ({ loop: !loop }),
  [SET_PAGE]: (currentPage) => ({ currentPage }),
});
