import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { addSubscriber } from '../utils/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.darkGreen,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: 'linear-gradient(45deg, #F2105A 30%, #F2105A 90%)',
    color: '#ffffff',
  },
  description: {
    textAlign: 'left',
  },
}));

const LearnMore = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    const response = await addSubscriber(firstname, email);
    if (response.status === 201) {
      setSuccessMessage('Thanks for signing up to stay updated on progress.');
    } else {
      setErrorMessage('There was a problem saving your info.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ContactMailIcon />
        </Avatar>
        <Typography variant="h6">Learn More</Typography>
        <Typography className={classes.description} variant="body1">
          Coronavirus restrictions have separated families and friends. Whether
          it be from COVID-19 or other causes, those who are fighting for their
          lives and those nearing the end of theirs do so alone. Many of them
          are too weak to speak or hold up a phone. Nurses, doctors, and other
          health care workers have limited time and resources to facilitate
          connection between loved ones.
        </Typography>
        <br />
        <Typography className={classes.description} variant="body1">
          Hearing is the last of the senses that remain unbroken at the time of
          death. The sound of loved ones voices can provide comfort and
          assurance. Sound of Your Love allows you to create a soundtrack of
          messages, accessible with any phone.
        </Typography>
        <p />
        <Typography variant="h6">Receive Updates</Typography>
        <p>Sign up below to receive updates about our service.</p>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="firstname"
                variant="outlined"
                required
                fullWidth
                id="firstname"
                label="First Name"
                autoFocus
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up for updates
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default LearnMore;
