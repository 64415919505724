import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({
    blueLight,
    darkGreen,
    white,
    greyLight,
    mainBlue,
    greenDark,
    breakpoints,
    greenGrey,
  }) => ({
    root: {
      minHeight: 500,
    },
    pagination: {
      marginTop: 32,
      alignSelf: 'center',
      '&& span': {
        lineHeight: 1,
      },
    },
    empty: {
      '&&': {
        minHeight: 'initial',
      },
      '&& th': {
        borderBottom: `2px solid ${greyLight}`,
      },
    },
    new: {
      background: blueLight,
    },
    hidden: {
      '& $cell': {
        opacity: 0.5,
      },
    },
    cell: {},
    isDragging: {
      outline: `2px dashed ${greenGrey}`,
      '&&': {
        background: white,
      },
      '& > *': {
        opacity: 0.5,
      },
    },
    tableContainer: {
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    table: {
      '& td, & th': {
        color: darkGreen,
      },
      '& th': {
        fontSize: '1rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
      },
    },
    playButton: {
      display: 'none',
      padding: 0,
    },
    playButtonActive: {
      display: 'block',
    },
    playIcon: {
      '&&': {
        width: 48,
        paddingLeft: '1rem',
      },
    },
    icon: {
      width: '3rem',
      paddingLeft: 0,
      paddingRight: 0,
      cursor: 'pointer',
      [breakpoints.down(1440)]: {
        width: '1rem',
      },
    },
    row: {
      [breakpoints.down(1440)]: {
        '& td': {
          fontSize: 16,
        },
      },
      cursor: 'grab',
      '&:hover': {
        '& .soyl-table-playButton': {
          display: 'block',
        },
      },
    },
    deleteMessage: {
      '& button': {
        padding: '5px 10px',
      },
      '&& .MuiSvgIcon-root': {
        width: 26,
        fill: greenDark,
      },
    },
    albums: {
      '& p': {
        [breakpoints.down(600)]: {
          width: 280,
        },
        [breakpoints.down(541)]: {
          width: 240,
        },
        [breakpoints.down(481)]: {
          width: 160,
        },
        [breakpoints.down(415)]: {
          width: 90,
        },
        [breakpoints.down(376)]: {
          width: 70,
        },
        [breakpoints.down(360)]: {
          width: 50,
        },

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: 'inherit',
        textOverflow: 'ellipsis',
      },
      '&& svg': {
        marginTop: 10,
      },
    },
    status: {},
    showHide: {
      '&& svg': {
        fill: greenDark,
      },
    },
    playbackCount: {},
    createdAt: {},
    duration: {
      minWidth: 50,
    },
    description: {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    messageActions: {
      '& > button': {
        backgroundColor: 'initial',
      },
      '& > div': {
        display: 'none',
      },
    },
    messageActionsButton: {
      '&:hover': {
        borderRadius: '50%',
        background: blueLight,
      },
    },
    messageActionsDropDown: {
      position: 'absolute',
      zIndex: 20,
      backgroundColor: 'white',
      padding: '12px 0',
      borderRadius: 5,
      boxShadow: '0 12px 32px rgba(26,26,26,.2)',
    },
    messageActionsSubItem: {
      '&& .messageActionsItemArrowCheckbox': {
        padding: 0,
      },
      '&& .messageActionsItemArrowCheckbox svg': {
        width: 20,
      },
      '&&:hover': {
        color: white,
        background: mainBlue,

        '&& .messageActionsItemArrowCheckbox svg': {
          fill: white,
        },
      },
    },
    messageActionsItem: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 250,
      '&&': {
        fontSize: 18,
      },
      color: darkGreen,
      '&&:hover': {
        color: white,
        background: mainBlue,
        '& .messageActionsItemIcons > svg': {
          fill: white,
        },
        '&& .messageActionsItemArrow': {
          boxSizing: 'border-box',
          width: 6,
          height: 6,
          borderWidth: '6px 0px 6px 9px',
          border: `6px solid ${white}`,
          borderColor: `transparent transparent transparent ${white}`,
        },
      },
      '&& .messageActionsItemIcons > svg': {
        width: 15,
        height: 16,
        marginRight: '.5rem',
        fill: greenDark,
      },
    },
    messageActionsArrow: {
      boxSizing: 'border-box',
      width: 6,
      height: 6,
      borderWidth: '6px 0px 6px 9px',
      border: `6px solid ${greenDark}`,
      borderColor: `transparent transparent transparent ${greenDark}`,
    },
    accHeader: {
      padding: '0 10px',
      borderBottom: `1px solid ${greyLight}`,
    },
    accRoot: {
      '&& p': {
        fontSize: 16,
      },
      '&& $icon': {
        width: 36,
      },
      '&& $playIcon': {
        padding: '0 5px',
      },
      '&& $duration': {
        width: 50,
      },
      '&& $playButton': {
        display: 'block',
      },
      '&& td': {
        border: 'none',
      },
    },
    accBody: {
      display: 'flex',
      flexDirection: 'column',
    },
    accBodyRow: {
      display: 'flex',
    },
    accBodyTitle: {
      minWidth: 150,
      '&& p': {
        padding: 10,
        textTransform: 'uppercase',
        color: greenGrey,
        fontSize: 14,
      },
    },
    descriptionMobile: {
      padding: '0px 10px',
    },
    accBodyIcon: {
      '&& p': {
        padding: 10,
        fontSize: 16,
      },
      '&& svg': {
        width: 18,
        height: 18,
        fill: greenDark,
      },
      '&& .MuiSvgIcon-root': {
        margin: '7px 0',
        width: 28,
        height: 28,
      },
    },
    accContentTitle: {
      display: 'flex',
      width: '100%',
      borderBottom: `1px solid ${greyLight}`,
      '&& th': {
        color: greenDark,
        fontSize: 16,
        lineHeight: '25px',
      },
    },
    drag: {
      '&& svg': {
        fill: greenDark,
      },
    },
    accAction: {
      width: 140,
    },
    accAlbum: {
      width: 120,
    },
    accClock: {},
  }),
  { name: 'MessagesTable' },
);
