import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, Typography as Text } from '@material-ui/core';
import { IDownloadableResource } from '../../types';

import useStyles from './styles';
import { formatedTranslation } from '../../utils/helpers';
import { socialMediaResources, downloadableResources } from '../../constants';
import Video from '../../components/Video';

const Chaplain = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const downloadableInfo: IDownloadableResource[] = [
    {
      id: 'flyer',
      title: t('flyer'),
      description: t('print-for-notice-board'),
      image: '',
      pdf: '',
    },
    {
      id: 'healthcare',
      title: t('info-sheet-health-workers'),
      description: t('share-with-colleagues'),
      image: '',
      pdf: '',
    },
    {
      id: 'family',
      title: t('info-sheet-families'),
      description: t('give-to-patient-family'),
      image: '',
      pdf: '',
    },
  ];

  const pdfDownloads: IDownloadableResource[] = downloadableInfo.map((t1) => ({
    ...t1,
    ...downloadableResources.find((t2) => t2.id === t1.id),
  }));

  const introSection = (
    <Grid
      container
      direction="row"
      spacing={3}
      justify="center"
      className={classes.titleBox}
    >
      <Grid md={6} sm={12} item>
        <Text className={classes.title}>{t('info-title-chaplain')}</Text>
        <Text className={classes.summary}>{t('info-summary-chaplain')}</Text>
      </Grid>
      <Grid md={6} sm={12} item>
        <Box className={classes.videoBox}>
          <Video videoId="_V2e0ZG_qgA" />
        </Box>
      </Grid>
    </Grid>
  );

  const instructionsSection = (
    <Box className={classes.infoBox}>
      <Text className={classes.infoPrompt}>
        {t('info-instructions-prompt')}
      </Text>
      <ul>
        <Text className={classes.infoDetails}>
          {t<string[]>('info-instructions-details', {
            returnObjects: true,
          }).map((item, index) => {
            return <li key={`inst-${index}`}>{item}</li>;
          })}
        </Text>
      </ul>
    </Box>
  );

  const benefitSection = (
    <Box className={classes.infoBox}>
      <Text className={classes.infoPrompt}>{t('info-benefit-prompt')}</Text>

      <ul>
        <Text className={classes.infoDetails}>
          {t<string[]>('info-benefit-details-chaplain', {
            returnObjects: true,
          }).map((item, index) => {
            return <li key={`benefit-${index}`}>{item}</li>;
          })}
        </Text>
      </ul>
    </Box>
  );

  const quoteSection = (
    <Box display="flex" alignSelf="center" className={classes.quoteBox}>
      <Text component="i">
        {formatedTranslation('info-quote-chaplain', [{ tag: 'p' }])}
      </Text>
    </Box>
  );

  const scriptSection = (
    <Box className={classes.infoBox}>
      <Text className={classes.infoPrompt}>
        {t('info-script-prompt-chaplain')}
      </Text>
      <div className={classes.infoDetails}>
        {t<string[]>('info-script-intro-chaplain', {
          returnObjects: true,
        }).map((item, index) => {
          return <p key={`script1-${index}`}>{item}</p>;
        })}
      </div>
      <ul>
        <Text className={classes.infoDetails}>
          {t<string[]>('info-script-details', {
            returnObjects: true,
          }).map((item, index) => {
            return <li key={`script2-${index}`}>{item}</li>;
          })}
        </Text>
      </ul>
    </Box>
  );

  const pdfDownloadsSection = (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={3}
      className={classes.resourcesBox}
    >
      <Grid item>
        <Text className={classes.resourceTitle}>
          {t('downloadable-resources')}
        </Text>
      </Grid>
      <Grid container item spacing={3} alignItems="center">
        {pdfDownloads.map(({ id, title, description, image, pdf }) => (
          <Grid
            md={4}
            xs={12}
            key={id}
            item
            className={classes.pdfDownload}
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Box mt={8} mb={3}>
              <img className={classes.resourceImage} src={image} alt={id} />
            </Box>
            <Text className={classes.resourceTitle}>{title}</Text>
            <Text className={classes.resourceDescription}>{description}</Text>
            <Box style={{ justifySelf: 'flex-end' }} my={3} width="50%">
              <Button
                href={pdf}
                color="primary"
                className={classes.resourceButton}
                variant="contained"
                download
              >
                {t('download')}
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const socialMediaSection = (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={3}
      className={classes.resourcesBox}
    >
      <Grid item>
        <Text className={classes.resourceTitle}>
          {t('social-media-shareables')}
        </Text>
      </Grid>
      <Grid container item spacing={3} alignItems="center">
        {socialMediaResources.map(({ id, image }) => (
          <Grid
            md={4}
            xs={12}
            key={id}
            item
            className={classes.socialMedia}
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <a href={image} download={id}>
              <img src={image} alt={id} />
            </a>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <Box
      className={classes.root}
      display="flex"
      width="100%"
      py={15}
      flexDirection="column"
      justifyContent="space-between"
    >
      {introSection}
      {instructionsSection}
      {benefitSection}
      {quoteSection}
      {scriptSection}
      {pdfDownloadsSection}
      {socialMediaSection}
    </Box>
  );
};

export default Chaplain;
