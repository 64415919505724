import { sessionStorageKey } from '../constants';
import { InviteActions } from '../types.d';
import { toNumber } from 'lodash';

const storageInvitationKey = (type: InviteActions): string => {
  return type === InviteActions.record
    ? `${sessionStorageKey}_record__`
    : `${sessionStorageKey}_playback__`;
};

export const setInvitation = (
  type: InviteActions,
  invitationCode: number,
  passcode: number,
) => {
  const storageKey = storageInvitationKey(type);
  if (invitationCode && passcode) {
    const storageValue = `${invitationCode}:${passcode}`;
    sessionStorage.setItem(storageKey, storageValue);
  } else {
    sessionStorage.removeItem(storageKey);
  }
};

export const getInvitation = (type: InviteActions) => {
  const storageKey = storageInvitationKey(type);
  const storageValue = sessionStorage.getItem(storageKey);
  const [invite, code] = storageValue ? storageValue.split(':') : [0, 0];
  const invitationCode = toNumber(invite) || 0;
  const passcode = toNumber(code) || 0;
  return { invitationCode, passcode };
};
