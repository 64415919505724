import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Text from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { update, SettingsActionTypes, IProfile } from '../../actions/settings';
import { getAuth, IAuthState } from '../../reducers/auth';
import useSettingsStyles from './styles';

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    email: {
      marginBottom: 76,
    },
    input: {
      width: '70%',
      minWidth: 280,
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
  { name: 'SettingsProfile' },
);

export const Profile = () => {
  const classes = {
    ...useSettingsStyles(),
    ...useStyles(),
  };
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { user }: IAuthState = useSelector(getAuth);
  const [profile, setProfile] = useState(user);

  const handleChange = (name: keyof IProfile) => (
    evt: ChangeEvent<HTMLInputElement>,
  ) => setProfile({ ...profile, [name]: evt.target.value });

  const handleUpdateProfile = (type: SettingsActionTypes) =>
    dispatch(update({ type, profile }));

  useEffect(() => {
    setProfile(user);
  }, [user]);

  return (
    <Grid container>
      <Grid xs={12} md={6} item direction="column" container spacing={3}>
        <Grid xs item>
          <Text className={classes.title} variant="body1">
            {t('name')}
          </Text>
        </Grid>
        <Grid xs item>
          <TextField
            className={classes.input}
            name="firstname"
            onChange={handleChange('firstname')}
            label={t('first-name')}
            value={profile.firstname}
          />
        </Grid>
        <Grid xs item>
          <TextField
            className={classes.input}
            onChange={handleChange('lastname')}
            name="lastname"
            label={t('last-name')}
            value={profile.lastname}
          />
        </Grid>
        <Grid xs item>
          <Button
            className={classes.button}
            color="primary"
            disabled={
              user.firstname === profile.firstname &&
              user.lastname === profile.lastname
            }
            variant="contained"
            onClick={() => handleUpdateProfile('name')}
          >
            {t('update-name')}
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12} md={6} item direction="column" container spacing={3}>
        <Grid xs item>
          <Text className={classes.title}>{t('email')}</Text>
        </Grid>
        <Grid xs item>
          <TextField
            className={cx(classes.input, classes.email)}
            onChange={handleChange('email')}
            name="email"
            label={t('email')}
            value={profile.email}
          />
        </Grid>
        <Grid xs item>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={user.email === profile.email}
            onClick={() => handleUpdateProfile('email')}
          >
            {t('update-email')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
