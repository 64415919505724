import React from 'react';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

const Microphone = ({ color = '#256065', width = 18, height = 32 }: IProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.62835 9.23849V4.99861C3.62835 2.27615 5.97358 0 8.95841 0C11.9006 0 14.2885 2.27615 14.2885 4.99861V9.23849H3.62835ZM17.9982 9.50628V18.0307C16.2499 23.0293 11.0904 23.788 11.0904 23.788V29.4561H16.7616V32H1.1552V29.4561H6.86903V23.788C6.86903 23.788 1.75217 23.0293 0.00390625 18.0307V9.50628L2.09329 8.47978V16.2455C2.09329 16.2455 2.7329 21.4672 8.95841 21.4672C15.1839 21.4672 15.9088 16.2455 15.9088 16.2455V8.47978L17.9982 9.50628ZM14.2885 10.7113V14.5495C14.2885 17.272 11.9006 19.5481 8.95841 19.5481C5.97358 19.5481 3.62835 17.272 3.62835 14.5495V10.7113H14.2885Z"
      fill={color}
    />
  </svg>
);

export default Microphone;
