import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route as RRDRoute, Switch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import CssBaseline from '@material-ui/core/CssBaseline';
import Layout from './containers/Layout';
import ProtectedRoute, {
  IProtectedRouteProps,
} from './containers/ProtectedRoute';

import LoadingSpinner from './components/LoadingSpinner';
import TermsOfService from './components/info/TermsOfService';
import FAQ from './components/info/FAQ';
import PrivacyPolicy from './components/info/PrivacyPolicy';
import CookiePolicy from './components/info/CookiePolicy';

import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import LearnMore from './pages/LearnMore';
import Donate from './pages/Donate';
import Verify from './pages/Verify';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Setup from './pages/Setup';
import Messages from './pages/Messages';
import PlaybackSettings from './pages/PlaybackSettings';
import Settings from './pages/Settings';
import Record from './pages/Record';
import Playback from './pages/Playback';

import Healthcare from './pages/Info/Healthcare';
import Hospice from './pages/Info/Hospice';
import Chaplain from './pages/Info/Chaplain';
import FaithLeaders from './pages/Info/FaithLeaders';
import Friends from './pages/Info/Friends';

import { Routes } from './constants';
import { history } from './store';
import { checkProtectedRoute } from './utils/helpers';
import { initializeAmplitude, initializeGA } from './utils/analyticsTracker';

const Route = (props: IProtectedRouteProps) => {
  const R = checkProtectedRoute(props.path || '') ? ProtectedRoute : RRDRoute;
  return <R {...props} />;
};

const AppRoutes = () => {
  return (
    <Layout>
      <Route path={Routes.Home} exact component={Home} />
      <Route path={Routes.Setup} component={Setup} />
      <Route path={Routes.Messages} component={Messages} />
      <Route path={Routes.Settings} component={Settings} />
      <Route path={Routes.PlaybackSettings} component={PlaybackSettings} />
      <Route path={Routes.LearnMore} component={LearnMore} />
      <Route path={Routes.Donate} component={Donate} />
      <Route path={Routes.Verify} component={Verify} />
      <Route path={Routes.TermsOfService} component={TermsOfService} />
      <Route path={Routes.FAQ} component={FAQ} />
      <Route path={Routes.PrivacyPolicy} component={PrivacyPolicy} />
      <Route path={Routes.Login} component={Login} />
      <Route path={Routes.SignUp} component={SignUp} />
      <Route path={Routes.ForgotPassword} component={ForgotPassword} />
      <Route path={Routes.ResetPassword} component={ResetPassword} />
      <Route path={Routes.Healthcare} component={Healthcare} />
      <Route path={Routes.Hospice} component={Hospice} />
      <Route path={Routes.Chaplain} component={Chaplain} />
      <Route path={Routes.FaithLeaders} component={FaithLeaders} />
      <Route path={Routes.Friends} component={Friends} />
      <Route path={Routes.CookiePolicy} component={CookiePolicy} />
      <Route path={Routes.Record} component={Record} />
      <Route path={Routes.Playback} component={Playback} />
    </Layout>
  );
};

const App = () => {
  initializeAmplitude();
  initializeGA();
  return (
    <ConnectedRouter history={history}>
      <CssBaseline />
      <Suspense fallback={<LoadingSpinner />}>
        <DndProvider
          backend={TouchBackend}
          options={{ enableMouseEvents: true }}
        >
          <Switch>
            <AppRoutes />
          </Switch>
        </DndProvider>
      </Suspense>
    </ConnectedRouter>
  );
};

export default App;
