/**
 * High order function - action creator helper
 * create action with data (payload)
 * @example
 * ```javascript
 * const SET_COUNTER = 'COUNTER/SET_VALUE';
 * const setCounter = createAction(SET_COUNTER);
 * store.dispatch(setCounter(10)); // store.dispatch({type: 'COUNTER/SET_VALUE', payload: 10 });
 * ```
 * @param type - redux action type (name)
 * @returns {function}
 */
export const createAction = <P>(type: string) => (payload: P) => ({
  type,
  payload,
});

/**
 * High order function - action creator helper
 * create action without data (payload)
 * @example
 * ```javascript
 * const INCREMENT = 'COUNTER/INCREMENT';
 * const increment = createEmptyAction(INCREMENT);
 * store.dispatch(increment()); // store.dispatch({type: 'COUNTER/INCREMENT', payload: {} });
 * ```
 * @param type - redux action type (name)
 * @returns {function}
 */
export const createEmptyAction = (type: string) => () => ({
  type,
  payload: {},
});
