import React from 'react';
import Navbar from './Navbar';
import Notification from './Notification';
import Confirmation from './Confirmation';
import Footer from '../components/Footer';
import LoadingSpinner from '../components/LoadingSpinner';
import Analytics from '../components/Analytics';

import { useSelector } from 'react-redux';
import { getApplication, IApplication } from '../reducers/app';

export interface ILayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
  const app: IApplication = useSelector(getApplication);
  return (
    <>
      <Analytics />
      <Navbar />
      {app.isLoading && <LoadingSpinner />}
      {children}
      <Footer />
      <Notification />
      <Confirmation />
    </>
  );
};

export default Layout;
