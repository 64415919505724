import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { Event } from '../utils/analytics';
import { getAuth, IAuthState } from '../reducers/auth';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { cookieConsentName, isCookieOptOutAnalytics } from '../utils/cookies';
import { googleAnalyticsId } from '../constants';
import { Helmet } from 'react-helmet';

const Analytics = () => {
  const location = useLocation();
  const { authorized, user }: IAuthState = useSelector(getAuth);
  const [cookies] = useCookies([cookieConsentName]);
  const [optOut, setOptOut] = useState(true);

  useEffect(() => {
    const optOutAnalytics =
      isCookieOptOutAnalytics(cookies[cookieConsentName]) ||
      (authorized && user.optOutAnalytics);
    amplitude.getInstance().setOptOut(optOutAnalytics);
    if (authorized) {
      amplitude.getInstance().setUserId(user._id);
    } else {
      amplitude.getInstance().setUserId(null);
      amplitude.getInstance().regenerateDeviceId();
    }
    setOptOut(optOutAnalytics);
  }, [authorized, user.optOutAnalytics, user._id, cookies]);

  useEffect(() => {
    // Amplitude analytics
    amplitude
      .getInstance()
      .logEvent(Event.VIEW_PAGE, { path: location.pathname });
    // Google analytics
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <Helmet>
      <script type="text/javascript">
        {`window['ga-disable-${googleAnalyticsId}'] = ${optOut};`}
      </script>
    </Helmet>
  );
};

export default Analytics;
