import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import cx from 'classnames';
import Text from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SettingsIcon from '@material-ui/icons/Settings';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Switch from '@material-ui/core/Switch';

import {
  changeLoop,
  changeVisibility,
  sort,
} from '../../actions/playbackSettings';
import { selectBeloved } from '../Messages/dispatcher';

import { getBelovedState, IBelovedState } from '../../reducers/beloved';
import { getFolders, IFolders } from '../../reducers/folders';

import PageContainer from '../../components/PageContainer';
import BelovedSelectbox from '../../components/BelovedSelectbox';
import FolderItem from './FolderItem';

import { IFolder } from '../../types';
import useStyles from './styles';
import { redirectTo } from '../../utils/helpers';
import { Routes } from '../../constants';

const PlaybackSettings = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { list: belovedList, selected }: IBelovedState = useSelector(
    getBelovedState,
  );
  const { list: foldersList }: IFolders = useSelector(getFolders);

  const {
    allMessagesHidden,
    allMessagesOrderNumber,
    favoritesHidden,
    favoritesOrderNumber,
    loopCount,
  } = selected || {
    allMessagesHidden: false,
    allMessagesOrderNumber: 0,
    favoritesHidden: false,
    favoritesOrderNumber: 0,
    loopCount: 0,
  };

  const constantFolders = [
    {
      _id: 'all',
      name: t('all-messages'),
      show: !allMessagesHidden,
      orderNumber: allMessagesOrderNumber,
    },
    {
      _id: 'favorites',
      name: t('favorites'),
      show: !favoritesHidden,
      orderNumber: favoritesOrderNumber,
    },
  ];

  const [folders, setFolders] = useState([...constantFolders, ...foldersList]);

  const toggleLoop = () => {
    dispatch(changeLoop(loopCount > 0 ? 0 : 1));
  };

  const changeTimesToPlay = (evt: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeLoop(Number(evt.target.value)));
  };

  const toggleVisibility = (f: Partial<IFolder>) => {
    dispatch(changeVisibility(f));
  };

  const saveOrdering = useCallback(() => {
    const newFolders = folders.map((f, i) => ({
      ...f,
      orderNumber: i,
    }));

    dispatch(sort(newFolders));
  }, [folders, dispatch]);

  const moveFolder = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragMsg = folders[dragIndex];
      setFolders(
        update(folders, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragMsg],
          ],
        }),
      );
    },
    [folders],
  );

  useEffect(() => {
    setFolders(
      [...constantFolders, ...foldersList].sort(
        (a, b) => a.orderNumber - b.orderNumber,
      ),
    );
    // eslint-disable-next-line
  }, [foldersList, selected]);

  const foldersElements = [
    ...folders,
  ].map((f: Partial<IFolder>, index: number) => (
    <FolderItem
      key={f._id}
      index={index}
      id={f._id}
      moveFolder={moveFolder}
      onDrop={saveOrdering}
      toggleVisibility={toggleVisibility}
      folder={f}
    />
  ));

  return (
    <PageContainer>
      <div className={classes.root}>
        <div className={classes.breadcrumbs}>
          <Button onClick={() => dispatch(redirectTo(Routes.Messages))}>
            <ChevronLeftIcon />
            {t('back-to-all-messages')}
          </Button>
        </div>
        <div className={classes.header}>
          <div className={classes.title}>
            <SettingsIcon />
            <Text component="h1" variant="h1">
              {t('playback-settings')}
            </Text>
          </div>
          <BelovedSelectbox
            onSelect={selectBeloved}
            selectedBeloved={selected}
            belovedList={belovedList}
          />
        </div>
        <Text variant="body2" className={classes.pageDescription}>
          {t('playback-settings-page-description')}
        </Text>
        <Text variant="body2" className={classes.pageDescription}>
          {t('playback-settings-loop-description')}
        </Text>
        <div className={cx(classes.container)}>
          <div className={cx(classes.loop)}>
            <Text className={classes.loopLabel} variant="body2">
              {t('loop')}
            </Text>
            <Switch
              className={classes.loopSwitch}
              color="primary"
              checked={loopCount > 0}
              onChange={toggleLoop}
            />
            {loopCount > 0 && (
              <TextField
                className={classes.countInput}
                color="primary"
                type="number"
                onChange={changeTimesToPlay}
                label={t('times-to-play')}
                value={loopCount}
              />
            )}
          </div>
          <div className={cx(classes.folders)}>{foldersElements}</div>
        </div>
      </div>
    </PageContainer>
  );
};

export default PlaybackSettings;
