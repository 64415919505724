import { call, put, takeLatest, select, take } from 'redux-saga/effects';
import { IAction } from '../utils/redux-create-reducer';

import {
  fetch,
  fetchSuccess,
  fetchFailure,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE,
  updateSuccess,
  updateFailure,
  ADD,
  addSuccess,
  addFailure,
  IUpdateParams,
} from '../actions/folders';
import { notify } from '../actions/notification';

import { getBelovedState, IBelovedState } from '../reducers/beloved';

import {
  changeFolderVisibility,
  getBelovedFolders,
  deleteFolder,
  renameFolder,
  addFolder,
} from '../utils/api';

import { callApi } from '../utils/helpers';
import amplitude from '../utils/analytics';

function* fetchWorker({ payload: belovedId }: IAction) {
  try {
    const { data: folders } = yield call(callApi, getBelovedFolders, belovedId);
    yield put(fetchSuccess(folders));
  } catch (error) {
    yield put(fetchFailure((error as Error).message));
  }
}

function* updateWorker({
  payload: { folder, type, sortedList: _sortedList },
}: IAction<IUpdateParams>): any {
  try {
    const { selected }: IBelovedState = yield select(getBelovedState);
    if (!selected) {
      return yield put(updateFailure('no beloved selected'));
    }
    switch (type) {
      case 'delete':
        {
          const { data: updatedFolder } = yield call(
            callApi,
            deleteFolder,
            folder._id,
          );
          amplitude.DELETE_PLAYLIST({
            belovedId: selected._id,
            playlistId: folder._id,
          });
          yield put(updateSuccess({ ...updatedFolder, isDeleted: true }));
          yield put(notify('delete-folder-success'));
        }
        break;
      case 'edit':
        {
          const { data: updatedFolder } = yield call(
            callApi,
            renameFolder,
            folder._id,
            folder.name,
          );
          amplitude.EDIT_NAME_PLAYLIST({
            belovedId: selected._id,
            playlistId: folder._id,
          });
          yield put(updateSuccess(updatedFolder));
          yield put(notify('rename-folder-success'));
        }
        break;
      case 'changeVisibility':
        {
          const { data: updatedFolder } = yield call(
            callApi,
            changeFolderVisibility,
            folder._id,
            !folder.show,
          );
          folder.show
            ? amplitude.MUTE_PLAYLIST({
                belovedId: selected._id,
                playlistId: folder._id,
              })
            : amplitude.UNMUTE_PLAYLIST({
                belovedId: selected._id,
                playlistId: folder._id,
              });
          yield put(updateSuccess(updatedFolder));
          yield put(
            notify(folder.show ? 'hide-folder-success' : 'show-folder-success'),
          );
        }
        break;
      default: {
        yield put(updateFailure('Wrong type provided'));
        yield put(notify('oops-something-went-wrong', 'error'));
        console.error('Wrong type provided');
      }
    }
  } catch (error) {
    yield put(notify('oops-something-went-wrong', 'error'));
    yield put(updateFailure((error as Error).message));
    console.error(error);
  }
}

function* addWorker({ payload: folderName }: IAction<string>): any {
  try {
    const { selected }: IBelovedState = yield select(getBelovedState);
    if (!selected) {
      return yield put(addFailure('no beloved selected'));
    }
    const { data: newFolder } = yield call(
      callApi,
      addFolder,
      folderName,
      selected._id,
    );
    amplitude.ADD_PLAYLIST({
      belovedId: selected._id,
      playlistId: newFolder._id,
    });
    yield put(addSuccess(newFolder));
    yield put(notify('add-folder-success'));
  } catch (error) {
    yield put(addFailure((error as Error).message));
    yield put(notify('oops-something-went-wrong', 'error'));
    console.error(error);
  }
}

export function* foldersFetcher(belovedId: string) {
  yield put(fetch(belovedId));

  const { type, payload } = yield take([FETCH_SUCCESS, FETCH_FAILURE]);

  if (type === FETCH_SUCCESS && payload) {
    return payload.length ? payload : [];
  }
  return [];
}

export default function* flodersSaga() {
  yield takeLatest(FETCH, fetchWorker);
  yield takeLatest(UPDATE, updateWorker);
  yield takeLatest(ADD, addWorker);
}
