import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      marginBottom: 85,
    },
    withoutSidebar: {
      justifyContent: 'center',
      '& $content': {
        maxWidth: 1550,
      },
    },
    sidebar: {
      minWidth: 200,
      [breakpoints.down('md')]: {
        display: 'none',
      },
    },
    content: {
      width: '100%',
      display: 'flex',
      [breakpoints.up('md')]: {
        padding: `0 40px`,
      },
      justifyContent: 'center',
      maxWidth: 1720,
    },
  }),
  { name: 'PageContainer' },
);

export default function PageContainer({
  children,
  sidebar,
  className,
}: {
  sidebar?: JSX.Element;
  children: React.ReactNode;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div
      className={cx(classes.container, className, {
        [classes.withoutSidebar]: !sidebar,
      })}
    >
      {sidebar && <div className={classes.sidebar}>{sidebar}</div>}
      <div className={classes.content}>{children}</div>
    </div>
  );
}
