import { call, put, takeLatest, debounce, select } from 'redux-saga/effects';

import {
  editPlaybackSettings,
  changeFolderVisibility,
  changeFolderOrder,
} from '../utils/api';

import { callApi } from '../utils/helpers';

import {
  SORT,
  sortSuccess,
  CHANGE_VISIBILITY,
  changeVisibilitySuccess,
  changeVisibilityFailure,
  CHANGE_LOOP,
  changeLoopSuccess,
  changeLoopFailure,
} from '../actions/playbackSettings';
import { updateSuccess as updateFolderSuccess } from '../actions/folders';
import { updateSuccess as updateBelovedSuccess } from '../actions/beloved';

import { getBelovedState, IBelovedState } from '../reducers/beloved';

import { notify } from '../actions/notification';
import { IAction } from '../utils/redux-create-reducer';
import { IBeloved, IFolder } from '../types';
import amplitude from '../utils/analytics';

function* changeLoopWorker({ payload: loopCount }: IAction<number>) {
  try {
    const { selected }: IBelovedState = yield select(getBelovedState);
    yield call(callApi, editPlaybackSettings, selected && selected._id, {
      loopCount,
    });
    amplitude.EDIT_LOOP_COUNT({
      belovedId: selected && selected._id,
      loopCount,
    });
    yield put(changeLoopSuccess(loopCount));
    yield put(notify('loop-count-success'));
  } catch (error) {
    yield put(notify('oops-something-went-wrong', 'error'));
    yield put(changeLoopFailure(error.message));
  }
}

function* changeVisibilityWorker({ payload: folder }: IAction<IFolder>) {
  try {
    const { selected }: IBelovedState = yield select(getBelovedState);
    if (['all', 'favorites'].includes(folder._id)) {
      const params = {
        [folder._id === 'all'
          ? 'allMessagesHidden'
          : 'favoritesHidden']: folder.show,
      };
      yield call(
        callApi,
        editPlaybackSettings,
        selected && selected._id,
        params,
      );
      folder.show
        ? amplitude.MUTE_PLAYLIST({
            belovedId: selected && selected._id,
            playlistId: folder._id,
          })
        : amplitude.UNMUTE_PLAYLIST({
            belovedId: selected && selected._id,
            playlistId: folder._id,
          });
      yield put(
        updateBelovedSuccess({ ...((selected || {}) as IBeloved), ...params }),
      );
    } else {
      yield call(callApi, changeFolderVisibility, folder._id, !folder.show);
      folder.show
        ? amplitude.MUTE_PLAYLIST({
            belovedId: selected && selected._id,
            playlistId: folder._id,
          })
        : amplitude.UNMUTE_PLAYLIST({
            belovedId: selected && selected._id,
            playlistId: folder._id,
          });
      yield put(updateFolderSuccess({ ...folder, show: !folder.show }));
    }
    yield put(changeVisibilitySuccess(folder));
    yield put(
      notify(
        folder.show
          ? 'make-folder-not-playable-success'
          : 'make-folder-playable-success',
      ),
    );
  } catch (error) {
    yield put(notify('oops-something-went-wrong', 'error'));
    yield put(changeVisibilityFailure(error));
  }
}

function* sortWorker({ payload: folders }: IAction<IFolder[]>) {
  try {
    const { selected }: IBelovedState = yield select(getBelovedState);
    const constantFolders = folders.filter((f) =>
      ['all', 'favorites'].includes(f._id),
    );
    const customFolders = folders.filter(
      (f) => !['all', 'favorites'].includes(f._id),
    );

    const params = constantFolders.reduce(
      (res, { _id, orderNumber }: IFolder) => ({
        ...res,
        [_id === 'all'
          ? 'allMessagesOrderNumber'
          : 'favoritesOrderNumber']: orderNumber,
      }),
      {},
    );
    yield call(callApi, editPlaybackSettings, selected && selected._id, params);
    yield call(callApi, changeFolderOrder, customFolders);
    const folderIds = folders.map((folder) => folder._id);
    amplitude.REORDER_PLAYLIST({
      belovedId: selected && selected._id,
      folderIds: folderIds,
    });
    yield put(
      updateBelovedSuccess({ ...((selected || {}) as IBeloved), ...params }),
    );

    yield put(sortSuccess(customFolders));
    yield put(notify('sort-success'));
  } catch (error) {
    yield put(notify('oops-something-went-wrong', 'error'));
  }
}

export default function* playbackSettingsSaga() {
  yield debounce(1000, CHANGE_LOOP, changeLoopWorker);
  yield takeLatest(CHANGE_VISIBILITY, changeVisibilityWorker);
  yield takeLatest(SORT, sortWorker);
}
