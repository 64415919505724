import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { close } from '../actions/notification';
import { getNotification, INotificationState } from '../reducers/notification';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ blueLight2, darkGreen }) => ({
  root: {
    '&& .MuiAlert-action': {
      alignItems: 'baseline',
    },
    '&& .MuiAlert-icon': {
      display: 'none',
    },
    '&& .MuiAlert-message': {
      paddingBottom: '1rem',
      paddingTop: '1rem',
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
  },
  info: {
    backgroundColor: blueLight2,
    color: darkGreen,
  },
}));

const Notification = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const classes = useStyles();

  const {
    isOpen,
    message,
    duration,
    type,
    position,
    messageVars,
  }: INotificationState = useSelector(getNotification);

  const handleClose = () => {
    dispatch(close());
  };

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={position}
      onClose={handleClose}
      {...(duration !== 0 ? { autoHideDuration: duration } : {})}
    >
      <Alert
        elevation={6}
        variant="filled"
        className={cx(classes.root, { [classes.info]: type === 'info' })}
        onClose={handleClose}
        severity={type}
      >
        {t(message, messageVars)}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
