import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CssBaseline } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import Typography from '@material-ui/core/Typography';
import Video from '../../components/Video';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.mainBlue,
  },
}));

const FAQ = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <BusinessIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Frequently Asked Questions
        </Typography>
        <Typography variant="body1">
          <br />
          <h3>Can you walk me through how Sound of Your Love works?</h3>
          <p>
            Watch the demo video below to see how to set up an account, record
            messages, and send info to the care worker who can play the messages
            for your loved one.
          </p>
          <Video videoId="WZle1xmqx6M" />
          <h3>How do I save messages?</h3>
          <p>
            Messages can be saved by calling the number on the Setup page and
            using the passcode assigned to the loved one for whom you’d like to
            record a message. While on the call, the prompts will guide you in
            the process of recording the message. After you’ve finished the
            message, it will automatically be saved on the website.
          </p>
          <h3>How do I delete my account?</h3>
          <p>
            If you want to delete your account with us, you can contact us, and
            we can remove your information from our system.
          </p>
          <h3>Is this free?</h3>
          <p>
            This service is free for early adopters while we’re in the beta
            stage.
          </p>
          <h3>What do I do if someone leaves an unwanted message?</h3>
          <p>
            It is possible to delete messages on the website after logging in
            and navigating to the Messages page.
          </p>
          <h3>Can I reset the access code?</h3>
          <p>
            Yes, you can reset the access code by first signing in and
            navigating to the Setup page. Then, you can click on the 3 vertical
            dots on the row for the loved one who’s access code you want to
            change. After this, simply click on Reset Recording Code or Reset
            Playback Code then click Reset in the box that pops up.
          </p>
          <h3>How long can the messages be?</h3>
          <p>
            The messages can be up to 5 minutes long, after which time the
            system will automatically stop the recording and save what was
            captured up to that point.
          </p>
          <h3>How do I re-arrange the messages?</h3>
          <p>
            Messages can be rearranged by clicking and dragging the message to
            the desired position on the Messages page.
          </p>
          <h3>How do I know when my loved one has heard the messages?</h3>
          <p>
            Currently, there is no way to see this in the web/mobile interface.
            In a later version, we will add an indicator to denote messages that
            have been played on the Messages page.
          </p>
          <h3>How is the data managed?</h3>
          <p>All of our data is managed on secure cloud servers.</p>
          <h3>Who made this product?</h3>
          <p>
            This was made by a team of people who came together for the
            FaithTech Covid hackathon, and has since been taken from concept to
            product after adding a couple more team members.
          </p>
          <h3>In what countries can SOYL be used?</h3>
          <p>
            When you set up an account, the call-in number options that are
            generated are in Canada, the U.S. and the U.K. However, anyone from
            any country can call any of these numbers to leave a message. For
            example, if your loved one is in a hospice in Canada and you are in
            the U.S., you can call the U.S. number to leave a message, and the
            health worker in Canada can call the Canadian number to play back
            the messages. If your loved one is in the U.S. but they have friends
            who want to call from Australia, Kenya, or France, they can call the
            U.S. call-in number (regular long-distance charges would apply,
            depending on their phone plan), and the health worker in the U.S.
            would call the U.S. number to retrieve the messages.
          </p>
        </Typography>
      </div>
    </Container>
  );
};

export default FAQ;
