import React, { useRef } from 'react';
import cx from 'classnames';
import Text from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FolderIcon from '@material-ui/icons/Folder';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import {
  NotaListIcon,
  // EyeIcon,
  // EyeCloseIcon,
  MoveIcon,
} from '../../components/icons';

import { IFolder } from '../../types';
import useStyles from './styles';

interface IFolderItem {
  folder: Partial<IFolder>;
  toggleVisibility: (f: Partial<IFolder>) => void;
  onDrop: (item: DragItem, monitor: DropTargetMonitor) => void;
  id?: string;
  index: number;
  moveFolder: (dragIndex: number, hoverIndex: number) => void;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}

const FolderItem = ({
  folder,
  toggleVisibility,
  onDrop,
  id,
  index,
  moveFolder,
}: IFolderItem) => {
  const classes = useStyles();

  const ShowHideIcon = folder.show ? VolumeUpIcon : VolumeOffIcon;

  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'row',
    drop: onDrop,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      const isMobile = window.innerWidth <= 600;

      const coordLess = !isMobile
        ? hoverClientX < hoverMiddleX
        : hoverClientY < hoverMiddleY;

      const coordGreat = !isMobile
        ? hoverClientX > hoverMiddleX
        : hoverClientY > hoverMiddleY;

      // Dragging downwards
      if (dragIndex < hoverIndex && coordLess) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && coordGreat) {
        return;
      }

      // Time to actually perform the action
      moveFolder(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'row', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(preview(ref));
  return (
    <div
      ref={ref}
      key={folder._id}
      className={cx(classes.folder, {
        [classes.hidden]: !folder.show,
        [classes.isDragging]: isDragging,
      })}
    >
      <ShowHideIcon
        onClick={() => toggleVisibility(folder)}
        className={classes.showHide}
      />
      <div ref={drag} className={classes.drag}>
        <MoveIcon />
      </div>
      <div className={cx(classes.ceil)}>
        {folder._id === 'all' ? (
          <NotaListIcon />
        ) : folder._id === 'favorites' ? (
          <FavoriteIcon />
        ) : (
          <FolderIcon />
        )}
        <Text variant="body2">{folder.name}</Text>
      </div>
      <Text className={classes.order}>
        {(folder.orderNumber === undefined ? index : folder.orderNumber) + 1}
      </Text>
    </div>
  );
};

export default FolderItem;
