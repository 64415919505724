import React from 'react';
import { REACT_APP_API_BASE_URL } from '../env';
import { Trans } from 'react-i18next';
import {
  put,
  take,
  call,
  fork,
  select,
  delay,
  cancel,
} from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router';
import get from 'lodash/get';
import { getToken } from './localStorage';

import { AUTHORIZE } from '../actions/auth';
import { startLoading, stopLoading, setAppError } from '../actions/app';
import { notify } from '../actions/notification';

import { getAuth, IAuthState } from '../reducers/auth';
import { getApplication } from '../reducers/app';

import { Routes, protectedRoutes } from '../constants';
import { IMessage, IFolder } from '../types';
import store from '../store';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

/**
 * Wait some time and start loading spinner. Used to avoid blinking of spinner;
 * @param time - delay time
 * @param immediately - skip delay
 */
export function* startDelayedLoading(time: number, immediately?: boolean) {
  if (!immediately) {
    yield delay(time);
  }
  yield put(startLoading());
}

/**
 * Wait some time and stop loading spinner. Used to avoid blinking of spinner;
 * @param time - delay time
 */
function* stopDelayedLoading(time: number) {
  yield delay(time);
  yield put(stopLoading());
}

/**
 * Saga effect - Wrapper on API calls to start loading (spinner) and stop after server response. Also it set error data in app recuder
 * @param fn - some function
 * @param args  - some any function arguments
 */
export function* callApi(fn: any, ...args: any[]): any {
  try {
    const loaderTask = yield fork(startDelayedLoading, 600);
    const result = yield call(fn, ...args);
    if (loaderTask.isRunning()) {
      yield cancel(loaderTask);
    } else {
      yield fork(stopDelayedLoading, 800);
    }
    return result;
  } catch (err) {
    yield put(stopLoading());
    yield put(setAppError(err));
    throw err;
  }
}

export const getMessageFile = (recordingId: string) =>
  `${
    REACT_APP_API_BASE_URL || 'http://localhost:5000'
  }/api/recordings/${recordingId}`;

export interface IURLOptions {
  search?: Record<string, string | number>;
  params?: Record<string, string | number>;
}

/**
 * Build URL from objects
 * @example
 * ```js
 * buildURL('/url/:p1/:p2', {search:{q1: 1, q2: 2}, params:{p1: 'param1', p2: 'param2'}}); /url/param1/param2?q1=1&q2=2
 * ```
 * @param url - Route
 * @param options - query or params of url
 * @returns {string} /url/param1/param2?q1=1&q2=2
 */
export const buildURL = (url: string, options?: IURLOptions) => {
  let search = '';
  let URL = url;
  if (options && options.search) {
    search = `?${Object.keys(options.search)
      .map(
        (key) => `${key}=${options && options.search && options.search[key]}`,
      )
      .join('&')}`;
  }
  if (options && options.params) {
    URL = Object.keys(options.params).reduce(
      (u, key) =>
        (u || '').replace(
          `:${key}`,
          (options && options.params && String(options.params[key])) || '',
        ),
      url,
    );
  }

  return URL + search;
};

/**
 * ActionCreator - create action object to change URL
 * @param url - route
 * @param options - route options (hash, query, params)
 */
export const redirectTo = (url: string, options?: IURLOptions) =>
  push(buildURL(url, options), {
    from: {
      pathname: window.location.pathname,
      search: window.location.search,
    },
  });

/**
 * Redux based redirectTo function
 * @param url - route
 * @param options - route options (hash, query, params)
 */
export const goTo = (url: string, options?: IURLOptions) => () => {
  store.dispatch(redirectTo(url, options));
};

/**
 * Check url for protectedRoutes
 * @param pathname - url or list urls which needed to check.
 */
export const checkProtectedRoute = (pathname: string | readonly string[]) => {
  const urls = pathname instanceof Array ? pathname : [pathname];
  return protectedRoutes.some((protectedRoute: Routes) =>
    urls.includes(protectedRoute),
  );
};

/**
 * Bloking effect which will wait for location change and check with provided sample (Route)
 * @param name - Route name
 * @param isRoot - when root and come route the same
 */
export function* waitForRouteAndDetect(name: string, isRoot?: boolean): any {
  const locAction = yield take(LOCATION_CHANGE);

  const path = get(locAction, 'payload.location.pathname');
  return yield path.toLowerCase() === name.toLowerCase() ||
    (isRoot && path === Routes.Home);
}

/**
 * this function helps to detect proper url by route condition and provide url params
 * @param path - window.location path
 * @param route - expected Route from Routes constants
 */
export const getRouteData = (path: string, route: Routes, isRoot?: boolean) => {
  const match = matchPath(path, route);

  const { params = {} } = match || {};
  const routeName = buildURL(route, { params });
  const isProperPage =
    path.toLowerCase() === routeName.toLowerCase() ||
    (isRoot && path === Routes.Home);

  return {
    match,
    isProperPage,
  };
};

/*
this helper control a sequence of data loading, you should use it as wrapper for {PAGE} sagas
to ensure that all data is ready you can use application state - isLoading,
because this helper controls START and END of loading data
*/
export function* appLoadingPlaceholder(
  routes: Routes | Routes[],
  childrenSaga: (match: any) => void,
  isRoot?: boolean,
): any {
  const locAction = yield take(LOCATION_CHANGE);

  const path = get(locAction, 'payload.location.pathname');

  const routesList = routes instanceof Array ? routes : [routes];

  const isProtectedRoute = checkProtectedRoute(routesList);

  const { isProperPage, match } = routesList
    .map((r) => getRouteData(path, r, isRoot))
    .find((r) => r.isProperPage) || { isProperPage: false, match: undefined };

  if (isProperPage) {
    const app = yield select(getApplication);
    const token = yield call(getToken);
    const loaderTask = yield fork(startDelayedLoading, 600, app.isFirstLoading);
    const { authorized, user }: IAuthState = yield select(getAuth);
    if (!authorized && !app.error) {
      if (isProtectedRoute || token) {
        yield take(AUTHORIZE);
      }
    }
    try {
      if (authorized && !user.verified && isProtectedRoute) {
        return yield put(redirectTo(Routes.Verify));
      }
      yield call(childrenSaga, match);
    } catch (err) {
      console.error('appLoadingPlaceholder', err);
    } finally {
      yield cancel(loaderTask);
      yield put(stopLoading());
    }
  }
}

/**
 * Parse url to extract query params and set as object
 * @example
 * ```javascript
 * parseQueryParams('/url?q1=1&q2=2'); // {q1: 1, q2: 2}
 * ```
 * @param url - route string
 * @returns //{q1: 1, q2: 2}
 */
export const parseQueryParams = (url: string): Record<string, string> => {
  const [, qParams] = url.split('?');
  const params = (qParams || '').split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=');
    result[key] = value;
    return result;
  }, {});

  return params;
};

/**
 * Returns messages filtered by folder
 * @param messages - list of messages
 * @param selectedFolder - folder name of ir
 */
export const filterMessagesByFolder = (
  messages: IMessage[],
  selectedFolder: string,
) => {
  if (selectedFolder === 'favorites') {
    return messages.filter((msg) => msg.favorite);
  }
  if (selectedFolder.length > 5) {
    return messages.filter((msg) =>
      msg.folderListOrder.some((f) => f._id === selectedFolder),
    );
  }
  return messages;
};

/**
 * Sort messages by order number (listOrder) - part of DragNDrop functionality of sorting messages (All folder)
 * @param messages - list of messages
 */
const sortByListOrder = (messages: IMessage[]) =>
  [...messages].sort((a, b) => a.listOrder - b.listOrder);

/**
 *  Sort messages by order number (favoriteListOrder) - part of DragNDrop functionality of sorting messages (favorites folder)
 * @param messages - list of messages
 */
const sortByFavoritesOrder = (messages: IMessage[]) =>
  [...messages].sort((a, b) => a.favoriteListOrder - b.favoriteListOrder);

/**
 * Sort messages by order number in some folder (orderNumber) - part of DragNDrop functionality of sorting messages ({id} folder)
 * @param messages - list of messages
 * @param folder - folder id
 */
const sortByFolder = (messages: IMessage[], folder: string) =>
  [...messages].sort((a, b) => {
    const aFolder = a.folderListOrder.find((f) => f._id === folder);
    const bFolder = b.folderListOrder.find((f) => f._id === folder);
    return (
      ((aFolder && aFolder.orderNumber) || 0) -
      ((bFolder && bFolder.orderNumber) || 0)
    );
  });

/**
 * Sort messages by folder - part of DragNDrop functionality of sorting messages
 * @param messages - list of messages
 * @param selectedFolder - folder name or id
 */
export const sortMessages = (messages: IMessage[], selectedFolder: string) => {
  if (selectedFolder === 'all') {
    return sortByListOrder(messages);
  }

  if (selectedFolder === 'favorites') {
    return sortByFavoritesOrder(messages);
  }

  return sortByFolder(messages, selectedFolder);
};

/**
 * Build page title by selected folder name. Return translation key of folder or custom folder name.
 * @param selectedFolderId - folder name or id
 * @param folders - list of folders
 * @returns folder name
 */
export const makeMessagesPageTitle = (
  selectedFolderId: string,
  folders: IFolder[],
) => {
  const title = 'all-messages';
  if (selectedFolderId !== 'favorites' && selectedFolderId !== 'all') {
    const selectedFolder = folders.find((f) => f._id === selectedFolderId);
    return (selectedFolder && selectedFolder.name) || title;
  }
  if (selectedFolderId === 'favorites') {
    return 'favorites';
  }
  return title;
};

/**
 * Convert seconds to time format - MIN:SEC
 * @example
 * convertSecondsToTime(65); // 1:05;
 * convertSecondsToTime(315); // 5:15;
 * @param sec - seconds
 */
export const convertSecondsToTime = (sec: number): string => {
  const minutes = parseInt(String(sec / 60));
  const seconds = sec % 60;

  return `${minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
};

/**
 * Copy to clipboard and show notification
 * @param phone - phone number string
 */
export const copyToClipBoard = (phone: string) => {
  try {
    navigator.clipboard.writeText(phone).then(
      function () {
        store.dispatch(notify('copy-to-clipboard-success'));
      },
      function (err) {
        console.error(err);
        store.dispatch(notify('copy-to-clipboard-error', 'error'));
      },
    );
  } catch (err) {
    console.error(err);
    store.dispatch(notify('copy-to-clipboard-error', 'error'));
  }
};
interface ITransParams {
  tag: string;
  value?: any;
  props?: Record<string, any>;
}

/**
 * Helper allows to make formated transition
 * @example
 * // transKey - "hw": "Hello <0/> <1>World</1> <2>Click here</2>"
 * ```javascript
 * formatedTranslation('hw', [{ tag: 'br'}, {tag: 'strong'}, {tag: 'a', props:{href: 'http://www.google.com'}}]);
 * // result - Hello <br/> <strong>world</strong> <a href="http://www.google.com">Clic here</a>
 * ```
 * @param transKey - translation key
 * @param transParams - translation markup params - tagName, tag atributes, tag children
 */
export const formatedTranslation = (
  transKey: string,
  transParams: ITransParams[],
) => (
  <Trans i18nKey={transKey}>
    {transParams.map(({ tag, value = '', props = {} }) =>
      React.createElement(tag, props, value),
    )}
  </Trans>
);

export const useMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

// cleanTextForFilename('hello world'); // hello_world
export const cleanTextForFilename = (text: string) => {
  return text.replace(/ /g, '_');
};

export const formatDateForFilename = (date?: string) => {
  const dt = date ? new Date(Date.parse(date)) : new Date();
  return `${dt.getFullYear()}-${
    dt.getMonth() + 1
  }-${dt.getDate()}_${dt.getHours()}${dt.getMinutes()}`;
};

/**
 * Convert seconds to time format - HOUR:MIN:SEC
 * @example
 * formatTimeForRecording(45); // 00:00:45
 * formatTimeForRecording(203); // 00:03:23
 * @param sec - seconds
 */
export const formatTimeForRecording = (duration: number): string => {
  const date = new Date(0);
  date.setSeconds(duration);
  return date.toISOString().substr(11, 8);
};
