export enum Routes {
  Home = '/',
  Setup = '/setup',
  Messages = '/messages',
  PlaybackSettings = '/playback-settings',
  LearnMore = '/learnmore',
  Verify = '/verify',
  TermsOfService = '/tos',
  FAQ = '/faq',
  PrivacyPolicy = '/privacy-policy',
  Login = '/login',
  SignUp = '/signup',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Settings = '/settings',
  Healthcare = '/info/healthcare',
  Hospice = '/info/hospice',
  Chaplain = '/info/chaplain',
  FaithLeaders = '/info/faith-leaders',
  Friends = '/info/friends',
  CookiePolicy = '/cookie-policy',
  Donate = '/donate',
  Record = '/record',
  Playback = '/playback',
}

export const rowsPerPage = 8;

export const protectedRoutes = [
  Routes.Messages,
  Routes.Setup,
  Routes.PlaybackSettings,
  Routes.Settings,
];

export const phoneList = [
  {
    title: 'United States of America',
    number: '+1 415 906 0420',
  },
  {
    title: 'Canada',
    number: '+1 647 371 1404',
  },
  {
    title: 'United Kingdom',
    number: '+44 1204 238827',
  },
];

export const mainEmailContact = 'support@soundofyourlove.com';
export const socialLinks = {
  facebook: 'https://www.facebook.com/soundofyourlove',
  instagram: 'https://www.instagram.com/soundofyourlove',
  twitter: 'https://www.twitter.com/soundofyourlove',
  youtube: 'https://www.youtube.com/channel/UCZ-DSGL-7e2QbA8NkZBJ8jQ',
};

export const downloadableResources = [
  {
    id: 'flyer',
    image: require('./assets/soyl_flyer.png').default,
    pdf: '/docs/soyl_flyer.pdf',
  },
  {
    id: 'healthcare',
    image: require('./assets/soyl_healthcare.png').default,
    pdf: '/docs/soyl_healthcare.pdf',
  },
  {
    id: 'family',
    image: require('./assets/soyl_family.png').default,
    pdf: '/docs/soyl_family.pdf',
  },
  {
    id: 'prompts',
    image: require('./assets/soyl_prompts.png').default,
    pdf: '/docs/soyl_prompts.pdf',
  },
];

export const socialMediaResources = [
  {
    id: 'soundofyourlove_1',
    image: require('./assets/soyl_holiday_social_1.jpg').default,
  },
  {
    id: 'soundofyourlove_2',
    image: require('./assets/soyl_holiday_social_3.jpg').default,
  },
  {
    id: 'soundofyourlove_3',
    image: require('./assets/soyl_social_1.jpg').default,
  },
  {
    id: 'soundofyourlove_4',
    image: require('./assets/soyl_social_2.jpg').default,
  },
  {
    id: 'soundofyourlove_5',
    image: require('./assets/soyl_social_3.jpg').default,
  },
  {
    id: 'soundofyourlove_6',
    image: require('./assets/soyl_social_4.jpg').default,
  },
];

export const testimonials = [
  {
    text:
      'A very dear friend of mine was admitted to hospital and we were told she did not have long to live. This was during the thick of COVID and neither her husband nor her 3 children could go see her or say goodbye. I set up a Sound of Your Love account and recorded a message saying goodbye. It was heart-wrenching but such a gift...',
    author: 'Erin B',
  },
  {
    text:
      "...Her husband was also able to record his and their children's messages and a nurse held it up to her ear. To everyone's amazement, she actually improved! She woke up from unconsciousness and is actually now home! She is still very sick and palliative but I know those messages made a huge impact.",
    author: 'Erin B',
  },
  {
    text: 'There were so many messages! How did they know how to find me?',
    author: 'Reino I., COVID survivor & message recipient',
  },
  {
    text:
      'Hearing the language of your ancestry makes you feel especially connected. I leave my messages for Reino in Finnish because I know he enjoys that unique connection to the language.',
    author: 'Anita R.',
  },
  {
    text:
      "Sound of Your Love is useful for me as a nurse because it is time efficient and not complicated to use. When I go into a patient's room to provide care, I can use Sound of Your Love at the same time.",
    author: 'Brooke L., nurse',
  },
  {
    text:
      'Using the service is straightforward and easy. As I sent messages to my friend, I felt these calls to be genuine encounters with him. I usually contacted him during my own morning or evening prayer time... I hope this service can be available to all of our aging parishioners.',
    author: 'Mia, pastor',
  },
];

export const googleAnalyticsId = 'UA-166021908-1';
export const googleAnalyticsDuration = 7; // In Days

export const localStorageKey = '__soundofyourlove_token__';

export const sessionStorageKey = '__soyl_inv';

export const maxRecordingDuration = 300; // In seconds
