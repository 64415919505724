import React from 'react';

interface IProps {
  className?: string;
}

const Lock = (props: IProps) => (
  <svg
    {...props}
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.5 8H19.8333C20.1428 8 20.4395 8.12041 20.6583 8.33474C20.8771 8.54906 21 8.83975 21 9.14286V22.8571C21 23.1602 20.8771 23.4509 20.6583 23.6653C20.4395 23.8796 20.1428 24 19.8333 24H1.16667C0.857247 24 0.560501 23.8796 0.341709 23.6653C0.122916 23.4509 0 23.1602 0 22.8571V9.14286C0 8.83975 0.122916 8.54906 0.341709 8.33474C0.560501 8.12041 0.857247 8 1.16667 8H3.5V6.85714C3.5 5.03852 4.2375 3.29437 5.55025 2.00841C6.86301 0.722447 8.64348 0 10.5 0C12.3565 0 14.137 0.722447 15.4497 2.00841C16.7625 3.29437 17.5 5.03852 17.5 6.85714V8ZM15.1667 8V6.85714C15.1667 5.64472 14.675 4.48196 13.7998 3.62465C12.9247 2.76735 11.7377 2.28571 10.5 2.28571C9.26232 2.28571 8.07534 2.76735 7.20017 3.62465C6.325 4.48196 5.83333 5.64472 5.83333 6.85714V8H15.1667ZM9.33333 14.8571V17.1429H11.6667V14.8571H9.33333ZM4.66667 14.8571V17.1429H7V14.8571H4.66667ZM14 14.8571V17.1429H16.3333V14.8571H14Z" />
  </svg>
);

export default Lock;
