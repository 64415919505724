import { IBeloved, BelovedActions, InviteActions } from '../types';
import { createAction, createEmptyAction } from '../utils/actions';

export const FETCH = 'BELOVED/FETCH';
export const FETCH_SUCCESS = 'BELOVED/FETCH_SUCCESS';
export const FETCH_FAILURE = 'BELOVED/FETCH_FAILURE';

export const ADD = 'BELOVED/ADD';
export const ADD_SUCCESS = 'BELOVED/ADD_SUCCESS';
export const ADD_FAILURE = 'BELOVED/ADD_FAILURE';

export const UPDATE = 'BELOVED/UPDATE';
export const UPDATE_SUCCESS = 'BELOVED/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'BELOVED/UPDATE_FAILURE';

export const SAVE_RECORDING_SUCCESS = 'BELOVED/SAVE_RECORDING_SUCCESS';
export const CONFIRM_RECORDING = 'BELOVED/CONFIRM_RECORDING';
export const SKIP_RECORDING = 'BELOVED/SKIP_RECORDING';

export const SEND_INVITATION = 'BELOVED/SEND_INVITATION';
export const SEND_INVITATION_SUCCESS = 'BELOVED/SEND_INVITATION_SUCCESS';
export const SKIP_INVITATION = 'BELOVED/SKIP_INVITATION';

export const SELECT = 'BELOVED/SELECT';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,

  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,

  SAVE_RECORDING_SUCCESS,
  CONFIRM_RECORDING,
  SKIP_RECORDING,

  SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SKIP_INVITATION,

  SELECT,
};

export interface IUpdateParams {
  type: BelovedActions;
  beloved: IBeloved;
}
export interface ISendParams {
  type: 'record' | 'playback';
  beloved: IBeloved;
  emails: string[];
}
export interface IInviteParams {
  type: InviteActions.record | InviteActions.playback;
}

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction<IBeloved[]>(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const update = createAction<IUpdateParams>(UPDATE);
export const updateSuccess = createAction<IBeloved>(UPDATE_SUCCESS);
export const updateFailure = createAction<string>(UPDATE_FAILURE);

export const add = createAction<string>(ADD);
export const addSuccess = createAction<IBeloved>(ADD_SUCCESS);
export const addFailure = createAction<string>(ADD_FAILURE);

export const saveRecordingSuccess = createEmptyAction(SAVE_RECORDING_SUCCESS);
export const confirmRecording = createEmptyAction(CONFIRM_RECORDING);
export const skipRecording = createEmptyAction(SKIP_RECORDING);

export const sendInvitation = createAction<ISendParams>(SEND_INVITATION);
export const sendInvitationSuccess = createAction<IInviteParams>(
  SEND_INVITATION_SUCCESS,
);
export const skipInvitation = createAction<IInviteParams>(SKIP_INVITATION);

export const select = createAction<IBeloved>(SELECT);

export default {
  types,
  fetch,
  fetchSuccess,
  fetchFailure,

  update,
  updateSuccess,
  updateFailure,

  add,
  addSuccess,
  addFailure,

  saveRecordingSuccess,
  confirmRecording,
  skipRecording,

  sendInvitation,
  sendInvitationSuccess,
  skipInvitation,

  select,
};
