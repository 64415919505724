import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getLocation } from 'connected-react-router';
import get from 'lodash/get';
import { Routes } from '../constants';

import { parseQueryParams, appLoadingPlaceholder } from '../utils/helpers';
import { getBelovedByInvite } from '../utils/api';
import { callApi } from '../utils/helpers';
import { setInvitation, getInvitation } from '../utils/sessionStorage';
import { IAction } from '../utils/redux-create-reducer';
import {
  getRecordInviteState,
  getPlaybackInviteState,
} from '../reducers/invite';

import {
  requestInvitePasscode,
  verifyInvite,
  verifyInviteSuccess,
  verifyInviteFailure,
  VERIFY_INVITE,
  IVerifyAction,
} from '../actions/invite';
import { notify } from '../actions/notification';
import { fetchMessages } from '../actions/playback';
import { InviteActions } from '../types.d';

import amplitude from '../utils/analytics';

function* verifyInvitationWorker({
  payload: { type, invitationCode, passcode },
}: IAction<IVerifyAction>) {
  try {
    const { data: beloved } = yield call(
      callApi,
      getBelovedByInvite,
      invitationCode,
      passcode,
      type,
    );
    yield call(setInvitation, type, invitationCode, passcode);
    if (type === InviteActions.record) {
      amplitude.VERIFY_RECORDING_INVITE({ belovedId: beloved._id });
    } else {
      amplitude.VERIFY_PLAYBACK_INVITE({ belovedId: beloved._id });
    }
    yield put(verifyInviteSuccess({ type, beloved }));
    yield put(notify('verify-invite-success'));
    if (type === InviteActions.playback) {
      yield put(fetchMessages({ invitationCode, playbackPasscode: passcode }));
    }
  } catch (err) {
    yield call(setInvitation, type, 0, 0);
    if (type === InviteActions.record) {
      amplitude.VERIFY_RECORDING_INVITE_ERROR();
    } else {
      amplitude.VERIFY_PLAYBACK_INVITE_ERROR();
    }
    yield put(verifyInviteFailure({ type, error: (err as Error).message }));
    yield put(notify('verify-invite-failure', 'error'));
  }
}

function* invitePageWorker(): any {
  const location = yield select(getLocation);
  const route = get(location, 'pathname', '');
  const type =
    route === Routes.Record ? InviteActions.record : InviteActions.playback;
  const { beloved } =
    type === InviteActions.record
      ? yield select(getRecordInviteState)
      : yield select(getPlaybackInviteState);
  if (beloved) {
    return;
  }
  const { b } = yield call(parseQueryParams, window.location.href);
  const invitationCode = parseInt(b) || '';
  if (invitationCode) {
    // Check storage
    const { invitationCode: invite, passcode } = getInvitation(type);
    if (invitationCode === invite && passcode) {
      // Verify values from storage
      yield put(verifyInvite({ type, invitationCode, passcode }));
    } else {
      yield put(requestInvitePasscode({ type, invitationCode }));
    }
  } else {
    // An error about missing invitation
    yield put(verifyInviteFailure({ type, error: 'Missing invitation code' }));
    yield put(notify('verify-invite-failure', 'error'));
  }
}

export default function* invitePageSaga() {
  yield takeLatest(VERIFY_INVITE, verifyInvitationWorker);
  while (true) {
    yield call(
      appLoadingPlaceholder,
      [Routes.Record, Routes.Playback],
      invitePageWorker,
    );
  }
}
