import createReducer from '../utils/redux-create-reducer';
import {
  OPEN_RECORDING,
  CLOSE_RECORDING,
  SAVE_RECORDING,
  SAVE_RECORDING_SUCCESS,
  SAVE_RECORDING_FAILURE,
} from '../actions/record';

export interface IRecordState {
  isSavingMessage: boolean;
  isOpen: boolean;
  keyCount: number;
  error?: Error;
}

interface IState {
  record: IRecordState;
}

export const initialState: IRecordState = {
  isSavingMessage: false,
  isOpen: false,
  keyCount: 0,
  error: undefined,
};

export const getRecordState = (state: IState): IRecordState => state.record;

export default createReducer(initialState, {
  [OPEN_RECORDING]: () => ({ isOpen: true }),
  [CLOSE_RECORDING]: ({}, { keyCount }) => ({
    isOpen: false,
    keyCount: keyCount + 1,
  }),
  [SAVE_RECORDING]: () => ({ isSavingMessage: true, message: null }),
  [SAVE_RECORDING_SUCCESS]: ({}, { keyCount }) => ({
    isSavingMessage: false,
    isOpen: false,
    keyCount: keyCount + 1,
  }),
  [SAVE_RECORDING_FAILURE]: (error) => ({ error, isSavingMessage: false }),
});
