import React from 'react';
import {
  Grid,
  Box,
  Typography as Text,
  Button,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(
  () => ({
    actions: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        width: '70%',
      },
    },
    disclaimer: {
      fontSize: 16,
    },
  }),
  { name: 'BelovedOnboard' },
);

interface IBelovedOnboardProps {
  title: string;
  message: string;
  disclaimer: string;
  presentButton: string;
  dismissButton: string;
  onPresent: () => void;
  onDismiss: () => void;
}

export default function BelovedOnboard({
  title,
  message,
  disclaimer,
  presentButton,
  dismissButton,
  onPresent,
  onDismiss,
}: IBelovedOnboardProps) {
  const classes = useStyles();
  return (
    <Grid xs={12} item>
      <Box px={1}>
        <Text style={{ fontWeight: 600, paddingBottom: 5 }} variant="body1">
          {title}
        </Text>
        <Text variant="body2">{message}</Text>
      </Box>
      <Box py={3} className={classes.actions}>
        <Button color="primary" variant="contained" onClick={onPresent}>
          {presentButton}
        </Button>
        <Button size="small" color="default" onClick={onDismiss}>
          {dismissButton}
        </Button>
      </Box>
      <Box px={1} display="flex" justifyContent="center">
        <Text component="i" className={classes.disclaimer}>
          {disclaimer}
        </Text>
      </Box>
    </Grid>
  );
}
