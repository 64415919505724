import React, { useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface IPasswordInputProps {
  className?: string;
  label: string;
  name: string;
  error?: string;
  helperText?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordInput = ({
  className,
  name,
  label,
  helperText = '',
  error = '',
  onChange,
  value,
}: IPasswordInputProps) => {
  const [show, setVisibility] = useState(false);

  const showHide = () => {
    setVisibility(!show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl className={className}>
      <InputLabel error={error.length > 0} htmlFor={`${name}-id`}>
        {label}
      </InputLabel>
      <Input
        id={`${name}-id`}
        error={error.length > 0}
        type={show ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle repeat password visibility"
              onClick={showHide}
              onMouseDown={handleMouseDownPassword}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText id={`${name}-hint`}>{error || helperText}</FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;
