import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Text from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import TagsInput from '../TagsInput';
import { IBeloved } from '../../types';
import BelovedSelectbox from '../BelovedSelectbox';
import CopyIcon from '../../components/icons/Copy';

import { makeStyles } from '@material-ui/core/styles';
import { copyToClipBoard } from '../../utils/helpers';
import { phoneList } from '../../constants';
import { getAuth, IAuthState } from '../../reducers/auth';

const useStyles = makeStyles(
  ({ mainBlue, blueLight, darkGreen, spacing, white, breakpoints }) => ({
    modal: {
      maxWidth: 1440,
      width: '100%',
      minHeight: 600,
    },
    copyButton: {
      backgroundColor: blueLight,
      color: darkGreen,
      padding: '5px 20px',
      fontWeight: 600,

      '&:hover': {
        color: white,
        backgroundColor: mainBlue,
      },
      '& svg': {
        fill: mainBlue,
      },
    },
    sendButton: {
      padding: '5px 20px',
      width: 250,
      color: white,
    },
    closeButton: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      color: darkGreen,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '40px 20px',
      [breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        '& button': {
          width: '100%',
          margin: 10,
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputs: {
      justifyContent: 'space-between',
      width: '100%',
      [breakpoints.up(800)]: {
        display: 'flex',
      },
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      [breakpoints.down(800)]: {
        width: '100%',
      },
    },
    rightSide: {
      width: '50%',
      '&& .react-tagsinput': {
        width: '80%',
        marginTop: 8,
        [breakpoints.down(800)]: {
          width: '100%',
        },
      },

      [breakpoints.down(800)]: {
        width: '100%',
        padding: 7,
      },
    },
    collapse: {
      marginTop: 30,
      marginLeft: 7,
      '&& a': {
        cursor: 'pointer',
      },
    },
    selectbox: {
      width: '80%',
      [breakpoints.down(800)]: {
        width: '100%',
      },
      '&& .MuiSelect-select': {
        [breakpoints.up('sm')]: {
          marginTop: 16,
        },
      },
    },
    invitationTextBlock: {
      marginTop: 20,
      marginLeft: 7,
      '&& p': {
        padding: 24,
        backgroundColor: blueLight,
        fontSize: 18,
      },
    },
  }),
  { name: 'InvitationModal' },
);

export interface ISendProps {
  beloved: IBeloved;
  emails: string[];
}

interface IProps {
  isOpen: boolean;
  type: 'record' | 'playback';
  selectedBeloved: IBeloved;
  belovedList: IBeloved[];
  onSend: (params: ISendProps) => void;
  onClose: () => any;
}

export default function InvitationModal({
  isOpen,
  type,
  selectedBeloved,
  belovedList,
  onSend,
  onClose,
}: IProps) {
  const classes = useStyles();
  const [t] = useTranslation();
  const { user }: IAuthState = useSelector(getAuth);

  const [isCollapsed, setCollapse] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [beloved, setBeloved] = useState<IBeloved>(selectedBeloved);

  const belovedName = (beloved && beloved.name) || '';
  const toggleCollapse = () => {
    setCollapse(!isCollapsed);
  };

  const handleChange = (emails: string[]) => {
    setEmails(emails);
  };

  const isDirty = emails.length > 0;
  const idRecord = type === 'record';

  const userName = `${user.firstname} ${user.lastname}`;
  const belovedCode = idRecord
    ? (beloved && beloved.recordPasscode) || ''
    : (beloved && beloved.playbackPasscode) || '';

  const inviteCode = (beloved && beloved.invitationCode) || '';
  const inviteLink = `${window.location.protocol}//${window.location.host}/${type}?b=${inviteCode}`;

  const invitationText = t(
    idRecord ? 'invitation-record-text' : 'invitation-playback-text',
    {
      user_name: userName,
      beloved_name: belovedName,
      passcode: belovedCode,
      invitation_link: inviteLink,
      phoneList: phoneList
        .map(({ number, title: country }) =>
          t('phone-line', {
            number,
            country,
            interpolation: { escapeValue: false },
          }),
        )
        .join(''),
    },
  )
    .replace(/&#x2F;/g, '/')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');

  const copyButton = () => {
    const text = invitationText
      .replace(/&#x2F;/g, '/')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/<strong>|<\/strong>/g, '')
      .replace(/<span class(.*?)>|<\/span>/g, '')
      .replace(/<a href(.*?)>|<\/a>/g, '')
      .replace(/<br\/>/g, '\n');
    copyToClipBoard(text);
  };

  useEffect(() => {
    setBeloved(selectedBeloved);
  }, [selectedBeloved]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.modal,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography color="textPrimary" component="span" variant="h6">
          {t(
            idRecord
              ? 'share-recording-invitation'
              : 'share-playback-instructions',
          )}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <div className={classes.inputs}>
            <div className={classes.leftSide}>
              <div className={classes.selectbox}>
                <BelovedSelectbox
                  onSelect={setBeloved}
                  selectedBeloved={beloved}
                  belovedList={belovedList}
                />
              </div>
            </div>
            <div className={classes.rightSide}>
              <TagsInput
                email
                value={emails}
                onChange={handleChange}
                placeholder={t('email-tag-input')}
              />
            </div>
          </div>
          <div className={classes.collapse}>
            <Link onClick={toggleCollapse} color="primary">
              {t(isCollapsed ? 'show-invitation' : 'hide-invitation')}
            </Link>
          </div>
          <div className={classes.invitationTextBlock}>
            <Collapse in={!isCollapsed}>
              <Text variant="body2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: invitationText,
                  }}
                />
              </Text>
            </Collapse>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          onClick={copyButton}
          className={classes.copyButton}
          startIcon={<CopyIcon />}
          color="primary"
        >
          {t('copy-text-invitation')}
        </Button>

        <Button
          onClick={() => onSend({ beloved, emails })}
          color="primary"
          className={classes.sendButton}
          variant="contained"
          disabled={!isDirty}
        >
          {t('send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
