import React from 'react';

const Star = (props: any) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 26H26V28H18V26Z" />
    <path d="M18 22H30V24H18V22Z" />
    <path d="M18 18H30V20H18V18Z" />
    <path d="M20.5493 11.217L16.0003 2L11.4513 11.217L1.28027 12.695L8.64027 19.87L6.90227 30L14.0003 26.269V24.009L9.55927 26.344L10.6113 20.208L10.7893 19.171L10.0363 18.438L5.57827 14.091L11.7393 13.196L12.7793 13.045L13.2453 12.102L16.0003 6.519L18.7553 12.102L19.2213 13.045L20.2613 13.196L27.7153 14.281L28.0003 12.3L20.5493 11.217Z" />
  </svg>
);

export default Star;
