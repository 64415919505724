import React from 'react';
import YouTube from 'react-youtube';
import { makeStyles } from '@material-ui/core/styles';
import amplitude from '../utils/analytics';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Text from '@material-ui/core/Typography';
import cx from 'classnames';
import {
  CookieCategory,
  cookieConsentName,
  isCookieCategoryFound,
  saveCookie,
} from '../utils/cookies';
import { useCookies } from 'react-cookie';

interface IVideo {
  videoId: string;
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    videoContainer: {
      position: 'relative',
      paddingBottom: '40.25%' /* 16:9 */,
      width: '75%',
      height: 0,
    },
    video: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    placeholder: {
      position: 'relative',
      paddingBottom: '40.25%',
      width: '75%',
      height: 0,
    },
    overlap: {
      position: 'absolute',
      top: 0,
    },
    image: {
      left: 0,
      top: 0,
      width: '100%',
    },
    consent: {
      left: 0,
      top: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      background: '#353535',
      padding: 10,
      zIndex: 20,
      opacity: 0.9,
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    text: {
      color: 'white',
    },
    button: {
      marginLeft: 10,
      marginRight: 10,
    },
  }),
  { name: 'Video' },
);

const Video = ({ videoId }: IVideo) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [cookies, setCookie] = useCookies([cookieConsentName]);

  const onAccept = () => {
    const allCategories = {};
    const cookie = cookies[cookieConsentName];
    Object.values(CookieCategory).forEach((category) => {
      allCategories[category] =
        category === CookieCategory.Necessary
          ? true
          : cookie !== undefined && isCookieCategoryFound(cookie, category);
    });
    allCategories[CookieCategory.Targeting] = true;
    saveCookie(allCategories, setCookie);
  };

  //youtube-manage-cookies
  const hasConsent = cookies[cookieConsentName] !== undefined;
  const showConsent =
    !hasConsent ||
    !isCookieCategoryFound(
      cookies[cookieConsentName],
      CookieCategory.Targeting,
    );

  return showConsent ? (
    <div className={classes.placeholder}>
      <img
        className={cx(classes.overlap, classes.image)}
        src={`https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`}
        alt={t('youtube-video-image')}
      />
      <div className={cx(classes.overlap, classes.consent)}>
        <Text className={classes.text} variant="body2">
          {hasConsent
            ? t('youtube-accept-cookie')
            : t('youtube-manage-cookies')}
        </Text>
        {hasConsent && (
          <Button
            className={classes.button}
            onClick={onAccept}
            color="primary"
            variant="contained"
          >
            {t('accept')}
          </Button>
        )}
      </div>
    </div>
  ) : (
    <YouTube
      className={classes.video}
      containerClassName={classes.videoContainer}
      videoId={videoId}
      opts={{
        host: 'https://www.youtube-nocookie.com',
      }}
      onPlay={() => amplitude.PLAY_VIDEO({ videoId })}
      onPause={() => amplitude.PAUSE_VIDEO({ videoId })}
      onEnd={() => amplitude.END_VIDEO({ videoId })}
    />
  );
};

export default Video;
