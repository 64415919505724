import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  ({ breakpoints }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    invitationContainer: {
      boxShadow: `1px 4px 12px 1px rgba(26, 67, 70, 0.07)`,
      borderRadius: 10,
      flexGrow: 1,
      height: '100%',
      [breakpoints.up('lg')]: {
        margin: '0 20px',
        padding: '23px 32px',
      },
      padding: '10px 5px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '30px 0 20px 0',
    },
    titleText: {
      paddingLeft: 10,
    },
    ctaText: {
      fontSize: 20,
      paddingBottom: 40,
    },
    emptyFolderPlaceholder: {
      marginTop: 64,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        width: '100%',
        maxWidth: 500,
      },
      [breakpoints.down('md')]: {
        marginTop: 30,
        padding: 40,
        textAlign: 'center',
      },
    },
    loop: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      marginBottom: 20,
      '& $loopLabel, & $loopSwitch': {
        marginTop: 10,
      },
    },
    loopLabel: {
      marginRight: 10,
    },
    loopSwitch: {
      marginRight: 0,
    },
  }),
  { name: 'PlaybackPage' },
);
