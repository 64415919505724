import get from 'lodash/get';

import {
  update as updateFolder,
  add as addFolder,
  fetch as fetchFolders,
  select as setFolder,
} from '../../actions/folders';

import { select as setBeloved } from '../../actions/beloved';

import {
  update as updateMessage,
  fetch as fetchMessages,
  sort,
} from '../../actions/messages';

import { play, stop, download } from '../../actions/player';
import { open, close } from '../../actions/confirmation';

import {
  ISaveMessageAction,
  openRecording,
  closeRecording,
  saveRecording,
} from '../../actions/record';

import { ISelectArgs } from '../../components/CarouselMenu';
import store from '../../store';

import {
  IMessage,
  MessageActions,
  IFolder,
  FolderActions,
  IBeloved,
} from '../../types';

export const changeMessage = (type: MessageActions, message: IMessage) => {
  if (type === 'delete') {
    const deleteAction = updateMessage({ type, message });
    store.dispatch(
      open({
        head: 'sure-delete-message',
        headParams: [
          [
            'strong',
            {
              name: message.description || '',
            },
          ],
        ],
        body: '',
        agreeAction: deleteAction,
        disagreeAction: close(),
      }),
    );
  } else {
    store.dispatch(updateMessage({ type, message }));
  }
};

export const playStopMessage = (playerInfo: any) => ({
  _id,
  recordingId,
}: IMessage) => {
  if (playerInfo.messageId !== _id) {
    store.dispatch(play({ messageId: _id, recordingId }));
  } else {
    store.dispatch(stop());
  }
};

export const downloadMessage = ({
  _id,
  recordingId,
  description,
  createdAt,
}: IMessage) => {
  store.dispatch(
    download({ messageId: _id, recordingId, description, createdAt }),
  );
};

export const saveNewFolder = (setFolderModalOpen: (a: boolean) => any) => (
  name: string,
) => {
  store.dispatch(addFolder(name));
  setFolderModalOpen(false);
};

export const changeFolder = (type: FolderActions, folder: IFolder) => {
  if (type === 'delete') {
    const deleteAction = updateFolder({ type, folder });
    store.dispatch(
      open({
        head: 'sure-delete-album',
        headParams: [
          [
            'strong',
            {
              name: folder.name,
            },
          ],
        ],
        body: 'messages-will-not-disappear',
        agreeAction: deleteAction,
        disagreeAction: close(),
      }),
    );
  } else {
    store.dispatch(updateFolder({ type, folder }));
  }
};

export const selectBeloved = (beloved: IBeloved) => {
  store.dispatch(setBeloved(beloved));
  store.dispatch(fetchMessages(beloved._id));
  store.dispatch(fetchFolders(beloved._id));
};

export const sortMessages = (messages: IMessage[], folder: string) => {
  store.dispatch(sort({ messages, folder }));
};

export const selectFolder = (selector: ISelectArgs) => {
  const id =
    selector.type === 'folder'
      ? get(selector, 'folder._id', 'all')
      : selector.type;
  store.dispatch(setFolder(id));
};

export const openRecordMessageModal = () => {
  store.dispatch(openRecording());
};

export const closeRecordMessageModal = () => {
  store.dispatch(closeRecording());
};

export const saveMessage = (params: ISaveMessageAction) => {
  store.dispatch(saveRecording(params));
};
