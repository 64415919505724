export interface IUser {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export interface IMe {
  createdAt: string;
  email: string;
  firstname: string;
  lastname: string;
  optOutAnalytics: boolean;
  accountRelatedNotifications: boolean;
  allNotifications: boolean;
  verified: boolean;
  _id: string;
}

export interface IProfile extends IMe {
  password?: string;
  passwordConfirm?: string;
}

export interface IAuthData {
  token: string;
  user: IMe;
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface ISignupData extends ILoginData {
  firstname: string;
  lastname: string;
}

export interface IListOrder {
  _id: string;
  orderNumber: number;
}
export interface IFolderListOrder {
  _id: string;
  name: string;
  orderNumber: number;
}

export interface IMessage {
  _id: string;
  recordingId: string;
  recipient: string;
  owner: string;
  ready: boolean;
  createdAt: string;
  listOrder: number;
  favoriteListOrder: number;
  folderListOrder: IFolderListOrder[];
  description: string;
  show: boolean;
  favorite: boolean;
  viewed: boolean;
  playback_count: number;
  duration: string;
}

export interface IFolder {
  _id: string;
  name: string;
  beloved: string;
  contact: string;
  orderNumber: number;
  messages: string[];
  createdAt: string;
  isDeleted?: boolean;
  show: boolean;
}

export interface IBeloved {
  _id: string;
  name: string;
  createdAt: string;
  contact: string;
  invitationCode: number;
  recordPasscode: number;
  playbackPasscode: number;
  isDeleted?: boolean;
  loopCount: number;
  allMessagesHidden: boolean;
  allMessagesOrderNumber: number;
  favoritesHidden: boolean;
  favoritesOrderNumber: number;
}

export interface IDownloadableResource {
  id: string;
  title: string;
  description: string;
  image: any;
  pdf: string;
}

export enum MessageActions {
  showInFolders = 'showInFolders',
  delete = 'delete',
  makeFavorite = 'makeFavorite',
  changeVisibility = 'changeVisibility',
  changeOrder = 'changeOrder',
  changeDescription = 'changeDescription',
}

export enum BelovedActions {
  add = 'add',
  delete = 'delete',
  resetPlaybackCode = 'resetPlaybackCode',
  resetRecordingCode = 'resetRecordingCode',
  editName = 'editName',
}

export enum FolderActions {
  edit = 'edit',
  changeOrder = 'changeOrder',
  delete = 'delete',
  changeVisibility = 'changeVisibility',
}

export enum InviteActions {
  record = 'record',
  playback = 'playback',
}

export type PlayerState = 'inactive' | 'playing' | 'paused';
