import { createAction } from '../utils/actions';
import { IBeloved, InviteActions } from '../types';

export const REQUEST_INVITE_PASSCODE = 'INVITE/REQUEST_PASSCODE';
export const VERIFY_INVITE = 'INVITE/VERIFY';
export const VERIFY_INVITE_SUCCESS = 'INVITE/VERIFY_SUCCESS';
export const VERIFY_INVITE_FAILURE = 'INVITE/VERIFY_FAILURE';

export const types = {
  REQUEST_INVITE_PASSCODE,
  VERIFY_INVITE,
  VERIFY_INVITE_SUCCESS,
  VERIFY_INVITE_FAILURE,
};

export interface IRequestInviteAction {
  type: InviteActions.record | InviteActions.playback;
  invitationCode: number;
}

export interface IVerifyAction {
  type: InviteActions.record | InviteActions.playback;
  invitationCode: number;
  passcode: number;
}

export interface IVerifySuccessAction {
  type: InviteActions.record | InviteActions.playback;
  beloved: IBeloved;
}

export interface IVerifyErrorAction {
  type: InviteActions.record | InviteActions.playback;
  error: string;
}

export const requestInvitePasscode = createAction<IRequestInviteAction>(
  REQUEST_INVITE_PASSCODE,
);
export const verifyInvite = createAction<IVerifyAction>(VERIFY_INVITE);
export const verifyInviteSuccess = createAction<IVerifySuccessAction>(
  VERIFY_INVITE_SUCCESS,
);
export const verifyInviteFailure = createAction<IVerifyErrorAction>(
  VERIFY_INVITE_FAILURE,
);

export default {
  types,
  requestInvitePasscode,
  verifyInvite,
  verifyInviteSuccess,
  verifyInviteFailure,
};
