import React, { useEffect, useState, useRef } from 'react';
import { IBeloved } from '../../../types';

import WaveSurfer from 'wavesurfer.js';
import { Mp3MediaRecorder } from '../../../utils/mp3-mediarecorder';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { IRecordState, getRecordState } from '../../../reducers/record';
import { ISaveMessageAction } from '../../../actions/record';
import { useSelector } from 'react-redux';
import {
  formatTimeForRecording,
  convertSecondsToTime,
} from '../../../utils/helpers';
import { maxRecordingDuration } from '../../../constants';
import { CSSTransition } from 'react-transition-group';

import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Pause from '@material-ui/icons/Pause';
import Stop from '@material-ui/icons/Stop';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';

import { MicrophoneIcon } from '../../icons';
import { RecordPauseIcon } from '../../icons';
import CloseIcon from '@material-ui/icons/Close';

import amplitude from '../../../utils/analytics';

interface IProps {
  isOpen: boolean;
  beloved: IBeloved;
  recordPasscode: number;
  onClose: () => any;
  onSave: (params: ISaveMessageAction) => void;
}

const RecordMessage = ({
  isOpen,
  onClose,
  beloved,
  recordPasscode,
  onSave,
}: IProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { isSavingMessage }: IRecordState = useSelector(getRecordState);
  const [duration, setDuration] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isPausedRecording, setIsPausedRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioReady, setIsAudioReady] = useState(false);

  const canvasRef: any = useRef(null);
  const waveformRef: any = useRef();
  const stream = useRef<MediaStream | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const playerRef = useRef<HTMLAudioElement>(null);
  const recorderRef: any = useRef(null);
  const workerRef: any = useRef(null);
  const blobs = useRef<Blob[]>([]);
  const timerRef: any = useRef(null);
  const audioCtxRef = useRef<AudioContext>();

  const PAUSE_RECORDING_TEXT = isPausedRecording
    ? t('resume-recording')
    : t('pause-recording');
  const STOP_RECORDING_TEXT = t('stop-recording');
  const START_RECORDING_TEXT = t('start-recording');

  useEffect(() => {
    if (duration + 1 >= maxRecordingDuration) {
      handleStopRecording();
    }
  }, [duration]);

  useEffect(() => {
    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!workerRef.current) {
      workerRef.current = new Worker('./js/mp3encoder-worker.js');
    }
    return () => {
      if (workerRef.current) workerRef.current.terminate();
    };
  }, []);

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  const initAudioVisualizer = () => {
    if (!wavesurfer.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        scrollParent: true,
        waveColor: '#CC0000',
        progressColor: '#666666',
      });
      wavesurfer.current.on('ready', function () {
        if (wavesurfer.current) {
          setIsAudioReady(true);
        }
      });
      wavesurfer.current.on('finish', function () {
        if (wavesurfer.current && wavesurfer.current.isPlaying) {
          setIsPlaying(false);
        }
      });
    }
  };

  const initMediaRecorder = async () => {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    if (!audioCtxRef.current) return;
    stream.current = audioStream;

    const source = audioCtxRef.current.createMediaStreamSource(stream.current);
    const analyser = audioCtxRef.current.createAnalyser();
    analyser.fftSize = 2048;
    source.connect(analyser);

    const recorder = new Mp3MediaRecorder(analyser, {
      audioContext: audioCtxRef.current,
      worker: workerRef.current,
    });
    recorderRef.current = recorder;
    recorderRef.current.ondataavailable = (event: any) => {
      if (event.data) blobs.current.push(event.data);
    };
    recorderRef.current.onstop = () => {
      showRecordedAudio();
    };
    recorderRef.current.start();

    visualize(analyser);
  };

  const startTimer = () => {
    if (timerRef.current) return;
    timerRef.current = setInterval(() => {
      setDuration((duration) => duration + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const visualize = (analyser: AnalyserNode) => {
    const canvas = canvasRef.current;
    const canvasCtx = canvasRef.current.getContext('2d');

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = '#f8fcfc';
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(255, 0, 0)';
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

      canvasCtx.beginPath();
      canvasCtx.moveTo(0, HEIGHT / 2);

      let x = 0;
      const sliceWidth = (WIDTH * 1.0) / bufferLength;

      for (const item of dataArray) {
        const v = item / 128.0;
        const y = (v * HEIGHT) / 2;
        canvasCtx.lineTo(x, y);
        x += sliceWidth;
      }
      canvasCtx.lineTo(WIDTH, HEIGHT / 2);
      canvasCtx.stroke();
    };

    draw();
  };

  const showRecordedAudio = () => {
    if (!blobs.current.length) return;
    const blob = new Blob(blobs.current, {
      type: 'audio/mpeg',
    });
    if (playerRef.current && wavesurfer.current) {
      wavesurfer.current.loadBlob(blob);
      playerRef.current.src = URL.createObjectURL(blob);
    }
  };

  const initializeRecording = () => {
    setIsRecording(false);
    setIsPausedRecording(false);
    setIsAudioReady(false);
    setIsPlaying(false);
    setDuration(0);
    blobs.current.length = 0;
    if (wavesurfer.current) {
      wavesurfer.current.empty();
    }
  };

  const handleStartRecording = async () => {
    const audioCtx = new AudioContext();
    audioCtxRef.current = audioCtx;

    initializeRecording();
    setIsRecording(true);
    await initMediaRecorder();
    startTimer();
    amplitude.START_RECORDING({ belovedId: beloved._id });
  };

  const handlePauseRecording = () => {
    if (!isRecording) return;
    if (recorderRef.current) {
      if (isPausedRecording) {
        recorderRef.current.resume();
        startTimer();
      } else {
        recorderRef.current.pause();
        stopTimer();
      }
    }
    isPausedRecording
      ? amplitude.CONTINUE_RECORDING({ belovedId: beloved._id })
      : amplitude.PAUSE_RECORDING({ belovedId: beloved._id });
    setIsPausedRecording((isPausedRecording) => !isPausedRecording);
  };

  const handleStopRecording = () => {
    if (!isRecording) return;
    setIsRecording(false);
    setIsPausedRecording(false);
    stopTimer();
    if (recorderRef.current) {
      recorderRef.current.stop();
      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop());
      }
      amplitude.DONE_RECORDING({ belovedId: beloved._id });
    }
  };

  const handlePlayRecording = () => {
    if (playerRef.current && wavesurfer.current) {
      wavesurfer.current.playPause();
      if (isPlaying) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
      wavesurfer.current.setMute(true);
      if (!isPlaying) {
        amplitude.REVIEW_RECORDING({ belovedId: beloved._id });
      }
      setIsPlaying((isPlaying) => !isPlaying);
    }
  };

  const handleSaveRecording = () => {
    const blob = new Blob(blobs.current, {
      type: 'audio/mpeg',
    });
    onSave({ beloved, recordPasscode, audioData: blob, duration });
  };

  const handleDeleteRecording = () => {
    amplitude.CANCEL_RECORDING({ belovedId: beloved._id });
    initializeRecording();
  };

  const isCloseToMaxDuration = maxRecordingDuration - duration < 15;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.modal,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.root}>
          <Box className={classes.titleContainer}>
            <MicrophoneIcon />
            <Text className={classes.titleText}>
              {t('record-message-for', { name: beloved.name })}
            </Text>
          </Box>
          <Text className={classes.recordingLimit}>
            {t('your-message-can-be-up-to', {
              max_duration: convertSecondsToTime(maxRecordingDuration),
            })}
          </Text>
          {isRecording && (
            <CSSTransition
              in={isRecording}
              timeout={2000}
              classNames={{
                enter: classes.enter,
                enterActive: classes.enterActive,
                exit: classes.exit,
                exitActive: classes.exitActive,
              }}
            >
              <Box className={classes.pauseStopContainer}>
                <IconButton
                  className={cx(classes.recordButton, classes.stopButton)}
                  onClick={handleStopRecording}
                >
                  <Tooltip title={STOP_RECORDING_TEXT}>
                    <Stop
                      className={classes.recordIcon}
                      aria-label={STOP_RECORDING_TEXT}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton
                  className={cx(classes.recordButton, classes.pauseButton)}
                  onClick={handlePauseRecording}
                >
                  <Tooltip title={PAUSE_RECORDING_TEXT}>
                    <Pause
                      className={classes.pauseIcon}
                      aria-label={PAUSE_RECORDING_TEXT}
                    />
                  </Tooltip>
                </IconButton>
              </Box>
            </CSSTransition>
          )}
          {!isRecording && (
            <CSSTransition
              in={!isAudioReady}
              timeout={500}
              classNames={{
                enter: classes.enter,
                enterActive: classes.enterActive,
                exit: classes.exit,
                exitActive: classes.exitActive,
              }}
            >
              <Box
                className={cx(classes.pauseStopContainer, classes.collapse, {
                  [classes.collapseIn]: !isAudioReady,
                  [classes.collapseNotIn]: isAudioReady,
                })}
              >
                <Tooltip title={START_RECORDING_TEXT}>
                  <IconButton
                    className={classes.recordButton}
                    onClick={handleStartRecording}
                  >
                    <RecordPauseIcon
                      active={false}
                      color={'#FFFFFF'}
                      alt={START_RECORDING_TEXT}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </CSSTransition>
          )}
          <Text
            className={cx(classes.duration, {
              [classes.warning]: isRecording && isCloseToMaxDuration,
            })}
          >
            {formatTimeForRecording(duration)}
          </Text>
          <Box className={classes.audioContainer}>
            <canvas
              className={cx(classes.audioPreview, {
                [classes.visible]: isRecording,
                [classes.hidden]: !isRecording,
              })}
              ref={canvasRef}
            ></canvas>
            <div
              className={cx(classes.waveform, {
                [classes.visible]: !isRecording,
                [classes.hidden]: isRecording,
                [classes.show]: isAudioReady,
                [classes.hide]: !isAudioReady,
              })}
              id="waveform"
              ref={(node: HTMLDivElement) => {
                if (node) {
                  waveformRef.current = node;
                  initAudioVisualizer();
                }
              }}
            ></div>
          </Box>
          <audio className={classes.player} ref={playerRef} controls></audio>
          <div className={isAudioReady ? classes.show : classes.hide}>
            <Box className={classes.actions}>
              <Button
                variant="contained"
                className={cx(classes.actionButton, classes.listenButton)}
                onClick={handlePlayRecording}
              >
                {isPlaying ? t('pause') : t('play')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={cx(classes.actionButton)}
                disabled={isSavingMessage}
                onClick={handleSaveRecording}
              >
                {isSavingMessage ? t('sending') : t('send')}
              </Button>
              <Button
                variant="contained"
                className={cx(classes.actionButton, classes.deleteButton)}
                disabled={isSavingMessage}
                onClick={handleDeleteRecording}
              >
                {t('delete')}
              </Button>
            </Box>
          </div>
          <Text className={classes.disclaimer}>
            {t('recording-playback-disclaimer', { name: beloved.name })}
          </Text>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RecordMessage;
