import React from 'react';

interface IProps {
  className?: string;
}

const CloudGear = (props: IProps) => (
  <svg
    {...props}
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="soylsvgfaqquestion"
      d="M8.25 27.4293H25.5L30 32.0007V16.0007C30 14.3246 28.65 12.9531 27 12.9531H8.25C6.6 12.9531 5.25 14.3246 5.25 16.0007V24.3817C5.25 26.0579 6.6 27.4293 8.25 27.4293Z"
    />
    <path
      className="soylsvgfaqtext"
      d="M18.6001 21.9409H15.9001L15.3751 23.5409H13.7251L16.5001 15.9219H17.9251L20.7001 23.5409H19.0501L18.6001 21.9409ZM16.2751 20.7219H18.1501L17.2501 17.8266L16.2751 20.7219Z"
    />
    <path
      className="soylsvgfaqanswer"
      d="M21.75 16H4.5L0 20.5714V3.04762C0 1.37143 1.35 0 3 0H21.75C23.4 0 24.75 1.37143 24.75 3.04762V12.9524C24.75 14.6286 23.4 16 21.75 16Z"
    />
    <path
      className="soylsvgfaqtext"
      d="M16.0497 7.77052C16.0497 8.53242 15.8997 9.14195 15.6747 9.67528C15.4497 10.2086 15.1497 10.6658 14.6997 10.9705L15.9747 11.961L14.9997 12.8753L13.3497 11.58C13.1997 11.58 12.9747 11.6562 12.7497 11.6562C12.2997 11.6562 11.8497 11.58 11.3997 11.4277C11.0247 11.2753 10.6497 10.9705 10.3497 10.6658C10.0497 10.361 9.82471 9.90385 9.67471 9.44671C9.52471 8.98956 9.44971 8.45623 9.44971 7.84671V7.54195C9.44971 6.93242 9.52471 6.39909 9.67471 5.94195C9.82471 5.4848 10.0497 5.02766 10.3497 4.7229C10.6497 4.41814 10.9497 4.11337 11.3997 3.96099C11.7747 3.80861 12.2247 3.73242 12.7497 3.73242C13.1997 3.73242 13.6497 3.80861 14.0997 3.96099C14.4747 4.11337 14.8497 4.41814 15.1497 4.7229C15.4497 5.02766 15.6747 5.4848 15.8247 5.94195C15.9747 6.39909 16.0497 6.93242 16.0497 7.54195V7.77052ZM14.3997 7.38956C14.3997 6.55147 14.2497 5.94195 13.9497 5.56099C13.6497 5.10385 13.2747 4.95147 12.7497 4.95147C12.2247 4.95147 11.7747 5.18004 11.5497 5.56099C11.2497 6.01814 11.0997 6.62766 11.0997 7.38956V7.77052C11.0997 8.15147 11.1747 8.53242 11.2497 8.83718C11.3247 9.14195 11.3997 9.44671 11.5497 9.59909C11.6997 9.82766 11.8497 9.98004 12.0747 10.0562C12.2997 10.1324 12.5247 10.2086 12.7497 10.2086C13.2747 10.2086 13.7247 9.98004 13.9497 9.59909C14.2497 9.14195 14.3997 8.53242 14.3997 7.69433V7.38956Z"
    />
  </svg>
);

export default CloudGear;
