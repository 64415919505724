import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';

import { SettingsPagesType, ISettingsPage } from '../../pages/Settings/Menu';
import useModalStyles from './styles';

const useStyles = makeStyles(({ greenDark, white, mainBlue }) => ({
  modalContent: {
    padding: 0,
  },
  icon: {
    fill: greenDark,
    verticalAlign: 'middle',
    width: 40,
  },
  edit: {
    marginTop: 3,
  },
  active: {
    '&& p': {
      color: white,
    },
    backgroundColor: mainBlue,
    '&& svg': {
      fill: white,
    },
  },
}));

interface IProps {
  isOpen: boolean;
  currentPage: string;
  menuList: ISettingsPage[];
  onSelect: (name: SettingsPagesType) => void;
  onClose: () => any;
}

export default function SettingsMenuModal({
  menuList,
  currentPage,
  isOpen,
  onClose,
  onSelect,
}: IProps) {
  const classes = {
    ...useModalStyles(),
    ...useStyles(),
  };
  const [t] = useTranslation();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography color="textPrimary" variant="h6">
          {t('select-section')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Box>
          {menuList.map(({ name, title, icon: Icon }) => (
            <Box
              key={name}
              display="flex"
              justifyContent="space-between"
              className={cx({
                [classes.active]: name === currentPage,
              })}
            >
              <Box py={1} display="flex" alignItems="center">
                <Box pl={2} mr={2}>
                  <Icon className={classes.icon} color="primary" />
                </Box>
                <Box onClick={() => onSelect(name)}>
                  <Text>{title}</Text>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
