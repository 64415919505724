import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Box from '@material-ui/core/Box';
import Text from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles(
  ({ breakpoints, white, mainBlue, greenDark }) => ({
    sidebarMenu: {
      minWidth: 130,
      height: '100%',
      boxShadow: '0 0 15px #aaa',
      clipPath: 'inset(0px -15px 0px 0px)',
      [breakpoints.down(600)]: {
        minHeight: 130,
        width: '100%',
      },
    },
    sidebarContainer: {
      minHeight: 790,
      paddingTop: 28,
    },
    folder: {
      borderRadius: 2,
      height: 120,
      '& p': {
        marginTop: 5,
        lineHeight: '18px',
      },
    },
    active: {
      '&&': {
        backgroundColor: mainBlue,
      },
      '& p': {
        color: white,
      },
      '&& svg': {
        fill: white,
      },
      '& .carouselMenuLittleButton': {
        display: 'block',
      },
    },
    listItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      background: white,
      '& svg': {
        margin: 5,
        fill: greenDark,
      },
      [breakpoints.down(600)]: {
        width: 150,
      },
      '&:hover': {
        backgroundColor: mainBlue,
        '& p': {
          color: white,
        },
        '& svg': {
          fill: white,
        },
        '& .carouselMenuLittleButton': {
          display: 'block',
        },
      },
    },
  }),
  { name: 'SettingsMenu' },
);

export type SettingsPagesType =
  | 'profile'
  | 'myPlan'
  | 'password'
  | 'notificationPreferences'
  | 'privacy'
  | 'closeAccount';

export interface ISettingsPage {
  name: SettingsPagesType;
  title: string;
  icon: any;
}

export interface IMenuProps {
  currentPage: string;
  menuList: ISettingsPage[];
  onChange: (name: SettingsPagesType) => void;
}

const Menu = ({ menuList, currentPage, onChange }: IMenuProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.sidebarMenu}>
      <div className={classes.sidebarContainer}>
        {menuList.map(({ name, title, icon: Icon }) => (
          <ListItem
            key={title}
            onClick={() => onChange(name)}
            className={cx(classes.listItem, classes.folder, {
              [classes.active]: name === currentPage,
            })}
            button
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Icon style={{ fontSize: 36 }} color="secondary" />
              <Text variant="body2" color="secondary">
                {title}
              </Text>
            </Box>
          </ListItem>
        ))}
      </div>
    </Box>
  );
};

export default Menu;
