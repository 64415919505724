import { createAction, createEmptyAction } from '../utils/actions';

export const VERIFY = 'AUTH/VERIFY';
export const VERIFY_SUCCESS = 'AUTH/VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'AUTH/VERIFY_FAILURE';

export const SEND_CODE = 'AUTH/SEND_VERIFICATION_CODE';
export const SEND_CODE_SUCCESS = 'AUTH/SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_CODE_FAILURE = 'AUTH/SEND_VERIFICATION_CODE_FAILURE';

export const types = {
  VERIFY,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  SEND_CODE,
  SEND_CODE_SUCCESS,
  SEND_CODE_FAILURE,
};

export const verify = createAction<string>(VERIFY);
export const verifySuccess = createEmptyAction(VERIFY_SUCCESS);
export const verifyFailure = createAction<string>(VERIFY_FAILURE);

export const sendCode = createEmptyAction(VERIFY);
export const sendCodeSuccess = createEmptyAction(SEND_CODE_SUCCESS);
export const sendCodeFailure = createAction<string>(SEND_CODE_FAILURE);

export default {
  types,
  verify,
  verifySuccess,
  verifyFailure,
  sendCode,
  sendCodeSuccess,
  sendCodeFailure,
};
