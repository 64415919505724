// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  START_LOADING,
  STOP_LOADING,
  INIT,
  SET_APP_ERROR,
} from '../actions/app';

export interface IApplication {
  isLoading?: boolean;
  isFirstLoading?: boolean;
  error?: Error;
}

interface IState {
  app: IApplication;
}

export const initialState: IApplication = {
  isLoading: false,
  isFirstLoading: true,
  error: undefined,
};

export const getApplication = (state: IState): IApplication => state.app;

export default createReducer(initialState, {
  [START_LOADING]: () => ({ isLoading: true }),
  [STOP_LOADING]: () => ({ isLoading: false }),
  [INIT]: () => ({ isFirstLoading: false }),
  [SET_APP_ERROR]: (error) => ({ error, isLoading: false }),
});
