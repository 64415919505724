/*
 * Based on https://github.com/elsmr/mp3-mediarecorder/
 */

import {
  EventTarget,
  getEventAttributeValue,
  setEventAttributeValue,
} from 'event-target-shim';

export interface Mp3WorkerEncodingConfig {
  sampleRate: number;
}

export enum PostMessageType {
  DATA_AVAILABLE = 'DATA_AVAILABLE',
  START_RECORDING = 'START_RECORDING',
  STOP_RECORDING = 'STOP_RECORDING',
  ERROR = 'ERROR',
  BLOB_READY = 'BLOB_READY',
  WORKER_RECORDING = 'WORKER_RECORDING',
}
export const errorMessage = (error: string) => ({
  type: PostMessageType.ERROR as PostMessageType.ERROR,
  error,
});
export const startRecordingMessage = (config: Mp3WorkerEncodingConfig) => ({
  type: PostMessageType.START_RECORDING as PostMessageType.START_RECORDING,
  config,
});
export const workerRecordingMessage = () => ({
  type: PostMessageType.WORKER_RECORDING as PostMessageType.WORKER_RECORDING,
});

export const dataAvailableMessage = (data: ArrayLike<number>) => ({
  type: PostMessageType.DATA_AVAILABLE as PostMessageType.DATA_AVAILABLE,
  data,
});

export const blobReadyMessage = (blob: Blob) => ({
  type: PostMessageType.BLOB_READY as PostMessageType.BLOB_READY,
  blob,
});
export const stopRecordingMessage = () => ({
  type: PostMessageType.STOP_RECORDING as PostMessageType.STOP_RECORDING,
});

export type WorkerPostMessage = ReturnType<
  | typeof errorMessage
  | typeof startRecordingMessage
  | typeof dataAvailableMessage
  | typeof blobReadyMessage
  | typeof stopRecordingMessage
  | typeof workerRecordingMessage
>;

export interface Mp3MediaRecorderOptions extends MediaRecorderOptions {
  worker: Worker;
  audioContext?: AudioContext;
}

type RecordingState = 'inactive' | 'paused' | 'recording';

const MP3_MIME_TYPE = 'audio/mpeg';
const SafeAudioContext: typeof AudioContext =
  (window as any).AudioContext || (window as any).webkitAudioContext;
const createGain = (ctx: AudioContext) =>
  (ctx.createGain || (ctx as any).createGainNode).call(ctx);
const createScriptProcessor = (ctx: AudioContext) =>
  (ctx.createScriptProcessor || (ctx as any).createJavaScriptNode).call(
    ctx,
    4096,
    1,
    1,
  );

export class Mp3MediaRecorder extends EventTarget {
  sourceNode: AudioNode;
  mimeType = MP3_MIME_TYPE;
  state: RecordingState = 'inactive';
  audioBitsPerSecond = 0;
  videoBitsPerSecond = 0;

  private audioContext: AudioContext;
  private gainNode: GainNode;
  private processorNode: ScriptProcessorNode;
  private worker: Worker;
  private isInternalAudioContext = false;

  static isTypeSupported = (mimeType: string) => mimeType === MP3_MIME_TYPE;

  get onstart() {
    return getEventAttributeValue(this, 'start');
  }
  set onstart(value) {
    setEventAttributeValue(this, 'start', value);
  }

  get onstop() {
    return getEventAttributeValue(this, 'stop');
  }
  set onstop(value) {
    setEventAttributeValue(this, 'stop', value);
  }

  get onpause() {
    return getEventAttributeValue(this, 'pause');
  }
  set onpause(value) {
    setEventAttributeValue(this, 'pause', value);
  }
  get onresume() {
    return getEventAttributeValue(this, 'resume');
  }
  set onresume(value) {
    setEventAttributeValue(this, 'resume', value);
  }

  get ondataavailable() {
    return getEventAttributeValue(this, 'dataavailable');
  }
  set ondataavailable(value) {
    setEventAttributeValue(this, 'dataavailable', value);
  }

  get onerror() {
    return getEventAttributeValue(this, 'error');
  }
  set onerror(value) {
    setEventAttributeValue(this, 'error', value);
  }

  constructor(
    sourceNode: AudioNode,
    { audioContext, worker }: Mp3MediaRecorderOptions,
  ) {
    super();

    if (!worker) {
      throw new Error('No worker provided in Mp3MediaRecorder constructor.');
    }
    this.sourceNode = sourceNode;
    this.isInternalAudioContext = !audioContext;
    this.audioContext = audioContext || new SafeAudioContext();
    this.worker = worker;
    this.gainNode = createGain(this.audioContext);
    this.gainNode.gain.value = 1;
    this.processorNode = createScriptProcessor(this.audioContext);
    sourceNode.connect(this.gainNode);
    this.gainNode.connect(this.processorNode);
    this.worker.onmessage = this.onWorkerMessage;
  }

  start(): void {
    if (this.state !== 'inactive') {
      throw this.getStateError('start');
    }
    this.processorNode.onaudioprocess = (event) => {
      this.worker.postMessage(
        dataAvailableMessage(event.inputBuffer.getChannelData(0)),
      );
    };
    this.processorNode.connect(this.audioContext.destination);
    if (this.audioContext.state === 'closed') {
      this.audioContext = new AudioContext();
    } else if (this.audioContext.state === 'suspended') {
      this.audioContext.resume();
    }
    this.worker.postMessage(
      startRecordingMessage({ sampleRate: this.audioContext.sampleRate }),
    );
  }

  stop(): void {
    if (this.state === 'inactive') {
      throw this.getStateError('stop');
    }
    this.processorNode.disconnect();
    if (this.isInternalAudioContext) {
      this.audioContext.close();
    }
    this.worker.postMessage(stopRecordingMessage());
  }

  pause(): void {
    if (this.state === 'inactive') {
      throw this.getStateError('pause');
    }
    this.audioContext.suspend().then(() => {
      this.state = 'paused';
      this.dispatchEvent(new Event('pause'));
    });
  }

  resume(): void {
    if (this.state === 'inactive') {
      throw this.getStateError('resume');
    }
    this.audioContext.resume().then(() => {
      this.state = 'recording';
      this.dispatchEvent(new Event('resume'));
    });
  }

  requestData(): void {
    // not implemented, dataavailable event only fires when encoding is finished
  }

  private getStateError(method: string) {
    return new Error(
      `Failed to execute '${method}' on 'MediaRecorder': The MediaRecorder's state is '${this.state}'.`,
    );
  }

  private onWorkerMessage = (event: MessageEvent): void => {
    const message: WorkerPostMessage = event.data;

    switch (message.type) {
      case PostMessageType.WORKER_RECORDING: {
        const event = new Event('start');
        this.dispatchEvent(event);
        this.state = 'recording';
        break;
      }
      case PostMessageType.ERROR: {
        const error = new Error(message.error) as DOMException;
        const errEvent = new Event('error');
        (errEvent as any).error = error;
        this.dispatchEvent(errEvent);
        this.state = 'inactive';
        break;
      }
      case PostMessageType.BLOB_READY: {
        const stopEvent = new Event('stop');
        const fallbackDataEvent = new Event('dataavailable');
        (fallbackDataEvent as any).data = message.blob;
        (fallbackDataEvent as any).timecode = Date.now();
        const dataEvent = window.BlobEvent
          ? new BlobEvent('dataavailable', {
              data: message.blob,
              timecode: Date.now(),
            })
          : fallbackDataEvent;
        this.dispatchEvent(dataEvent);
        this.dispatchEvent(stopEvent);
        this.state = 'inactive';
        break;
      }
      case PostMessageType.ERROR: {
        throw new Error(message.error);
      }
    }
  };
}
