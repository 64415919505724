import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    greenGrey,
    white,
    mainBlue,
    darkGreen,
    greenDark,
    blueLight,
  }) => ({
    root: {
      maxHeight: 720,
      padding: '0 10px',
      [breakpoints.down(500)]: {
        width: '100%',
      },
    },
    formControl: {
      margin: spacing(1),
      minWidth: 320,
      width: '100%',
      '&& .Mui-focused': {
        color: greenGrey,
        backgroundColor: white,
      },
    },
    label: {
      color: greenGrey,
      fontSize: 18,
    },
    select: {
      '& .MuiSelect-select:focus': {
        backgroundColor: white,
        color: mainBlue,
      },
      '& .MuiSelect-select': {
        fontSize: 18,
        color: darkGreen,
      },
      '&& svg': {
        fill: darkGreen,
      },
    },
    active: {
      '&&': {
        color: greenDark,
        background: blueLight,
      },
    },
    menuItem: {
      color: darkGreen,
      fontSize: 18,
      '&&:hover': {
        color: white,
        background: mainBlue,
      },
    },
  }),
  { name: 'Selectbox' },
);

export interface ISelectboxItem {
  label: string;
  value: string;
}

interface ISelectboxProps {
  label?: string;
  defaultValue?: ISelectboxItem;
  items: ISelectboxItem[];
  onSelect: (item: ISelectboxItem) => any;
}

export default function Selectbox({
  label,
  defaultValue,
  items,
  onSelect,
}: ISelectboxProps) {
  const classes = useStyles();
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    const item = find(items, { value });
    setSelected(item as ISelectboxItem);
    onSelect(item as ISelectboxItem);
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} id="select-loved-one-label">
          {label}
        </InputLabel>
        <Select
          className={classes.select}
          labelId={`${label}-label`}
          id={label}
          value={selected && selected.value}
          onChange={handleChange}
        >
          {items.map((item: ISelectboxItem) => (
            <MenuItem
              key={item.value}
              className={cx(classes.menuItem, {
                [classes.active]: selected && selected.value === item.value,
              })}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
