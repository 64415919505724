import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Text from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import cx from 'classnames';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { Grid, Paper } from '@material-ui/core';

import {
  update,
  add,
  sendInvitation,
  skipInvitation,
  skipRecording,
  confirmRecording,
} from '../../actions/beloved';
import { open, close } from '../../actions/confirmation';
import {
  ISaveMessageAction,
  openRecording,
  closeRecording,
  saveRecording,
} from '../../actions/record';

import {
  getBelovedState,
  IBelovedState,
  BelovedOnboarding,
} from '../../reducers/beloved';
import { getRecordState } from '../../reducers/record';

import BelovedTable from '../../components/BelovedTable/index';
import BelovedTableRow from '../../components/BelovedTable/Row';
import CloudGearIcon from '../../components/icons/CloudGear';
import PageContainer from '../../components/PageContainer';
import AddEditEntityName from '../../components/modal/AddEditEntityName';
import AddEditEntityNameInline from '../../components/inline/AddEditEntityName';
import InvitationModal, { ISendProps } from '../../components/modal/Invitation';
import RecordMessageModal from '../../components/modal/RecordMessage';
import BelovedOnboard from '../../components/BelovedOnboard';

import { phoneList } from '../../constants';

import { BelovedActions, IBeloved, InviteActions } from '../../types.d';
import useStyles from './styles';

const Setup = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  //====================REDUX SELECTORS=====================
  const { list: belovedList, onboarding }: IBelovedState = useSelector(
    getBelovedState,
  );

  const {
    isOpen: isRecordingModalOpen,
    keyCount: recordingKeyCount,
  } = useSelector(getRecordState);

  const isShowingBelovedList = belovedList.length > 0 && onboarding === null;

  const [isRecordInvitationModalOpen, setRecordInvitationModalOpen] = useState(
    false,
  );
  const [
    isPlaybackInvitationModalOpen,
    setPlaybackInvitationModalOpen,
  ] = useState(false);
  const [isEditNameModalOpen, setEditModal] = useState(false);
  const [isNewBelovedModalOpen, setNewBelovedModal] = useState(false);
  const [selectedBeloved, setBeloved] = useState<Partial<IBeloved>>({});

  const onboardingBeloved =
    onboarding && belovedList.length > 0
      ? belovedList[belovedList.length - 1]
      : null;

  const showInvite = (type: InviteActions, beloved: IBeloved) => {
    setBeloved(beloved);
    if (type === InviteActions.record) {
      toggleRecordInvitationModal();
    } else {
      togglePlaybackInvitationModal();
    }
  };

  const change = (type: BelovedActions, beloved: IBeloved) => {
    const deleteOrResetAction = update({ type, beloved });
    const confirmSharedParams = {
      bodyParams: [['strong', { name: beloved.name }]],
      agreeAction: deleteOrResetAction,
      disagreeAction: close(),
    };

    switch (type) {
      case 'delete': {
        return dispatch(
          open({
            ...confirmSharedParams,
            head: 'sure-delete-beloved',
            body: 'delete-beloved-confirmation-info',
            bodyParams: [['strong', { name: beloved.name }]],
          }),
        );
      }
      case 'resetPlaybackCode': {
        return dispatch(
          open({
            ...confirmSharedParams,
            head: 'passcode-reset-confirmation',
            body: 'playback-passcode-reset-confirmation-info',
          }),
        );
      }
      case 'resetRecordingCode': {
        return dispatch(
          open({
            ...confirmSharedParams,
            head: 'passcode-reset-confirmation',
            body: 'recording-passcode-reset-confirmation-info',
          }),
        );
      }
      case 'editName': {
        setBeloved(beloved);
        setEditModal(true);
      }
    }
    return null;
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const closeNewBelovedModal = () => {
    setNewBelovedModal(false);
  };

  const saveBelovedName = (name: string) => {
    dispatch(
      update({
        type: BelovedActions.editName,
        beloved: { ...selectedBeloved, name } as IBeloved,
      }),
    );
    closeEditModal();
  };

  const saveNewbeloved = (name: string) => {
    dispatch(add(name));
    closeNewBelovedModal();
  };

  const skipRecordingTestMessage = () => {
    dispatch(skipRecording());
  };

  const handleConfirmRecording = () => {
    dispatch(confirmRecording());
  };

  const toggleRecordInvitationModal = () => {
    setRecordInvitationModalOpen(!isRecordInvitationModalOpen);
  };

  const togglePlaybackInvitationModal = () => {
    setPlaybackInvitationModalOpen(!isPlaybackInvitationModalOpen);
  };

  const sendRecordInvitation = ({ beloved, emails }: ISendProps) => {
    dispatch(sendInvitation({ beloved, emails, type: 'record' }));
    toggleRecordInvitationModal();
  };

  const sendPlaybackInvitation = ({ beloved, emails }: ISendProps) => {
    dispatch(sendInvitation({ beloved, emails, type: 'playback' }));
    togglePlaybackInvitationModal();
  };

  const skipSendingInvitation = (type: InviteActions) => {
    dispatch(skipInvitation({ type }));
  };

  const saveMessage = (params: ISaveMessageAction) => {
    dispatch(saveRecording(params));
  };

  const handleRecordingModelOpen = () => {
    dispatch(openRecording());
  };

  const handleRecordingModelClose = () => {
    dispatch(closeRecording());
  };

  const lovedOnesList = (
    <>
      <Grid xs={12} item>
        <Box mb={3} px={1} display="flex" justifyContent="space-between">
          <Text style={{ fontWeight: 600, padding: 5 }} variant="body1">
            {t('my-loved-ones')}
          </Text>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setNewBelovedModal(true)}
            color="primary"
          >
            {t('add-loved-one')}
          </Button>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box px={1} className={cx(classes.description)}>
          <Text variant="body2" className={classes.descriptionText}>
            {t('loved-one-template-hint')}
          </Text>
        </Box>
        <Box px={1} className={cx(classes.description)}>
          <Text variant="body2" className={classes.descriptionText}>
            {t('my-loved-ones-description')}
          </Text>
        </Box>
      </Grid>
      <Grid item>
        <Box className={cx(classes.table)}>
          <BelovedTable>
            {belovedList.map((beloved: IBeloved) => (
              <BelovedTableRow
                key={beloved.name}
                beloved={beloved}
                onChange={change}
                onInvite={showInvite}
              />
            ))}
          </BelovedTable>
          <AddEditEntityName
            entity="name"
            isOpen={isEditNameModalOpen}
            name={selectedBeloved.name}
            onClose={closeEditModal}
            onSave={saveBelovedName}
          />
        </Box>
      </Grid>
    </>
  );

  const onboardingAddLovedOne = (
    <Grid xs={12} item>
      <Box px={1}>
        <Text style={{ fontWeight: 600, paddingBottom: 5 }} variant="body1">
          {t('add-loved-one')}
        </Text>
        <Text variant="body2">{t('no-loved-one-message')}</Text>
      </Box>
      <Box py={3}>
        <AddEditEntityNameInline
          entity="beloved"
          placeholder={t('name-loved-one')}
          onSave={saveNewbeloved}
        />
      </Box>
    </Grid>
  );

  const onboardingRecordMessage = (
    <BelovedOnboard
      title={t('record-test-message')}
      message={t('record-test-message-instructions')}
      disclaimer={t('record-message-later')}
      presentButton={t('record-message')}
      onPresent={handleRecordingModelOpen}
      dismissButton={t('skip')}
      onDismiss={skipRecordingTestMessage}
    />
  );

  const onboardingConfirmRecording = (
    <Grid xs={12} item>
      <Box px={1}>
        <Text style={{ fontWeight: 600, paddingBottom: 5 }} variant="body1">
          {t('playback-message')}
        </Text>
        <Text style={{ paddingBottom: 8 }} variant="body2">
          {t('playback-message-instructions')}
        </Text>
        <Text variant="body2">{t('call-number-your-location')}</Text>
        <Box pb={2}>
          <List dense={true}>
            {phoneList.map(({ title, number }) => (
              <Box key={title}>
                <ListItem>
                  <ListItemText
                    className={classes.phoneItemText}
                    primary={title}
                    secondary={number}
                  />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Box>
        <Text variant="body2">
          {t('when-asked-passcode-enter')}{' '}
          <span className={classes.recording}>
            {onboardingBeloved ? onboardingBeloved.playbackPasscode : ''}
          </span>
        </Text>
      </Box>
      <Box py={3} className={classes.actionSkipButtons}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleConfirmRecording}
        >
          {t('next')}
        </Button>
      </Box>
      <Box px={1} display="flex" justifyContent="center">
        <Text component="i" className={classes.later}>
          {t('playback-message-later')}
        </Text>
      </Box>
    </Grid>
  );

  const onboardingShareRecordingInvitation = (
    <BelovedOnboard
      title={t('share-recording-invitation')}
      message={t('share-recording-invitation-message')}
      disclaimer={t('share-invitation-later')}
      presentButton={t('invitation-to-record')}
      onPresent={toggleRecordInvitationModal}
      dismissButton={t('skip')}
      onDismiss={() => skipSendingInvitation(InviteActions.record)}
    />
  );

  const onboardingSharePlaybackInstructions = (
    <BelovedOnboard
      title={t('share-playback-instructions')}
      message={t('share-playback-instructions-message')}
      disclaimer={t('share-instructions-later')}
      presentButton={t('invitation-to-playback')}
      onPresent={togglePlaybackInvitationModal}
      dismissButton={t('skip')}
      onDismiss={() => skipSendingInvitation(InviteActions.playback)}
    />
  );

  const BelovedContent = () => {
    if (isShowingBelovedList) {
      return lovedOnesList;
    }
    switch (onboarding) {
      case BelovedOnboarding.sendRecordingInvitation:
        return onboardingShareRecordingInvitation;
      case BelovedOnboarding.sendPlaybackInstruction:
        return onboardingSharePlaybackInstructions;
      case BelovedOnboarding.recordMessage:
        return onboardingRecordMessage;
      case BelovedOnboarding.recordingConfirmation:
        return onboardingConfirmRecording;
      default:
        return onboardingAddLovedOne;
    }
  };

  const belovedToInvite =
    (onboarding && belovedList[belovedList.length - 1]) ||
    (selectedBeloved as IBeloved);

  return (
    <PageContainer>
      <InvitationModal
        type="record"
        isOpen={isRecordInvitationModalOpen}
        selectedBeloved={belovedToInvite}
        belovedList={belovedList}
        onClose={toggleRecordInvitationModal}
        onSend={sendRecordInvitation}
      />
      <InvitationModal
        type="playback"
        isOpen={isPlaybackInvitationModalOpen}
        selectedBeloved={belovedToInvite}
        belovedList={belovedList}
        onClose={togglePlaybackInvitationModal}
        onSend={sendPlaybackInvitation}
      />
      <AddEditEntityName
        entity="beloved"
        placeholder={t('name-loved-one')}
        isOpen={isNewBelovedModalOpen}
        onClose={closeNewBelovedModal}
        onSave={saveNewbeloved}
      />
      {onboardingBeloved && (
        <RecordMessageModal
          isOpen={isRecordingModalOpen}
          beloved={onboardingBeloved}
          recordPasscode={onboardingBeloved.recordPasscode}
          onClose={handleRecordingModelClose}
          onSave={saveMessage}
          key={recordingKeyCount}
        />
      )}
      <Box className={classes.root}>
        <Grid container direction="column">
          <Grid container item>
            <Grid lg={6} md={6} xs={12} item>
              <Box py={5} px={2} className={classes.title}>
                <CloudGearIcon />
                <Text component="h1" variant="h1">
                  {t('manage-loved-ones')}
                </Text>
              </Box>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid lg={8} md={7} sm={12} item className={classes.emptyPrompt}>
              <Paper className={cx(classes.content)}>
                <Grid container direction="column">
                  <BelovedContent />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Setup;
